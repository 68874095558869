<template>
  <div>
    <CustomCard :cardShow="true">
      <template v-slot:toolbar>
          <div style="width:100%;">
            <div style="float:left;">
                <h4>  DATA TRANSAKSI MANUAL</h4>
            </div>
            <div style="float:right;">
              <v-btn
                color="success"
                small
                outlined
                @click="$store.commit('transaction/setMemberPage', 'list')">
                Reset
              </v-btn>
            </div>
          </div>
      </template>

      <template v-slot:content>
        <v-tabs
          v-model="tab"
          align-with-title
          style="width:100vw"
        >

          <v-tab
            v-for="item in items"
            :key="item"
            style="width:50vw"
          >
            {{ item }}
          </v-tab>

      </v-tabs>
      

      <v-tabs-items v-model="tab">
        <v-tab-item
            key="1"
            style="padding:40px 50px;"
        >
            <ViewPembelian></ViewPembelian>
        </v-tab-item>
        <v-tab-item 
        key="2"
        style="padding:40px 50px;">
            <ViewPenjualan></ViewPenjualan>
        </v-tab-item>
      </v-tabs-items>

    </template>
    
    </CustomCard>
  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";
import ViewPembelian from "./component/pembelian/index.vue";
import ViewPenjualan from "./component/penjualan/index.vue";

export default {
  data() {
    return {
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      isDisabled:false,
      details:[],
      dinDetailsIp:null,
      dinDetailsDin:null,
      dinDetailsLog:null,
      isSubmits:false,
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      loading: false,
      loadingText: "Loading Data...",
      tab:null,
      items: [
        'Trx Pembelian', 'Trx Penjualan'
      ],
      searchForm:{

      }
    };
  },
  methods: {
    fetch() {
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      this.isSubmits = true;

      this.$store
          .dispatch("transaction/manualtrx", {
              ipaymuid:this.searchForm.member
          })
          .then(() => {
            this.$store.commit("main/setLoading", false);
            this.loading = false;
            this.loadingtext = '';

            this.details        = this.$store.state.transaction.member.manualList;
            this.dinDetailsIp   = this.$store.state.transaction.member.dinmanualList;
            this.dinDetailsDin  = this.$store.state.transaction.member.dinTrxDinList;
            this.dinDetailsLog  = this.$store.state.transaction.member.dinTrxDinLog;

            if(this.dinDetailsIp == null){
              this.isDisabled = true;
            }

          })
          .catch((res) => {
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
            this.loading = false;
            this.loadingtext = '';
      });

    },
    clear(){
        this.details        = null;
        this.dinDetailsIp   = null;
        this.dinDetailsDin  = null;
        this.dinDetailsLog  = null; 

        this.isDisabled = false;
    },
    addTrans(){
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      this.$store
          .dispatch("transaction/postTrxManual", {
              ref_id:this.details.ReferenceId,
              transaksi:JSON.stringify(this.dinDetailsLog),
              ipaymu:JSON.stringify(this.details)
          })
          .then(() => {
            this.$store.commit("main/setLoading", false);
            this.loading = false;
            this.loadingtext = '';

          // console.log("lengthMax" , this.dinDetailsIp);
            if(this.dinDetailsIp == null){
              this.isDisabled = false;
            }

          })
          .catch((res) => {
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
            this.loading = false;
            this.loadingtext = '';
      });
          
    }
  },
  mounted() {
   // alert("tes");
   //this.getRates("");
   this.details = this.$store.state.transaction.member.manualList;
   this.clear();
   // console.log("items" , this.$store.state.transaction.member.detail);
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        //if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            
            //this.fetch()
        //}
        //if(this.$store.state.member.member.is_back == 1){
        //this.pagination.page = this.$store.state.member.member.paging_page;
        //}

       // this.$store.state.member.member.is_back = 0;
    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard , ViewPembelian , ViewPenjualan },
};

</script>

<style>
</style>