<template>
    <div>
      <v-container grid-list-xs fluid>
        <List v-if="$store.state.member.member.pageCorpList == 'list'"></List>
        <Detail v-if="$store.state.member.member.pageCorpList == 'detail'"></Detail>
        <Form v-if="$store.state.member.member.pageCorpList == 'form'"></Form>
      </v-container>
    </div>
  </template>
  
  <script>
  import List from "./corporate/list.vue";
  import Detail from "./corporate/detail.vue";
  import Form from "./corporate/newform.vue";
  export default { 
    data() {
      return {
        pages:"list",
        profiles:JSON.parse(localStorage.getItem("din-dashboard-profile")),
      }
      },
      mounted() {
        let segments      = window.location.toString();
        segments = segments.replace("http://","");
        segments = segments.replace("https://","");
  
        let segmentakhir = segments.split("/");
  
        let lastSegment = segmentakhir[(segmentakhir.length)-1];
  
        this.$store
            .dispatch("user/cekAkses", {
                segment: lastSegment,
                user: this.profiles.id,
            })
            .then(() => {
              if(this.$store.state.user.cekAkses <= 0){
                  window.location = "/";
              }
            })
            .catch((res) => {
              window.location = "/";
              console.log(res);
        });
  
        this.$store.commit("member/setMemberCorpPage", "list");
      },
      components: { List, Detail , Form } 
    };
  </script>
  
  <style>
  
  </style>