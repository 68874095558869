<template>
  <div>
    <v-container grid-list-xs fluid>
      <List></List>
      <Detail v-if="$store.state.affiliate.user.page == 'detail'"></Detail>
    </v-container>
  </div>
</template>

<script>
import List from "./user/list.vue";
import Detail from "./user/detail.vue";
export default { 
data() {
    return {
      pages:"list"
    }
    },
    mounted() {
      this.$store.commit("affiliate/setAffiliatesPages", "list");
    },
    components: { List, Detail } 
};
</script>

<style>

</style>