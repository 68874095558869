const namespaced = true

const defaultState = () => {
    return {
        list: [],
        detail: {},
        pagination: {},
        search: {},
        total: 0,
        page: 'list',
        dialog: {}
    }
}

const state = defaultState()
const mutations = {}
const actions = {}

export default {
    namespaced, state, mutations, actions
}