<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <CustomCard>

        <template v-slot:toolbar>

            <div style="width:100%;">

                <v-btn color="success" small outlined @click="filterToggle" style="float:left;">
                    filter <v-icon>mdi-chevron-down</v-icon>
                </v-btn>

                <v-dialog
                            transition="dialog-top-transition"
                            max-width="600">
                            <!-- <template v-slot:activator="{ on, attrs }">
                                <v-btn color="success" small outlined  style="float:right;">
                                    ADD RESELLER / AFFILIATE EKSLUSIF / AFFILIATE COMMUNITY
                                </v-btn>
                            </template> -->
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="success" small outlined  style="float:right;" 
                                    v-bind="attrs"
                                    v-on="on" 
                                    @click="clearall()">
                                    ADD REWARD AFFILIATE
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                color="success"
                                dark
                                >ADD REWARD AFFILIATE</v-toolbar>

                                <v-container>
                                    <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.referral_code"
                                                label="Referral Code">
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.token_alias"
                                                label="Referral Alias">
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout>

                                        <v-flex>
                                            <v-autocomplete
                                                v-model="formLock.action_pembelian"
                                                :items="tipereseller"
                                                item-text="text"
                                                item-value="value"
                                                label="Action Pemberian Reward Saat">
                                            </v-autocomplete>
                                            <div style="color:red; font-size:12px;  margin-top:-18px;">
                                                saat ini pemberian reward pada action register saja
                                            </div>
                                        </v-flex>

                                        <v-spacer></v-spacer>

                                        <v-flex>
                                            <v-autocomplete
                                                v-model="formLock.jenis_pemberian"
                                                :items="reward"
                                                item-text="text"
                                                item-value="value"
                                                label="Jenis Pemberian Reward Berupa">
                                            </v-autocomplete>
                                            <div style="color:red; font-size:12px; margin-top:-18px;">
                                                saat ini pemberian reward berupa balance saja
                                            </div>
                                        </v-flex>

                                    </v-layout>
                                    
                                    <v-layout style="margin-top:20px;">

                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.reward_amount"
                                                label="Reward Amount">
                                            </v-text-field>
                                        </v-flex>

                                        <v-spacer></v-spacer>

                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.limit_reward"
                                                label="Limit Reward">
                                            </v-text-field>
                                        </v-flex>

                                    </v-layout>


                                    <v-layout>

                                        <v-flex>
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                :return-value.sync="date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="searchForm.date"
                                                    label="Start Periode"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="searchForm.date"
                                                no-title
                                                scrollable
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.menu.save(date)"
                                                >
                                                    OK
                                                </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>

                                        <v-spacer></v-spacer>

                                        <v-flex>
                                            <v-menu
                                                ref="menu"
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :return-value.sync="date2"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">

                                                <v-text-field
                                                    v-model="searchForm.date2"
                                                    label="End Periode"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on">
                                                </v-text-field>

                                                </template>
                                                <v-date-picker
                                                v-model="searchForm.date2"
                                                no-title
                                                scrollable
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.menu.save(date2)"
                                                >
                                                    OK
                                                </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>

                                    </v-layout>

                                    <v-layout>

                                        <v-textarea
                                            outlined
                                            v-model="formLock.textarea_note"
                                            label="Note">
                                        </v-textarea>

                                    </v-layout>

                                </v-container>

                                <v-card-actions class="justify-end">
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="success"
                                    @click="postAffiliateReward(dialog)"
                                >Submit</v-btn>
                                </v-card-actions>
                            </v-card>
                            </template>
                </v-dialog>

            </div>

        </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.ref_code"
                                label="Search By Referral Code"
                                single-line
                                small
                                clearable
                            >
                            </v-text-field>
                        </v-flex>

                        <!-- <v-flex>
                            <v-autocomplete
                                v-model="searchForm.tipereseller"
                                :items="tipereseller"
                                item-text="text"
                                item-value="value"
                                label="Type"
                            >
                            </v-autocomplete>
                        </v-flex> -->
                    
                </v-layout>

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">
            <v-data-table
                :headers="headers"
                :items="$store.state.affiliate.reward.list"
                :options.sync="pagination"
                :server-items-length="$store.state.affiliate.reward.total"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

            <template v-slot:[`item.member`]="{ item }">
                    <div> <b> {{ item.member.name }} </b> <br /> {{ item.member.email }}  <br />  {{ item.member.phone }}  </div>
                    <!-- <div v-bind:id="item.id" class="hidden sub-items-tables"> {{ item.format_id }} <br /> {{ item.phone }} <br /> {{ item.religion }} <br /> Birthdate : {{ item.date_of_birth }}</div> -->
            </template>

            <template v-slot:[`item.member_reward`]="{ item }">
                    <div> Action Reward : <b> {{ item.action }} </b> <br /> Jenis Reward : <b> {{ item.reward }} </b>  <br /> Amount Reward ( IDR ) : {{ item.reward_amount }}  </div>
                    <!-- <div> <small> TRX </small> </div> -->
            </template>

            <template v-slot:[`item.periode_reward`]="{ item }">
                    <div>  {{ item.periode_awal }}  <br /> <b> s/d </b> <br /> {{ item.periode_akhir }}  </div>
            </template>

            <template v-slot:[`item.statusaktif`]="{ item }">
                    <div v-if="item.status == 0" style="color:red"> <v-icon color="red"> mdi-block-helper </v-icon> Not Active </div>
                    <div v-if="item.status == 1" style="color:green"> <v-icon color="green"> mdi-check </v-icon> Active  </div>
            </template>

            <!-- <template v-slot:[`item.expiredyear`]="{ item }">
                    <div>  {{ item.expired }} Tahun </div>
            </template>

            <template v-slot:[`item.bal_gramasi`]="{ item }">
                    <div>  <b> {{ item.bal_gramasi }} </b> <br /> </div>
                    <div style="margin-top:-5px;"> <small> {{ item.real_gramasi }} </small> </div>
                    <div style="margin-top:-5px;"> <small> GR </small> </div>
            </template>

            <template v-slot:[`item.lock_balance`]="{ item }">
                <div style="text-align:right;">
                    <div> <small>  Emas Impian <v-icon small @click="showExpand($event,item.id)">mdi-chevron-right</v-icon> <b> {{ item.lock_emasimpian }} </b> </small> </div>
                    <div style="margin-top:-5px;"> <small>  Dinaran <v-icon small @click="showExpand($event,item.id)">mdi-chevron-right</v-icon> <b> {{ item.lock_dinaran }} </b> </small> </div>
                    <div style="margin-top:-5px;"> <small>  User <v-icon small @click="showExpand($event,item.id)">mdi-chevron-right</v-icon> <b> {{ item.lock_user }} </b> </small> </div>
                </div>
            </template> -->

            <!-- <template v-slot:[`item.verified_date`]="{ item }">
                    <div>  {{ item.verified_at }} </div>
                    <div style="margin-top:-5px;"> <small> {{ item.verified_time }} </small> </div> -->
                    <!-- <div style="margin-top:-5px;"> <small> {{ item.real_gramasi }} </small> </div>
                    <div style="margin-top:-5px;"> <small> GR </small> </div> -->
            <!-- </template> -->

            <!-- <template v-slot:[`item.real_gramasi`]="{ item }">
                    <div>  <b> {{ item.real_gramasi }} </b> <br /> </div>
                    <div> <small> GR </small> </div>
            </template> -->

            
            <template v-slot:[`item.em_verified`]="{ item }">
                <div style="text-align:center;">
                    <div v-if="item.email_verified == 0" style="color:red;"> 
                        <v-icon small color="red">mdi-block-helper</v-icon> 
                        <div style="margin-top:-5px;"> <small> Not Verified </small> </div>
                    </div>
                    <div v-if="item.email_verified == 1" style="color:green;"> 
                        <v-icon small color="green">mdi-check</v-icon> 
                        <div style="margin-top:-5px;"> <small>  Verified </small> </div>
                    </div>
               </div>
            </template>

             <template v-slot:[`item.verified`]="{ item }">

                <div style="text-align:center;">

                    <div v-if="item.is_block == false" style="color:red;"> 

                        <div v-if="item.verified == 0" style="color:red;"> 
                            <v-icon small color="red">mdi-block-helper</v-icon> 
                            <div style="margin-top:-5px;"> <small> Not Verified </small> </div>
                        </div>
                        <div v-if="item.verified == 1" style="color:green;"> 
                            <v-icon small color="green">mdi-check</v-icon> 
                            <div style="margin-top:-5px;"> <small>  Verified </small> </div>
                        </div>

                    </div>

                    <div v-if="item.is_block == true" style="color:red;"> 
                            <!-- <div v-if="item.verified == 0" style="color:red;">  -->
                            <v-icon small color="red">mdi-block-helper</v-icon> 
                            <div style="margin-top:-5px;"> <small> Suspend </small> </div>
                        <!-- </div> -->
                    </div>

               </div>
               
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <v-dialog
                            transition="dialog-top-transition"
                            max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="info"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="showLock(item.id,item.real_gramasi , item.lock_user , item.lock_dinaran)"
                            >
                                <v-icon small>mdi-lock</v-icon>
                            </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                color="success"
                                dark
                                >Lock Member Balance</v-toolbar>

                                <v-text-field
                                    v-model="formLock.userId"
                                    hidden
                                    label=""
                                    >
                                </v-text-field>

                                <v-container>
                                    <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.last_balance"
                                                label="Last Balance"
                                                disabled
                                                >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                     <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.lock_user"
                                                label="Lock Balance by User"
                                                value="0"
                                                >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                     <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.lock_dinaran"
                                                label="Lock Balance by Dinaran"
                                                value="0"
                                                >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    
                                </v-container>

                                <v-card-actions class="justify-end">
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="success"
                                    @click="submitLock(dialog)"
                                >Submit</v-btn>
                                </v-card-actions>
                            </v-card>
                            </template>
                </v-dialog>
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="info"
                        icon
                        @click="showDetail(item)"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon small>mdi-lock</v-icon>
                    </v-btn>
                    </template>
                    <span>Lock</span>
                </v-tooltip> -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="success"
                        icon
                        @click="showDetail(item)"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon small>mdi-eye</v-icon>
                    </v-btn>
                    </template>
                    <span>Detail</span>
                </v-tooltip>
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="error"
                        icon
                        @click="cancelTrx(item)"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon small>mdi-block-helper</v-icon>
                    </v-btn>
                    </template>
                    <span>Cancel</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success" icon v-bind="attrs" v-on="on">
                        <v-icon small>mdi-thumb-up-outline</v-icon>
                    </v-btn>
                    </template>
                    <span>Success</span>
                </v-tooltip> -->
                </template>
                <template v-slot:[`item.email`]="{ item }">
                <div v-if="item.type_code == 1 || item.type_code == 10">
                    {{ item.receiver_email }}
                </div>
                <div v-else>
                    {{ item.sender_email }}
                </div>
                </template>
            </v-data-table>
            </v-flex>
        </template>
    </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";
//import downloadexcel from "vue-json-excel";


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      bankList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      status: [
            { text: "All Status", value: "" },
            { text: "Email Verified", value: "email_verified" },
            { text: "Email Not Verified", value: "email_not_verified" },
            { text: "User Verified", value: "user_verified" },
            { text: "User Not Verified", value: "user_not_verified" },
            { text: "User Suspend", value: "user_suspend" }
            // { text: "Gagal", value: "2" },
      ],
      json_fields: {
        'Id': 'id',
        'Name': 'name',
        'Email': 'email',
      },
      reward: [
            { text: "Balance", value: "balance" }
            // { text: "Gagal", value: "2" },
      ],
      tipereseller: [
            { text: "Register", value: "register" }
      ],
      bulan: [
        { text: "Januari", value: "01" },
        { text: "Februari", value: "02" },
        { text: "Maret", value: "03" },
        { text: "April", value: "04" },
        { text: "Mei", value: "05" },
        { text: "Juni", value: "06" },
        { text: "Juli", value: "07" },
        { text: "Agustus", value: "08" },
        { text: "September", value: "09" },
        { text: "Oktober", value: "10" },
        { text: "November", value: "11" },
        { text: "Desember", value: "12" },
        // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Member", value: "member"},
        { text: "Referral", value: "referral_code"},
        { text: "Reward", align: "left", value: "member_reward" , width:250},
        { text: "Limit", align: "left", value: "limit"},
        { text: "Limit Used", align: "left", value: "limit_used"},
        { text: "Periode", align: "center", value: "periode_reward" , width:250},
        { text: "Note", align: "center", value: "note"},
        { text: "Status", align: "center", value: "statusaktif"},
        { text: "Action", align: "center", value: "action"},
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    clear(){
        this.formLock.tipe_reseller = "";
        this.formLock.tipe_email = "";
    },
    fetchProv(params){
        this.$store
            .dispatch("wilayah/fetchWilayah", {
                find: params,
            })
            .then(() => {
                this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("prov" , res.response.data.message);
            });
    },
    fetchTotal(params){
        this.$store
            .dispatch("member/fetchtotals", {
                find: params,
            })
            .then(() => {
                //alert("tess");
                //this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("prov" , res.response.data.message);
            });
    },
    fetchAll() {
    //   if(cari == 1){
    //       this.pagination.page = 1;
    //   }
    //   this.loading = true;
    //   this.loadingtext = 'Loading... Please wait';
    //   this.$store.commit("main/setLoading", true);
      this.$store
        .dispatch("affiliate/fetchListAffiliateMember", {
            page: this.pagination.page,
            member:this.searchForm.member,
            religion:this.searchForm.religion,
            status_verified:this.searchForm.status_verified
        })
        .then((res) => {
        //   this.$store.commit("main/setLoading", false);
        //   this.loading = false;
        //   this.loadingtext = '';
              console.log("tesData" , res);
              return res;
        })
        .catch((res) => {
           console.log("fetchdata" , res.response.data.message);
        //   this.$store.commit("main/setLoading", false);
        //   this.$store.commit("main/setSnackbarFail", res.response.data.message);
        //   this.loading = false;
        //   this.loadingtext = '';
        });
    },
    startDownload(){
        alert('show loading');
    },
    finishDownload(){
        alert('hide loading');
    },
    fetch(cari) {
        
      if(cari == 1){
          this.pagination.page = 1;
      }

      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      
      this.$store
        .dispatch("affiliate/fetchRewardAffiliate", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            refcode:this.searchForm.ref_code
            // tipe:this.searchForm.tipereseller
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });

    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
    clearall(){
        this.formLock.action_pembelian = "";
        this.formLock.referral_code  = "";
        this.formLock.jenis_pemberian  = "";
        this.formLock.limit_reward   = 1000;
        this.formLock.reward_amount  = 25000;
        this.searchForm.date  = "";
        this.searchForm.date2  = "";
        this.formLock.textarea_note  = "";
    },
    postAffiliateReward: function(dialog){
    //this.$store.commit("main/setLoading", true);
      this.$store
        .dispatch("affiliate/AddRewardPostAffiliate", {
            action: this.formLock.action_pembelian,
            referral_code: this.formLock.referral_code,
            jenis: this.formLock.jenis_pemberian,
            limit: this.formLock.limit_reward,
            token_alias: this.formLock.token_alias,
            reward: this.formLock.reward_amount,
            date: this.searchForm.date,
            date2: this.searchForm.date2,
            note:this.formLock.textarea_note
        })
        .then(() => {
          this.fetch(0);

          dialog.value = false;
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
  },
  mounted() {
    this.formLock.limit_reward   = 1000;
    this.formLock.reward_amount  = 25000;
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        }

        if(this.$store.state.member.member.is_back == 1){
            this.pagination.page = this.$store.state.member.member.paging_page;
        }

        this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard  },
};
</script>

<style>
</style>