<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Detail Member</v-btn> -->
    <p></p>

    <v-container>

    <v-row
        no-gutters>

    <v-col
            sm="12"
            md="12">

        <v-col
            sm="12"
            md="12">

            <v-text-field
                v-model="formLock.adminEmail"
                label="Input Admin Email"
                outlined
                clearable>
            </v-text-field>

            <CustomCard>

                <template v-slot:toolbar>
                    <!-- <v-btn color="success" small outlined @click="showList()">
                        <v-icon>mdi-chevron-left</v-icon> Back to List
                    </v-btn> -->
                    <v-row>
                        <v-col
                            sm="6"
                            md="6">
                                Edit Data Module List
                        </v-col>
                        <v-col
                            sm="1"
                            md="1">
                            Read
                        </v-col>
                        <v-col
                            sm="1"
                            md="1">
                            Write
                        </v-col>
                        <v-col
                            sm="1"
                            md="1">
                            Update
                        </v-col>
                        <v-col
                            sm="1"
                            md="1">
                            Delete
                        </v-col>
                    </v-row>

                </template>

                <template v-slot:datatable>
                    <!-- <v-flex md12 style="overflow-x: scroll">
                            Data Module List
                    </v-flex> -->
                    

                    <template v-for="(item, i) in $store.state.user.sidebaredit">
                       
                        <v-list-group :prepend-icon="item.icon" class="active-group" :value="true" :key="i" no-action>
                            <template v-slot:activator>
                                <v-list-item-title>
                                    {{ item.module }}
                                </v-list-item-title>
                                </template>
                                <template v-for="(child, a) in item.children" >

                                <v-list-item
                                    :key="a"
                                    class="pl-1"
                                    active-class="active-group"
                                    v-if="!checkChild(child.children)">
                                    <v-row>

                                    <v-col
                                        sm="6"
                                        md="6">
                                            
                                        <div style="padding-left:40px;">
                                            <v-checkbox
                                                v-model="selected[5*(((jmlsidetmp[(jmlside[i])+a])))]"
                                                :value="`${child.id}#${item.id}`"
                                                class="checkboxparent"
                                                :label="child.module"
                                                @click="appendChange(child.id , item.id)">
                                            </v-checkbox>
                                        </div>

                                        </v-col>

                                        <v-col
                                            sm="1"
                                            md="1">
                                            <!-- :class="`checkmodule-${child.id}`" -->
                                            <v-checkbox
                                                v-model="selected[5*(((jmlsidetmp[(jmlside[i])+a])))+1]"
                                                :class="`checkmodule sub-${child.id}`"
                                                :value="`t#${child.id}#r`">
                                            </v-checkbox>
                                        </v-col>

                                        <v-col
                                            sm="1"
                                            md="1">
                                            <v-checkbox
                                                v-model="selected[5*(((jmlsidetmp[(jmlside[i])+a])))+2]"
                                                :class="`checkmodule sub-${child.id}`"
                                                :value="`t#${child.id}#w`">
                                            </v-checkbox>
                                        </v-col>

                                        <v-col
                                            sm="1"
                                            md="1">
                                                <v-checkbox
                                                    v-model="selected[5*(((jmlsidetmp[(jmlside[i])+a])))+3]"
                                                    :class="`checkmodule sub-${child.id}`"
                                                    :value="`t#${child.id}#u`">
                                                </v-checkbox>
                                        </v-col>

                                        <v-col
                                            sm="1"
                                            md="1">
                                                <v-checkbox
                                                    v-model="selected[5*(((jmlsidetmp[(jmlside[i])+a])))+4]"
                                                    :class="`checkmodule sub-${child.id}`"
                                                    :value="`t#${child.id}#d`">
                                                </v-checkbox>
                                        </v-col>

                                </v-row>

                                </v-list-item>

                                <v-list-group
                                    :prepend-icon="child.icon"
                                    no-action
                                    sub-group
                                    :key="a"
                                    v-else>
                                    <template v-slot:activator>

                                        <v-list-item-content>
                                            <v-list-item-title>{{ child.module }}</v-list-item-title>
                                        </v-list-item-content>

                                        </template>

                                        <v-list-item v-for="(gc, x) in child.children"  :to="link_to(gc.to)" :key="x" link>

                                        <v-row>

                                            <v-col
                                            sm="6"
                                            md="6">
                                            
                                                <v-list-item-icon>
                                                    <!-- <v-icon v-text="gc.icon"></v-icon> -->
                                                    <!--  v-model="selected[5*(((jmlside[i])+(a+x)))]" -->
                                                    <v-checkbox
                                                        :class="`checkboxparent`"
                                                        :label="gc.module"
                                                        :value="`${gc.id}#${child.id}`">
                                                    </v-checkbox>

                                                </v-list-item-icon>

                                            </v-col>

                                            <v-col
                                                sm="1"
                                                md="1"
                                                style="margin-left:-40px; margin-top:15px;">
                                                <!-- :class="`checkmodule-${child.id}`" -->
                                                <!-- v-model="selected[5*(((jmlside[i])+(a+x)))+1]" -->
                                                    <v-checkbox
                                                        :class="`checkmodule sub-${child.id}`"
                                                        :value="`t#${gc.id}#r`">
                                                    </v-checkbox>

                                            </v-col>

                                            <v-col
                                                sm="1"
                                                md="1"
                                                style="padding-left:18px;  margin-top:15px;">

                                                    <v-checkbox
                                                        :class="`checkmodule sub-${child.id}`"
                                                        :value="`t#${gc.id}#w`">
                                                    </v-checkbox>

                                            </v-col>

                                            <v-col
                                                sm="1"
                                                md="1"
                                                style="padding-left:24px;  margin-top:15px;">

                                                    <v-checkbox
                                                        :class="`checkmodule sub-${child.id}`"
                                                        :value="`t#${gc.id}#u`">
                                                    </v-checkbox>

                                            </v-col>

                                            <v-col
                                                sm="1"
                                                md="1"
                                                style="padding-left:32px;  margin-top:15px;">

                                                    <v-checkbox
                                                        :class="`checkmodule sub-${child.id}`"
                                                        :value="`t#${gc.id}#d`">
                                                    </v-checkbox>

                                            </v-col>

                                        </v-row>

                                    </v-list-item>   

                                </v-list-group>

                                </template>
                            </v-list-group>

                    </template>

                                
                </template>

            </CustomCard>

            <v-row class="rowStyles" style="margin-top:10px;">

                    <div style="width:100%; padding:0 10px;">

                        <div style="float:right; margin-right:10px;"
                                sm="3"
                                md="3">
                            <v-btn color="success" small outlined @click="simpanData()">
                                Submit 
                            </v-btn>
                        </div>

                        <div style="float:right; margin-right:10px;"
                                sm="3"
                                md="3">
                            <v-btn color="red" small outlined @click="backTo()">
                                Cancel 
                            </v-btn>
                        </div>

                    </div>

            </v-row>

        </v-col>

    </v-col>


    <!-- <v-col
            sm="8"
            md="8">

        <v-col
            sm="12"
            md="12">

            <CustomCard>

                <template v-slot:toolbar>
                    <v-btn color="success" small outlined @click="showList()">
                        <v-icon>mdi-chevron-left</v-icon> Back to List
                    </v-btn>
                    Data Admin Access
                </template>

                <template v-slot:datatable>
                    <v-flex md12 style="overflow-x: scroll">
                            Data Admin Access
                    </v-flex>
                </template>

            </CustomCard>

        </v-col>

    </v-col> -->

</v-row>

</v-container>

    </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      profiles:this.$store.state.auth.profile,
      checkBox:[],
      checkboxName:"",
      detailsAkses:this.$store.state.settings.attributes.adminAkses,
      tipe: [],
      selected: this.$store.state.user.sidebararrays,
      jmlside: this.$store.state.user.sidebarcounts,
      jmlsidetmp: this.$store.state.user.sidebarcountstmp,
      moduleParentObj:{},
      submoduleParentObj:{},
      moduleParentArray:[],
      submoduleParentArray:[],
      menu: null,
      form: {},
      pagination: {},
      formCheck: {
        parent_id: [],
        parent_sub_name : []
      },
      formLock:{
        adminEmail:"",
      },
      headers: [
        { text: "Name", value: "name"},
        { text: "Email", value: "new_email" },
        { text: "Phone", value: "phone"},
        { text: "Reg Date", value: "verified_date"},
        { text: "E-Ver", value: "em_verified"},
        { text: "M-Ver", value: "verified"},
        { text: "Action", align: "center", value: "action"},
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    checkChild(value) {
      if (typeof value != "undefined") {
        return true;
      } else {
        return false;
      }
    },
    tes(){
       // alert('tes');
    },
    link_to(params){
       // alert("param = "+params);
       console.log("params" , params);
    },
    fetch() {
      
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      //alert("limits "+this.pagination.itemsPerPage);
      
      this.$store
        .dispatch("user/getSidebarEdit", {
            page : 1,
            user : this.detailsAkses.member.id,
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
         
          this.loading = false;
          this.loadingtext = '';

          this.selected = this.$store.state.user.sidebararrays;
          this.jmlside = this.$store.state.user.sidebarcounts;
          this.jmlsidetmp = this.$store.state.user.sidebarcountstmp;
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    appendChange(number , inputs){
        console.log("number" , number);
        console.log("inputs" , inputs);
    },
    simpanData(){
        var markedCheckbox = document.querySelectorAll('.checkboxparent input[type="checkbox"]:checked');
        var submarkedCheckbox = document.querySelectorAll('.checkmodule input[type="checkbox"]:checked');

        this.moduleParentArray = [];
        this.submoduleParentArray = [];

        for (var checkbox of markedCheckbox) {
            //alert(checkbox.value)
            //this.moduleParentObj.name = checkbox.value;
            this.moduleParentArray.push({name:checkbox.value});
        }

        for (var checkboxval of submarkedCheckbox) {
            //this.submoduleParentObj.name = checkboxval.value;
            this.submoduleParentArray.push({actions:checkboxval.value});
        }
        //console.log("arrModule" , this.moduleParentArray);

        this.loading = true;
        this.loadingtext = 'Loading... Please wait';
        this.$store.commit("main/setLoading", true);
        this.$store
            .dispatch("settings/AddSettingAdmin", {
                moduleData:JSON.stringify(this.moduleParentArray),
                email:this.formLock.adminEmail,
                subModuleData:JSON.stringify(this.submoduleParentArray)
            })
            .then(() => {
                this.$store.commit("main/setLoading", false);
                this.$store.commit("main/setSnackbarSuccess", "Admin Akses Berhasil Ditambahkan");
                this.loading = false;
                this.loadingtext = '';
            })
            .catch((res) => {
                this.$store.commit("main/setLoading", false);
                this.$store.commit("main/setSnackbarFail", res.response.data.message);
                this.loading = false;
                this.loadingtext = '';
        });
    },
    backTo(){
        this.$store.state.settings.attributes.page = 'list'
    },
  },
  mounted() {
    //alert("crea"+this.selected[0]);
    //console.log("addin",this.$store.state.user.sidebararrays);
    
   this.fetch();
   this.formLock.adminEmail = this.detailsAkses.member.email;
  },
//   watch: {
//     pagination() {
//         this.loading = true
//         this.loadingtext = 'Loading... Please wait'
//         this.fetch()
//     },
//     searchSponsor(val) {
//         val && val !== this.searchForm.sponsor && this.getSponsor(val);
//     },
//     searchMember(val) {
//         val && val !== this.searchForm.member && this.getMember(val);
//     }
//   },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
    // itemsWithIndex() {
    //   return this.$store.state.user.sidebaredit.map(
    //     (items, index) => ({
    //       ...items,
    //       index: index + 1
    //     }))
    // },
  },
  components: { CustomCard },
};
</script>
