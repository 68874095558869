<template>
<div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

<v-container class="no-pad-left">

</v-container>

<CustomCard>

    <template v-slot:toolbar>
        <div style="width:100%;">

            <v-dialog
                transition="dialog-top-transition"
                max-width="600">

                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="success" small 
                        style="float:left; margin-right:20px;"
                        v-bind="attrs"
                        v-on="on"
                        @click="showLock()">
                            Add Lokasi
                    </v-btn>
                </template>
                
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                                color="success"
                                dark>
                                Form Add / Edit Lokasi ATM Emas
                        </v-toolbar>

                            <v-layout style="display:none;">

                                <v-text-field
                                    v-model="formLock.userId"
                                    label=""
                                    hidden>
                                </v-text-field>

                            </v-layout>

                            <v-container>

                                <v-layout>
                                    <v-row>

                                        <v-flex style="padding:15px;">

                                            <v-text-field
                                                v-model="formLock.namalokasi"
                                                type="text"
                                                label="Nama Lokasi">
                                            </v-text-field>

                                        </v-flex>

                                    </v-row>

                                </v-layout>

                                <v-layout>

                                        <v-flex style="padding:15px;">
                                        
                                        <v-text-field
                                            v-model="formLock.tekslongitude"
                                            type="text"
                                            label="Longitude">
                                        </v-text-field>

                                    </v-flex>

                                    <v-flex style="padding:15px;">

                                        <v-text-field
                                            v-model="formLock.tekslatitude"
                                            type="text"
                                            label="Latitude">
                                        </v-text-field>

                                    </v-flex>

                                </v-layout>

                                <v-layout>

                                        <v-btn
                                            color="success" large
                                            style="width:100%"
                                            @click="showLock()">
                                                <v-icon>mdi-plus</v-icon> Tambah Input Mesin
                                        </v-btn>

                                </v-layout>

                                <v-layout>

                                    <v-row>
                                    
                                        <v-col 
                                            sm="4"
                                            md="4">

                                            <v-col
                                                sm="12"
                                                md="12">

                                                <v-text-field
                                                    v-model="formLock.teksmesin[0]"
                                                    type="text"
                                                    label="Kode Mesin 1">
                                                </v-text-field>

                                            </v-col>
                                                
                                        </v-col>

                                        <v-col 
                                            sm="4"
                                            md="4">

                                            <v-col
                                                sm="12"
                                                md="12">

                                                <v-text-field
                                                    v-model="formLock.teksmesin[1]"
                                                    type="text"
                                                    label="Kode Mesin 2">
                                                </v-text-field>

                                            </v-col>
                                                
                                        </v-col>

                                        <v-col 
                                            sm="4"
                                            md="4">

                                            <v-col
                                                sm="12"
                                                md="12">

                                                <v-text-field
                                                    v-model="formLock.teksmesin[2]"
                                                    type="text"
                                                    label="Kode Mesin 3">
                                                </v-text-field>

                                            </v-col>
                                                
                                        </v-col>


                                        <v-col 
                                            sm="4"
                                            md="4">

                                            <v-col
                                                sm="12"
                                                md="12">

                                                <v-text-field
                                                    v-model="formLock.teksmesin[3]"
                                                    type="text"
                                                    label="Kode Mesin 4">
                                                </v-text-field>

                                            </v-col>
                                                
                                        </v-col>

                                        <v-col 
                                            sm="4"
                                            md="4">

                                            <v-col
                                                sm="12"
                                                md="12">

                                                <v-text-field
                                                    v-model="formLock.teksmesin[4]"
                                                    type="text"
                                                    label="Kode Mesin 5">
                                                </v-text-field>

                                            </v-col>
                                                
                                        </v-col>

                                        <v-col 
                                            sm="4"
                                            md="4">

                                            <v-col
                                                sm="12"
                                                md="12">

                                                <v-text-field
                                                    v-model="formLock.teksmesin[5]"
                                                    type="text"
                                                    label="Kode Mesin 6">
                                                </v-text-field>

                                            </v-col>
                                                
                                        </v-col>

                                    </v-row>

                                </v-layout>

                                
                            </v-container>

                            <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                            <v-btn
                                color="success"
                                @click="postStockGold(dialog)"
                            >Submit</v-btn>
                            </v-card-actions>
                        </v-card>

                </template>

            </v-dialog>

            <v-btn color="success" small outlined @click="filterToggle" style="float:right; margin-right:20px;">
                filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn>

        </div>
        

    </template>

    <template v-slot:expansion>
        <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.emailmember"
                                label="Email"
                                single-line
                                small
                                clearable>
                            </v-text-field>
                        </v-flex>

                        <!-- <v-flex>
                            <v-autocomplete
                                v-model="searchForm.thirdparty"
                                :items="thirdparty"
                                item-text="text"
                                item-value="value"
                                label="ThirdParty Tipe"
                            >
                            </v-autocomplete>
                        </v-flex> -->
                    
                    <v-flex>
                        <v-autocomplete
                            v-model="searchForm.tipeStock"
                            :items="tipestock"
                            item-text="text"
                            item-value="value"
                            label="Tipe"
                        >
                        </v-autocomplete>
                    </v-flex>


                    <v-flex>
                        <v-text-field
                            v-model="searchForm.flag"
                            label="Flag"
                            single-line
                            small
                            clearable>
                        </v-text-field>
                    </v-flex>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.trx_id"
                            label="TRX ID"
                            single-line
                            small
                            clearable>
                        </v-text-field>
                    </v-flex>
                    
                </v-layout>

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>

            <v-flex md12 style="overflow-x: scroll">
                    <v-data-table
                        :headers="headers"
                        :items="$store.state.atmList.exports.lokasiAtm"
                        :options.sync="pagination"
                        :server-items-length="$store.state.atmList.exports.totalAtm"
                        :footer-props="{
                        'items-per-page-options': [10, 25, 50, 100],
                        'items-per-page-text': 'Baris per Halaman:',
                        }"
                        :items-per-page="10"
                        :loading="loading"
                        :loading-text="loadingtext">

                        <template v-slot:item.mesinlist="{ item }">

                            <v-container>

                                <!-- <template v-for="(itemlist, i) in item.mesin">
                                    <div v-bind:id="item.id">{{ i }}. {{ itemlist.name }}</div>
                                </template> -->
                                <!-- <div v-for="(itemlist, index) in item.mesin" v-bind:key="item.id"> -->
                                <div v-for="(user,id) in item.mesin" :key="id" >
                                    - &nbsp; {{ user.kodemesin }}
                                </div>

                            </v-container>

                        </template>

                        <template v-slot:item.action="{ item }">

                            <v-tooltip bottom>

                                <template v-slot:activator="{ on, attrs }">

                                    <v-btn
                                        color="info"
                                        icon
                                        @click="showDetail(item)"
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon small>mdi-eye</v-icon>
                                    </v-btn>

                                </template>

                            </v-tooltip>

                            <v-dialog
                                transition="dialog-top-transition"
                                max-width="600">

                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="info"
                                        icon
                                        @click="editLockNew(item)"
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar
                                                color="success"
                                                dark>
                                                Form Add / Edit Lokasi ATM Emas
                                        </v-toolbar>

                                            <v-layout style="display:none;">

                                                <v-text-field
                                                    v-model="editLock.userId"
                                                    label=""
                                                    hidden>
                                                </v-text-field>

                                            </v-layout>

                                            <v-container>

                                                <v-layout>
                                                    <v-row>

                                                        <v-flex style="padding:15px;">

                                                            <v-text-field
                                                                v-model="editLock.namalokasi"
                                                                type="text"
                                                                label="Nama Lokasi">
                                                            </v-text-field>

                                                        </v-flex>

                                                    </v-row>

                                                </v-layout>

                                                <v-layout>

                                                    <v-flex style="padding:15px;">
                                                        
                                                        <v-text-field
                                                            v-model="editLock.tekslongitude"
                                                            type="text"
                                                            label="Longitude">
                                                        </v-text-field>

                                                    </v-flex>

                                                    <v-flex style="padding:15px;">

                                                        <v-text-field
                                                            v-model="editLock.tekslatitude"
                                                            type="text"
                                                            label="Latitude">
                                                        </v-text-field>

                                                    </v-flex>

                                                </v-layout>

                                                <v-layout>

                                                        <v-btn
                                                            color="success" large
                                                            style="width:100%"
                                                            @click="showLock()">
                                                                <v-icon>mdi-plus</v-icon> Tambah Input Mesin
                                                        </v-btn>

                                                </v-layout>

                                                <v-layout>

                                                    <v-row>
                                                    
                                                        <v-col 
                                                            sm="4"
                                                            md="4">

                                                            <v-col
                                                                sm="12"
                                                                md="12">

                                                                <v-text-field
                                                                    v-model="editLock.teksmesin[0]"
                                                                    type="text"
                                                                    label="Kode Mesin 1">
                                                                </v-text-field>

                                                            </v-col>
                                                                
                                                        </v-col>

                                                        <v-col 
                                                            sm="4"
                                                            md="4">

                                                            <v-col
                                                                sm="12"
                                                                md="12">

                                                                <v-text-field
                                                                    v-model="editLock.teksmesin[1]"
                                                                    type="text"
                                                                    label="Kode Mesin 2">
                                                                </v-text-field>

                                                            </v-col>
                                                                
                                                        </v-col>

                                                        <v-col 
                                                            sm="4"
                                                            md="4">

                                                            <v-col
                                                                sm="12"
                                                                md="12">

                                                                <v-text-field
                                                                    v-model="editLock.teksmesin[2]"
                                                                    type="text"
                                                                    label="Kode Mesin 3">
                                                                </v-text-field>

                                                            </v-col>
                                                                
                                                        </v-col>


                                                        <v-col 
                                                            sm="4"
                                                            md="4">

                                                            <v-col
                                                                sm="12"
                                                                md="12">

                                                                <v-text-field
                                                                    v-model="editLock.teksmesin[3]"
                                                                    type="text"
                                                                    label="Kode Mesin 4">
                                                                </v-text-field>

                                                            </v-col>
                                                                
                                                        </v-col>

                                                        <v-col 
                                                            sm="4"
                                                            md="4">

                                                            <v-col
                                                                sm="12"
                                                                md="12">

                                                                <v-text-field
                                                                    v-model="editLock.teksmesin[4]"
                                                                    type="text"
                                                                    label="Kode Mesin 5">
                                                                </v-text-field>

                                                            </v-col>
                                                                
                                                        </v-col>

                                                        <v-col 
                                                            sm="4"
                                                            md="4">

                                                            <v-col
                                                                sm="12"
                                                                md="12">

                                                                <v-text-field
                                                                    v-model="editLock.teksmesin[5]"
                                                                    type="text"
                                                                    label="Kode Mesin 6">
                                                                </v-text-field>

                                                            </v-col>
                                                                
                                                        </v-col>

                                                    </v-row>

                                                </v-layout>

                                                
                                            </v-container>

                                            <v-card-actions class="justify-end">
                                            <v-btn
                                                text
                                                @click="dialog.value = false"
                                            >Close</v-btn>
                                            <v-btn
                                                color="success"
                                                @click="postEditAtmGold(dialog)"
                                            >Submit</v-btn>
                                            </v-card-actions>
                                        </v-card>

                                </template>

                            </v-dialog>
                            

                        </template>

                    </v-data-table>        
            </v-flex>

    </template>

</CustomCard>

</div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      listTotalAll:this.$store.state.transaction.atmgold.listTotals,
      lokasimesin:this.$store.state.atmList.exports.atmList,
      mesinatm:[],
      provinsiList:[],
      idMesinLoc:"",
      indexAtmEmas:[1,2,3,4,5,6,7,8,9,10],
      currentPage:1,
      decimals:"10.00",
      arraymesinedit:[],
      keping2gr:0,
      keping3gr:0,
      keping5gr:0,
      keping10gr:0,
      icons:"chevron-right",
      statusPembayaran: [
            { text: "All Status", value: "" },
            { text: "Pending", value: 0 },
            { text: "Proses Pengiriman", value: 1 },
            { text: "Cancel", value: 2 }
            // { text: "Gagal", value: "2" },
      ],
      kepinggramasi: [
            { text: "2 GR", value: 2 },
            { text: "3 GR", value: 3 },
            { text: "5 GR", value: 5 },
            { text: "10 GR", value: 10 },
            { text: "25 GR", value: 25 }
            // { text: "Gagal", value: "2" },
      ],
      typetransaksi: [
            { text: "All Type", value: "" },
            { text: "NDP", value: "NDP" },
            { text: "SDP", value: "SDP" }
            // { text: "Gagal", value: "2" },
      ],
      thirdparty: [
            { text: "All", value: "all" },
            { text: "IDCASH", value: "idcash" }
            // { text: "Gagal", value: "2" },
      ],
      tipestock: [
            { text: "All", value: "all" },
            { text: "IN", value: "IN" },
            { text: "OUT", value: "OUT" },
            // { text: "Gagal", value: "2" },
      ],
      statusver: [
            { text: "All", value: "all" },
            { text: "Request Verfikasi", value: "false" },
            { text: "Terverifikasi", value: "true" }
            // { text: "Gagal", value: "2" },
      ],
      statusaddr: [
            { text: "All", value: "all" },
            { text: "Address + Current Address", value: "1" },
            { text: "Address", value: "2" },
            { text: "No Address", value: "3" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: '',
        keping2gr:0,
        keping3gr:0,
        keping5gr:0,
        keping10gr:0,
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{
        keping2gr:0,
        jumlahkeping:1,
        nomorrak:0,
        teksmesin:[],
      },
      editLock:{
        teksmesin:[],
      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Nama Lokasi", value: "nama_lokasi" },
        { text: "List Mesin", value: "mesinlist" },
        { text: "Aksi", value: "action" }
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    getMesinList(){
        //alert(this.formLock.lokasimesin);
        let indexmesin = parseInt(this.formLock.lokasimesin);
        this.mesinatm = this.lokasimesin[indexmesin-1].mesin;
        //alert(this.lokasimesin[indexmesin].mesin[0].kodemesin);
    },
    postLokasiAtm(){
    
    // let mesinNew = JSON.stringify(this.formLock.teksmesin);

    //     this.$store
    //     .dispatch("atmList/insertAtmLokasiList", {
    //         page: this.pagination.page,
    //         itemsPerPage: this.pagination.itemsPerPage,
    //         lokasi:this.formLock.namalokasi,
    //         longitude:this.formLock.tekslongitude,
    //         latitude:this.formLock.tekslatitude,
    //         mesin:mesinNew
    //     })
    //     .then(() => {
    //         this.$store.commit("main/setLoading", false);
    //         this.loading = false;
    //         this.loadingtext = '';
    //     })
    //     .catch((res) => {
    //         this.$store.commit("main/setLoading", false);
    //         this.$store.commit("main/setSnackbarFail", res.response.data.message);
    //         this.loading = false;
    //         this.loadingtext = '';
    //     });

    },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      //alert("limits "+this.pagination.itemsPerPage);
      this.$store
        .dispatch("atmList/fetchAtmLokasiList", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            index:""
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    fetchAtm() {
      this.$store
        .dispatch("atmList/fetchAtmListAtmTrans", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            index:""
        })
        .then(() => {
            this.lokasimesin = this.itemsWithIndex;
        })
        .catch((res) => {
          this.$store.commit("main/setSnackbarFail", res);
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    postStockGold: function(dialog){
        this.$store.commit("main/setLoading", true);

        let mesinNew = JSON.stringify(this.formLock.teksmesin);

        this.$store
            .dispatch("atmList/postAtmLokasiNew", {
                lokasi:this.formLock.namalokasi,
                longitude:this.formLock.tekslongitude,
                latitude:this.formLock.tekslatitude,
                mesin:mesinNew
            })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Update Data Stock Successfully");
        })
        .catch((res) => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
        });
    },
    postEditAtmGold: function(dialog){
        this.$store.commit("main/setLoading", true);

        let mesinNew = JSON.stringify(this.editLock.teksmesin);
        let mesinId = JSON.stringify(this.arraymesinedit);

        this.$store
            .dispatch("atmList/editAtmLokasiNew", {
                lokasi:this.editLock.namalokasi,
                longitude:this.editLock.tekslongitude,
                latitude:this.editLock.tekslatitude,
                mesin:mesinNew,
                idMesin:mesinId,
                idlokasi:this.idMesinLoc
            })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Update Data Stock Successfully");
        })
        .catch((res) => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
        });
    },
    showDetail(item) {
        this.$store.commit("transaction/setMemberHisGoldPage", "detail");
        this.$store.commit("transaction/setMemberTrxHisGold", item);
        //console.log("ItemList" , item);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(){
        this.formLock.lokasi = "";
        this.formLock.longitude = "";
        this.formLock.latitude = "";
        this.formLock.mesin = "";

        this.formLock.teksmesin = [];
    },
    editLockNew:function(items){
        //alert(items.longitude);
        //const propertyNames = Object.keys(items.mesin);

        this.editLock.namalokasi = items.nama_lokasi;
        this.editLock.tekslongitude = items.longitude;
        this.editLock.tekslatitude = items.latitude;
        this.editLock.mesin = "";

        this.editLock.teksmesin = items.arrayedit;
        this.arraymesinedit = items.arrayid;

        this.idMesinLoc = items.id;
    },
  },
  mounted() {
      //this.fetchAtm();
  },
  watch: {
    pagination() {
        this.loading = true
        this.loadingtext = 'Loading... Please wait'
        this.fetch(0)
    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    itemsWithIndex() {
      return this.$store.state.atmList.exports.atmList.map(
        (items, index) => ({
          ...items,
          index: index + 1,
        }))
    },
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>