var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.$store.state.trxmember.list,"options":_vm.pagination,"item-key":"id","loading":_vm.loading,"server-items-length":_vm.$store.state.trxmember.total,"footer-props":{
    'items-per-page-options': [10, 25, 50, 100],
    'items-per-page-text': 'Baris per Halaman:',
  },"items-per-page":10},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.headers),function(head,i){return _c('th',{key:i},[_c('div',{staticStyle:{"text-align":"center"},domProps:{"innerHTML":_vm._s(head.text)}})])}),0)])]},proxy:true},{key:"item",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [_c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[(item.sender != -1)?_c('div',[_vm._v(" "+_vm._s(item.sender_email)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.receiver_email)+" ")])]),_c('td',[_c('v-btn',{attrs:{"color":"black","text":"","x-small":""}},[_vm._v(" "+_vm._s(item.type)+" ")])],1),_c('td',[_c('v-btn',{attrs:{"color":_vm.statusColor(item.status),"text":"","x-small":""}},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('v-btn',{attrs:{"color":"success","text":"","x-small":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-block-helper")])],1)],1),_c('td',[_c('div',{staticStyle:{"text-align":"right"}},[_c('small',[_vm._v(" "+_vm._s(item.created_at)+" ")]),_vm._v(" "),_c('br')]),_c('div',{staticStyle:{"margin-top":"-5px","text-align":"right"}},[_c('b',[_vm._v(" "+_vm._s(item.updated_at)+" ")])])]),_c('td',[_c('div',{staticStyle:{"text-align":"right"}},[_c('b',[_vm._v(" "+_vm._s(item.amount)+" ")]),_vm._v(" "),_c('br')]),_c('div',{staticStyle:{"margin-top":"-5px","text-align":"right"}},[_c('b',[_vm._v(" "+_vm._s(item.gramasi)+" ")])])]),_c('td',[_c('div',{staticStyle:{"text-align":"right"}},[_c('b',[_vm._v(" "+_vm._s(item.buy_rate)+" ")]),_vm._v(" "),_c('br')]),_c('div',{staticStyle:{"margin-top":"-5px","text-align":"right"}},[_c('small',[_vm._v(" "+_vm._s(item.sell_rate)+" ")])])]),_c('td',{staticStyle:{"text-align":"center"}},[_c('v-btn',{attrs:{"color":"success","text":"","small":""}},[_vm._v(" "+_vm._s(item.pay_method)+" ")])],1),_c('td',[_c('v-btn',{attrs:{"color":"black","text":"","small":""}},[_vm._v(" "+_vm._s(item.admin)+" ")])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.showDetail(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Detail")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","icon":""},on:{"click":function($event){return _vm.lockDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock")])],1)]}}],null,true)},[_c('span',[_vm._v("Lock Balance")])])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }