<template>
<div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

<CustomCard>

    <template v-slot:toolbar>
        <div style="width:100%;">

            <v-dialog
                transition="dialog-top-transition"
                max-width="600"
                v-model="deletecountersoff">
                
                <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="red"
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                            <v-icon small color="red">mdi-block-helper</v-icon>
                    </v-btn>
                </template> -->
                    <!-- <span>Unverified User</span> -->
                    <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar
                            color="red"
                            dark
                            >Del Counter Offline</v-toolbar>

                            <v-container>

                                <v-layout>
                                    <v-flex>
                                        Anda yakin akan menghapus data counter offline ini ?
                                    </v-flex>
                                </v-layout>
                                    
                            </v-container>

                            <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                            <v-btn
                                style="color:#ffffff;"
                                color="red"
                                @click="delCounters(dialog , selectedId)"
                            >Submit</v-btn>
                            </v-card-actions>
                        </v-card>
                </template>
            </v-dialog>

            <v-dialog
                transition="dialog-top-transition"
                max-width="600"
                v-model="editcountersoff">
                
                <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="info"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="showItemDetail(item);">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </template> -->

                <!-- <span>Unverified User</span> -->
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                        color="success"
                        dark
                        >Edit Counter Offline</v-toolbar>

                        <v-container>

                            <v-layout>
                                <v-flex>
                                    <!-- Nama Counter : <br /> -->
                                    <v-text-field
                                        v-model="searchForm.edit_counter_name"
                                        label="Nama Counter"
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex>
                                    <!-- Nama Counter : <br /> -->
                                    <v-textarea
                                        v-model="searchForm.edit_counter_alamat"
                                        outlined
                                        label="Alamat Counter">
                                    </v-textarea>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex>
                                    <!-- Nama Counter : <br /> -->
                                    <v-text-field
                                        v-model="searchForm.edit_counter_pic"
                                        label="PIC"
                                        readonly
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex>
                                    <!-- Nama Counter : <br /> -->
                                    <v-text-field
                                        v-model="searchForm.edit_counter_phone"
                                        label="Phone"
                                        readonly
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex>
                                    <!-- Nama Counter : <br /> -->
                                    <v-autocomplete
                                        v-model="searchForm.edit_counter_status"
                                        :items="statusver"
                                        item-text="text"
                                        item-value="value"
                                        label="Status">
                                    </v-autocomplete>
                                </v-flex>
                            </v-layout>

                                
                        </v-container>

                        <v-card-actions class="justify-end">
                        <v-btn
                            text
                            @click="dialog.value = false"
                        >Close</v-btn>
                        <v-btn
                            style="color:#ffffff;"
                            color="success"
                            @click="editCounters(editcountersoff , selectedId)"
                        >Submit</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>

            </v-dialog>


            <v-dialog
                transition="dialog-top-transition"
                max-width="600">

                <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success" small 
                                style="float:left; margin-left:0;"
                                v-bind="attrs"
                                v-on="on"
                                @click="showLock(item.id,item.name , item.new_email , item.phone)">
                                    Add Counter
                            </v-btn>
                </template>
                
                <template v-slot:default="dialog">
                    <v-card>
                        <v-toolbar
                                color="success"
                                dark>
                                Add Counter Offline
                        </v-toolbar>

                                <v-layout style="display:none;">

                                    <v-text-field
                                        v-model="formLock.userId"
                                        label=""
                                        hidden
                                        >
                                    </v-text-field>

                                </v-layout>

                                <v-container>

                                    <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.namaCounter"
                                                label="Nama Counter"
                                                >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                     <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.alamatCounter"
                                                label="Alamat"
                                                >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                     <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.pcicCounter"
                                                label="PCIC"
                                                >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.phoneCounter"
                                                label="Phone"
                                                >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout>
                                       <v-autocomplete
                                            v-model="formLock.statusCounter"
                                            :items="statusver"
                                            item-text="text"
                                            item-value="value"
                                            label="Status">
                                        </v-autocomplete>
                                    </v-layout>
                                    
                                </v-container>

                                <v-card-actions class="justify-end">
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="success"
                                    @click="postCounters(dialog)"
                                >Submit</v-btn>
                                </v-card-actions>
                            </v-card>
                </template>

            </v-dialog>

            <!-- <v-btn color="success" small outlined @click="filterToggle" style="float:right; margin-right:20px;">
                filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn> -->

        </div>
        

    </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.emailmember"
                                label="Email"
                                single-line
                                small
                                clearable>
                            </v-text-field>
                        </v-flex>

                        <!-- <v-flex>
                            <v-autocomplete
                                v-model="searchForm.thirdparty"
                                :items="thirdparty"
                                item-text="text"
                                item-value="value"
                                label="ThirdParty Tipe"
                            >
                            </v-autocomplete>
                        </v-flex> -->
                    
                    <v-flex>
                        <v-autocomplete
                            v-model="searchForm.tipeStock"
                            :items="tipestock"
                            item-text="text"
                            item-value="value"
                            label="Tipe"
                        >
                        </v-autocomplete>
                    </v-flex>


                    <v-flex>
                        <v-text-field
                            v-model="searchForm.flag"
                            label="Flag"
                            single-line
                            small
                            clearable>
                        </v-text-field>
                    </v-flex>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.trx_id"
                            label="TRX ID"
                            single-line
                            small
                            clearable>
                        </v-text-field>
                    </v-flex>
                    
                </v-layout>

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>

            <v-flex md12 style="overflow-x: scroll">

                    <v-data-table
                        :headers="headers"
                        :items="$store.state.transaction.offlinecounter.list"
                        :options.sync="pagination"
                        :server-items-length="$store.state.transaction.offlinecounter.total"
                        :footer-props="{
                        'items-per-page-options': [10, 25, 50, 100],
                        'items-per-page-text': 'Baris per Halaman:',
                        }"
                        :items-per-page="10"
                        :loading="loading"
                        :loading-text="loadingtext">

                        <template v-slot:item.action="{ item }">

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="info"
                                    icon
                                    @click="showDetail(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon small>mdi-magnify</v-icon>
                                </v-btn>
                                </template>
                                <span>Counter Detail & Penjadwalan</span>
                            </v-tooltip>

                            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="info"
                                    icon
                                    @click="showDetail(item)"
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                </template>
                                <span>Detail</span>
                            </v-tooltip> -->

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="info"
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="showItemDetail(item);">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit Counter</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="red"
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="deletecountersdlg(item.id)">
                                            <v-icon small color="red">mdi-block-helper</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete Counter Offline</span>
                            </v-tooltip>
                        
                        </template>

                        <template v-slot:[`item.statuscounter`]="{ item }">
                                <div style="margin-top:-5px; color:green;" v-if="item.status == 1"> <small>  <v-icon small>mdi-check</v-icon> <b> Active </b> </small> </div>
                                <div style="margin-top:-5px; color:red;"   v-if="item.status == 2"> <small>   <v-icon small>mdi-block</v-icon> <b> Not Active </b> </small> </div>
                        </template>

                    </v-data-table>        
                    
            </v-flex>

    </template>

</CustomCard>

</div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      selectedId:"",
      editcountersoff:false,
      deletecountersoff:false,
      keping2gr:0,
      keping3gr:0,
      keping5gr:0,
      keping10gr:0,
      icons:"chevron-right",
      statusPembayaran: [
            { text: "All Status", value: "" },
            { text: "Pending", value: 0 },
            { text: "Proses Pengiriman", value: 1 },
            { text: "Cancel", value: 2 }
            // { text: "Gagal", value: "2" },
      ],
      typetransaksi: [
            { text: "All Type", value: "" },
            { text: "NDP", value: "NDP" },
            { text: "SDP", value: "SDP" }
            // { text: "Gagal", value: "2" },
      ],
      thirdparty: [
            { text: "All", value: "all" },
            { text: "IDCASH", value: "idcash" }
            // { text: "Gagal", value: "2" },
      ],
      tipestock: [
            { text: "All", value: "all" },
            { text: "IN", value: "IN" },
            { text: "OUT", value: "OUT" },
            // { text: "Gagal", value: "2" },
      ],
      statusver: [
            { text: "No Active", value: 2 },
            { text: "Active", value: 1 }
            // { text: "Gagal", value: "2" },
      ],
      statusaddr: [
            { text: "All", value: "all" },
            { text: "Address + Current Address", value: "1" },
            { text: "Address", value: "2" },
            { text: "No Address", value: "3" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: '',
        keping2gr:0,
        keping3gr:0,
        keping5gr:0,
        keping10gr:0,
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{
        keping2gr:0,
        keping3gr:0,
        keping5gr:0,
        keping10gr:0
      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Name", value: "name" },
        { text: "Alamat", value: "alamat" },
        { text: "Pic", value: "pic" },
        { text: "Phone", value: "phone" },
        { text: "Status", value: "statuscounter" , width:100 },
        { text: "Action", value: "action" , width:180 },
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      

      //alert("limits "+this.pagination.itemsPerPage);
      this.$store
        .dispatch("transaction/fetchOfflineCounter", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            index:"",
            // tipestock:this.searchForm.tipeStock,
            // flag:this.searchForm.flag,
            // trxId:this.searchForm.trx_id,
            // emailmember:this.searchForm.emailmember,
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    editVerificationBan: function(dialog , id , code){
    //  console.log("updateId" , this.profiles.id);
    //  console.log("code" , code);
    //  console.log("trxid" , id);
     //console.log("id" , id);
      this.$store.commit("main/setLoading", true);
      //alert("ban");
      this.$store
        .dispatch("transaction/editMemberTransactionBan", {
            updateId: this.profiles.id,
            trxid: id,
            code: code,
            id: id
        })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
        })
        .catch((res) => {
          this.fetch(this.pagination.page);
          dialog.value = false;
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    editVerificationSuccess: function(dialog , id , code){
     this.$store.commit("main/setLoading", true);

        this.$store
            .dispatch("transaction/editMemberTransactionSuccess", {
                updateId: this.profiles.id,
                code: code,
                trxid: id,
                id: id
            })
            .then(() => {
                this.fetch(this.pagination.page);
                dialog.value = false;
                this.$store.commit("main/setLoading", false);
                this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
            })
            .catch((res) => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
            this.loading = false;
            this.loadingtext = '';
        });

    },
    postCounters: function(dialog){

        this.$store.commit("main/setLoading", true);

        this.$store
            .dispatch("transaction/postCounters", {
                name: this.formLock.namaCounter,
                alamat: this.formLock.alamatCounter,
                pic: this.formLock.pcicCounter,
                phone: this.formLock.phoneCounter,
                status: this.formLock.statusCounter,
        })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Update Data Stock Successfully");
        })
        .catch((res) => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
        });

    },
    editCounters: function(dialog , index){
        this.$store.commit("main/setLoading", true);

        this.$store
            .dispatch("transaction/editCounters", {
                id: index,
                type:"edit",
                name: this.searchForm.edit_counter_name,
                alamat: this.searchForm.edit_counter_alamat,
                pic: this.searchForm.edit_counter_pic,
                phone: this.searchForm.edit_counter_phone,
                status: this.searchForm.edit_counter_status,
        })
        .then(() => {
            this.fetch(this.pagination.page);
            //dialog.value = false;
            this.editcountersoff = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Update Data Counter Successfully");
        })
        .catch((res) => {
            this.fetch(this.pagination.page);
            //dialog.value = false;
            this.editcountersoff = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
        });

    },
    delCounters: function(dialog , index){
        this.$store.commit("main/setLoading", true);

        this.$store
            .dispatch("transaction/editCounters", {
                id: index,
                type:"del"
        })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Delete Data Counter Successfully");
        })
        .catch((res) => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
        });

    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        //this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.state.transaction.offlinecounter.page = "detail";
        this.$store.state.transaction.offlinecounter.detail = item;
        
        // this.$store.commit("transaction/setMemberOfflineCounter", "detail");
        // this.$store.commit("transaction/setMemberDetailListCounter", item);

        //alert(this.$store.state.transaction.offlinecounter.page);
        //console.log("ItemList" , item);
        this.loading = false;
        this.loadingtext = '';
    },

    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showItemDetail:function(items){
      this.searchForm.edit_counter_name = items.name
      this.searchForm.edit_counter_alamat = items.alamat
      this.searchForm.edit_counter_pic = items.pic
      this.searchForm.edit_counter_phone = items.phone
      this.searchForm.edit_counter_status = items.status

      this.editcountersoff = true
      this.selectedId = items.id
    },
    deletecountersdlg(index){
        this.deletecountersoff = true
        this.selectedId = index
    }
  },
  mounted() {
      //alert("tess");
      //console.log("tesnew" , this.$store.state.transaction.stockgold.list);
      this.keping2gr = 0;
      this.keping3gr = 0;
      this.keping5gr = 0;
      this.keping10gr = 0;
  },
  watch: {
    pagination() {
        //alert("tess");
        //alert(this.$store.state.member.member.is_back);
        //if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        //}

        //if(this.$store.state.member.member.is_back == 1){
        //this.pagination.page = this.$store.state.member.member.paging_page;
        //}

       // this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>