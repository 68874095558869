import { addVouchersNew , apiFetchTransactionSingleCorporate, apiGetGoldRatesbyDates, apiManualWithdrawTrx , editVouchersNew , getVouchers , apiFetchTransactionMember , exportMemberCorpTrx , apiFetchTransactionCorporate , apiPengirimanAwb,apiPostTrxManual , apiManualTrx , exportMemberTrx , apiUploadICDX , apiGetDataMemberChart , apiHitDataMemberChart, apiHitDataTransChart , ApiExportTrxExcel , ApiExportNewTrxExcel , ApiFetchStokEmas , ApiAddSetorEmas, editMemberPostCounter , deleteCounterJadwal , postCounterJadwal , apiOfflineJadwalCounter , apiOfflineCounter , addMemberPostCounter , apiPostAtmStockGold , apiPostStockGold , apiListTrxNewGoldAtm , apiListTrxListAtmGold , apiListTrxListStockGold , apiListTrxListFinalConnGold , apiListTrxListHisGold , apiListTrxListConnGold , apiListTrxListRefundGold , apiVerifyTransaction , apiVerifyTrxMemberSuccess , apiVerifyTrxMemberBan , apiListTrxListSettlement , apiListPickupAwb } from "../../api/transaction"

import fileDownload from 'js-file-download';

const namespaced = true

const defaultState = () => {
    return {
        vouchers: {
            list: [],
            detail: [],
            trxList:[],
            status:"",
            total:0,
            page: "list",
        },
        member: {
            list: [],
            detail: [],
            trxList:[],
            status:"",
            total:0,
            page: "list",
            listCorpTrx:"",
            uploadICDX:[],
            transaksi: [],
            goldRateList: [],
            searchtransaksi:[{ 
                "member"            :  "",
                "typetransaksi"     :  "",
                "statusPembayaran"  :  "",
                "startDate"         :  "",
                "endDate"           :  "",
                "pages"             : 1
            }],
            searchDetail:"no",
            currentPages:0,
            transaksiMember: [],
            transaksinum: [],
            transaksibuy: [],
            transaksisell: [],
            totalMemberNormal:[],
            totalMemberFormatted:[],
            totaltahunan: [],
            totalbulanan: [],
            manualList:[],
            dinmanualList:[],
            dinTrxDinList:[],
            dinTrxDinLog:[],
            lastDaytrans:[]
        },
        settlement: {
            trxListSettlement:[],
            list: [],
            detail: {},
            page:"list",
            status:"",
            total:0,
        },
        hisgold:{
            trxListGold:[],
            list: [],
            detail: {},
            page:"list",
            status:"",
            total:0,
        },
        stockgold:{
            trxStockGold:[],
            list: [],
            detail: {},
            page:"list",
            status:"",
            total:0,
            totalkeping2:0,
            totalkeping3:0,
            totalkeping5:0,
            totalkeping10:0,
        },
        atmgold:{
            list: [],
            detail: {},
            page:"list",
            status:"",
            listTotals:[],
            listTotalsSum:[],
            total:0,
            listTotals1:0
        },
        goldatm:{
            list: [],
            detail: {},
            page:"list",
            status:"",
            listTotals:[],
            total:0,
            listTotals1:0
        },
        updatestok:{
            list: [],
            detail: {},
            page:"list",
            status:"",
            listTotals:[],
            listTotalsSum:[],
            total:0,
            listTotals1:0
        },
        lokasistok:{
            list: [],
            detail: {},
            page:"list",
            status:"",
            listTotals:[],
            listTotalsSum:[],
            total:0,
            listTotals1:0
        },
        offlinecounter:{
            list: [],
            detail: {},
            page:"list",
            status:"",
            listTotals:[],
            listTotalsSum:[],
            total:0,
            listTotals1:0,
            listjadwal:[]
        },
        stokemas:{
            list: [],
            detail: {},
            page:"list",
            status:"",
            listTotals:[],
            listTotalsSum:[],
            total:0,
            hargaEmas:0,
            hargaId:0,
            listTotals1:0,
            listjadwal:[]
        },
        exports:{
            list: [],
            detail: {},
            page:"list",
            filename:"",
            status:"",
            total:0,
            listTotals1:0
        }
    }
}

const state = defaultState()

const mutations = {
    setDataManualList: (state, payload) => {
        state.member.manualList = payload
    },
    setDataDinManualList: (state, payload) => {
        state.member.dinmanualList = payload
    },
    setDinTrxDinList: (state, payload) => {
        state.member.dinTrxDinList = payload
    },
    setDataDinTrxLog: (state, payload) => {
        state.member.dinTrxDinLog = payload
    },
    setDataTransaksi: (state, payload) => {
        state.member.transaksi = payload
    },
    setDataTransaksiMember: (state, payload) => {
        state.member.transaksiMember = payload
    },
    setDataMemberChart: (state, payload) => {
        state.member.transaksi = payload
    },
    setDataTransaksinum: (state, payload) => {
        state.member.transaksinum = payload
    },
    setDataTransaksibuy: (state, payload) => {
        state.member.transaksibuy = payload
    },
    setDataTransaksisell: (state, payload) => {
        state.member.transaksisell = payload
    },
    setMemberPage: (state, payload) => {
        state.member.page = payload
    },
    setSearchTransaksi: (state, payload) => {
        state.member.searchtransaksi = payload
    },
    setMemberList: (state, payload) => {
        state.member.list = payload
    },
    setMemberTrxTotal: (state, payload) => {
        state.member.total = payload
    },
    setMemberDetail: (state, payload) => {
        state.member.detail = payload
    },
    setTransactionMember: (state, payload) => {
        state.member.detail = payload
    },
    setSuccessTransaction: (state, payload) => {
        state.member.status = payload
    },
    setMemberSettlePage: (state, payload) => {
        state.settlement.page = payload
    },
    setMemberTrxListSettlement: (state, payload) => {
        state.settlement.trxListSettlement = payload
    },
    setMemberTrxTotalSettlement: (state, payload) => {
        state.settlement.total = payload
    },
    setMemberTrxDetailSettlement: (state, payload) => {
        state.settlement.trxListSettlement = payload
    },
    setMemberTrxMembersSettlement: (state, payload) => {
        state.settlement.trxListSettlement = payload
    },
    setMemberTrxSuccessSettlement: (state, payload) => {
        state.settlement.status = payload
    },
    setMemberHisGoldPage: (state, payload) => {
        state.hisgold.page = payload
    },
    setMemberTrxListHisGold: (state, payload) => {
        state.hisgold.list = payload
    },
    setMemberTrxTotalHisGold: (state, payload) => {
        state.hisgold.total = payload
    },
    setMemberTrxHisGold: (state, payload) => {
        state.hisgold.detail = payload
    },
    setMemberTrxSuccessHisGold: (state, payload) => {
        state.hisgold.status = payload
    },
    setMemberTrxListStockGold:() => (state, payload) => {
        state.stockgold.list = payload
    },
    setMemberTrxTotalStockGold:() => (state, payload) => {
        state.stockgold.total = payload
    },
    setMemberStockGoldPage:() => (state, payload) => {
        state.stockgold.page = payload
    },
    setMemberTrxGoldStatus: (state, payload) => {
        state.stockgold.status = payload
    },
    setMemberTrxTotalGoldAtm:() => (state, payload) => {
        state.goldatm.listTotals = payload
    },
    setMemberTrxTotalAtmStockGold:() => (state, payload) => {
        state.atmgold.total = payload
    },
    setMemberAtmStockGoldPage:() => (state, payload) => {
        state.atmgold.page = payload
    },
    setMemberTrxAtmGoldStatus: (state, payload) => {
        state.atmgold.status = payload
    },
    setMemberTrxAtmStockGold:() => (state, payload) => {
        state.atmgold.list = payload
    },
    setMemberTotalAtmStockGold:() => (state, payload) => {
        state.atmgold.total = payload
    },
    setMemberAtmGoldPage:() => (state, payload) => {
        state.atmgold.page = payload
    },
    setMemberUpdateStokPage:() => (state, payload) => {
        state.updatestok.page = payload
    },
    setStatelistTotals:() => (state, payload) => {
        state.atmgold.listTotals = payload
    },
    setMemberTrxListCounter:() => (state, payload) => {
        state.offlinecounter.list = payload
    },
    setMemberOfflineCounter:() => (state, payload) => {
        state.offlinecounter.page = payload
    },
    addMemberPostCounter:() => (state, payload) => {
        state.offlinecounter.status = payload
    },
    setMemberDetailListCounter:() => (state, payload) => {
        state.offlinecounter.detail = payload
    },
    setMemberTransactGold: (state, payload) => {
        state.stokemas.status = payload
    },
    setTotalMemberNormal: (state, payload) => {
        state.member.totalMemberNormal = payload
    },
    settotalMemberFormatted: (state, payload) => {
        state.member.totalMemberFormatted = payload
    },
    setTotalTahunan: (state, payload) => {
        state.member.totaltahunan = payload
    },
    setTotalBulanan: (state, payload) => {
        state.member.totalbulanan = payload
    },
    setlastDaytrans: (state, payload) => {
        state.member.lastDaytrans = payload
    },
    setUploadICDX: (state, payload) => {
        state.member.uploadICDX = payload
    },
    setVouchersMessage: (state, payload) => {
        state.vouchers.status = payload
    },
    setVouchersList: (state, payload) => {
        state.vouchers.list = payload
    },
    setVouchersTotal: (state, payload) => {
        state.vouchers.total = payload
    },
    setGoldRateList: (state, payload) => {
        state.member.goldRateList = payload
    },
}

const actions = {
    fetchMember({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchTransactionMember(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberList', res.trx)
                        commit('setMemberTrxTotal' , res.total)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    getGoldRatesbyDates({commit}, payload){
        return new Promise((resolve, reject) => {
            apiGetGoldRatesbyDates(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setGoldRateList', res.data)
                        console.log("GoldRateList" , res.data);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchCorporateTrx({commit}, payload){
        return new Promise((resolve, reject) => {
            apiFetchTransactionCorporate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberList', res.trx)
                        commit('setMemberTrxTotal' , res.total)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchCorporateSingleTrx({commit}, payload){
        return new Promise((resolve, reject) => {
            apiFetchTransactionSingleCorporate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberList', res.trx)
                        commit('setMemberTrxTotal' , res.total)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    SetPengirimanAwb({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPengirimanAwb(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setSuccessTransaction', "success")

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    apiHitDataTransChart({commit} , payload) {
        return new Promise((resolve, reject) => {
            apiHitDataTransChart(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setDataTransaksi', res.data);
                        commit('setDataTransaksinum', res.datafetch);
                        commit('setDataTransaksibuy', res.databuy);
                        commit('setDataTransaksisell', res.datajual);
                        //state.member.transaksi = res.data;
                        //console.log("dataTransList" , state.member.transaksi);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    getDataMemberChart({commit}) {
        return new Promise((resolve, reject) => {
            apiGetDataMemberChart()
                .then((res) => {
                    if (res.status == 200) {
                        commit('setDataMemberChart', res.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editMemberTransaction({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiVerifyTransaction(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberList', res.trx)
                        commit('setMemberTrxTotal' , res.total)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editMemberTransactionBan({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiVerifyTrxMemberBan(payload)
                .then((res) => {
                    //alert(res.trx);
                    if (res.status == 200) {
                        //alert(res.trx);
                        commit('setSuccessTransaction', res.trx)

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editMemberTransactionSuccess({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiVerifyTrxMemberSuccess(payload)
                .then((res) => {
                    if (res.status == 200) {
                        //alert(res.trx);
                        commit('setSuccessTransaction', "success")

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editMemberTransactionSingleBan({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiVerifyTrxMemberBan(payload)
                .then((res) => {
                    //alert(res.trx);
                    if (res.status == 200) {
                        //alert(res.trx);
                        commit('setSuccessTransaction', res.trx)

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editMemberTransactionSingleSuccess({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiVerifyTrxMemberSuccess(payload)
                .then((res) => {
                    if (res.status == 200) {
                        //alert(res.trx);
                        commit('setSuccessTransaction', "success")

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchMemberListSettlement({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiListTrxListSettlement(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberTrxListSettlement', res.trx)
                        commit('setMemberTrxTotalSettlement' , res.total)

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchMemberListHisGold({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiListTrxListHisGold(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberTrxListHisGold', res.trx)
                        commit('setMemberTrxTotalHisGold' , res.total)
                        //console.log("data fetch", res.trx);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchMemberListStockGold({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiListTrxListStockGold(payload)
                .then((res) => {

                    //console.log("datafetchnew", res.data.data);

                    if (res.status == 200) {
                        commit('setMemberTrxListStockGold', res.data)
                        commit('setMemberTrxTotalStockGold' , res.total)

                        state.stockgold.list = res.data;
                        state.stockgold.total = res.total;
                        
                        state.stockgold.totalkeping2 = res.total_stock2
                        state.stockgold.totalkeping3 = res.total_stock3
                        state.stockgold.totalkeping5 = res.total_stock5
                        state.stockgold.totalkeping10 = res.total_stock10
                        //console.log("datafetchnew", state.stockgold.list);

                        //console.log("data fetch", res.data.data);
                        //console.log("data new fetch" ,  state.stockgold.list);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchMemberListAtmStockGold({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiListTrxListAtmGold(payload)
                .then((res) => {

                    //console.log("datafetchnew", res.data.data);
                    if (res.status == 200) {
                        commit('setMemberTrxAtmStockGold', res.data)
                        commit('setMemberTotalAtmStockGold' , res.total)
                        //commit('setStatelistTotals' , res.listTotal);
                        //console.log("stateTotals" , this.$store.state.transaction.atmgold.listTotals);
                        state.atmgold.list = res.data;
                        state.atmgold.listTotals = [res.listTotal["slot1in"]["total"],res.listTotal["slot2in"]["total"],res.listTotal["slot3in"]["total"],res.listTotal["slot4in"]["total"],res.listTotal["slot5in"]["total"],res.listTotal["slot6in"]["total"],res.listTotal["slot7in"]["total"],res.listTotal["slot8in"]["total"],res.listTotal["slot9in"]["total"],res.listTotal["slot9in"]["total"],res.listTotal["slot10in"]["total"]];
                        state.atmgold.listTotalsSum = [parseInt(res.listTotal["slot1in"]["total"]+res.listTotal["slot2in"]["total"]),parseInt(res.listTotal["slot3in"]["total"]+res.listTotal["slot4in"]["total"]),parseInt(res.listTotal["slot5in"]["total"]+res.listTotal["slot6in"]["total"]),parseInt(res.listTotal["slot7in"]["total"]+res.listTotal["slot8in"]["total"]),parseInt(res.listTotal["slot9in"]["total"]+res.listTotal["slot10in"]["total"])];
                        
                        state.atmgold.total = res.total;
                        //state.atmgold.listTotals = res.listTotal["slot1in"]["total"];

                        //console.log("stateTotals" , state.atmgold.listTotals["slot1in"]["total"]);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchMemberListGoldAtm({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiListTrxNewGoldAtm(payload)
                .then((res) => {

                    //console.log("datafetchnew", res.data.data);
                    if (res.status == 200) {
                        commit('setMemberTrxTotalGoldAtm', res.data);
                        state.goldatm.listTotals = res.data;

                        console.log("datafetchnew", res.data);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postAtmStockGold({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPostAtmStockGold(payload)
                .then((res) => {

                    //console.log("datafetchnew", res.data.data);
                    if (res.status == 200) {
                        commit('setMemberTrxGoldStatus', "Success")

                        resolve(res)
                    }
                    
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postStockGold({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPostStockGold(payload)
                .then((res) => {

                    //console.log("datafetchnew", res.data.data);

                    if (res.status == 200) {
                        commit('setMemberTrxGoldStatus', "Success")

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    SetMemberConnGold({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiListTrxListConnGold(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberTrxSuccessHisGold', "Success")

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    SetMemberRefundGold({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiListTrxListRefundGold(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberTrxSuccessHisGold', "Success")

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    SetMemberFinalConfirmGold({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiListTrxListFinalConnGold(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberTrxSuccessHisGold', "Success")

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    setApiListPickupAwb({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiListPickupAwb(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberTrxSuccessHisGold', "Success")

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    setMemberTrxTotalAtmStockGold({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiListTrxListFinalConnGold(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberTrxSuccessAtmGold', "Success")

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchOfflineCounter({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiOfflineCounter(payload)
                .then((res) => {
                    if (res.status == 200) {
                        // commit('setMemberTrxSuccessAtmGold', "Success");
                        commit('setMemberTrxListCounter', res.data)
                        //commit('setMemberTotalAtmStockGold' , res.total)

                        state.offlinecounter.list = res.data.data;
                        state.offlinecounter.total = res.total;

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchOfflineJadwalCounter({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiOfflineJadwalCounter(payload)
                .then((res) => {
                    if (res.status == 200) {
                        // commit('setMemberTrxSuccessAtmGold', "Success");
                       // commit('setMemberTrxListCounter', res.data)
                        //commit('setMemberTotalAtmStockGold' , res.total)

                        state.offlinecounter.listjadwal = res.data;
                        commit('addMemberPostCounter', "Success");
                       // state.offlinecounter.total = res.total;

                        console.log("data fetch", state.offlinecounter.listjadwal);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postCounters({ commit } , payload) {
        return new Promise((resolve, reject) => {
            addMemberPostCounter(payload)
                .then((res) => {
                    if (res.status == 200) {
                        // commit('setMemberTrxSuccessAtmGold', "Success");
                        commit('addMemberPostCounter', 'status')
                        //commit('setMemberTotalAtmStockGold' , res.total)

                        // state.offlinecounter.list = res.data.data;
                        // state.offlinecounter.total = res.total;

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editCounters({ commit } , payload) {
        return new Promise((resolve, reject) => {
            editMemberPostCounter(payload)
                .then((res) => {
                    if (res.status == 200) {
                        // commit('setMemberTrxSuccessAtmGold', "Success");
                        commit('addMemberPostCounter', 'status')
                        //commit('setMemberTotalAtmStockGold' , res.total)

                        // state.offlinecounter.list = res.data.data;
                        // state.offlinecounter.total = res.total;

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    setCounterJadwal({ commit } , payload) {
        return new Promise((resolve, reject) => {
            postCounterJadwal(payload)
                .then((res) => {
                    if (res.status == 200) {
                        // commit('setMemberTrxSuccessAtmGold', "Success");
                        commit('addMemberPostCounter', 'status')
                        //commit('setMemberTotalAtmStockGold' , res.total)

                        // state.offlinecounter.list = res.data.data;
                        // state.offlinecounter.total = res.total;

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    deleteCounterJadwal({ commit } , payload) {
        return new Promise((resolve, reject) => {
            deleteCounterJadwal(payload)
                .then((res) => {
                    if (res.status == 200) {
                        // commit('setMemberTrxSuccessAtmGold', "Success");
                        commit('addMemberPostCounter', 'status')
                        //commit('setMemberTotalAtmStockGold' , res.total)

                        // state.offlinecounter.list = res.data.data;
                        // state.offlinecounter.total = res.total;

                        ///console.log("data fetch", res.trx.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchTransactStokEmas({ commit } , payload) {
        return new Promise((resolve, reject) => {
            ApiFetchStokEmas(payload)
                .then((res) => {
                    if (res.status == 200) {
                         commit('setMemberTransactGold', "Success");
                        //commit('addMemberPostCounter', 'status');
                        state.stokemas.list = res.data;
                        state.stokemas.hargaEmas = res.hargaEmas;
                        state.stokemas.hargaId = res.EmasGoldId;
                        //alert(res.hargaEmas);
                        //state.stokemas.page = res.data.page;
                        state.stokemas.total = res.total;

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editPostSaveEmas({ commit } , payload) {
        return new Promise((resolve, reject) => {
            ApiAddSetorEmas(payload)
                .then((res) => {
                    if (res.status == 200) {
                         commit('setMemberTransactGold', "Success");
                        // //commit('addMemberPostCounter', 'status');
                        // state.stokemas.list = res.data;
                        // state.stokemas.hargaEmas = res.hargaEmas;
                        // state.stokemas.hargaId = res.EmasGoldId;
                        // //alert(res.hargaEmas);
                        // //state.stokemas.page = res.data.page;
                        // state.stokemas.total = res.total;

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    exportTrxExcel({ commit } , payload) {
        return new Promise((resolve, reject) => {
            ApiExportTrxExcel(payload)
                .then((res) => {
                   // if (res.status == 200) {
                        commit('setMemberTransactGold', "Success");
                        //alert("success");
                        fileDownload(res, state.exports.filename);
                        // //commit('addMemberPostCounter', 'status');
                        // state.stokemas.list = res.data;
                        // state.stokemas.hargaEmas = res.hargaEmas;
                        // state.stokemas.hargaId = res.EmasGoldId;
                        // //alert(res.hargaEmas);
                        // //state.stokemas.page = res.data.page;
                        // state.stokemas.total = res.total;

                        resolve(res)
                    //}
                   // reject(res)
                }).catch(res => reject(res))
        })
    },
    exportMemberCorpTrx({ commit } , payload) {
        return new Promise((resolve, reject) => {
            exportMemberCorpTrx(payload)
                .then((res) => {
                   // if (res.status == 200) {
                        commit('setMemberTransactGold', "Success");
                        //alert("success");
                        fileDownload(res, state.exports.filename);
                        // //commit('addMemberPostCounter', 'status');
                        // state.stokemas.list = res.data;
                        // state.stokemas.hargaEmas = res.hargaEmas;
                        // state.stokemas.hargaId = res.EmasGoldId;
                        // //alert(res.hargaEmas);
                        // //state.stokemas.page = res.data.page;
                        // state.stokemas.total = res.total;

                        resolve(res)
                    //}
                   // reject(res)
                }).catch(res => reject(res))
        })
    },
    exportNewTrxExcel({ commit } , payload) {
        return new Promise((resolve, reject) => {
            ApiExportNewTrxExcel(payload)
                .then((res) => {
                   // if (res.status == 200) {
                        commit('setMemberTransactGold', "Success");
                        //alert("success");
                        fileDownload(res, state.exports.filename);
                        // //commit('addMemberPostCounter', 'status');
                        // state.stokemas.list = res.data;
                        // state.stokemas.hargaEmas = res.hargaEmas;
                        // state.stokemas.hargaId = res.EmasGoldId;
                        // //alert(res.hargaEmas);
                        // //state.stokemas.page = res.data.page;
                        // state.stokemas.total = res.total;

                        resolve(res)
                    //}
                   // reject(res)
                }).catch(res => reject(res))
        })
    },
    apiHitDataMemberChart({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiHitDataMemberChart(payload)
                .then((res) => {
                   if (res.status == 200) {
                        commit('setMemberTransactGold', "Success");
                        commit('setTotalMemberNormal' , res.dataMember);
                        commit('settotalMemberFormatted' , res.dataMemberFormatted);
                        commit('setTotalTahunan' , res.arr_tahunan);
                        commit('setTotalBulanan' , res.arr_bulanan);

                        commit('setlastDaytrans' , res.lastDay);

                        //console.log("apiHitDataMemberChart" , res.dataMember);
                       
                        resolve(res)
                    }
                   reject(res)
                }).catch(res => reject(res))
        })
    },
    manualtrx({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiManualTrx(payload)
                .then((res) => {
                   if (res.status == 200) {
                        commit('setMemberTransactGold', "Success");
                        commit('setDataManualList' , res.data);
                        commit('setDataDinManualList' , res.din_ip);
                        commit('setDinTrxDinList' , res.trans_din);
                        commit('setDataDinTrxLog' , res.trans_log);
                       
                        resolve(res)
                    }
                   reject(res)
                }).catch(res => reject(res))
        })
    },
    postTrxManual({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiPostTrxManual(payload)
                .then((res) => {
                   if (res.status == 200) {
                        commit('setMemberTransactGold', "Success");
                        // commit('setDataManualList' , res.data);
                        // commit('setDataDinManualList' , res.din_ip);
                        // commit('setDinTrxDinList' , res.trans_din);
                        // commit('setDataDinTrxLog' , res.trans_log);
                       
                        resolve(res)
                    }
                   reject(res)
                }).catch(res => reject(res))
        })
    },
    withdrawTrx({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiManualWithdrawTrx(payload)
                .then((res) => {
                   if (res.status == 200) {
                        commit('setMemberTransactGold', "Success");
                        resolve(res)
                    }
                   reject(res)
                }).catch(res => reject(res))
        })
    },
    uploadICDX({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiUploadICDX(payload)
                .then((res) => {
                   if (res.status == 200) {
                        commit('setMemberTransactGold', "Success");
                       
                        resolve(res)
                    }
                   reject(res)
                }).catch(res => reject(res))
        })
    },
    exportMemberTrx({ commit } , payload) {
        return new Promise((resolve, reject) => {
            exportMemberTrx(payload)
                .then((res) => {
                  // if (res.status == 200) {
                        //commit('setMemberTransactGold', "Success");

                        commit('setMemberTransactGold', "Success");
                        //alert("success");
                        fileDownload(res, state.exports.filename);
                       
                        resolve(res)
                //     }
                //    reject(res)
                }).catch(res => reject(res))
        })
    },
    addVouchers({ commit } , payload) {
        return new Promise((resolve, reject) => {
            addVouchersNew(payload)
                .then((res) => {
                  if (res.status == 200) {
                        commit('setVouchersMessage', "Success");
                       
                        resolve(res)
                    }
                   reject(res)
                }).catch(res => reject(res))
        })
    },
    editVouchers({ commit } , payload) {
        return new Promise((resolve, reject) => {
            editVouchersNew(payload)
                .then((res) => {
                  if (res.status == 200) {
                        commit('setVouchersMessage', "Success");
                       
                        resolve(res)
                    }
                   reject(res)
                }).catch(res => reject(res))
        })
    },
    getVouchers({ commit } , payload) {
        return new Promise((resolve, reject) => {
            getVouchers(payload)
                .then((res) => {
                  if (res.status == 200) {
                        commit('setVouchersList', res.data);
                        commit('setVouchersTotal', res.total);
                       
                       
                        resolve(res)
                    }
                   reject(res)
                }).catch(res => reject(res))
        })
    },
}

export default {
    namespaced, state, mutations, actions
}