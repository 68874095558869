<template>
  <v-data-table
    :items="$store.state.trxmember.list"
    :options.sync="pagination"
    item-key="id"
    :loading="loading"
    :server-items-length="$store.state.trxmember.total"
    :footer-props="{
      'items-per-page-options': [10, 25, 50, 100],
      'items-per-page-text': 'Baris per Halaman:',
    }"
    :items-per-page="10"
  >
    <template v-slot:header>
      <thead class="v-data-table-header">
        <tr>
          <th v-for="(head, i) in headers" :key="i">
            <div v-html="head.text" style="text-align: center"></div>
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:item="{ item, index }">
      <tr :key="index">
        <td>{{ item.id }}</td>
        <td>
          <div v-if="item.sender != -1">
            {{ item.sender_email }}
          </div>
          <div v-else>
            {{ item.receiver_email }}
          </div>
        </td>
        <td>
          <v-btn color="black" text x-small> {{ item.type }} </v-btn>
        </td>
        <td>
          <v-btn :color="statusColor(item.status)" text x-small>
            {{ item.status }}
          </v-btn>
          <v-btn color="success" text x-small>
            <v-icon x-small>mdi-block-helper</v-icon>
          </v-btn>
        </td>
        <td>
          <div style="text-align: right">
            <small> {{ item.created_at }} </small> <br />
          </div>
          <div style="margin-top: -5px; text-align: right">
            <b> {{ item.updated_at }} </b>
          </div>
        </td>
        <td>
          <div style="text-align: right">
            <b> {{ item.amount }} </b> <br />
          </div>
          <div style="margin-top: -5px; text-align: right">
            <b> {{ item.gramasi }} </b>
          </div>
        </td>
        <td>
          <div style="text-align: right">
            <b> {{ item.buy_rate }} </b> <br />
          </div>
          <div style="margin-top: -5px; text-align: right">
            <small> {{ item.sell_rate }} </small>
          </div>
        </td>
        <td style="text-align: center">
          <v-btn color="success" text small> {{ item.pay_method }} </v-btn>
        </td>
        <td>
          <v-btn color="black" text small> {{ item.admin }} </v-btn>
        </td>
        <td>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                icon
                @click="showDetail(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Detail</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="warning"
                icon
                @click="lockDialog(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-lock</v-icon>
              </v-btn>
            </template>
            <span>Lock Balance</span>
          </v-tooltip>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Email", value: "emailmember" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status_act" },
        {
          text: "Trx Date <br/> Paid Date",
          value: "created_at",
        },
        {
          text: "Paid Amount <br/> Gramasi (gr)",
          value: "amounts",
        },
        { text: "Rate", value: "rates_sell", align: "right" },
        { text: "Payment Method", value: "pay_method", align: "center" },
        { text: "Update By", value: "admin" },
        { text: "Action", align: "left", value: "action" },
      ],
      loading: false,
      pagination: {},
    };
  },
  methods: {
    fetch() {
      this.$store.commit("main/setLoading", true);
      this.$store.commit("trxmember/setPaginationSetting", this.pagination);
      this.$store
        .dispatch("trxmember/fetchTrx")
        .then(() => {
          this.$store.commit("main/setLoading", false);
        })
        .catch((res) =>
          this.$store.commit("main/setSnackbarFail", res.response.data.message)
        );
    },
    statusColor(text) {
      switch (text) {
        case "PENDING":
          return "warning";
        case "SUCCESS":
          return "success";
        default:
          return "black";
      }
    },
  },
  watch: {
    pagination() {
      this.fetch();
    },
  },
};
</script>

<style>
</style>