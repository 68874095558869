<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <CustomCard>

        

        
<template v-slot:toolbar>
            
    <div style="width:100%;">

    <div  style="float:right; margin-top:8px;">

        <v-btn color="success" small outlined>
            Filter <v-icon>mdi-chevron-down</v-icon> 
        </v-btn>

    </div>

    <div  style="float:left;">

        <v-col
                class="pa-2 font-weight-bold"
                outlined
                v-bind="attrs"
                v-on="on"
                style="float:right;"
                @click="showDetail()"
                tile>
                    
                <v-btn color="success" small outlined>
                    <v-icon>mdi-plus</v-icon> Add Pages
                </v-btn>
                    
        </v-col>

    </div>
</div>

&nbsp;

    <!-- <v-btn color="success" small outlined @click="filterToggle">
    filter <v-icon>mdi-chevron-down</v-icon>
    </v-btn> -->

    </template>

            <template v-slot:expansion>
                <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel expand focusable>
                    <v-expansion-panel-content>
                        
                    <v-layout>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.tipe"
                                label="Search By Key"
                                small
                                clearable
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.subject"
                                label="Search By Subject"
                                small
                                clearable
                            >
                            </v-text-field>
                        </v-flex>    
                        
                    </v-layout>

                    <v-layout align-end justify-end>
                        <v-spacer></v-spacer>
                        <v-btn
                        small
                        depressed
                        color="success"
                        class="m0"
                        @click="fetch(1)"
                        >
                        <v-icon>mdi-magnify</v-icon> Search
                        </v-btn>
                    </v-layout>

                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
            </template>

            <template v-slot:datatable>
                <v-flex md12 style="overflow-x: scroll">

                <v-data-table
                    :headers="headers"
                    :items="itemsWithIndex"
                    :options.sync="pagination"
                    :server-items-length="$store.state.webadmin.attributes.total"
                    :footer-props="{
                    'items-per-page-options': [10, 25, 50, 100],
                    'items-per-page-text': 'Baris per Halaman:',
                    }"
                    :items-per-page="10"
                    :loading="loading"
                    :loading-text="loadingtext">

                     <template  v-slot:[`item.actions`]="{ item }">

                        <v-icon @click="edit_data_popup(item)" small>mdi-pencil</v-icon> 

                        <v-dialog
                            transition="dialog-top-transition"
                            max-width="600">
                            
                            <template v-slot:activator="{ on, attrs }">

                            <v-btn
                                color="red"
                                icon
                                v-bind="attrs"
                                style="padding:5px"
                                v-on="on">
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>

                            </template>
                            <span>Delete Web Pages</span>
                            <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                color="red"
                                dark
                                >Delete  Web Pages ? </v-toolbar>
                                
                                <v-container>

                                    <v-layout>
                                        <v-flex>
                                            Yakin akan hapus data Web Pages ini ?
                                        </v-flex>
                                    </v-layout>

                                </v-container>

                                <v-card-actions class="justify-end">
                                    
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="red"
                                    style="color:#ffffff;"
                                    @click="deletePages(dialog , item.id)"
                                >Submit</v-btn>
                                </v-card-actions>
                            </v-card>
                            </template>
                        </v-dialog>

                        <!-- <v-col
                            class="pa-2 font-weight-bold"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            @click="edit_data_popup(item)"
                            tile>
                                    <v-icon>mdi-pencil</v-icon> 
                        </v-col> -->

                    </template>

                    <template v-slot:[`item.status_verified`]="{ item }">

                        <div style="text-align:center;">
                            <div v-if="item.status == 0" style="color:red;"> 
                                <v-icon small color="red">mdi-block-helper</v-icon> 
                                <div style="margin-top:-5px;"> <small> Not Active </small> </div>
                            </div>
                            <div v-if="item.status == 1" style="color:green;"> 
                                <v-icon small color="green">mdi-check</v-icon> 
                                <div style="margin-top:-5px;"> <small>  aktif </small> </div>
                            </div>    
                        </div>

                    </template>


                    <template  v-slot:[`item.details`]="{ item }">

                        <div><b>Title:</b> {{ item.title }} </div>
                        <div><b>URL:</b> {{ item.url }} </div>

                    </template>

                    <template  v-slot:[`item.images`]="{ item }" style="padding:10px;">

                        <v-container>

                            <v-row>
                            
                                <v-dialog
                                    v-model="dialogImage"
                                    width="500"
                                    >

                                    <template v-slot:activator="{ on, attrs }">
                                        <!-- <v-btn
                                        color="red lighten-2"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        > -->
                                        <img v-bind:src="item.url_full_thumb" style="width:150px; padding:10px;"  v-bind="attrs"
                                        v-on="on" />
                                        <!-- </v-btn> -->
                                    </template>

                                    <v-card>
                                        <v-card-title class="text-h5 primary-colors text-light" style="color:#FFF;">
                                        Image Details
                                        </v-card-title>
                                        

                                        <v-card-text style="margin-top:20px;">
                                            <img v-bind:src="item.url_full_thumb" style="width:100%;" />
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <!-- <v-btn
                                            color="primary"
                                            text
                                            @click="dialog = false"
                                        >
                                            Oke
                                        </v-btn> -->
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                        </v-row>

                    </v-container>

                </template>
                    
            </v-data-table>

        </v-flex>
    </template>
  </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";
//import RichTextEditor from 'rich-text-editor-vuetify'


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      searchForm: {
        member: '',
        //addStatusAct:1
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      statusAct: [
            { text: "Aktif", value: 1 },
            { text: "Tidak Aktif", value: 0 }
            // { text: "Gagal", value: "2" },
      ],
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Title", value: "title" , align: "left"},
        { text: "Slug", value: "slug" , align: "left"},
        { text: "Aksi", value: "actions" , align: "left"}
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    onUpdate (text) {
      this.searchForm.richDescNew = text
    },
    edit_data_popup(items){
        this.$store.commit("webadmin/setPageListAction", "edit");
        this.$store.commit("webadmin/setPagesState", "detail");
        this.$store.commit("webadmin/setPagesItem", items);
        //alert(index);
    },
    showDetail() {
        this.$store.commit("webadmin/setPageListAction", "save");
        this.$store.commit("webadmin/setPagesState", "detail");
        //alert(this.$store.state.webadmin.attributes.pageList)
    },
    add_data(){
        this.formLock.add_email = ""
        this.formLock.add_module = ""
        //this.searcForm.addStatusAct = 1;
    },
    deletePages(dialog , index){
        this.$store
            .dispatch("webadmin/deletePages", {
                id:index
            })
            .then(() => {
                this.fetch(0);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    uploadWebAdmin(dialog){
        this.$store
            .dispatch("webadmin/postPopUpNew", {
                //find: params,
                // file: this.formLock.upload_files
                image:this.searchForm.addImagesUrl, 
                title:this.searchForm.addTitles, 
                url:this.searchForm.addUrlLink, 
                status:this.searchForm.addStatusAct 
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    post_edit_data(dialog){
        this.$store
            .dispatch("webadmin/editPopUpNew", {
                //find: params,
                // file: this.formLock.upload_files
                id:this.searchForm.editIndex,
                image:this.searchForm.editImagesUrl, 
                title:this.searchForm.editTitles, 
                url:this.searchForm.editUrlLink, 
                status:this.searchForm.editStatusAct 
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    fetch(cari) {
      this.dialogImage = false
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      this.$store
        .dispatch("webadmin/fetchWebPages", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            key:this.searchForm.tipe,
            subject:this.searchForm.subject
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });

    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
    //this.fetchTotal("");
  },
  watch: {
    pagination() {
        this.dialogImage = false
        //alert(this.$store.state.member.member.is_back);
        this.fetch(0)
    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
    itemsWithIndex() {
      return this.$store.state.webadmin.attributes.listPages.map(
        (items, index) => ({
          ...items,
          no:index,
        }))
    }
  },
  components: { CustomCard  },
};
</script>

<style>
</style>