var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CustomCard',{scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-btn',{attrs:{"color":"success","small":"","outlined":""},on:{"click":_vm.filterToggle}},[_vm._v(" filter "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true},{key:"expansion",fn:function(){return [_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{attrs:{"expand":"","focusable":""}},[_c('v-expansion-panel-content',[_c('v-layout',[_c('v-flex',[_c('v-text-field',{attrs:{"label":"(Name, Email, Payment No, Ref ID, iPaymu ID)","single-line":"","small":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13){ return null; }return _vm.fetch(1)}},model:{value:(_vm.searchForm.member),callback:function ($$v) {_vm.$set(_vm.searchForm, "member", $$v)},expression:"searchForm.member"}})],1),_c('v-flex',[_c('v-autocomplete',{attrs:{"items":_vm.statusPembayaran,"item-text":"text","item-value":"value","label":"Status"},model:{value:(_vm.searchForm.statusPembayaran),callback:function ($$v) {_vm.$set(_vm.searchForm, "statusPembayaran", $$v)},expression:"searchForm.statusPembayaran"}})],1),_c('v-flex',[_c('v-autocomplete',{attrs:{"items":_vm.typetransaksi,"item-text":"text","item-value":"value","label":"RPX Service"},model:{value:(_vm.searchForm.typetransaksi),callback:function ($$v) {_vm.$set(_vm.searchForm, "typetransaksi", $$v)},expression:"searchForm.typetransaksi"}})],1)],1),_c('v-layout',[_c('v-flex',[_c('v-menu',{attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.searchForm.date),callback:function ($$v) {_vm.$set(_vm.searchForm, "date", $$v)},expression:"searchForm.date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.searchForm.date),callback:function ($$v) {_vm.$set(_vm.searchForm, "date", $$v)},expression:"searchForm.date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-flex',[_c('v-menu',{attrs:{"close-on-content-click":false,"return-value":_vm.date2,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date2=$event},"update:return-value":function($event){_vm.date2=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.searchForm.date2),callback:function ($$v) {_vm.$set(_vm.searchForm, "date2", $$v)},expression:"searchForm.date2"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.searchForm.date2),callback:function ($$v) {_vm.$set(_vm.searchForm, "date2", $$v)},expression:"searchForm.date2"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-layout',{attrs:{"align-end":"","justify-end":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"m0",attrs:{"small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.fetch(1)}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Search ")],1)],1)],1)],1)],1)]},proxy:true},{key:"datatable",fn:function(){return [_c('v-flex',{staticStyle:{"overflow-x":"scroll"},attrs:{"md12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.state.transaction.hisgold.list,"options":_vm.pagination,"server-items-length":_vm.$store.state.transaction.hisgold.total,"footer-props":{
                        'items-per-page-options': [10, 25, 50, 100],
                        'items-per-page-text': 'Baris per Halaman:',
                        },"items-per-page":10,"loading":_vm.loading,"loading-text":_vm.loadingtext},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.member",fn:function(ref){
                        var item = ref.item;
return [_c('div',[_c('b',[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.amountall",fn:function(ref){
                        var item = ref.item;
return [_c('div',[_vm._v(" Rp. "),_c('b',[_vm._v(" "+_vm._s(item.amount)+" ")])])]}},{key:"item.totalgr",fn:function(ref){
                        var item = ref.item;
return [_c('div',[_c('b',[_vm._v(" "+_vm._s(item.gramasi)+" ")]),_vm._v(" GR ")]),_c('div',[_vm._v(" Rp. "+_vm._s(item.sell_rate)+" ")])]}},{key:"item.kepingemas",fn:function(ref){
                        var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.keping)}})]}},{key:"item.biayafee",fn:function(ref){
                        var item = ref.item;
return [_c('v-row',[_c('v-col',{staticStyle:{"fontSize":"12px","padding":"0","margin":"0"},attrs:{"sm":"3","md":"3"}},[_vm._v(" Fee ")]),_c('v-col',{staticStyle:{"fontSize":"12px","padding":"0","margin":"0"},attrs:{"sm":"1","md":"1"}},[_vm._v(" : ")]),_c('v-col',{staticStyle:{"fontSize":"12px","padding":"0","margin":"0"},attrs:{"sm":"7","md":"7"}},[_vm._v(" "+_vm._s(item.fee)+" ")])],1),_c('v-row',[_c('v-col',{staticStyle:{"fontSize":"12px","padding":"0","margin":"0"},attrs:{"sm":"3","md":"3"}},[_vm._v(" Ongkir ")]),_c('v-col',{staticStyle:{"fontSize":"12px","padding":"0","margin":"0"},attrs:{"sm":"1","md":"1"}},[_vm._v(" : ")]),_c('v-col',{staticStyle:{"fontSize":"12px","padding":"0","margin":"0"},attrs:{"sm":"7","md":"7"}},[_vm._v(" Rp. "+_vm._s(item.ongkir)+" ")])],1)]}},{key:"item.tipepengambilan",fn:function(ref){
                        var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.tipe_pengambilan.toLowerCase().replaceAll(" ","").replace("vending-0" , "Vending - AtmDinaran01").replace("vending-" , "OFFLINE").replace("offlinecounterjkt" , "Offline Counter Jkt"))}})]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":""},on:{"click":function($event){return _vm.showDetail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Detail")])])]}},{key:"item.email",fn:function(ref){
                        var item = ref.item;
return [(item.type_code == 1 || item.type_code == 10)?_c('div',[_vm._v(" "+_vm._s(item.receiver_email)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.sender_email)+" ")])]}}],null,true)})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }