import GamesDinaran from '../../views/pages/games/mainGamesDinaranReward.vue'
import ScoresDinaran from '../../views/pages/games/mainScoresDinaranReward.vue'
import MainComponent from '../../views/Main.vue'

export default [
    {
        path: '/panello/',
        name: 'Main Transaction',
        component: MainComponent,
        children: [
            {
                path: '/panello/games-dinaran',
                name: 'Games Reward Dinaran List',
                component: GamesDinaran
            },
            {
                path: '/panello/score-games',
                name: 'Scores Games Dinaran List',
                component: ScoresDinaran
            },
        ]
    }
]