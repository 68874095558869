<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <CustomCard>

        <template v-slot:toolbar>
        
            <v-col
                class="pa-2 font-weight-bold"
                outlined
                    @click="filterToggle"
                tile>
                            
                    <v-btn color="success" small outlined>
                        <v-icon>mdi-chevron-down</v-icon> Filter 
                    </v-btn>
                            
            </v-col>

        </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                    <v-flex>
                        <v-autocomplete
                                v-model="searchForm.tipe_transaksi"
                                :items="tipe_transaksi"
                                item-text="text"
                                item-value="value"
                                label="Search By Tipe"
                            >
                        </v-autocomplete>
                    </v-flex>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.tipe_search"
                            label="Search "
                            small
                            clearable
                        >
                        </v-text-field>
                    </v-flex>    
                    
                </v-layout>

                

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>

                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">

            <v-data-table
                :headers="headers"
                :items="$store.state.settings.attributes.listTrxModule"
                :options.sync="pagination"
                :server-items-length="$store.state.settings.attributes.totalTrxModule"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

                    

                    <template  v-slot:[`item.aksi`]="{ item }">

                    <v-container>

                        <v-row>
                        
                <v-col
                    class="pa-2 font-weight-bold"
                    outlined
                    tile>
                        
                    <v-icon small 
                    color="success"
                    style="padding:5px; margin-top:-3px;"
                    @click="showDetail(item)">
                        mdi-eye
                    </v-icon>             
                </v-col>

                        </v-row>

                    </v-container>

                    </template>
                    

                    {{ someDate | moment("YYYY") }}

            </v-data-table>

        </v-flex>
    </template>
  </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      tipe_transaksi: [
            { text: "Semua Tipe", value: "all" },
            { text: "Request Pembelian", value: "buy" },
            { text: "Notifikasi Pembelian", value: "trx-notify" },
            { text: "Penjualan", value: "withdraw" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "No", value: "id" , align: "left"},
        { text: "Time", value: "time" , align: "left"},
        { text: "IP Address", value: "ip" , align: "left"},
        { text: "Url", value: "url" , align: "left"},
        { text: "Action", value: "aksi" , align: "left"}
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    editTemplate(items){
        this.formLock.edit_index_template = items.id
        this.formLock.edit_email = items.member.email
        this.formLock.edit_module = items.modul
        //alert(index);
    },
    add_data(){
        this.formLock.add_email = ""
        this.formLock.add_module = ""
    },
    editTemplatePost(dialog){
        //alert("posted");
        this.$store
            .dispatch("settings/editModuleSettings", {
                email: this.formLock.edit_email,
                access: this.formLock.edit_module
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    
    addTemplatePost(dialog){
        this.$store
            .dispatch("settings/addModuleSettings", {
                //find: params,
                email: this.formLock.add_email,
                access: this.formLock.add_module
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    deleteTemplate(dialog , index){
         this.$store
            .dispatch("settings/deleteEmailTemplate", {
                //find: params,
                id: index,
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
            });
    },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      //alert("limits "+this.pagination.itemsPerPage);
      
      this.$store
        .dispatch("settings/fetchTrxModule", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            type:this.searchForm.tipe_transaksi,
            search:this.searchForm.tipe_search
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        this.$store.commit("settings/setPageTrx", "detail");
        this.$store.commit("settings/setTrxListDetail", item);
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
    //this.fetchTotal("");
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        }

        if(this.$store.state.member.member.is_back == 1){
            this.pagination.page = this.$store.state.member.member.paging_page;
        }

        this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>