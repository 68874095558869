<template>
  <div>
    <ModalDialog></ModalDialog>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <v-container class="no-pad-left">
      <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600">
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="success" dark>Approve User Corporate</v-toolbar>

            <v-container>
              <v-layout>
                Apakah anda akan menjadikan user ini sebagai user corporate ?
              </v-layout>
            </v-container>

            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
              <v-btn color="success" @click="approveCorporate()">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-row no-gutters>

        <v-col sm="3" md="3">
          <v-card elevation="2" style="border-top: 3px solid rgb(40, 167, 69)">
            <v-col cols="12" sm="12" md="12" class="text-center">
              <v-icon medium color="green">mdi-clipboard-account</v-icon>
              <!-- <v-icon>mdi-account-card-details</v-icon> -->
              <h5 style="font-size: 28px; color: #888">
                {{ $store.state.member.member.totaluserreg }}
              </h5>
              <small> Total User Register </small>
            </v-col>
          </v-card>
        </v-col>

        <v-col sm="3" md="3" style="margin-left: 20px">
          <v-card elevation="2" style="border-top: 3px solid rgb(40, 167, 69)">
            <v-col cols="12" sm="12" md="12" class="text-center">
              <v-icon medium color="green">mdi-account-check</v-icon>
              <!-- <v-icon>mdi-account-card-details</v-icon> -->
              <h5 style="font-size: 28px; color: #888">
                {{ $store.state.member.member.totaluserver }}
              </h5>
              <small> Total User Verified </small>
            </v-col>
          </v-card>
        </v-col>

        <v-col sm="3" md="3" style="margin-left: 20px">
          <v-card elevation="2" style="border-top: 3px solid rgb(40, 167, 69)">
            <v-col cols="12" sm="12" md="12" class="text-center">
              <v-icon medium color="green">mdi-account-check</v-icon>
              <h5 style="font-size: 28px; color: #888">
                {{ $store.state.member.member.totaluseractive }}
              </h5>
              <small> Total User Active </small>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <CustomCard>
      <template v-slot:toolbar>
        <div style="width: 100%">
          <v-btn color="success" small outlined @click="filterToggle" style="float: left">
            filter <v-icon>mdi-chevron-down</v-icon>
          </v-btn>

          <!-- <v-btn color="success" small outlined @click="downloadExcels(1)" style="float:left; margin-left:20px;">
                    Export <v-icon>mdi-download</v-icon>
                </v-btn> -->

          <v-btn color="success" small outlined @click="isAwal = true; fetch(pagination.page)" style="float: right">
            <v-icon>mdi-refresh</v-icon> Refresh
          </v-btn>
        </div>

        <v-btn color="success" small outlined style="margin-left: 20px" @click="downloadExcels(1)">
          Export By Filter <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>

      <template v-slot:expansion>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel expand focusable>
            <v-expansion-panel-content>
              <v-layout>
                <v-flex>
                  <v-text-field v-model="searchForm.userindex" label="Search By DINARAN NUMBER" single-line small
                    clearable @keyup.13="searchMember(1)">
                  </v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field v-model="searchForm.member" label="Search By Email / Nama / Phone" single-line small
                    clearable @keyup.13="searchMember(1)">
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex>
                  <v-autocomplete v-model="searchForm.status_verified" :items="status" item-text="text"
                    item-value="value" label="Status Verified">
                  </v-autocomplete>
                </v-flex>
                <v-flex>
                  <v-autocomplete v-model="searchForm.monthDate" :items="bulan" item-text="text" item-value="value"
                    label="Month BirthDate">
                  </v-autocomplete>
                </v-flex>

                <v-flex>
                  <v-autocomplete v-model="searchForm.religion" :items="religion" item-text="text" item-value="value"
                    label="Religion">
                  </v-autocomplete>
                </v-flex>
                <v-flex>
                  <v-autocomplete v-model="searchForm.gender" :items="gender" item-text="text" item-value="value"
                    label="Gender">
                  </v-autocomplete>
                </v-flex>

                <v-flex>
                  <v-autocomplete v-model="searchForm.provinsiList" :items="provinsiList" item-text="provinsi"
                    item-value="provinsi" label="Provinsi">
                  </v-autocomplete>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-select :items="balanceitems" label="Balance Search Option"
                      v-model="searchForm.inpbalopt"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateRangeText" label="Register Date" prepend-icon="mdi-calendar"
                          clearable readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="form.date" no-title scrollable range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-spacer></v-spacer>
                </v-row>
              </v-layout>

              <v-layout align-end justify-end>
                <v-spacer></v-spacer>
                <v-btn small depressed color="success" class="m0" @click="fetch(1)">
                  <v-icon>mdi-magnify</v-icon> Search
                </v-btn>
              </v-layout>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template v-slot:datatable>
        <v-flex md12 style="overflow-x: scroll">
          <v-data-table :headers="headers" :items="$store.state.member.member.list" :options.sync="pagination"
            :server-items-length="$store.state.member.member.total" :footer-props="{
              'items-per-page-options': [10, 25, 50, 100],
              'items-per-page-text': 'Baris per Halaman:',
            }" :items-per-page="10" :loading="loading" :loading-text="loadingtext">
            <template v-slot:[`item.name`]="{ item }">
              <div>
                <v-icon small @click="showExpand($event, item.id)">mdi-{{ icons }}</v-icon>
                <b> {{ item.name }} </b> <br />
                <div class="sub-items-tables">{{ item.email }}</div>
              </div>
              <div v-bind:id="item.id" class="hidden sub-items-tables">
                {{ item.format_id }} <br />
                {{ item.phone }} <br />
                {{ item.religion }} <br />
                Birthdate : {{ item.date_of_birth }}
              </div>
            </template>

            <template v-slot:[`item.trxCount`]="{ item }">
              <div style="text-align: right">
                <b> {{ item.trxCount }} </b> <br />
              </div>
            </template>

            <template v-slot:[`item.profitFormatloss`]="{ item }">
              <div style="text-align: right">
                <b> {{ item.profitloss != null ? item.profitloss.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0 }} </b> <br />
              </div>
            </template>

            <template v-slot:[`item.bal_gramasi`]="{ item }">
              <div style="text-align: right">
                <b> {{ item.bal_gramasi }} </b> <br />
              </div>
              <div style="margin-top: -5px; text-align: right">
                <small> {{ item.real_gramasi }} </small>
              </div>
              <div style="margin-top: -5px; text-align: right">
                <small> 
                  <span v-if="item.badge === 'REGULAR'"> {{ item.badge }} </span> 
                  <span v-if="item.badge === 'SILVER'" style="color:#666666; font-weight:bold;"> {{ item.badge }} </span> 
                  <span v-if="item.badge === 'GOLD'" style="color:rgb(204,105,0); font-weight:bold;"> {{ item.badge }} </span>
                  <span v-if="item.badge === 'PLATINUM'" style="color:#4D4F51; font-weight:bold;"> {{ item.badge }} </span>
                </small>
              </div>
            </template>

            <template v-slot:[`item.lock_balance`]="{ item }">
              <div style="text-align: right">
                <div>
                  <small>
                    Emas Impian
                    <v-icon small @click="showExpand($event, item.id)">mdi-chevron-right</v-icon>
                    <b> {{ item.lock_emasimpian }} </b>
                  </small>
                </div>
                <div style="margin-top: -5px">
                  <small>
                    Dinaran
                    <v-icon small @click="showExpand($event, item.id)">mdi-chevron-right</v-icon>
                    <b> {{ item.lock_dinaran }} </b>
                  </small>
                </div>
                <div style="margin-top: -5px">
                  <small>
                    User
                    <v-icon small @click="showExpand($event, item.id)">mdi-chevron-right</v-icon>
                    <b> {{ item.lock_user }} </b>
                  </small>
                </div>
              </div>
            </template>

            <template v-slot:[`item.verified_date`]="{ item }">
              <div style="font-size:12px!important;">{{ item.created_at }}</div>
            </template>

            <template v-slot:[`item.em_verified`]="{ item }">
              <v-layout row wrap>
                <v-flex xs6>
                  <div v-if="item.email_verified == 0" style="color: red">
                    <v-icon small color="red">mdi-email-outline</v-icon>
                  </div>
                  <div v-if="item.email_verified == 1" style="color: green">
                    <v-icon small color="green">mdi-email-outline</v-icon>
                    <div><small style="font-size:10px;">{{
                      item.email_ver | moment("YYYY-MM-DD, h:mm:ss a")
                    }}</small></div>
                  </div>
                </v-flex>
                <v-flex xs6>
                  <div v-if="item.is_block == false" style="color: red">
                    <div v-if="item.verified == 0" style="color: red">
                      <v-icon small color="red">mdi-account-alert</v-icon>
                    </div>
                    <div v-if="item.verified == 1" style="color: green">
                      <v-icon small color="green">mdi-account-check</v-icon>
                    </div>
                  </div>

                  <div v-if="item.is_block == true" style="color: red">
                    <!-- <div v-if="item.verified == 0" style="color:red;">  -->
                    <v-icon small color="red">mdi-block-helper</v-icon>
                    <!-- </div> -->
                  </div>
                </v-flex>
              </v-layout>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-dialog transition="dialog-top-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="info" icon v-bind="attrs" v-on="on" @click="
                    showLock(
                      item.id,
                      item.real_gramasi,
                      item.lock_user,
                      item.lock_dinaran
                    )
                  ">
                    <v-icon small>mdi-lock</v-icon>
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="success" dark>Lock Member Balance</v-toolbar>

                    <v-text-field v-model="formLock.userId" hidden label="">
                    </v-text-field>

                    <v-container>
                      <v-layout>
                        <v-flex>
                          <v-text-field v-model="formLock.last_balance" label="Last Balance" disabled>
                          </v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout>
                        <v-flex>
                          <v-text-field v-model="formLock.lock_user" label="Lock Balance by User" value="0">
                          </v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout>
                        <v-flex>
                          <v-text-field v-model="formLock.lock_dinaran" label="Lock Balance by Dinaran" value="0">
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false">Close</v-btn>
                      <v-btn color="success" @click="submitLock(dialog)">Submit</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" icon @click="showDetail(item)" v-bind="attrs" v-on="on">
                    <v-icon small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Detail</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" icon @click="showCorporate(item)" v-bind="attrs" v-on="on"
                    v-if="item.corporate == 0 && item.verified == 1">
                    <v-icon small>mdi-account-group</v-icon>
                  </v-btn>
                </template>
                <span>Add Corporate</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.email`]="{ item }">
              <div v-if="item.type_code == 1 || item.type_code == 10">
                {{ item.receiver_email }}
              </div>
              <div v-else>
                {{ item.sender_email }}
              </div>
            </template>
          </v-data-table>
        </v-flex>
      </template>
    </CustomCard>
  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";
import ModalDialog from "./component/dialog/dialogUpdateCorporate.vue";

export default {
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      modal: false,
      panel: null,
      profiles: this.$store.state.auth.profile,
      provinsiList: [],
      bankList: [],
      currentPage: 1,
      selectedIndex: [],
      isAwal:true,
      dialog: false,
      decimals: "10.00",
      icons: "chevron-right",
      status: [
        { text: "All Status", value: "" },
        { text: "Email Verified", value: "email_verified" },
        { text: "Email Not Verified", value: "email_not_verified" },
        { text: "User Verified", value: "user_verified" },
        { text: "User Not Verified", value: "user_not_verified" },
        { text: "User Suspend", value: "user_suspend" },
        // { text: "Gagal", value: "2" },
      ],
      json_fields: {
        Id: "id",
        Name: "name",
        Email: "email",
      },
      balanceitems: [
        { text: "All", value: "" },
        { text: "Regular ( > 0 and < 10 )", value: "REGULAR" },
        { text: "Silver ( > 10 and <= 20 )", value: "SILVER" },
        { text: "Gold ( > 20 and <= 50 )", value: "GOLD" },
        { text: "Platinum ( > 50 )", value: "PLATINUM" },
        // { text: "Gagal", value: "2" },
      ],
      gender: [
        { text: "All Gender", value: "" },
        { text: "Laki-Laki", value: "Laki-Laki" },
        { text: "Perempuan", value: "Perempuan" },
        // { text: "Gagal", value: "2" },
      ],
      religion: [
        { text: "All Religion", value: "" },
        { text: "Hindu", value: "Hindu" },
        { text: "Katolik", value: "Katolik" },
        { text: "Kristen", value: "Kristen" },
        { text: "Buddha", value: "Buddha" },
        { text: "Islam", value: "Islam" },
        { text: "Konghucu", value: "Konghucu" },
        // { text: "Gagal", value: "2" },
      ],
      bulan: [
        { text: "Januari", value: "01" },
        { text: "Februari", value: "02" },
        { text: "Maret", value: "03" },
        { text: "April", value: "04" },
        { text: "Mei", value: "05" },
        { text: "Juni", value: "06" },
        { text: "Juli", value: "07" },
        { text: "Agustus", value: "08" },
        { text: "September", value: "09" },
        { text: "Oktober", value: "10" },
        { text: "November", value: "11" },
        { text: "Desember", value: "12" },
        // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: "",
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock: {},
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Member", value: "name" },
        { text: "Trx", align: "center", value: "trxCount" , width: "80", },
        { text: "Balance (gr)", align: "center", value: "bal_gramasi" , width: "120", },
        { text: "Profit Loss", align: "center", value: "profitFormatloss" , width: "120", },
        {
          text: "Lock Bal (gr)",
          align: "center",
          value: "lock_balance",
          width: "150",
        },
        { text: "Reg Date", value: "verified_date", width: "100" },
        { text: "Verification", value: "em_verified", width: "200", align: "center" },
        {
          text: "Update By",
          align: "center",
          value: "tukangupdate",
          width: "120",
        },
        { text: "Action", align: "left", value: "action", width: "150" },
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    fetchProv(params) {
      this.$store
        .dispatch("wilayah/fetchWilayah", {
          find: params,
        })
        .then(() => {
          this.provinsiList = this.$store.state.wilayah.wilayah.list;
        })
        .catch(() => {
          // console.log("prov", res.response.data.message);
        });
    },
    fetchTotal(params) {
      this.$store
        .dispatch("member/fetchtotals", {
          find: params,
        })
        .then(() => {
        })
        .catch(() => {
          // console.log("prov", res.response.data.message);
        });
    },
    fetchAll() {
      this.$store
        .dispatch("member/fetchListMemberAll", {
          page: this.pagination.page,
          member: this.searchForm.member,
          religion: this.searchForm.religion,
          status_verified: this.searchForm.status_verified,
          provinsi: this.searchForm.provinsiList,
          gender: this.searchForm.gender,
          date: this.searchForm.date,
          date2: this.searchForm.date2,
          monthDate: this.searchForm.monthDate,
          userindex: this.searchForm.userindex
        })
        .then((res) => {
         // console.log("tesData", res);
          return res;
        })
        .catch((res) => {
          console.log("fetchdata", res.response.data.message);
        });
    },
    startDownload() {
      alert("show loading");
    },
    finishDownload() {
      alert("hide loading");
    },
    showCorporate(item) {
      this.dialog = true;
      this.selectedIndex = item;
    },
    approveCorporate() {
      this.dialog = false;
      this.$store.state.member.member.dialogApproveCorporate = true;
      this.$store.state.member.member.selectedIndex = this.selectedIndex;

      this.$store.state.corporate.editform.editNamaUsaha = "";
      this.$store.state.corporate.editform.editIjinUsaha = "";
      this.$store.state.corporate.editform.websiteUtama  = "";
      this.$store.state.corporate.editform.addrCorporate = "";
      this.$store.state.corporate.editform.rekeningkoran = "";
      this.$store.state.corporate.editform.editKodePost = "";
      this.$store.state.corporate.editform.provinsi = "";
      this.$store.state.corporate.editform.editKabupaten = "";
    },
    downloadExcels(typeId) {
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = "Downloading Report ... Please wait";

      var today = new Date().toLocaleDateString();
      this.$store.state.member.exports.filename =
        "Trx-Export-Member-List-" + today + ".xls";

      let datecari1 = "";
      let datecari2 = "";

      if (this.dateRangeText != null) {
        let datecari = this.dateRangeText.split("-");
        datecari1 = datecari[0].trim() + "-" + datecari[1].trim() + "-" + datecari[2].trim();
        datecari2 = datecari[3].trim() + "-" + datecari[4].trim() + "-" + datecari[5].trim();
      }

      this.$store
        .dispatch("member/exportMemberExcel", {
          member: this.searchForm.member,
          religion: this.searchForm.religion,
          filterMemberStatus: this.searchForm.status_verified,
          provinsi: this.searchForm.provinsiList,
          gender: this.searchForm.gender,
          filterMemberStartdate: datecari1,
          filterMemberEnddate: datecari2,
          monthDate: this.searchForm.monthDate,
          typeId: typeId,
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
        })
        .catch((res) => {
          this.$store.commit("main/setSnackbarFail", res);
        });
    },
    fetch(cari) {
      if (cari == 1) {
        this.pagination.page = 1;
      }

      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = "Loading... Please wait";

      let datecari1 = "";
      let datecari2 = "";

      if (this.dateRangeText != null) {
        let datecari = this.dateRangeText.split("-");
        datecari1 = datecari[0].trim() + "-" + datecari[1].trim() + "-" + datecari[2].trim();
        datecari2 = datecari[3].trim() + "-" + datecari[4].trim() + "-" + datecari[5].trim();
      }

      //alert("limits "+this.pagination.itemsPerPage);
      let sortsDesc = "DESC";
      let sortsId = "id"

      if(this.isAwal === false){
        sortsDesc = this.pagination.sortDesc[0] === true ? "DESC" : "ASC";
        sortsId = this.pagination.sortBy.length === 0 ? "id" : this.pagination.sortBy[0];

        if(sortsId === "verified_date"){
          sortsId = "created_at";
        }

        if(sortsId === "tukangupdate"){
          sortsId = "updated_by";
        }
      }

      this.$store
        .dispatch("member/fetchListMember", {
          page: this.pagination.page,
          itemsPerPage: this.pagination.itemsPerPage,
          sortBy: sortsId,
          sortDesc: sortsDesc,
          member: this.searchForm.member,
          religion: this.searchForm.religion,
          status_verified: this.searchForm.status_verified,
          provinsi: this.searchForm.provinsiList,
          gender: this.searchForm.gender,
          date: datecari1,
          date2: datecari2,
          monthDate: this.searchForm.monthDate,
          userindex: this.searchForm.userindex,
          gramasi: this.searchForm.inpbalopt
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = "";
          this.isAwal = false;
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = "";
        });
    },
    getDaysArray(start, end) {
      for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        const dateVal = new Date(dt).toISOString().split('T')[0];
        if (this.getAllowedDates(dateVal)) {
          arr.push(dateVal);
        }
      }
      return arr;
    },
    searchMember(cari) {
      if (cari == 1) {
        this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = "Loading... Please wait";
      if (typeof this.form.date != "undefined") {
        if (this.form.date.length > 1) {
          this.searchForm.date = this.form.date[0];
          this.searchForm.date2 = this.form.date[1];
        } else {
          this.searchForm.date = this.form.date[0];
        }
      }

      //alert("limits "+this.pagination.itemsPerPage);

      this.$store
        .dispatch("member/fetchListMember", {
          page: this.pagination.page,
          itemsPerPage: this.pagination.itemsPerPage,
          member: this.searchForm.member,
          religion: this.searchForm.religion,
          status_verified: this.searchForm.status_verified,
          provinsi: this.searchForm.provinsiList,
          gender: this.searchForm.gender,
          date: this.searchForm.date,
          date2: this.searchForm.date2,
          monthDate: this.searchForm.monthDate,
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = "";
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = "";
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
      //this.$store.commit("main/setLoading", false);
      //this.$store.commit("main/setSnackbarFail", res.response.data.message);
      this.$store.state.member.member.paging_page = this.pagination.page;
      this.$store.commit("member/setMemberPage", "detail");
      this.$store.commit("member/setMemberDetail", item.id);
      this.loading = false;
      this.loadingtext = "";
    },
    showExpand: function (element, index) {
      const detailElement = document.getElementById(index);
      if (detailElement.classList.contains("hidden")) {
        element.target.classList.remove("mdi-chevron-right");
        element.target.classList.add("mdi-chevron-down");
        detailElement.classList.remove("hidden");
        //this.icons = "chevron-down";
      } else {
        element.target.classList.add("mdi-chevron-right");
        element.target.classList.remove("mdi-chevron-down");
        detailElement.classList.add("hidden");
        //this.icons = "chevron-right";
      }
    },
    showLock: function (userId, real_gramasi, lock_user, lock_dinaran) {
      this.formLock.userId = userId;
      this.formLock.last_balance = real_gramasi;
      this.formLock.lock_user = lock_user;
      this.formLock.lock_dinaran = lock_dinaran;
    },
    submitLock: function (dialog) {
      //this.$store.commit("main/setLoading", true);
      this.$store
        .dispatch("member/postLockGold", {
          userId: this.formLock.userId,
          lockdinaran: this.formLock.lock_dinaran,
          lockuser: this.formLock.lock_user,
          updateId: this.profiles.id,
          page: this.pagination.page,
        })
        .then(() => {
          //   this.$store.commit("main/setLoading", false);
          //   this.loading = false;
          //   this.loadingtext = '';

          this.fetch(0);
          dialog.value = false;
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = "";
        });
    },
  },
  mounted() {
    this.fetchProv("provinsi");
    this.fetchTotal("");

    this.$store.state.member.member.formStates = false;
    // alert(this.$store.state.user.ac_read);

    //alert(this.$store.state.user.ac_read);
  },
  watch: {
    pagination() {
      //alert(this.$store.state.member.member.is_back);
      if (this.$store.state.member.member.is_back != 1) {
        this.loading = true;
        this.loadingtext = "Loading... Please wait";
        // alert("tes");
        //console.log("user_con" , "nils "+this.profiles.id);
        //alert("tes");

        this.fetch(0);
      }

      if (this.$store.state.member.member.is_back == 1) {
        this.pagination.page = this.$store.state.member.member.paging_page;
      }

      this.$store.state.member.member.is_back = 0;
    },
    searchSponsor(val) {
      val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
      val && val !== this.searchForm.member && this.getMember(val);
    },
  },

  computed: {
    dateRangeText() {
      if (typeof this.form.date != "undefined") {
        if (this.form.date.length > 1) {
          return this.form.date.join(" - ");
        } else {
          return this.form.date[0];
        }
      } else {
        return null;
      }
    },
  },
  //components: { CustomCard , downloadexcel },
  components: { CustomCard , ModalDialog },
};
</script>

<style>

</style>