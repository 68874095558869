<template>
<v-layout row wrap>

<v-container>

  <v-flex md12 class="px-2">

    <CustomCard :cardShow="true">

      <template v-slot:toolbar>

        <div style="width:100%;">
          <div style="float:left;">Export Gold Rate</div>
            <div style="float:right;">
                <v-btn
                color="success"
                small
                outlined
                @click="$store.commit('transaction/setMemberHisGoldPage', 'list')">
                back
                </v-btn>
            </div>
        </div>
        
      </template>

      <template v-slot:content>
          <v-layout>
                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date"
                                label="Start Date"
                                prepend-icon="mdi-calendar"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="date2"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date2"
                                label="End Date"
                                prepend-icon="mdi-calendar"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date2"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu2 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date2)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                 <v-flex>
                    <v-btn
                        color="green"
                        style="margin-top:12px;"
                        @click="downloadExcels(1)"
                        >
                            <v-icon small color="white">mdi-download</v-icon>
                    </v-btn>
                </v-flex>

          </v-layout>
      </template>
    </CustomCard>

  </v-flex>

  </v-container>

</v-layout>
  
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      feeList: [
            { text: "Yes", value: 1 },
            { text: "No", value: 0 }
            // { text: "Gagal", value: "2" },
      ],
      feeList2: [
            { text: "Yes", value: 1 },
            { text: "No", value: 0 }
            // { text: "Gagal", value: "2" },
      ],
      feeList3: [
            { text: "Yes", value: 1 },
            { text: "No", value: 0 }
            // { text: "Gagal", value: "2" },
      ],
      feeList4: [
            { text: "Yes", value: 1 },
            { text: "No", value: 0 }
            // { text: "Gagal", value: "2" },
      ],
      awb_no:"",
      counter:"",
      jadwal_pengambilan:"",
      verified:"",
      details:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      loading: false,
      loadingText: "Loading Data...",
      searchForm:{
          date:""
      },
      formLock:{
          date:""
      }
    };
  },
  methods: {
    downloadExcels(typeId){
        this.$store.commit("main/setLoading", true);
        this.loading = true;
        this.loadingtext = 'Downloading Report ... Please wait';

        
        let startsdate = this.searchForm.date;
        let endssdate = this.searchForm.date2;

        var today= new Date().toLocaleDateString();
        this.$store.state.goldrate.exports.filename = "Trx-Export-Gold-Rate-"+today+".xls";

        this.$store
            .dispatch("goldrate/exportTrxExcel", {
                type:typeId,
                startdate:startsdate,
                enddate:endssdate
            })
            .then(() => {
                this.$store.commit("main/setLoading", false);
                this.loading = false;
            })
            .catch((res) => {
                this.$store.commit("main/setSnackbarFail", res);
            });
    },
    downloadNewExcels(typeId){
        this.$store.commit("main/setLoading", true);
        this.loading = true;
        this.loadingtext = 'Downloading Report ... Please wait';

        var today= new Date().toLocaleDateString();

        this.$store.state.transaction.exports.filename = "Trx-Export-Ambil-emas-"+today+".xls";

        this.$store
            .dispatch("transaction/exportNewTrxExcel", {
                type:typeId,
                fee:this.formLock.feeList3,
                startdate:this.searchForm.date5,
                enddate:this.searchForm.date6
        })
        .then(() => {
            this.$store.commit("main/setLoading", false);
            this.loading = false;
        })
        .catch((res) => {
            this.$store.commit("main/setSnackbarFail", res.response.data.message);
        });
    },
    fetch() {
      //alert("new tes");

      this.$store
        .dispatch("transaction/fetchMemberListHisGold", {
            index:this.details.id
        })
        .then(() => {
          //console.log("detauls_trans" , this.$store.state.transaction.hisgold.detail);
            this.details = this.$store.state.transaction.hisgold.list[0];
            //alert(this.$store.state.transaction.hisgold.list[0].awb_no);
            this.awb_no =  this.$store.state.transaction.hisgold.detail[0].awb_no;
            this.jadwal_pengambilan = this.$store.state.transaction.hisgold.detail[0].jadwal_pengambilan;
            this.counter = this.$store.state.transaction.hisgold.detail[0].counter;
            this.verified = this.$store.state.transaction.hisgold.detail[0].verified;
        })
        .catch((res) => {
           this.$store.commit("main/setSnackbarFail", res.response.data.message);
        });
    },
  },
  mounted() {
      
  },
  watch: {
    pagination() {

        this.loading = true
        this.loadingtext = 'Loading... Please wait'
        this.fetch(0);

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};

</script>

<style>
</style>