import { apiFetchEmailTemplate , saveAdminAccess , apiDeleteDenomList , apiAddDenomList , apiEditDenomList ,apiDenomList ,  apiAddSettingAdmin , editChannelBayar , openMarketApiData , editChannelStatus , apiFetchOpenChannel, apiEditSubmitVar , apiFetchCheckData , apiDeleteSubmitVar , apiFetchCustomVar, apiAddSubmitVar, apiFetchAdminModule , apiFetchTrxModule, apiAddAdminModule , apiEditAdminModule , apiPostEmailTemplate , apiPostDeleteEmailTemplate , apiAddEmailTemplate } from "../../api/settings"
 
const namespaced = true

const defaultState = () => {
    return {
        colors:{
            primary: "rgb(16, 121, 72)"
        },
        region:{
            provinsiList:[]
        },
        attributes: {
            gender: [
                { text: "All Gender", value: "" },
                { text: "Laki-Laki", value: "Laki-Laki" },
                { text: "Perempuan", value: "Perempuan" },
                // { text: "Gagal", value: "2" },
            ],
            religion: [
                { text: "All Religion", value: "" },
                { text: "Hindu", value: "Hindu" },
                { text: "Katolik", value: "Katolik" },
                { text: "Kristen", value: "Kristen" },
                { text: "Buddha", value: "Buddha" },
                { text: "Islam", value: "Islam" },
                { text: "Konghucu", value: "Konghucu" }
                // { text: "Gagal", value: "2" },
            ],
            listEmail: [],
            detail: {},
            listDetail:[],
            listDenom:[],
            totalDenom:0,
            listEmailTemplate:[],
            listAdminModule:[],
            listTrxModule:[],
            listCustomVar:[],
            listCheckDataBalance:[],
            listCheckDataTrx:[],
            listCheckDataTrxIpaymu:[],
            DetailTrxModule:[],
            total:0,
            is_back:0,
            totalEmailTemplate:0,
            totalEmailModule:0,
            totalTrxModule:0,
            totalCustomVar:0,
            totalCheckBalance:0,
            totalCheckTrxBalance:0,
            totalMemberFormatted:0,
            adminAkses:null,
            pageDenom:"list",
            pageTrx:"list",
            page: "list",
            pageCustom:"list",
            pageCheck:"list",
            status:""
        },
        channel: {
            page: "list",
            total:0,
            list:[],
            detail:[],
            listFitur:[],
            totalFitur:0,
            marketList:[],
        },
        settlement: {
            list: [],
            detail: {}
        }
    }
}

const state = defaultState()

const mutations = {
    setProvinsiList: (state, payload) => {
        state.attributes.provinsiList = payload
    },
    setEmailList: (state, payload) => {
        state.attributes.listEmail = payload
    },
    setDenomList: (state, payload) => {
        state.attributes.listDenom = payload
    },
    setPageEmail: (state, payload) => {
        state.attributes.page = payload
    },
    setAdminModule: (state, payload) => {
        state.attributes.listAdminModule = payload
    },
    setEmailTemplate: (state, payload) => {
        state.attributes.listEmailTemplate = payload
    },
    setTrxList: (state, payload) => {
        state.attributes.listTrxModule = payload
    },
    setCustomVar: (state, payload) => {
        state.attributes.listCustomVar = payload
    },
    setCheckDataBalance: (state, payload) => {
        state.attributes.listCheckDataBalance = payload
    },
    setCheckDataTrx: (state, payload) => {
        state.attributes.listCheckDataTrx = payload
    },
    setlistCheckDataTrxIpaymu: (state, payload) => {
        state.attributes.listCheckDataTrxIpaymu = payload
    },
    setTotalCheckBalance: (state, payload) => {
        state.attributes.totalCheckBalance = payload
    },
    setTotalCheckTrxBalance: (state, payload) => {
        state.attributes.totalCheckTrxBalance = payload
    },
    setTrxListDetail: (state, payload) => {
        state.attributes.DetailTrxModule = payload
    },
    setPageTrx: (state, payload) => {
        state.attributes.pageTrx = payload
    },
    setPageCustom: (state, payload) => {
        state.attributes.pageCustom = payload
    },
    setPageCheck: (state, payload) => {
        state.attributes.pageCheck = payload
    },
    setPageDenom: (state, payload) => {
        state.attributes.pageDenom = payload
    },
    setStatus: (state, payload) => {
        state.attributes.status = payload
    },
    setChannelBalance: (state, payload) => {
        state.channel.page = payload
    },
    setChannelTotalBalance:(state, payload) => {
        state.channel.total = payload
    },
    setChannelListBal:(state, payload) => {
        state.channel.list = payload
    },
    setChannelListFitur: (state, payload) => {
        state.channel.listFitur = payload
    },
    setChannelTotalFitur:(state, payload) => {
        state.channel.totalFitur = payload
    },
    setMarketDashboard:(state, payload) => {
        state.channel.marketList = payload
    },
}

const actions = {
    fetchDenomList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiDenomList(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setDenomList', res.data);

                        state.attributes.totalDenom = res.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    addDenomNominal({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiAddDenomList(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    saveAdminAccess({ commit }, payload) {
        return new Promise((resolve, reject) => {
            saveAdminAccess(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editDenom({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiEditDenomList(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    deleteDenom({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiDeleteDenomList(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchEmailTemplate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchEmailTemplate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setEmailTemplate', res.data.data);

                        state.attributes.totalEmailTemplate = res.data.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    AddSettingAdmin({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiAddSettingAdmin(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        
                        //state.attributes.totalEmailTemplate = res.data.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postEmailTemplate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPostEmailTemplate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    addEmailTemplate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiAddEmailTemplate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
            }).catch(res => reject(res))
        })
    },
    deleteEmailTemplate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPostDeleteEmailTemplate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchAdminModule({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchAdminModule(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setAdminModule', res.data.data);

                        state.attributes.totalEmailModule = res.data.total
                        //commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    addModuleSettings({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiAddAdminModule(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editModuleSettings({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiEditAdminModule(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchTrxModule({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchTrxModule(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setTrxList', res.data.data);
                        

                        state.attributes.totalTrxModule = res.data.total
                        //commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchCustomVar({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchCustomVar(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setCustomVar', res.data);
                        
                        state.attributes.totalCustomVar = res.total
                        //commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    addSubmitVar({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiAddSubmitVar(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    getAtmList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiAddSubmitVar(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editCustomVar({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiEditSubmitVar(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    deleteCustomVar({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiDeleteSubmitVar(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchCheckData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchCheckData(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setCheckDataBalance', res.balance);
                        commit('setCheckDataTrx' , res.transaksi);
                        commit('setTotalCheckBalance', res.totalBalance);
                        commit('setTotalCheckTrxBalance' , res.totalTrx);
                        commit('setlistCheckDataTrxIpaymu' , res.ipaymu_transaksi);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchOpenChannelBal({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchOpenChannel(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setChannelListBal', res.data.data);
                        commit('setChannelListFitur', res.fitur.data);
                        commit('setChannelTotalBalance', res.data.total);
                        commit('setChannelTotalFitur', res.fitur.total);
                        
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    openChannelStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            editChannelStatus(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    openChannelBayar({ commit }, payload) {
        return new Promise((resolve, reject) => {
            editChannelBayar(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    openMarketData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            openMarketApiData(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMarketDashboard', res.data);
                        console.log("resdata" , res.data);
                        
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
}

export default {
    namespaced, state, mutations, actions
}