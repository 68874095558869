import MainComponent from '../../views/Main.vue'
import GoldRateList from '../../views/pages/goldrate/mainListGoldRate.vue'
import ExportGoldRate from '../../views/pages/goldrate/export/list.vue'

export default [
    {
        path: '/panello/',
        name: 'Main Member',
        component: MainComponent,
        children: [
            {
                path: '/panello/GoldRateList',
                name: 'Gold Rate List',
                component: GoldRateList
            },
            {
                path: '/panello/export-goldrate',
                name: 'Export GoldRate List',
                component: ExportGoldRate
            },
        ]
    }
]