import { apiGetDataTransaksi , apiDashboardStatistik } from "../../api/dashboard"

const namespaced = true

const defaultState = () => {
    return {
        trx: {
            profile: {},
            transaksicountlist: [],
            transaksilist:"page",
            success:"",
            androidArrays:"",
            iosArrays:"",
            webappArrays:"",
            websiteArrays:"",
            mobileArrays:"",
            dataTrans:[]
        }
    }
}

const state = defaultState()

const mutations = {
    setDataTransaksi: (state, payload) => {
        state.trx.transaksicountlist = payload
    },
    setDataSuccess: (state, payload) => {
        state.trx.success = payload
    },
    setDataStatistik: (state, payload) => {
        state.trx.dataTrans = payload
    },
    setArrayTahunanAndroid: (state, payload) => {
        state.trx.androidArrays = payload
    },
    setArrayTahunanIos: (state, payload) => {
        state.trx.iosArrays = payload
    },
    setArrayTahunanWebsite: (state, payload) => {
        state.trx.websiteArrays = payload
    },
    setArrayTahunanWebapp: (state, payload) => {
        state.trx.webappArrays = payload
    },
    setArrayTahunanMobile: (state, payload) => {
        state.trx.mobileArrays = payload
    },
}

const actions = {
    getDataTransaksi({commit}) {
        return new Promise((resolve, reject) => {
            apiGetDataTransaksi()
                .then((res) => {
                    if (res.status == 200) {
                        commit('setDataTransaksi', res.data);
                        commit('setDataStatistik' , res.data);
                        // console.log("dataTrans" , res.data);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    getDashboardStatistik({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiDashboardStatistik(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setDataSuccess', "Success");
                        //console.log("dataTrans" , res.data);
                        commit('setDataStatistik' , res.data);
                        commit('setArrayTahunanAndroid' , res.array_tahunan_android);
                        commit('setArrayTahunanIos' , res.array_tahunan_ios);
                        commit('setArrayTahunanWebsite' , res.array_tahunan_website)
                        commit('setArrayTahunanWebapp' , res.array_tahunan_webapp)
                        commit('setArrayTahunanMobile' , res.array_tahunan_mobile)

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
}

export default {
    namespaced,
    state,
    mutations,
    actions
}