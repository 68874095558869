import { apiGetAtmList , apiGetLokasiList , addAtmLokasiNew , editAtmLokasiNew } from "../../api/atmList"

const namespaced = true

const defaultState = () => {
    return {
        exports:{
            list: [],
            detail: {},
            page:"list",
            atmList:[],
            lokasiAtm:[],
            status:"",
            total:0,
            listTotals1:0,
            totalAtm:0
        }
    }
}

const state = defaultState()

const mutations = {
    setDataAtmList: (state, payload) => {
        state.exports.atmList = payload
    },
    setDatalokasiAtm: (state, payload) => {
        state.exports.lokasiAtm = payload
    },
    setDataTotalAtm: (state, payload) => {
        state.exports.totalAtm = payload
    },
    setStatusSuccess:(state, payload) => {
        state.exports.totalAtm = payload
    },
}

const actions = {
    fetchAtmListAtmTrans({commit}) {
        return new Promise((resolve, reject) => {
            apiGetAtmList()
                .then((res) => {
                    if (res.status == 200) {
                        commit('setDataAtmList', res.data)
                        // console.log("atmlistData" , res.data)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchAtmLokasiList({commit}) {
        return new Promise((resolve, reject) => {
            apiGetLokasiList()
                .then((res) => {
                    if (res.status == 200) {
                        commit('setDatalokasiAtm', res.data)
                        commit('setDataTotalAtm' , res.total)

                        //this.$store.state.atmList.exports.lokasiAtm = res.data;
                        //console.log("atmlistDataNew" , "tesnew =");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postAtmLokasiNew({commit} , payload){
        return new Promise((resolve, reject) => {
            addAtmLokasiNew(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatusSuccess', "success")

                        resolve(res)
                    }
                    reject(res)
            }).catch(res => reject(res))
        })
    },
    editAtmLokasiNew({commit} , payload){
        return new Promise((resolve, reject) => {
            editAtmLokasiNew(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatusSuccess', "success")

                        resolve(res)
                    }
                    reject(res)
            }).catch(res => reject(res))
        })
    },
}

export default {
    namespaced, state, mutations, actions
}