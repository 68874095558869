import MainComponent from '../../views/Main.vue'
import AffiliateUser from '../../views/pages/affiliate/mainAffiliateMember.vue'
import AffiliateRewardUser from '../../views/pages/affiliate/mainAffiliateRewardMember.vue'
import AffiliateTransaksiUser from '../../views/pages/affiliate/mainAffiliateTransaksiMember.vue'
import AffiliatePromoUser from '../../views/pages/affiliate/mainAffiliatePromoMember.vue'

export default [
    {
        path: '/panello/',
        name: 'Main Transaction',
        component: MainComponent,
        children: [
            {
                path: '/panello/listuser-affiliate',
                name: 'Affiliate Member List',
                component: AffiliateUser
            },
            {
                path: '/panello/affiliate-reward',
                name: 'Affiliate Reward Member List',
                component: AffiliateRewardUser
            },
            {
                path: '/panello/affiliate-transaksi',
                name: 'Affiliate Transaksi Member List',
                component: AffiliateTransaksiUser
            },
            {
                path: '/panello/affiliate-promo',
                name: 'Affiliate Promo Member List',
                component: AffiliatePromoUser
            }
        ]
    }
]