<template>



<v-layout row wrap>

  <v-flex md6 class="px-2">

    <CustomCard :cardShow="true">
      <template v-slot:toolbar>
        <div style="width:100%;">
          <div style="float:left;">Data Counter</div>
          <div style="float:right;">
            <v-btn
              color="success"
              small
              outlined
              @click="$store.state.transaction.offlinecounter.page = 'list'">
              back
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <v-layout row wrap>

          <v-flex md12 class="px-2">
            <!-- <v-toolbar color="#9e9e9e59" dense flat> <b> Detail Member </b> </v-toolbar> -->

              <v-simple-table style="border:1px solid #dddddd;">
                  <template v-slot:default>

                      <tbody >
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Nama Counter</td><td style="text-align: right; background:white;">{{ details.name }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Alamat</td><td style="text-align: right; background:white;">{{ details.alamat }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">PIC</td><td style="text-align: right; background:white;">{{ details.pic }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Phone</td><td style="text-align: right; background:white;">{{ details.phone }}</td></tr>
                          <!-- <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Current Kabupaten	</td><td style="text-align: right; background:white;">{{ details.currkab }}</td></tr> -->
                          <!-- <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Current Kecamatan</td><td style="text-align: right; background:white;">{{ details.currkec }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Current Kelurahan</td><td style="text-align: right; background:white;">{{ details.currkel }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Current Kodepos</td><td style="text-align: right; background:white;">{{ details.currpost }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Current Address</td><td style="text-align: right; background:white;hi">{{ details.curraddr }}</td></tr> -->
                      </tbody>

                  </template>
              </v-simple-table>

          </v-flex>
          
        </v-layout>
      </template>
    </CustomCard>

  </v-flex>


<v-flex md6 class="px-2" v-if="details.tipe_pengambilan == 'rpx'">

    <CustomCard :cardShow="true">
      <template v-slot:toolbar>
        <div style="width:100%;">
          <div style="float:left;">Data Pengambilan RPX</div>
          
          <div style="float:right;">
            <v-btn
              color="red"
              small
              outlined
              @click="$store.commit('transaction/setMemberPage', 'list')">
              Cancel
            </v-btn>
          </div>

          <div style="float:right; margin-right:20px;">

            <v-dialog
                transition="dialog-top-transition"
                max-width="600">
                
                <template v-slot:activator="{ on, attrs }">

                        <!-- <v-tooltip> -->
                        <!-- <v-btn
                            color="red"
                            icon
                            v-bind="attrs"
                            v-bind:class="item.status_code != 2 ? '' : 'hidden'"
                            v-on="on">
                            <v-icon small color="red">mdi-block-helper</v-icon>
                        </v-btn> -->
                        <v-btn
                          color="success"
                          small
                          outlined
                          v-bind="attrs"
                          v-on="on">
                          Confirm Via Offline Counter
                        </v-btn>
                        <!-- 
                            <span>Detail</span>
                        </v-tooltip> -->
                        
                    </template>
                    <!-- <span>Unverified User</span> -->
                    <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar
                            color="success"
                            dark
                            >Konfirmasi Pengambilan Emas</v-toolbar>

                            <v-container>

                                <v-layout>
                                    <v-flex>
                                        Yakin anda akan konfirmasi transaksi cetak emas atas user ini ? <br /> Setelah konfirmasi sistem akan mengirimkan email konfirmasi pengambilan emas ke user.
                                    </v-flex>
                                </v-layout>
                                    
                            </v-container>

                            <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                            <v-btn
                                style="color:#ffffff;"
                                color="success"
                                @click="verifikasi_emas(dialog)"
                            >Submit</v-btn>
                            </v-card-actions>
                        </v-card>
                </template>
            </v-dialog>
            
          </div>
          
        </div>
      </template>
      <template v-slot:content>
        <v-layout row wrap>

          <v-flex md12 class="px-2">
            <!-- <v-toolbar color="#9e9e9e59" dense flat> <b> Detail Member </b> </v-toolbar> -->

              <v-simple-table style="border:1px solid #dddddd;">
                  <template v-slot:default>

                      <tbody >
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">No. AWB</td><td style="text-align: right; background:white;">{{ details.trxId }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">No. Pickup</td><td style="text-align: right; background:white;">{{ details.cetak2 }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Pickup Pin</td><td style="text-align: right; background:white;">{{ details.cetakgramasi }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Note</td><td style="text-align: right; background:white;">{{ details.cetakamount }}</td></tr>
                          <tr><td style="border-right:1px solid #dddddd; background:#F5F5F5;">Status </td><td style="text-align: right; background:white;">{{ details.cetakfee }}</td></tr>
                      </tbody>

                  </template>
              </v-simple-table>

          </v-flex>
          
        </v-layout>
      </template>
    </CustomCard>

  </v-flex>

  <v-flex md6 class="px-2" v-if="details.tipe_pengambilan != 'rpx'">

    <CustomCard :cardShow="true">
      <template v-slot:toolbar>
        <div style="width:100%;">
          <div style="float:left;">Next Schedule Open Counter</div>

          <div style="float:right; margin-right:20px;">
            <v-dialog
                transition="dialog-top-transition"
                max-width="600">
                
                <template v-slot:activator="{ on, attrs }">

                        <!-- <v-tooltip> -->
                        <!-- <v-btn
                            color="red"
                            icon
                            v-bind="attrs"
                            v-bind:class="item.status_code != 2 ? '' : 'hidden'"
                            v-on="on">
                            <v-icon small color="red">mdi-block-helper</v-icon>
                        </v-btn> -->
                        <v-btn
                          color="success"
                          small
                          v-bind="attrs"
                          v-on="on">
                            Add Schedule
                        </v-btn>
                        <!-- 
                            <span>Detail</span>
                        </v-tooltip> -->
                        
                    </template>
                    <!-- <span>Unverified User</span> -->
                    <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar
                            color="success"
                            dark
                            >Add Schedule Counter</v-toolbar>

                            <v-container>

                                <v-layout>
                                    <v-flex>
                                        <b> Tanggal </b> <br />
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="date"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="searchForm.date"
                                                label="Register FromDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="searchForm.date"
                                            no-title
                                            scrollable
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menu = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menu.save(date)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                </v-layout>
                                    
                            </v-container>

                            <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                            <v-btn
                                style="color:#ffffff;"
                                color="success"
                                @click="konfirmasi_jadwal(dialog)"
                            >Submit</v-btn>
                            </v-card-actions>
                        </v-card>
                </template>
            </v-dialog>
            
          </div>
          
        </div>
      </template>
      <template v-slot:content>
        <v-layout row wrap>

          <v-flex md12 class="px-2">
            <!-- <v-toolbar color="#9e9e9e59" dense flat> <b> Detail Member </b> </v-toolbar> -->

              <v-simple-table style="border:1px solid #dddddd;">
                  <template v-slot:default>

                    <v-data-table
                        hide-default-footer
                        disable-pagination
                        disable-sort
                        :headers="headersData"
                        :items="$store.state.transaction.offlinecounter.listjadwal"
                        :loading="loading"
                        :loading-text="loadingtext">

                        <template v-slot:[`item.action`]="{ item }">
                            <!-- <div> Hapus {{ item.id }}  </div>  -->
                            <v-dialog
                                transition="dialog-top-transition"
                                max-width="600">
                                
                            <template v-slot:activator="{ on, attrs }">

                                <!-- <v-tooltip> -->
                                <!-- <v-btn
                                    color="red"
                                    icon
                                    v-bind="attrs"
                                    v-bind:class="item.status_code != 2 ? '' : 'hidden'"
                                    v-on="on">
                                    <v-icon small color="red">mdi-block-helper</v-icon>
                                </v-btn> -->
                                <v-btn
                                color="red"
                                small
                                outlined
                                v-bind="attrs"
                                v-on="on">
                                   <v-icon small color="red">mdi-block-helper </v-icon> &nbsp;  Hapus Schedule
                                </v-btn>
                                </template>

                                <template v-slot:default="dialog">
                                        <v-card>
                                            <v-toolbar
                                            color="red"
                                            dark
                                            >Add Schedule Counter</v-toolbar>

                                            <v-container>

                                                <v-layout>
                                                    <v-flex>
                                                        Anda yakin akan menghapus schedule counter ini?
                                                    </v-flex>
                                                </v-layout>
                                                    
                                            </v-container>

                                            <v-card-actions class="justify-end">
                                            <v-btn
                                                text
                                                @click="dialog.value = false"
                                            >Close</v-btn>
                                            <v-btn
                                                style="color:#ffffff;"
                                                color="red"
                                                @click="cancel_jadwal(dialog , item.id)"
                                            >Save</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                </template>
                            </v-dialog>

                        </template>
                            
                    </v-data-table>

                  </template>
              </v-simple-table>

          </v-flex>
          
        </v-layout>
      </template>
    </CustomCard>

  </v-flex>

</v-layout>
  
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      awb_no:"",
      counter:"",
      jadwal_pengambilan:"",
      verified:"",
      details:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      loading: false,
      loadingText: "Loading Data...",
      headersData: [
        { text: "Tanggal", value: "datestart" , class: 'header-jadwal-table' },
        { text: "Action", value: "action" , class: 'header-jadwal-table' },
    ],
    };
  },
  methods: {
    fetch() {
      //alert("new tes");
      this.$store
        .dispatch("transaction/fetchOfflineJadwalCounter", {
            id:this.details.id
        })
        .then(() => {
          //console.log("detauls_trans" , this.$store.state.transaction.hisgold.detail);
           // this.details = this.$store.state.transaction.offlinecounter.list[0];
            //alert(this.$store.state.transaction.hisgold.list[0].awb_no);
            // this.awb_no =  this.$store.state.transaction.hisgold.detail[0].awb_no;
            // this.jadwal_pengambilan = this.$store.state.transaction.hisgold.detail[0].jadwal_pengambilan;
            // this.counter = this.$store.state.transaction.hisgold.detail[0].counter;
            // this.verified = this.$store.state.transaction.hisgold.detail[0].verified;
        })
        .catch((res) => {
           this.$store.commit("main/setSnackbarFail", res.response.data.message);
        });
    },
    searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
    },
    
    konfirmasi_jadwal(dialog){
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      this.$store
        .dispatch("transaction/setCounterJadwal", {
            tgl:this.searchForm.date,
            counter_id:this.details.id
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = 'Loading... Please wait';
          this.$store.commit("main/setSnackbarSuccess", "Jadwal Pengambilan Emas Counter Berhasil ditambahkan");
          this.fetch();

          dialog.value = false;
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = 'Loading... Please wait';
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          dialog.value = false;
        });
      },
      cancel_jadwal(dialog,index){
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      this.$store
        .dispatch("transaction/deleteCounterJadwal", {
            id : index
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = 'Loading... Please wait';
          this.$store.commit("main/setSnackbarSuccess", "Jadwal Pengambilan Emas Counter Berhasil dihapus");
          this.fetch();

          dialog.value = false;
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = 'Loading... Please wait';
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          dialog.value = false;
        });
      }
  },
  mounted() {
   // alert("tes");
   this.details = this.$store.state.transaction.offlinecounter.detail;
   this.fetch();
//    this.awb_no = this.details.awb_no;
//    this.jadwal_pengambilan = this.details.jadwal_pengambilan;
//    this.counter = this.details.counter;
//    this.verified = this.details.verified;
   // console.log("items" , this.$store.state.transaction.member.detail);
  },
  watch: {
    pagination() {

        this.loading = true
        this.loadingtext = 'Loading... Please wait'
        this.fetch(0);

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};

</script>

<style>
</style>