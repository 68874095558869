
<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <CustomCard>

        <template v-slot:toolbar>
                    
            <div style="width:100%;">

                <div  style="float:left;">

                    <v-btn color="success" small outlined>
                        Detail 
                    </v-btn>

                </div>

                <div  style="float:right;">

                    <v-btn color="success" small outlined @click="showList()">
                         <v-icon>mdi-chevron-left</v-icon> Back 
                    </v-btn>

                </div>
            
            </div>
                
        </template>

        <template  v-slot:datatable>


            <v-container>

                <v-row class="rowStyles"
                    no-gutters>

                    <v-col
                            sm="3"
                            md="3">
                        Title
                    </v-col>

                    <v-col
                            sm="1"
                            md="1">
                        :
                    </v-col>

                    <v-col
                            sm="8"
                            md="8">
                        <!-- {{ detailsTrans.id }} -->
                        
                        <v-text-field
                            v-model="formLock.addIndex"
                            style="display:none;"
                            small
                            clearable>
                        </v-text-field>

                        <v-text-field
                            v-model="formLock.addUrlInput"
                            small
                            clearable>
                        </v-text-field>

                    </v-col>

                </v-row>

                <v-row class="rowStyles"
                    no-gutters>

                    <v-col
                            sm="3"
                            md="3">
                        Slug
                    </v-col>

                    <v-col
                            sm="1"
                            md="1">
                        :
                    </v-col>

                    <v-col
                            sm="8"
                            md="8">
                        <!-- {{ detailsTrans.time }} -->
                        <v-text-field
                            v-model="formLock.addSlugInput"
                            small
                            clearable>
                        </v-text-field>

                    </v-col>

                </v-row>

                <v-row class="rowStyles"
                    no-gutters>

                    <v-col
                            sm="3"
                            md="3">
                        Content
                    </v-col>

                    <v-col
                            sm="1"
                            md="1">
                        :
                    </v-col>

                    <v-col
                            sm="8"
                            md="8">
                        <!-- {{ detailsTrans.ip }} -->
                        <!-- <rich-text-editor style="width:100%;" v-model="formLock.addDescription" /> -->
                        <v-textarea
                            v-model="formLock.addDescription"
                            height="600"
                            outlined
                        ></v-textarea>
                    </v-col>

                </v-row>

                <v-row class="rowStyles">

                    <div style="width:100%; padding:0 10px;">

                        <div style="float:right; margin-right:10px;"
                                sm="3"
                                md="3">
                            <v-btn color="success" small outlined @click="submitData()">
                                Submit 
                            </v-btn>
                        </div>

                        <div style="float:right; margin-right:10px;"
                                sm="3"
                                md="3">
                            <v-btn color="success" small outlined>
                                Cancel 
                            </v-btn>
                        </div>

                    </div>

                </v-row>


            </v-container>

    </template>

    </CustomCard>

    </div>

</template>


<script>
import CustomCard from "../../../../components/CustomCard.vue";



export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      profiles:this.$store.state.auth.profile,
      detailsPages:this.$store.state.webadmin.attributes.pagesItem,
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      formLock:{
        addUrlInput:"",
        addSlugInput:"",
        addDescription:"",
      },
      searchForm:{
        member: '',
      },
      headers: [
        { text: "Name", value: "name"},
        { text: "Email", value: "new_email" },
        { text: "Phone", value: "phone"},
        { text: "Reg Date", value: "verified_date"},
        { text: "E-Ver", value: "em_verified"},
        { text: "M-Ver", value: "verified"},
        { text: "Action", align: "center", value: "action"},
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    editItemsNew(){
        //alert("tes")
        this.formLock.addUrlInput    = "tes";
        //   this.searchForm.editSlugInput   = this.detailsPages.slug;
        //   this.searchForm.editDescription = this.detailsPages.content;
    },
    fetch() {
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      this.$store.commit("main/setLoading", true);
      this.$store
        .dispatch("member/fetchListMemberDetail", {
            id_member:this.$store.state.member.member.detail,
        })
        .then(() => {
          //alert("tes-dd");
          console.log("itemsfetch",this.$store.state.member.member.listDetail[0]);
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
          this.detailsMember = this.$store.state.member.member.listDetail[0];
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
      
      this.$store.commit("transaction/setMemberPage", "detail");
      this.$store.commit("transaction/setMemberDetail", item);
      //this.detailsName = "New Member";
    },
    editProfiles: function(dialog){
      this.$store
        .dispatch("member/editProfiles", {
            userId: this.formLock.userId,
            edit_name: this.formLock.edit_name,
            edit_email: this.formLock.edit_email,
            updateId: this.profiles.id,
            edit_phone:this.formLock.edit_phone
        })
        .then(() => {
        //   this.$store.commit("main/setLoading", false);
        //   this.loading = false;
        //   this.loadingtext = '';
            this.fetch();
            dialog.value = false;
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    userUnverify: function(dialog){
      this.$store
        .dispatch("member/userUnverify", {
            userId: this.formLock.userId,
            updateId: this.profiles.id,
        })
        .then(() => {
        //   this.$store.commit("main/setLoading", false);
        //   this.loading = false;
        //   this.loadingtext = '';
            this.fetch();
            dialog.value = false;
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    userSuspend: function(dialog){
      this.$store
        .dispatch("member/userSuspend", {
            userId: this.formLock.userId,
            updateId: this.profiles.id,
            suspend_reason:this.formLock.suspend_reason
        })
        .then(() => {
        //   this.$store.commit("main/setLoading", false);
        //   this.loading = false;
        //   this.loadingtext = '';
            this.fetch();
            dialog.value = false;
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    submitData: function(){
      this.$store.commit("main/setLoading", true);
      
      this.$store
        .dispatch("webadmin/fetchSubmitPages", {
             id:this.formLock.addIndex,
             title:this.formLock.addUrlInput,
             slug:this.formLock.addSlugInput,
             content:this.formLock.addDescription
        })
        .then(() => {
        //   this.$store.commit("main/setLoading", false);
        //   this.loading = false;
        //   this.loadingtext = '';
            //this.fetch();
            //dialog.value = false;
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarSuccess", "Success");
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    showLock:function(userId,member_name,member_email,member_phone){
      this.formLock.userId     = userId
      this.formLock.edit_name  = member_name
      this.formLock.edit_email = member_email
      this.formLock.edit_phone = member_phone
    },
    showList() {
        this.$store.commit("webadmin/setPagesState", "list");
    },
    showDetailId:function(userId){
        this.formLock.userId     = userId
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
  },
  clear(){
      this.searchForm.addUrlInput    = "";
      this.searchForm.addSlugInput   = "";
      this.searchForm.addDescription = "";
      this.searchForm.addIndex       = "";
  },
  mounted() {
    if(this.$store.state.webadmin.attributes.pageListAction == "save"){
        this.formLock.addUrlInput     = "";
        this.formLock.addSlugInput    = "";
        this.formLock.addDescription  = "";
        this.formLock.addIndex        = "";
    }
    else{
        this.formLock.addUrlInput       = this.detailsPages.title;
        this.formLock.addSlugInput      = this.detailsPages.slug;
        this.formLock.addDescription    = this.detailsPages.content;
        this.formLock.addIndex          = this.detailsPages.id;
    }
    //this.fetch();
  },
//   watch: {
//     pagination() {
//         this.loading = true
//         this.loadingtext = 'Loading... Please wait'
//         this.fetch()
//     },
//     searchSponsor(val) {
//         val && val !== this.searchForm.sponsor && this.getSponsor(val);
//     },
//     searchMember(val) {
//         val && val !== this.searchForm.member && this.getMember(val);
//     }
//   },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>
