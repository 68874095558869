var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CustomCard',{scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-btn',{attrs:{"color":"success","small":"","outlined":""},on:{"click":_vm.filterToggle}},[_vm._v(" filter "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]},proxy:true},{key:"expansion",fn:function(){return [_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{attrs:{"expand":"","focusable":""}},[_c('v-expansion-panel-content',[_c('v-layout',[_c('v-flex',[_c('v-text-field',{attrs:{"label":"Search By Name","small":"","clearable":""},model:{value:(_vm.searchForm.member),callback:function ($$v) {_vm.$set(_vm.searchForm, "member", $$v)},expression:"searchForm.member"}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Search By Email ","small":"","clearable":""},model:{value:(_vm.searchForm.email),callback:function ($$v) {_vm.$set(_vm.searchForm, "email", $$v)},expression:"searchForm.email"}})],1),_c('v-flex',[_c('v-text-field',{attrs:{"label":"Search By Phone","small":"","clearable":""},model:{value:(_vm.searchForm.phone),callback:function ($$v) {_vm.$set(_vm.searchForm, "phone", $$v)},expression:"searchForm.phone"}})],1),_c('v-flex',[_c('v-menu',{attrs:{"close-on-content-click":false,"return-value":_vm.date2,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date2=$event},"update:return-value":function($event){_vm.date2=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.searchForm.date2),callback:function ($$v) {_vm.$set(_vm.searchForm, "date2", $$v)},expression:"searchForm.date2"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.searchForm.date2),callback:function ($$v) {_vm.$set(_vm.searchForm, "date2", $$v)},expression:"searchForm.date2"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-layout',{attrs:{"align-end":"","justify-end":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"m0",attrs:{"small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.fetch(1)}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Search ")],1)],1)],1)],1)],1)]},proxy:true},{key:"datatable",fn:function(){return [_c('v-flex',{staticStyle:{"overflow-x":"scroll"},attrs:{"md12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.state.games.games.list,"options":_vm.pagination,"server-items-length":_vm.$store.state.games.games.total,"footer-props":{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                },"items-per-page":10,"loading":_vm.loading,"loading-text":_vm.loadingtext},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.membersDetail",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('b',[_vm._v(" "+_vm._s(item.member.name)+" ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',[_vm._v(" "+_vm._s(item.member.email)+" ")]),_vm._v(" "),_c('div',[_vm._v(" "+_vm._s(item.member.phone)+" ")])])]}},{key:"item.scores",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('b',[_vm._v("Play:")]),_vm._v(" "+_vm._s(item.games.note)),_c('br'),_c('b',[_vm._v("Score:")]),_vm._v(" "+_vm._s(item.games.score)+" ")])]}},{key:"item.rewards",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('b',[_vm._v("TrxID:")]),_vm._v(" "+_vm._s(item.trx_id)),_c('br'),_c('b',[_vm._v("Reward:")]),_vm._v(" "+_vm._s(item.gramasi)+" Gr / "+_vm._s(item.amount)+" "),_c('br'),_c('b',[_vm._v("GoldRate:")]),_vm._v(" "+_vm._s(item.goldRateBuy)+" / "+_vm._s(item.goldRateSell)+" ")])]}}],null,true)})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }