<template>
    <div>
      <v-container grid-list-xs fluid>
        <List v-if="$store.state.settings.attributes.pageDenom == 'list'"></List>
        <!-- <Detail v-if="$store.state.settings.attributes.page == 'detail'"></Detail> -->
      </v-container>
    </div>
  </template>
  
  <script>
  import List from "./denom/list.vue";
  // import Detail from "./admin/detail.vue";
  
  export default { 
    data() {
      return {
        pages:"list"
      }
      },
      mounted() {
        this.$store.commit("settings/setPageDenom", "list");
      },
      components: { List } 
  };
  
  </script>
  
  <style>
  
  </style>