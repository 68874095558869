<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-if="$store.state.settings.attributes.page == 'list'"></List>
      <Detail v-if="$store.state.settings.attributes.page == 'detail'"></Detail>
    </v-container>
  </div>
</template>

<script>
import List from "./email/list.vue";
import Detail from "./email/detail.vue";

export default { 
  data() {
    return {
      pages:"list"
    }
    },
    mounted() {
      this.$store.commit("settings/setPageEmail", "list");
    },
    components: { List, Detail } 
};

</script>

<style>

</style>