<template>
<div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

<CustomCard>

    <template v-slot:toolbar>
        <div style="width:100%;">
            <div style="float:left;">
                <v-btn color="success" small outlined @click="filterToggle">
                    filter <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
            </div>
            <div style="float:right;">
                <!-- <v-btn color="success" small>
                    <v-icon>mdi-plus</v-icon> New Transaction
                </v-btn> -->
                    <v-dialog
                    transition="dialog-top-transition"
                    max-width="600">

                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success" small 
                                @click="clear()"
                                v-bind="attrs"
                                v-on="on">
                                    <v-icon>mdi-plus</v-icon> New Transaction
                            </v-btn>
                        </template>
                        
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                        color="success"
                                        dark>
                                        Add New Transaction
                                </v-toolbar>

                                        <v-layout style="display:none;">

                                            <v-text-field
                                                v-model="formLock.userId"
                                                label=""
                                                hidden
                                                >
                                            </v-text-field>

                                        </v-layout>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    <div style="float:left;"> Buy Rate :  </div>

                                                    <div style="float:right;">
                                                        <span style="color:#666666; font-size:21px; font-weight:bold;">Rp. {{ $store.state.transaction.stokemas.hargaEmas }} </span>
                                                    </div>
                                                    
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    
                                                    <v-autocomplete
                                                        @change="selectItemsMember"
                                                        :search-input.sync="search"
                                                        :loading="isLoading"
                                                        v-model="formLock.searchMember"
                                                        :items="items"
                                                        item-text="name"
                                                        item-value="id"
                                                        class="mx-4 lighten-3"
                                                        flat
                                                        return-object
                                                        hide-no-data
                                                        hide-selected
                                                        style="margin:10px 0 40px 0; height:20px;"
                                                        label="Search Member By Name Or Email ( min 3 karakter )">
                                                    </v-autocomplete> 

                                                    <v-divider></v-divider>

                                                </v-flex>
                                            </v-layout>

                                            <v-layout style="border-bottom:1px solid #DDDDDD; margin-top:0; padding:15px;">
                                                <!-- <v-flex>
                                                    <div style="float:left;">
                                                    <b> Nama  :</b> 
                                                    </div>
                                                    <div style="float:right;">
                                                        {{ addname }}
                                                    </div>
                                                </v-flex> -->
                                                <v-row class="" no-gutters>

                                                    <v-col
                                                        sm="4"
                                                        md="4">
                                                          <b>  Nama </b>
                                                    </v-col>

                                                    <v-col
                                                        sm="1"
                                                        md="1">
                                                            :
                                                    </v-col>

                                                    <v-col
                                                        sm="7"
                                                        md="7">
                                                            {{ addname }}
                                                    </v-col>

                                                </v-row>

                                            </v-layout>

                                            <v-layout style="border-bottom:1px solid #DDDDDD; padding:15px;">
                                                <!-- <v-flex>
                                                   <b> Email  :</b> &nbsp; {{ addemail }}
                                                </v-flex> -->
                                            <v-row class="" no-gutters>

                                                <v-col
                                                    sm="4"
                                                    md="4">
                                                       <b> Email </b>
                                                </v-col>

                                                <v-col
                                                    sm="1"
                                                    md="1">
                                                        :
                                                </v-col>

                                                <v-col
                                                    sm="7"
                                                    md="7">
                                                        {{ addemail }}
                                                </v-col>

                                            </v-row>
                                                
                                            </v-layout>

                                            <v-layout style="border-bottom:1px solid #DDDDDD; padding:15px;">

                                                <v-row class="" no-gutters>
                                                
                                                    <v-col
                                                        sm="4"
                                                        md="4">
                                                           <b> Phone </b>
                                                    </v-col>

                                                    <v-col
                                                        sm="1"
                                                        md="1">
                                                            :
                                                    </v-col>

                                                    <v-col
                                                        sm="7"
                                                        md="7">
                                                            {{ addphone }}
                                                    </v-col>

                                                </v-row>

                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-autocomplete
                                                        @change="changefunc()"
                                                        v-model="formLock.statusCounter"
                                                        :items="statusver"
                                                        item-text="text"
                                                        item-value="value"
                                                        label="Status">
                                                    </v-autocomplete>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                    <v-text-field
                                                        v-model="formLock.gramasi_real"
                                                        type="number"
                                                        label="Gramasi Real (Example 2.5)"
                                                        :disabled="pilihanOpt == 1 ? false : true"
                                                        @blur="gramasi_calc()"
                                                        >
                                                    </v-text-field>
                                            </v-layout>

                                             <v-layout>
                                                    <v-text-field
                                                        v-model="formLock.nominals"
                                                        type="number"
                                                        label="Nominal"
                                                        :disabled="pilihanOpt == 0 ? false : true"
                                                        @blur="gramasi_calc()"
                                                        >
                                                    </v-text-field>
                                            </v-layout>

                                             <v-layout>
                                                <v-select
                                                    v-model="formLock.notes"
                                                    :items="paymentVia"
                                                    label="Pembayaran Via"
                                                ></v-select>
                                            </v-layout>

                                            <v-layout>
                                                    
                                            </v-layout>
                                            
                                        </v-container>

                                        <v-card-actions class="justify-end">
                                        <v-btn
                                            text
                                            @click="dialog.value = false"
                                        >Close</v-btn>
                                        <v-btn
                                            color="success"
                                            @click="postSaveEmas(dialog)"
                                            :disabled="submitStatus == 0 ? false : true"
                                        >Submit</v-btn>
                                        </v-card-actions>
                                    </v-card>
                        </template>

                </v-dialog>
            </div>
        </div>
        

    </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.member"
                                label="(Phone. Name, Email)"
                                single-line
                                small
                                clearable>
                            </v-text-field>
                        </v-flex>

                        <!-- <v-flex>
                            <v-autocomplete
                                v-model="searchForm.thirdparty"
                                :items="thirdparty"
                                item-text="text"
                                item-value="value"
                                label="ThirdParty Tipe"
                            >
                            </v-autocomplete>
                        </v-flex> -->
                    
                    <v-flex>
                        <v-text-field
                                v-model="searchForm.transaction_id"
                                label="Transaction ID"
                                single-line
                                small
                                clearable>
                            </v-text-field>
                    </v-flex>

                    <v-flex>
                        <v-select
                            v-model="searchForm.notes"
                            :items="paymentSearchVia"
                            label="Note Pembayaran Via"
                        ></v-select>
                    </v-flex>


                </v-layout>

                 <v-layout>

                        <v-flex>
                            <v-menu
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchForm.date"
                                    label="Tanggal Awal"
                                    prepend-icon="mdi-calendar"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="searchForm.date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>

                    
                        <v-flex>
                            <v-menu
                                :close-on-content-click="false"
                                :return-value.sync="date2"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchForm.date2"
                                    label="Tanggal Akhir"
                                    prepend-icon="mdi-calendar"
                                    clearable
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="searchForm.date2"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date2)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                    </v-flex>
                    
                </v-layout>

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>

            <v-flex md12 style="overflow-x: scroll">
                    <v-data-table
                        :headers="headers"
                        :items="$store.state.transaction.stokemas.list"
                        :options.sync="pagination"
                        :server-items-length="$store.state.transaction.stokemas.total"
                        :footer-props="{
                        'items-per-page-options': [10, 25, 50, 100],
                        'items-per-page-text': 'Baris per Halaman:',
                        }"
                        :items-per-page="10"
                        :loading="loading"
                        :loading-text="loadingtext">

                        <template v-slot:[`item.member`]="{ item }">
                                <div>  <b> {{ item.name }} </b> </div>
                                <div>  {{ item.email }} </div>
                        </template>

                    </v-data-table>        
            </v-flex>

    </template>

</CustomCard>

</div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      addname:"-",
      addemail:"-",
      typingTimer:null,
      addphone:"-",
      addIdTrans:"",
      currentPage:1,
      decimals:"10.00",
      //disabled:"",
      icons:"chevron-right",
      descriptionLimit: 60,
      listMembers:[],
      selectedItems:"",
      entries: [],
      pilihanOpt:0,
      isLoading: false,
      model: null,
      search: null,
      searchValue:"",
      setorEmas:[],
      submitStatus:0,
      statusPembayaran: [
            { text: "All Status", value: "" },
            { text: "Pending", value: 0 },
            { text: "Proses Pengiriman", value: 1 },
            { text: "Cancel", value: 2 }
            // { text: "Gagal", value: "2" },
      ],
      paymentVia: [
            { text: "Dinaran", value: "Dinaran" },
            { text: "MicroGold", value: "Microgold" },
            { text: "GiveAway", value: "GiveAway" }
      ],
      paymentSearchVia: [
            { text: "All", value: "" },
            { text: "Dinaran", value: "Dinaran" },
            { text: "MicroGold", value: "Microgold" },
            { text: "GiveAway", value: "GiveAway" }
      ],
      typetransaksi: [
            { text: "All Type", value: "" },
            { text: "NDP", value: "NDP" },
            { text: "SDP", value: "SDP" }
            // { text: "Gagal", value: "2" },
      ],
      thirdparty: [
            { text: "All", value: "all" },
            { text: "IDCASH", value: "idcash" }
            // { text: "Gagal", value: "2" },
      ],
      statusver: [
            { text: "Nominal", value: 0 },
            { text: "Gramasi", value: 1 }
            // { text: "Gagal", value: "2" },
      ],
      statusaddr: [
            { text: "All", value: "all" },
            { text: "Address + Current Address", value: "1" },
            { text: "Address", value: "2" },
            { text: "No Address", value: "3" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{
          searchMember:{id: null, name: null , email:null},
          gramasi_real:0,
          nominals:0,
          gramasi_system:0
      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Tanggal", value: "created_at" },
        { text: "Buy Rate(Rp)", value: "buy_rate" },
        { text: "ID", value: "id" },
        { text: "ID Trx", value: "trx_id" },
        { text: "Nama", value: "users.name" },
        { text: "Email", value: "users.email" },
        { text: "Real", value: "gramasi_real" },
        { text: "System", value: "gramasi" },
        { text: "Nominal", value: "amount" },
        { text: "Note", value: "note" },
        { text: "Created By", value: "admin.name" },
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    querySelections (v) {
        this.loading = true
        //Simulated ajax query
        setTimeout(() => {
            this.items = this.states.filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
            })
            this.loading = false
        }, 500)
       // this.fetchMember(v);
        //alert(v);
    },
    changefunc(){
        //alert(this.formLock.statusCounter);
        this.pilihanOpt = this.formLock.statusCounter;
        this.formLock.nominals          = 0;
        this.formLock.gramasi_system    = 0;
        this.formLock.gramasi_real      = 0;
    },
    clear(){
        this.listMembers                = [];
        this.items                      = [];
        this.formLock.searchMember      = "";
        this.addname                    = "";
        this.addemail                   = "";
        this.addphone                   = "";
        this.addIdTrans                 = "";
        this.formLock.nominals          = 0;
        this.formLock.gramasi_system    = 0;
        this.formLock.gramasi_real      = 0;
    },
    gramasi_calc(){
        //alert(this.formLock.statusCounter);
        //this.gramasi_real = 0;
        let hargaemasnow = this.$store.state.transaction.stokemas.hargaEmas.replaceAll(".","");
        //alert((parseInt(this.formLock.nominals).toFixed(0)+" / "+parseInt(hargaemasnow).toFixed(0)).toFixed(5));
        
        if(this.formLock.statusCounter == "0"){
            this.formLock.gramasi_real = (parseInt(this.formLock.nominals).toFixed(0) / parseInt(hargaemasnow).toFixed(0)).toFixed(5);
            this.formLock.gramasi_system = (parseInt(this.formLock.nominals).toFixed(0) / parseInt(hargaemasnow).toFixed(0)).toFixed(5);
            //alert((parseInt(this.formLock.nominals).toFixed(0)+" / "+parseInt(hargaemasnow).toFixed(0)).toFixed(5));
        }
        else{
            this.formLock.nominals = (parseFloat(this.formLock.gramasi_real) * parseInt(hargaemasnow)).toFixed(0);
            this.formLock.gramasi_system = this.formLock.gramasi_real;
        }
        //alert(hargaemasnow)
    },
    // onInputClicked(myClickEvent) {    
    // },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      //alert("limits "+this.pagination.itemsPerPage);
      this.$store
        .dispatch("transaction/fetchTransactStokEmas", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            index:"",
            member:this.searchForm.member,
            startDate:this.searchForm.date,
            endDate:this.searchForm.date2,
            trx_id:this.searchForm.transaction_id,
            note:this.searchForm.notes,
            // status:this.searchForm.statusPembayaran
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    fetchMember(cari) {
    //alert(cari);
    this.isLoading = true;

        if(this.searchValue.length > 2){
            this.$store
                .dispatch("member/fetchTransMemberAll", {
                    page: this.pagination.page,
                    member:cari
                })
                .then((res) => {
                //   this.$store.commit("main/setLoading", false);
                //   this.loading = false;
                //   this.loadingtext = ''
                    //alert(cari);
                    this.listMembers = this.$store.state.member.member.listMemberTrans;
                    this.isLoading = false;
                    console.log("tesData" , this.listMembers);
                    return res;
                })
                .catch((res) => {
                console.log("fetchdata" , res.response.data.message);
                this.isLoading = false;
                //   this.$store.commit("main/setLoading", false);
                //   this.$store.commit("main/setSnackbarFail", res.response.data.message);
                //   this.loading = false;
                //   this.loadingtext = '';
            });
        }
    },
    searchMembers(){    
        //alert(this.searchValue);
        clearTimeout(this.typingTimer);
        if (this.searchValue) {
             this.typingTimer = setTimeout(function(){
            //     //do stuff here e.g ajax call etc....
            //     var v = $("#in").val();
            //     $("#out").html(v);
                this.fetchMember(this.searchValue)
            }, 10000);
        }
        
    },
    selectItemsMember(){    
        // alert(this.formLock.searchMember.selectedIndex);
        //console.log("index", this.formLock.searchMember)
        this.addname = this.formLock.searchMember.name;
        this.addemail = this.formLock.searchMember.email;
        this.addphone = this.formLock.searchMember.phone;
        this.addIdTrans = this.formLock.searchMember.id;

        //alert(this.addIdTrans);
        //this.selectedItems = this.formLock.searchMember;
        this.formLock.searchMember = this.addname;
        //alert(this.selectedItems);
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    editVerificationBan: function(dialog , id , code){
    //  console.log("updateId" , this.profiles.id);
    //  console.log("code" , code);
    //  console.log("trxid" , id);
     //console.log("id" , id);
      this.$store.commit("main/setLoading", true);
      //alert("ban");
      this.$store
        .dispatch("transaction/editMemberTransactionBan", {
            updateId: this.profiles.id,
            trxid: id,
            code: code,
            id: id
        })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
        })
        .catch((res) => {
          this.fetch(this.pagination.page);
          dialog.value = false;
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    editVerificationSuccess: function(dialog , id , code){
     this.$store.commit("main/setLoading", true);
      this.$store
        .dispatch("transaction/editMemberTransactionSuccess", {
            updateId: this.profiles.id,
            code: code,
            trxid: id,
            id: id
        })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
        })
        .catch((res) => {
          this.fetch(this.pagination.page);
          dialog.value = false;
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    postSaveEmas: function(dialog){
        this.$store.commit("main/setLoading", true);
        this.submitStatus = 1;

        this.$store
            .dispatch("transaction/editPostSaveEmas", {
                gramReal: this.formLock.gramasi_real,
                nominal: this.formLock.nominals,
                gramSystem: this.formLock.gramasi_system,
                gramId: this.formLock.gramasi_system,
                note: this.formLock.notes,
                userId: this.addIdTrans,
                updateId: this.profiles.id,
                goldId:this.$store.state.transaction.stokemas.hargaId,
        }).then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
            this.submitStatus = 0;
        })
        .catch((res) => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
            this.submitStatus = 0;
        });

    },
    showDetail(item) {
        this.$store.commit("transaction/setMemberHisGoldPage", "detail");
        this.$store.commit("transaction/setMemberTrxHisGold", item);
        //console.log("ItemList" , item);
        this.loading = false;
        this.loadingtext = '';
    },

    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
    this.pilihanOpt = 0;
  },
  watch: {
    pagination() {
        this.loading = true
        this.loadingtext = 'Loading... Please wait'
        this.fetch(0)
    },
    search (val) {
        clearTimeout(this.typingTimer);
        if (val) {
             this.typingTimer = setTimeout(()=> {
                this.searchValue = val;
                this.fetchMember(val);
            }, 1000);
        }
    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  computed: {
    fields () {
        if (!this.formLock.searchMember) return []

        return Object.keys(this.formLock.searchMember).map(key => {
          return {
            key,
            value: this.formLock.searchMember[key] || 'n/a',
          }
        })
    },
    items () {
        return this.listMembers.map(entry => {
          const Description = entry.name;

          return Object.assign({}, entry, { Description })
        })
    },

  },
  components: { CustomCard },
};
</script>

<style>
</style>