import request_util from '../util/request'
import request_util_formdata from '../util/requestFormData'

export function ApiRefferalList(data) {
    return request_util({
        url: '/admin/corporate/referral',
        method: 'POST',
        data: data
    })
}


export function ApiRegisterExistingCorporate(data) {
    var formData = new FormData();
    
    formData.append("userId", data['userId']);
    formData.append("name", data['name']);
    formData.append("namausaha", data['namausaha']);
    formData.append("ijinusaha", data['ijinusaha']);
    formData.append("website", data['website']);
    formData.append("alamat", data['alamat']);
    formData.append("provinsi", data['provinsi']);
    formData.append("kabupaten", data['kabupaten']);
    formData.append("kodePost", data['kodePost']);
    formData.append("rekeningkoran", data['rekeningkoran']);

    return request_util_formdata({
        url: '/admin/corporate/existing_register',
        method: 'POST',
        data: formData
    })
}

export function ApiRegisterCorporate(data) {
    var formData = new FormData();
    
    formData.append("name", data['name']);
    formData.append("email", data['email']);
    formData.append("phone", data['phone']);
    formData.append("password", data['password']);
    formData.append("nik", data['nik']);
    formData.append("gender", data['gender']);
    formData.append("religion", data['religion']);
    formData.append("birth_place", data['birth_place']);
    formData.append("date_of_birth", data['date_of_birth']);
    formData.append("namausaha", data['namausaha']);
    formData.append("ijinusaha", data['ijinusaha']);
    formData.append("website", data['website']);
    formData.append("alamat", data['alamat']);
    formData.append("alamatCorp", data['alamatCorp']);
    formData.append("propinsi", data['propinsi']);
    formData.append("ijinusaha", data['ijinusaha']);
    formData.append("kabupaten", data['kabupaten']);
    formData.append("kecamatan", data['kecamatan']);
    formData.append("kelurahan", data['kelurahan']);
    formData.append("kodepost", data['kodepost']);
    formData.append("bank_id", data['bank_id']);
    formData.append("bank_name", data['bank_name']);
    formData.append("bank_acc_number", data['bank_acc_number']);
    formData.append("bank_acc_holder", data['bank_acc_holder']);
    formData.append("img_ktp", data['img_ktp']);
    formData.append("img_selfie", data['img_selfie']);
    formData.append("img_bank", data['img_bank']);
    formData.append("rekeningkoran", data['rekeningkoran']);

    return request_util_formdata({
        url: '/admin/corporate/register',
        method: 'POST',
        data: formData
    })
}

export function ApiEditCorporate(data) {
    var formData = new FormData();
    
    formData.append("user_id" , data["user_id"]); 
    formData.append("id" , data["id"]); 
    formData.append("phone_num" , data["phone_num"]); 
    formData.append("corp_email" , data["corp_email"]); 
    formData.append("name", data['name']);
    formData.append("email", data['email']);
    formData.append("phone", data['phone']);
    formData.append("password", data['password']);
    formData.append("nik", data['nik']);
    formData.append("gender", data['gender']);
    formData.append("religion", data['religion']);
    formData.append("birth_place", data['birth_place']);
    formData.append("date_of_birth", data['date_of_birth']);
    formData.append("namausaha", data['namausaha']);
    formData.append("ijinusaha", data['ijinusaha']);
    formData.append("website", data['website']);
    formData.append("alamat", data['alamat']);
    formData.append("alamatCorp", data['alamatCorp']);
    formData.append("propinsi", data['propinsi']);
    formData.append("ijinusaha", data['ijinusaha']);
    formData.append("kabupaten", data['kabupaten']);
    formData.append("kecamatan", data['kecamatan']);
    formData.append("kelurahan", data['kelurahan']);
    formData.append("kodepost", data['kodepost']);
    formData.append("bank_id", data['bank_id']);
    formData.append("bank_name", data['bank_name']);
    formData.append("bank_acc_number", data['bank_acc_number']);
    formData.append("bank_acc_holder", data['bank_acc_holder']);
    formData.append("img_ktp", data['img_ktp']);
    formData.append("img_selfie", data['img_selfie']);
    formData.append("img_bank", data['img_bank']);
    formData.append("rekeningkoran", data['rekeningkoran']);
    formData.append("corporate_provinsi", data['corporate_provinsi']);
    formData.append("corporate_kabupaten", data['corporate_kabupaten']);
    formData.append("corporate_alamat", data['corporate_alamat']);
    formData.append("corporate_kodepost", data['corporate_kodepost']);

    return request_util_formdata({
        url: '/admin/corporate/edit',
        method: 'POST',
        data: formData
    })
}

export function ApiTrxRefferalList(data) {
    return request_util({
        url: '/admin/corporate/trx-referral',
        method: 'POST',
        data: data
    })
}

export function apiVerifyCorporate(data) {
    return request_util({
        url: '/admin/corporate/verify-corporate',
        method: 'POST',
        data: data
    })
}

export function ApiAddCorporate(data) {
    return request_util({
        url: '/admin/corporate/register',
        method: 'POST',
        data: data
    })
}


//export default function apiFetchListMember()