<template>
<div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

<CustomCard>

    <template v-slot:toolbar>
        <div style="width:100%;">

            <v-btn color="success" small outlined @click="filterToggle">
                filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn>

            <v-btn color="success" small outlined style="margin-left:40px; float:right;" @click="fetch(1)">
                Refresh <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-btn color="red" small  style="margin-left:20px; color:#ffffff; float:right;" @click="confirmAll(0)">
                Delete All <v-icon>mdi-trash</v-icon>
            </v-btn>

            <v-btn color="success" small  style="margin-left:20px; float:right;" @click="confirmAll(1)">
                Approve All <v-icon>mdi-trash</v-icon>
            </v-btn>

        </div>

    </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.member"
                            label="(Name, Email, Payment No, Ref ID, iPaymu ID)"
                            single-line
                            small
                            clearable>
                        </v-text-field>
                    </v-flex>

                    <!-- <v-flex>
                        <v-autocomplete
                            v-model="searchForm.thirdparty"
                            :items="thirdparty"
                            item-text="text"
                            item-value="value"
                            label="ThirdParty Tipe"
                        >
                        </v-autocomplete>
                    </v-flex> -->

                    <!-- <v-flex>
                        <v-autocomplete
                            v-model="searchForm.typetransaksi"
                            :items="typetransaksi"
                            item-text="text"
                            item-value="value"
                            label="Type Transaksi"
                        >
                        </v-autocomplete>
                    </v-flex>
                    
                    <v-flex>
                        <v-autocomplete
                            v-model="searchForm.statusPembayaran"
                            :items="statusPembayaran"
                            item-text="text"
                            item-value="value"
                            label="Status"
                        >
                        </v-autocomplete>
                    </v-flex>
                    
                </v-layout>

                <v-layout> -->

                    <v-flex>

                            <v-menu
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchForm.date"
                                    label="Start Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="searchForm.date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>

                    </v-flex>

                    <v-flex>
                        
                        <v-menu
                                :close-on-content-click="false"
                                :return-value.sync="date2"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchForm.date2"
                                    label="End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="searchForm.date2"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>

                    </v-flex>

                </v-layout>
                
                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="$store.state.transaction.settlement.trxListSettlement"
                        :options.sync="pagination"
                        :server-items-length="$store.state.transaction.settlement.total"
                        :single-select="singleSelect"
                        item-key="id"
                        show-select
                        :footer-props="{
                        'items-per-page-options': [10, 25, 50, 100],
                        'items-per-page-text': 'Baris per Halaman:',
                        }"
                        :items-per-page="10"
                        :loading="loading"
                        :loading-text="loadingtext"
                    >

                        <template v-slot:[`item.rates_sell`]="{ item }">
                                <div>  <b> {{ item.buy_rate }} </b> </div>
                                <div>  {{ item.sell_rate }} </div>
                        </template>

                        <template v-slot:[`item.members`]="{ item }">
                                <div>  <b> {{ item.receiver_name }} </b> </div>
                                <div>  {{ item.receiver_email }} </div>
                        </template>

                        <template v-slot:[`item.status_act`]="{ item }">
                                <!-- <div>  <b> {{ item.buy_rate }} </b> </div>
                                <div>  {{ item.sell_rate }} </div> -->
                                <div v-if="item.status == 'GAGAL'"> <v-icon small color="red">mdi-block-helper</v-icon>  <br /> <span style="color:red;"> {{ item.status }} </span> </div>
                                <div v-if="item.status == 'BERHASIL'" > <v-icon small color="green">mdi-check</v-icon>  <br /> <span style="color:green;"> {{ item.status }} </span> </div>

                                <div v-if="item.status != 'GAGAL' && item.status != 'BERHASIL'"> <v-icon small>mdi-clock</v-icon>  <br /> <span> {{ item.status }} </span> </div>

                        </template>
                        
                        <template v-slot:item.action="{ item }">

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="info"
                                icon
                                @click="showDetail(item)"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon small>mdi-eye</v-icon>
                            </v-btn>
                            </template>
                            <span>Detail</span>
                        </v-tooltip>
                        
                        <v-dialog
                                transition="dialog-top-transition"
                                max-width="600">
                                
                                <template v-slot:activator="{ on, attrs }">

                                        <!-- <v-tooltip> -->
                                        <v-btn
                                            color="red"
                                            icon
                                            v-bind="attrs"
                                            v-bind:class="item.status_code != 2 ? '' : 'hidden'"
                                            v-on="on">
                                            <v-icon small color="red">mdi-block-helper</v-icon>
                                        </v-btn>
                                        <!-- 
                                            <span>Detail</span>
                                        </v-tooltip> -->
                                        
                                    </template>
                                    <!-- <span>Unverified User</span> -->
                                    <template v-slot:default="dialog">
                                        <v-card>
                                            <v-toolbar
                                            color="red"
                                            dark
                                            >Manual Banned Transaction</v-toolbar>

                                            <v-container>

                                                <v-layout>
                                                    <v-flex>
                                                        Yakin akan Banned Transaksi ini ?
                                                    </v-flex>
                                                </v-layout>
                                                    
                                            </v-container>

                                            <v-card-actions class="justify-end">
                                            <v-btn
                                                text
                                                @click="dialog.value = false"
                                            >Close</v-btn>
                                            <v-btn
                                                style="color:#ffffff;"
                                                color="red"
                                                @click="editVerificationBan(dialog , item.id , 0)"
                                            >Submit</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                </template>
                            </v-dialog>
                        
                            <v-dialog
                                transition="dialog-top-transition"
                                max-width="600">
                                
                                <template v-slot:activator="{ on, attrs }">

                                        <!-- <v-tooltip> -->
                                        <v-btn
                                            color="warning"
                                            icon
                                            v-bind="attrs"
                                            v-bind:class="item.status_code != 1 ? '' : 'hidden'"
                                            v-on="on">
                                            <v-icon small color="green">mdi-thumb-up-outline</v-icon>
                                        </v-btn>
                                        <!-- 
                                            <span>Detail</span>
                                        </v-tooltip> -->
                                        
                                    </template>
                                    <!-- <span>Unverified User</span> -->
                                    <template v-slot:default="dialog">
                                        <v-card>
                                            <v-toolbar
                                            color="success"
                                            dark
                                            >Manual Success Transaction</v-toolbar>

                                            <v-container>

                                                <v-layout>
                                                    <v-flex>
                                                        Yakin akan Approve Transaksi ini ?
                                                    </v-flex>
                                                </v-layout>
                                                    
                                            </v-container>

                                            <v-card-actions class="justify-end">
                                                <v-btn
                                                    text
                                                    @click="dialog.value = false"
                                                >Close
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    @click="editVerificationSuccess(dialog , item.id , 1)"
                                                >Submit
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                </template>
                            </v-dialog>

                        </template>

                        <template v-slot:item.email="{ item }">
                            <div v-if="item.type_code == 1 || item.type_code == 10">
                                {{ item.receiver_email }}
                            </div>
                            <div v-else>
                                {{ item.sender_email }}
                            </div>
                        </template>

                    </v-data-table>
                    
            </v-flex>
    </template>

</CustomCard>

</div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      statusPembayaran: [
            { text: "All Status", value: "" },
            { text: "Menunggu Pembayaran", value: "0" },
            { text: "Berhasil", value: "1" },
            { text: "Gagal", value: "2" },
            { text: "Menunggu Settlement", value: "3" },
            { text: "Menunggu Settlement Komisi", value: "4" }
            // { text: "Gagal", value: "2" },
      ],
      typetransaksi: [
            { text: "All Type", value: "" },
            { text: "Pembelian", value: "1" },
            { text: "Setor Emas", value: "10" },
            { text: "Refund IdCash", value: "11" },
            { text: "Reward Dinaran Games", value: "12" },
            { text: "Reward Dinaran Affiliate", value: "13" },
            { text: "Penjualan", value: "2" },
            { text: "Beri Emas", value: "3" },
            { text: "Fee Pembelian", value: "4" },
            { text: "Fee Penjualan", value: "5" },
            { text: "Ambil Emas", value: "6" },
            { text: "Fee Ambil Emas", value: "7" },
            { text: "Ongkir Ambil Emas", value: "8" },
            { text: "Komisi", value: "9" }
            // { text: "Gagal", value: "2" },
      ],
      thirdparty: [
            { text: "All", value: "all" },
            { text: "IDCASH", value: "idcash" }
            // { text: "Gagal", value: "2" },
      ],
      statusver: [
            { text: "All", value: "all" },
            { text: "Request Verfikasi", value: "false" },
            { text: "Terverifikasi", value: "true" }
            // { text: "Gagal", value: "2" },
      ],
      statusaddr: [
            { text: "All", value: "all" },
            { text: "Address + Current Address", value: "1" },
            { text: "Address", value: "2" },
            { text: "No Address", value: "3" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [
        { text: "TrxID", value: "id" },
        { text: "Member", value: "members" },
        { text: "Type", value: "type", width: "150px" },
        { text: "Status", value: "status_act", width: "200px" , align:"center" },
        { text: "Trx Date", value: "created_at", width: "180px" },
        { text: "Paid Date", value: "updated_at", width: "180px" },
        { text: "Paid Amount", value: "amount", align: "right" },
        { text: "Gramasi", value: "gramasi", align: "right" },
        { text: "Rate", value: "rates_sell", align: "right" },
        { text: "Payment Method", value: "pay_method" },
        { text: "Update By", value: "admin" },
        { text: "Action", align: "left", value: "action", width: "200px" },
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    confirmAll: function(code){

        let conn_notification = "";
        let routes_url = "";

        if(code == 0){
            conn_notification = 'Are you sure you want to delete this item ? ';
            routes_url = "transaction/editMemberTransactionBan";
        }
        else{
            conn_notification = 'Are you sure you want to Approve this item ? ';
            routes_url = "transaction/editMemberTransactionSuccess";
        }

        if(confirm(conn_notification)){
            // alert(this.selected.length);
            // for(var i = 0; i < this.selected.length; i++){
            //     var delete_this_id = this.selected[i].id;
            // }
            // console.log("selected-length" , JSON.stringify(this.selected));
            // console.log("codes" , code);
            // return false;
            // this.$store.commit("main/setLoading", true);

            this.$store.commit("main/setLoading", true);

            this.$store
                .dispatch(routes_url, {
                    updateId: this.profiles.id,
                    trxid: JSON.stringify(this.selected),
                    code: code,
                    id: JSON.stringify(this.selected)
            })
            .then(() => {
               // if(i == this.selected.length){
                    this.fetch(this.pagination.page);
                    //dialog.value = false;
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
               // }
            })
            .catch((res) => {
               // if(i == this.selected.length){
                    this.fetch(this.pagination.page);
                    //dialog.value = false;
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res);
                    this.loading = false;
                    this.loadingtext = '';
               // }
            });

        }
        
    },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      //alert("limits "+this.pagination.itemsPerPage);
      this.$store
        .dispatch("transaction/fetchMemberListSettlement", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            member:this.searchForm.member,
            typetransaksi:this.searchForm.typetransaksi,
            statusPembayaran:this.searchForm.statusPembayaran,
            startDate:this.searchForm.date,
            endDate:this.searchForm.date2
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    editVerificationBan: function(dialog , id , code){
    //  console.log("updateId" , this.profiles.id);
    //  console.log("code" , code);
    //  console.log("trxid" , id);
     //console.log("id" , id);

      this.$store.commit("main/setLoading", true);
      //alert("ban");

      let arr = [];
        let obj = {
            "id": id.replaceAll(".",""),
        };
        arr[0]  = obj;

      this.$store
        .dispatch("transaction/editMemberTransactionBan", {
            updateId: this.profiles.id,
            trxid: JSON.stringify(arr),
            code: code,
            id: JSON.stringify(arr)
        })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
        })
        .catch((res) => {
          this.fetch(this.pagination.page);
          dialog.value = false;
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    editVerificationSuccess: function(dialog , id , code){
        
    let arr = [];
    let obj = {
        "id": id.replaceAll(".", ""),
    };
    arr[0]  = obj;

     this.$store.commit("main/setLoading", true);
      this.$store
        .dispatch("transaction/editMemberTransactionSuccess", {
            updateId: this.profiles.id,
            code: code,
            trxid: JSON.stringify(arr),
            id: JSON.stringify(arr)
        })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
        })
        .catch((res) => {
          this.fetch(this.pagination.page);
          dialog.value = false;
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    userLock: function(dialog , id , code){

        this.$store.commit("main/setLoading", true);

        this.$store
            .dispatch("member/editUserLockMember", {
                updateId: this.profiles.id,
                code: code,
                id: id
            })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
        })
        .catch((res) => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
        });

    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("transaction/setMemberPage", "detail");
        this.$store.commit("transaction/setMemberDetail", item);
        //console.log("ItemList" , item);
        this.loading = false;
        this.loadingtext = '';
    },

    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
     // alert("tess");
  },
  watch: {
    pagination() {
      //  alert("tess");
        //alert(this.$store.state.member.member.is_back);
        //if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        //}

        //if(this.$store.state.member.member.is_back == 1){
        //this.pagination.page = this.$store.state.member.member.paging_page;
        //}

       // this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>