<template>
    <div>
      <v-container grid-list-xs fluid>
        <List></List>
        <Detail v-if="$store.state.games.score.page == 'detail'"></Detail>
      </v-container>
    </div>
  </template>
  
  <script>
  import List from "./score/list.vue";
  import Detail from "./score/detail.vue";
  export default { 
  data() {
      return {
        pages:"list"
      }
    },
    mounted() {
        this.$store.commit("games/setGamesPages", "list");
    },
    components: { List, Detail } 
  };
  </script>
  
  <style>
  
  </style>