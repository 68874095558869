<template>
    <div>
        <p>&nbsp;</p>

        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialog"
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5">
                    Verification Dialog
                    </v-card-title>

                    <v-card-text>
                        Verify this Corporate Account ?
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="verifyAccount()">
                        Verify
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>
        <!-- <v-btn color="success" @click="fetch">Detail Member</v-btn> -->
        <CustomCard>
            <p></p>

            <template v-slot:toolbar>
                <div style="width:100%;">
                    <v-btn color="success" small outlined @click="showList()" style="float:left;">
                        <v-icon>mdi-chevron-left</v-icon> Back to List
                    </v-btn>
                    <v-btn color="success" small outlined @click="fetch()" style="float:right;">
                        <v-icon>mdi-refresh</v-icon> Refresh
                    </v-btn>
                </div>
            </template>

            <template v-slot:datatable>
                <v-flex md12 style="overflow-x: scroll">

                    <v-data-table :headers="headers" :items="$store.state.member.member.listCorpDetail"
                        :loading="loading" :loading-text="loadingtext">

                        <template v-slot:[`item.verified_date`]="{ item }">
                            <div> {{ item.verified_at }} </div>
                            <div style="margin-top:-5px;"> <small> {{ item.verified_time }} </small> </div>
                            <!-- <div style="margin-top:-5px;"> <small> {{ item.real_gramasi }} </small> </div>
                            <div style="margin-top:-5px;"> <small> GR </small> </div> -->
                        </template>

                        <template v-slot:[`item.sts_verified`]="{ item }">
                            <div style="text-align:center;">
                                <div v-if="item.status_corp == 0" style="color:red;">
                                    <v-icon small color="red">mdi-block-helper</v-icon>
                                    <div style="margin-top:-5px;"> <small> Not Verified </small> </div>
                                </div>
                                <div v-if="item.status_corp == 1" style="color:green;">
                                    <v-icon small color="green">mdi-check</v-icon>
                                    <div style="margin-top:-5px;"> <small> Verified </small>

                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-slot:[`item.em_verified`]="{ item }">

                            <div style="text-align:center;">
                                <div v-if="item.email_verified == 0" style="color:red;">
                                    <v-icon small color="red">mdi-block-helper</v-icon>
                                    <div style="margin-top:-5px;"> <small> Not Verified </small> </div>
                                </div>
                                <div v-if="item.email_verified == 1" style="color:green;">
                                    <v-icon small color="green">mdi-check</v-icon>
                                    <div style="margin-top:-5px;"> <small> Verified </small> </div>
                                </div>
                            </div>

                        </template>

                        <template v-slot:[`item.verified`]="{ item }">
                            <div style="text-align:center;">

                                <div v-if="item.is_block == false" style="color:red;">

                                    <div v-if="item.verified == 0" style="color:red;">
                                        <v-icon small color="red">mdi-block-helper</v-icon>
                                        <div style="margin-top:-5px;"> <small> Not Verified </small> </div>
                                    </div>
                                    <div v-if="item.verified == 1" style="color:green;">
                                        <v-icon small color="green">mdi-check</v-icon>
                                        <div style="margin-top:-5px;"> <small> Verified </small> </div>
                                    </div>

                                </div>

                                <div v-if="item.is_block == true" style="color:red;">
                                    <!-- <div v-if="item.verified == 0" style="color:red;">  -->
                                    <v-icon small color="red">mdi-block-helper</v-icon>
                                    <div style="margin-top:-5px;"> <small> Suspend </small> </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="info" icon v-bind="attrs" v-on="on"
                                        v-if="$store.state.user.ac_update == 't'"
                                        @click="showLock()">
                                        <v-icon small color="green">mdi-check</v-icon>
                                    </v-btn>
                                </template>
                                <span>Verify Data Corporate</span>
                            </v-tooltip>

                            <v-dialog transition="dialog-top-transition" max-width="600" v-model="showDialogUpdate">
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar color="success" dark>Form Edit Detail Member</v-toolbar>

                                        <v-layout style="display:none;">

                                            <v-text-field v-model="formLock.userId" label="" hidden>
                                            </v-text-field>

                                        </v-layout>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field v-model="formLock.edit_name" label="Name">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field v-model="formLock.edit_email" label="Email">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field v-model="formLock.edit_phone" label="Phone">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                        </v-container>

                                        <v-card-actions class="justify-end">
                                            <v-btn text @click="dialog.value = false">Close</v-btn>
                                            <v-btn color="success" @click="editProfiles()">Submit</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>

                            <v-dialog transition="dialog-top-transition" max-width="600" v-model="unverdialog">
                                <!-- <span>Unverified User</span> -->
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar color="success" dark>Unverified User Account</v-toolbar>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    Yakin anda akan melakukan Unverified atas user ini? Request
                                                    Verifikasi sebelumnya akan di unverified
                                                </v-flex>
                                            </v-layout>

                                        </v-container>

                                        <v-card-actions class="justify-end">
                                            <v-btn text @click="dialog.value = false">Close</v-btn>
                                            <v-btn color="success" @click="userUnverify(dialog)">Unverified This
                                                Account</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>


                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="#999999" icon v-bind="attrs" v-on="on" v-if="item.is_block == false"
                                        @click="showDetailId(item.id)">
                                        <v-icon small>mdi-block-helper</v-icon>
                                    </v-btn>
                                </template>
                                <span>Suspend User Account</span>
                            </v-tooltip>


                            <v-dialog transition="dialog-top-transition" max-width="600" v-model="suspenddialog">
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar color="error" dark>Suspend User Account </v-toolbar>

                                        <v-layout style="display:none;">

                                            <v-text-field v-model="formLock.userId" label="" hidden>
                                            </v-text-field>

                                        </v-layout>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    Yakin anda akan melakukan Suspend atas user ini?
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field v-model="formLock.suspend_reason"
                                                        label="Suspend Reason">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>


                                        </v-container>

                                        <v-card-actions class="justify-end">
                                            <v-btn text @click="dialog.value = false">Close</v-btn>
                                            <v-btn color="error" @click="userSuspend(dialog)">Suspend User
                                                Account</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>

                            <v-dialog transition="dialog-top-transition" max-width="600">
                                <template v-slot:activator="{ on, attrs }">

                                    <v-btn color="success" icon v-bind="attrs" v-on="on" v-if="item.is_block == true"
                                        @click="showDetailId(item.id)">
                                        <v-icon small>mdi-check</v-icon>
                                    </v-btn>

                                </template>

                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar color="success" dark>UnSuspend User Account</v-toolbar>

                                        <v-text-field v-model="formLock.userId" label="" hidden>
                                        </v-text-field>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    Yakin anda akan melakukan UnSuspend atas user ini?
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>

                                                <v-flex>
                                                    <v-text-field v-model="formLock.suspend_reason"
                                                        label="UnSuspend Reason">
                                                    </v-text-field>
                                                </v-flex>

                                            </v-layout>

                                        </v-container>

                                        <v-card-actions class="justify-end">
                                            <v-btn text @click="dialog.value = false">Close</v-btn>
                                            <v-btn color="success" @click="userUnSuspend(dialog)">UnSuspend User
                                                Account</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </template>
                    </v-data-table>
                </v-flex>
            </template>
            <p></p>

        </CustomCard>
        
        <p>&nbsp;</p>

        <CustomCard>
            <template v-slot:toolbar style="margin-bottom:20px;">

                <div style="width:100%;">
                    <v-btn color="success" small outlined @click="showList()" v-if="detailsMember.verified == 1">
                        <!-- <v-icon>mdi-chevron-left</v-icon> -->
                        Data Verify
                    </v-btn>


                    <v-btn color="blue" small outlined @click="showEdit()"
                        v-if="$store.state.member.member.formStates == false && detailsMember.verified == 1"
                        style="float:right;">
                        <!-- <v-icon>mdi-chevron-left</v-icon> -->
                        <v-icon>mdi-pencil</v-icon> Edit Data Corporate
                    </v-btn>


                    <v-btn color="blue" small outlined @click="showListMember()"
                        v-if="$store.state.member.member.formStates == true" style="float:right;">
                        <!-- <v-icon>mdi-chevron-left</v-icon> -->
                        <v-icon>mdi-chevron-left</v-icon> Back to Detail
                    </v-btn>

                    <v-btn color="success" small outlined @click="showList()" v-if="detailsMember.verified == 0">
                        <!-- <v-icon>mdi-chevron-left</v-icon> -->
                        Not Verified
                    </v-btn>
                </div>

            </template>

            <template v-slot:datatable  style="margin-top:20px;">
                <MemberDetail />
            </template>
        </CustomCard>

    </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";
import MemberDetail from "./detailcomponents/memberdetail.vue";


export default {
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            panel: null,
            currentPage: 1,
            decimals: "10.00",
            icons: "chevron-right",
            showDialogUpdate: false,
            unverdialog: false,
            suspenddialog: false,
            dis_kab: true,
            dis_kec: true,
            dis_kel: true,
            dialog: false,
            curr_dis_kab: true,
            curr_dis_kec: true,
            curr_dis_kel: true,
            curr_dis_prov: true,
            profiles: this.$store.state.auth.profile,
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],
            currkabupatenList: [],
            currkecamatanList: [],
            currkelurahanList: [],
            IndexKel: "",
            bankList: [],
            detailsMember: "",
            editAct: this.$store.state.member.member.formStates,
            tipe: [],
            menu: null,
            form: {},
            tab: null,
            items: [
                'Member Detail', 'Corporate Detail', 'Images'
            ],
            searchForm: {
                member: ''
            },
            pagination: {},
            formLock: {

            },
            headers: [
                { text: "Name", value: "namausaha" },
                { text: "Provinsi", value: "provinsicorp" },
                { text: "Email", value: "corp_email" },
                { text: "Phone Num", value: "phone_num" },
                { text: "Reg Date", value: "verified_date" },
                { text: "Gramasi", value: "real_gramasi" },
                { text: "Verified", value: "sts_verified" },
                { text: "Action", align: "center", value: "action" },
            ],
            gender: [
                { text: "All Gender", value: "" },
                { text: "Laki-Laki", value: "Laki-Laki" },
                { text: "Perempuan", value: "Perempuan" },
                // { text: "Gagal", value: "2" },
            ],
            religion: [
                { text: "All Religion", value: "" },
                { text: "Hindu", value: "Hindu" },
                { text: "Katolik", value: "Katolik" },
                { text: "Kristen", value: "Kristen" },
                { text: "Buddha", value: "Buddha" },
                { text: "Islam", value: "Islam" },
                { text: "Konghucu", value: "Konghucu" }
                // { text: "Gagal", value: "2" },
            ],
            loading: false,
            loadingText: "Loading Data...",
        };
    },
    methods: {
        showLock(){
            this.dialog = true;
        },
        verifyAccount(){
            this.loading = true;
            this.loadingtext = 'Loading... Please wait';
            this.$store.commit("main/setLoading", true);
            this.$store
                .dispatch("corporate/verifyCorporate", {
                    id: this.$store.state.member.member.listCorpDetail[0].id,
                })
                .then(() => {
                    //alert("tes-dd");
                    //console.log("itemsfetch", this.$store.state.member.member.listDetail[0]);
                    this.$store.commit("main/setLoading", false);
                    this.loading = false;
                    this.loadingtext = '';
                    this.dialog = false;
                    this.fetch();
                    //this.detailsMember = this.$store.state.member.member.listCorpDetail[0];
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        fetchProv(params) {
            this.$store
                .dispatch("wilayah/fetchWilayah", {
                    find: params,
                })
                .then(() => {
                    this.provinsiList = this.$store.state.wilayah.wilayah.list;
                    //console.log("provlist", this.$store.state.wilayah.wilayah.list);
                    //alert("prov list");
                })
                .catch((res) => {
                    console.log("prov", res.response.data.message);
                });
        },
        getIndexKel(index) {
            // alert("index = "+index)
            console.log(index);
        },
        fetchBank(params) {
            this.$store
                .dispatch("bank/fetchBank", {
                    find: params,
                })
                .then(() => {
                    this.bankList = this.$store.state.bank.bank.listBank;
                })
                .catch((res) => {
                    console.log("prov", res.response.data.message);
                });
        },
        fetch() {
            this.$store.state.member.member.formStates = false;

            this.loading = true;
            this.loadingtext = 'Loading... Please wait';
            this.$store.commit("main/setLoading", true);
            this.$store
                .dispatch("member/fetchListMemberCorpDetail", {
                    id_member: this.$store.state.member.member.detail,
                })
                .then(() => {
                    //alert("tes-dd");
                    //console.log("itemsfetch", this.$store.state.member.member.listDetail[0]);
                    this.$store.commit("main/setLoading", false);
                    this.loading = false;
                    this.loadingtext = '';
                    this.detailsMember = this.$store.state.member.member.listCorpDetail[0];
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        filterToggle() {
            if (this.panel == null)
                this.panel = [...Array(this.items).keys()].map((k, i) => i);
            else this.panel = null;
        },
        showDetail(item) {
            this.$store.commit("transaction/setMemberCorpPage", "detail");
            this.$store.commit("transaction/setCorpMemberDetail", item);
            //this.detailsName = "New Member";
        },
        showList() {
            this.$store.commit("member/setMemberCorpPage", "list");
            this.$store.state.member.member.is_back = 1;
            //   this.$store.commit("transaction/setMemberDetail", item);
        },
        getKabWilayah(value , province) {
            this.$store
                .dispatch("wilayah/fetchAreaWilayah", {
                    find: value,
                    provinsi: province,
                    kabupaten: "",
                    kecamatan: "",
                })
                .then(() => {
                this.$store.state.corporate.editform.kabupatenList = this.$store.state.wilayah.wilayah.listKabupaten;
            })
        },
        getWilayah(value) {

            if (value == "kabupaten") {
                this.$store.state.member.list.dis_kab = false;
            }

            if (value == "kecamatan") {
                this.$store.state.member.list.dis_kec = false;
            }

            if (value == "kelurahan") {
                this.$store.state.member.list.dis_kel = false;
            }

            this.$store
                .dispatch("wilayah/fetchAreaWilayah", {
                    find: value,
                    provinsi: this.$store.state.corporate.editform.editProvinsi,
                    kabupaten: this.$store.state.corporate.editform.editKabupaten,
                    kecamatan: this.$store.state.corporate.editform.editKecamatan,
                })
                .then(() => {

                    if (value == "kabupaten") {
                        
                        this.kabupatenList = this.$store.state.wilayah.wilayah.listKabupaten;
                        this.$store.state.member.list.dis_kab = false;
                    }

                    if (value == "kecamatan") {
                        this.kecamatanList = this.$store.state.wilayah.wilayah.listKecamatan;
                        this.$store.state.member.list.dis_kec = false;
                    }

                    if (value == "kelurahan") {
                        this.kelurahanList = this.$store.state.wilayah.wilayah.listKelurahan;
                        this.$store.state.member.list.dis_kel = false;
                    }


                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        showEdit() {
            let BankValue = this.$store.state.member.member.listCorpDetail[0].bank_name_detail + "#" + this.$store.state.member.member.listCorpDetail[0].bank_id;

            this.$store.state.corporate.editform.editNik = this.$store.state.member.member.listCorpDetail[0].nik;
            this.$store.state.corporate.editform.editProvinsi = this.$store.state.member.member.listCorpDetail[0].provinsi;
            this.$store.state.corporate.editform.editBirthPlace = this.$store.state.member.member.listCorpDetail[0].birth_place;
            this.$store.state.corporate.editform.editDateOfBirth = this.$store.state.member.member.listCorpDetail[0].date_of_birth;
            this.$store.state.corporate.editform.editKabupaten = this.$store.state.member.member.listCorpDetail[0].kabupaten;
            this.$store.state.corporate.editform.editKecamatan = this.$store.state.member.member.listCorpDetail[0].kecamatan;
            this.$store.state.corporate.editform.editKodePost = this.$store.state.member.member.listCorpDetail[0].kode_post;
            this.$store.state.corporate.editform.editAddress = this.$store.state.member.member.listCorpDetail[0].address;
            this.$store.state.corporate.editform.editBankName = this.$store.state.member.member.listCorpDetail[0].bank_id;
            this.$store.state.corporate.editform.editGender = this.$store.state.member.member.listCorpDetail[0].gender;
            this.$store.state.corporate.editform.editReligion = this.$store.state.member.member.listCorpDetail[0].religion;
            this.$store.state.corporate.editform.editBankAccNo = this.$store.state.member.member.listCorpDetail[0].bank_acc_number;
            this.$store.state.corporate.editform.editBankAccHolder = this.$store.state.member.member.listCorpDetail[0].bank_acc_holder;
            this.$store.state.corporate.editform.editId = this.$store.state.member.member.listCorpDetail[0].id;
            this.$store.state.corporate.editform.editNamaUsaha = this.$store.state.member.member.listCorpDetail[0].namausaha;
            this.$store.state.corporate.editform.editAtasNamaRek = this.$store.state.member.member.listCorpDetail[0].bank_acc_holder;
            this.$store.state.corporate.editform.editNamaBank = BankValue;
            this.$store.state.corporate.editform.editNoRekening = this.$store.state.member.member.listCorpDetail[0].bank_acc_number;
            this.$store.state.corporate.editform.websiteUtama = this.$store.state.member.member.listCorpDetail[0].website;
            this.$store.state.corporate.editform.corp_email = this.$store.state.member.member.listCorpDetail[0].corp_email;
            this.$store.state.corporate.editform.phone_num = this.$store.state.member.member.listCorpDetail[0].phone_num;
            this.$store.state.corporate.editform.editEmail = this.$store.state.member.member.listCorpDetail[0].email;
            this.$store.state.corporate.editform.editName = this.$store.state.member.member.listCorpDetail[0].name;
            this.$store.state.corporate.editform.editPassword = "";
            this.$store.state.corporate.editform.editPhone = this.$store.state.member.member.listCorpDetail[0].phone;
            this.$store.state.corporate.editform.corporateprovinsi = this.$store.state.member.member.listCorpDetail[0].provinsicorp;
            this.$store.state.corporate.editform.editCorporateKabupaten = this.$store.state.member.member.listCorpDetail[0].kabupatencorp;
            this.$store.state.corporate.editform.editCorporateAlamat = this.$store.state.member.member.listCorpDetail[0].alamatcorp;
            this.$store.state.corporate.editform.editCorporateKodePost = this.$store.state.member.member.listCorpDetail[0].kode_postcorp;
            this.$store.state.corporate.editform.dis_kab = false;

            if (this.$store.state.member.member.listCorpDetail[0].provinsi != "") {
                this.getWilayah("kabupaten");
            }

            if(this.$store.state.member.member.listCorpDetail[0].provinsicorp != ""){
                //alert("Provinsi Fetched");
                this.getKabWilayah("kabupaten" , this.$store.state.member.member.listCorpDetail[0].provinsicorp);
            }

            if (this.$store.state.member.member.listCorpDetail[0].kabupaten != "") {
                this.getWilayah("kecamatan");
            }

            if (this.$store.state.member.member.listCorpDetail[0].kecamatan != "") {
                this.getWilayah("kelurahan");
            }

            if (this.$store.state.member.member.listCorpDetail[0].current_provinsi != "") {
                this.getWilayah("currkabupaten");
            }

            if (this.$store.state.member.member.listCorpDetail[0].current_kabupaten != "") {
                this.getWilayah("currkecamatan");
            }

            if (this.$store.state.member.member.listCorpDetail[0].current_kecamatan != "") {
                this.getWilayah("currkelurahan");
            }

            this.$store.commit("member/setFormSwitch", true);
        },
        showListMember() {
            this.$store.commit("member/setFormSwitch", false);
        },
    },
    mounted() {
        this.fetchProv("");
        this.fetchBank("bank");
        this.$store.commit("member/setFormSwitch", false);
        this.fetch();

        //alert(this.$store.state.user.ac_update);
    },
    //   watch: {
    //     pagination() {
    //         this.loading = true
    //         this.loadingtext = 'Loading... Please wait'
    //         this.fetch()
    //     },
    //     searchSponsor(val) {
    //         val && val !== this.searchForm.sponsor && this.getSponsor(val);
    //     },
    //     searchMember(val) {
    //         val && val !== this.searchForm.member && this.getMember(val);
    //     }
    //   },

    computed: {
        dateRangeText() {
            if (typeof this.form.dates != "undefined") {
                if (this.form.dates.length > 1) {
                    return this.form.dates.join(" ~ ");
                } else {
                    return this.form.dates[0];
                }
            } else {
                return null;
            }
        },
    },
    components: { CustomCard, MemberDetail },
};
</script>
