<template>
  <div>
    <CustomCard :cardShow="true">
      <template v-slot:toolbar>
        <v-btn color="success" small outlined @click="backList()">
          back
        </v-btn>
      </template>
      <template v-slot:content>
        <v-layout row wrap>
          <v-flex md4 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Data Profile </b> </v-toolbar>
            <v-simple-table v-if="$store.state.transaction.member.detail.type_code == 1 || $store.state.transaction.member.detail.type_code == 10">
              <template v-slot:default>

                <tbody v-if="$store.state.transaction.member.detail.receiver_id != null">
                  <tr>
                    <td>Nama</td>
                    <td style="text-align: right">{{ $store.state.transaction.member.detail.receiver_name }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td style="text-align: right">{{ $store.state.transaction.member.detail.receiver_email }}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td style="text-align: right">{{ $store.state.transaction.member.detail.receiver_phone }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td style="text-align: right">{{ $store.state.transaction.member.detail.receiver_verified }}</td>
                  </tr>
                </tbody>

              </template>
            </v-simple-table>

            <v-simple-table v-if="$store.state.transaction.member.detail.type_code == 2">
              <template v-slot:default>

                <tbody>
                  <tr>
                    <td>Nama</td>
                    <td style="text-align: right">{{ $store.state.transaction.member.detail.sender_name }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td style="text-align: right">{{ $store.state.transaction.member.detail.sender_email }}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td style="text-align: right">{{ $store.state.transaction.member.detail.sender_phone }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td style="text-align: right">{{ $store.state.transaction.member.detail.sender_verified }}</td>
                  </tr>
                </tbody>

              </template>
            </v-simple-table>

            <!-- if($detail->type==1 or $detail->type==10)
                {
                    if(isset($detail->receiveruser))
                    {
                        $form .= '<tr><td>Nama</td><td style="text-align: right">'.$detail->receiveruser['name'].'</td></tr>
                        <tr><td>Email</td><td style="text-align: right">'.$detail->receiveruser['email'].'</td></tr>
                        <tr><td>Phone</td><td style="text-align: right">'.$detail->receiveruser['phone'].'</td></tr>
                        <tr><td>Status</td><td style="text-align: right">';

                        if($detail->receiveruser['is_verified'] == true){ $form .= '<span class="text-success">Verified</span>'; }
                        else { $form .= '<span class="text-danger">Unverified</span>'; }

                        $form .= '</td></tr>';
                    }
                }
                else if($detail->type==2)
                {
                    if(isset($detail->senderuser))
                    {
                        $form .= '<tr><td>Nama</td><td style="text-align: right">'.$detail->senderuser['name'].'</td></tr>
                        <tr><td>Email</td><td style="text-align: right">'.$detail->senderuser['email'].'</td></tr>
                        <tr><td>Phone</td><td style="text-align: right">'.$detail->senderuser['phone'].'</td></tr>
                        <tr><td>Status</td><td style="text-align: right">';
                    
                        if($detail->senderuser['is_verified'] == true) { $form .= '<span class="text-success">Verified</span>'; }
                        else { $form .= '<span class="text-danger">Unverified</span>'; }

                        $form .= '</td></tr>';
                    }
                } -->

          </v-flex>
          <v-flex md4 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Data Transaksi </b> </v-toolbar>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Tanggal Trx</td>
                    <td> {{ $store.state.transaction.member.detail.created_at }}</td>
                  </tr>
                  <tr>
                    <td>ID Transaksi</td>
                    <td> {{ $store.state.transaction.member.detail.id.replace(".", "") }}</td>
                  </tr>
                  <tr>
                    <td>Ref ID</td>
                    <td> {{ $store.state.transaction.member.detail.ref_id }}</td>
                  </tr>
                  <tr>
                    <td>Type Transaksi</td>
                    <td> {{ $store.state.transaction.member.detail.type }}</td>
                  </tr>
                  <tr>
                    <td>Total Gramasi </td>
                    <td> {{ $store.state.transaction.member.detail.gramasi }} GR</td>
                  </tr>
                  <tr>
                    <td>Total Amount</td>
                    <td> {{ $store.state.transaction.member.detail.amount }}</td>
                  </tr>
                  <tr>
                    <td>Status Pembayaran</td>
                    <td> {{ $store.state.transaction.member.detail.status }}</td>
                  </tr>
                  <tr>
                    <td>Note</td>
                    <td> {{ $store.state.transaction.member.detail.note }}</td>
                  </tr>
                  <!-- <tr>
                    <td>{{ item.text }}</td>
                    <td style="text-align: right">{{ item.value }}</td>
                  </tr> -->
                  <!-- <tr><td>ID Transaksi</td><td style="text-align: right">{{ item.id }}</td></tr>
                  <tr><td>Type Transaksi</td><td style="text-align: right">{{ item.trxType }}</td></tr>
                  <tr><td>Total Gramasi</td><td style="text-align: right">{{ item.gramasi }} Gr</td></tr> -->

                  <!-- @isset($detail->goldrate)
                  @if($detail->type==1)
                  <tr><td>Gold Rate</td><td style="text-align: right">{{ number_format($detail->goldrate['buy_rate'], 2, ',', '.') }} IDR</td></tr>
                  @elseif($detail->type==2)
                  <tr><td>Gold Rate</td><td style="text-align: right">{{ number_format($detail->goldrate['sell_rate'], 2, ',', '.') }} IDR</td></tr>
                  @endif
                  @endisset -->

                  <!-- <tr><td>Total Amount</td><td style="text-align: right">{{ item.amount }}  IDR</td></tr>
                  <tr><td>Status Pembayaran</td><td style="text-align: right">{{ item.trxStatus }}  </td></tr> -->
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
          <v-flex md4 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Data Ipaymu </b> </v-toolbar>
            <v-simple-table>
              <template v-slot:default>
                <tbody v-if="$store.state.transaction.member.detail.ipaymu_id != 0">
                  <!-- <tr>
                    <td>{{ item.text }}</td>
                    <td style="text-align: right">{{ item.value }}</td>
                  </tr> -->
                  <tr>
                    <td>Ipaymu ID</td>
                    <td> {{ $store.state.transaction.member.detail.ipaymu_id.replaceAll(".", "") }}</td>
                  </tr>
                  <tr>
                    <td>Ref ID</td>
                    <td> {{ $store.state.transaction.member.detail.ref_id_ipaymu }}</td>
                  </tr>
                  <tr>
                    <td>Payment Channel</td>
                    <td> {{ $store.state.transaction.member.detail.pay_channel }}</td>
                  </tr>
                  <tr>
                    <td>Payment Method </td>
                    <td> {{ $store.state.transaction.member.detail.pay_method }} </td>
                  </tr>
                  <tr>
                    <td>Payment No</td>
                    <td> {{ $store.state.transaction.member.detail.payment_no }}</td>
                  </tr>
                  <tr>
                    <td>Received Amount</td>
                    <td> {{ $store.state.transaction.member.detail.received_amount }}</td>
                  </tr>
                  <tr>
                    <td>Created</td>
                    <td> {{ $store.state.transaction.member.detail.updated_ipaymu }}</td>
                  </tr>
                  <tr>
                    <td>Expired</td>
                    <td> {{ $store.state.transaction.member.detail.expired_at }}</td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>

        <v-layout style="margin-top:30px;">

          <v-flex md12 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Data ICDX </b> </v-toolbar>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <!-- <tr>
                        <td>{{ item.text }}</td>
                        <td style="text-align: right">{{ item.value }}</td>
                      </tr> -->
                  <tr>
                    <td style="width:300px!important;">Hit Status</td>
                    <td v-if="$store.state.transaction.member.detail.icdx_hit == 1" style="color:green;"> TRUE </td>
                    <td v-if="$store.state.transaction.member.detail.icdx_hit == 0" style="color:red;"> FALSE</td>
                  </tr>
                  <tr>
                    <td style="width:300px!important;">Param</td>
                    <td> {{ $store.state.transaction.member.detail.icdx_param }}</td>
                  </tr>
                  <tr>
                    <td style="width:300px!important;">Response</td>
                    <td> {{ $store.state.transaction.member.detail.icdx_res }}</td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>
          </v-flex>

        </v-layout>

      </template>
    </CustomCard>
  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles: this.$store.state.auth.profile,
      provinsiList: [],
      details: [],
      currentPage: 1,
      decimals: "10.00",
      icons: "chevron-right",
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    backList() {
      this.$store.commit('transaction/setMemberPage', 'list');
      this.$store.state.transaction.member.searchDetail = "yes";

      //console.log("searchtrans", this.$store.state.transaction.member.searchtransaksi);
    }
  },
  mounted() {
    //alert("tes");
    this.details = this.$store.state.transaction.member.detail;
    //console.log("memberstarns", this.$store.state.transaction.member.detail);

    //alert(this.searchParams[0].member);
    //console.log("items-all" , this.searchParams);
  },
  watch: {
    pagination() {
      //alert(this.$store.state.member.member.is_back);
      //if(this.$store.state.member.member.is_back != 1){
      this.loading = true
      this.loadingtext = 'Loading... Please wait'
      // alert("tes");
      //console.log("user_con" , "nils "+this.profiles.id);
      //alert("tes");

      this.fetch(0)
      //}

      //if(this.$store.state.member.member.is_back == 1){
      //this.pagination.page = this.$store.state.member.member.paging_page;
      //}

      // this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
      val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
      val && val !== this.searchForm.member && this.getMember(val);
    }
  },

  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};

</script>

<style>

</style>