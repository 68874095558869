<template>
<v-layout row wrap>

  <v-flex md6 class="px-2">

    <CustomCard :cardShow="true">

      <template v-slot:toolbar>
        <div style="width:100%;">
          <div style="float:left;">Export Transaksi Beli Emas</div>
          <div style="float:right;">
            <v-btn
              color="success"
              small
              outlined
              @click="$store.commit('transaction/setMemberHisGoldPage', 'list')">
              back
            </v-btn>
          </div>
        </div>
      </template>

      <template v-slot:content>
          <v-layout>
                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date"
                                label="Date 1"
                                prepend-icon="mdi-calendar"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="date2"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date2"
                                label="Date 2"
                                prepend-icon="mdi-calendar"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date2"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu2 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date2)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                 <v-flex>
                     <v-autocomplete
                        v-model="formLock.feeList1"
                        :items="feeList"
                        item-text="text"
                        item-value="value"
                        label="Fee">
                    </v-autocomplete>
                </v-flex>

                 <v-flex>
                    <v-btn
                        color="green"
                        style="margin-top:12px;"
                        @click="downloadExcels(1)"
                        >
                            <v-icon small color="white">mdi-download</v-icon>
                    </v-btn>
                </v-flex>

          </v-layout>
      </template>

    </CustomCard>

  </v-flex>

  <v-flex md6 class="px-2">

    <CustomCard :cardShow="true">
      <template v-slot:toolbar>
        <div style="width:100%;">
          <div style="float:left;">Export Transaksi Jual Emas</div>
          <div style="float:right;">
            <v-btn
              color="success"
              small
              outlined
              @click="$store.commit('transaction/setMemberHisGoldPage', 'list')">
              back
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:content>
          <v-layout>
                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu3"
                            :close-on-content-click="false"
                            :return-value.sync="date3"
                            transition="scale-transition"
                            clearable
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date3"
                                label="Date 1"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date3"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu3 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date3)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu4"
                            :close-on-content-click="false"
                            :return-value.sync="date4"
                            transition="scale-transition"
                            clearable
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date4"
                                label="Date 2"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date4"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu4 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date4)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                 <v-flex>
                     <v-autocomplete
                        v-model="formLock.feeList2"
                        :items="feeList2"
                        item-text="text"
                        item-value="value"
                        label="Fee">
                    </v-autocomplete>
                </v-flex>

                 <v-flex>
                    <v-btn
                        color="green"
                        style="margin-top:12px;"
                        @click="downloadExcels(2)"
                        >
                            <v-icon small color="white">mdi-download</v-icon>
                    </v-btn>
                </v-flex>

          </v-layout>
      </template>
    </CustomCard>

  </v-flex>

  <v-flex md6 class="px-2" style="margin-top:20px;">

    <CustomCard :cardShow="true">
      <template v-slot:toolbar>
        <div style="width:100%;">
          <div style="float:left;">Export Transaksi Ambil Emas</div>
          <div style="float:right;">
            <v-btn
              color="success"
              small
              outlined
              @click="$store.commit('transaction/setMemberHisGoldPage', 'list')">
              back
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:content>
          <v-layout>
                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu5"
                            :close-on-content-click="false"
                            :return-value.sync="date5"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date5"
                                label="Date 1"
                                prepend-icon="mdi-calendar"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date5"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu5 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date5)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu6"
                            :close-on-content-click="false"
                            :return-value.sync="date6"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date6"
                                label="Date 2"
                                prepend-icon="mdi-calendar"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date6"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu6 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date6)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex style="display:none;">
                     <v-autocomplete
                        v-model="formLock.feeList3"
                        :items="feeList3"
                        item-text="text"
                        item-value="value"
                        label="Fee">
                    </v-autocomplete>
                </v-flex>

                 <v-flex>
                    <v-btn
                        color="green"
                        style="margin-top:12px;"
                        @click="downloadNewExcels(1)"
                        >
                            <v-icon small color="white">mdi-download</v-icon>
                    </v-btn>
                </v-flex>

          </v-layout>
      </template>
    </CustomCard>

  </v-flex>

  <v-flex md6 class="px-2" style="margin-top:20px;">

    <CustomCard :cardShow="true">
      <template v-slot:toolbar>
        <div style="width:100%;">
          <div style="float:left;">Export Transaksi Setor Emas</div>
          <div style="float:right;">
            <v-btn
              color="success"
              small
              outlined
              @click="$store.commit('transaction/setMemberHisGoldPage', 'list')">
              back
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:content>
          <v-layout>
                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu7"
                            :close-on-content-click="false"
                            :return-value.sync="date7"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date7"
                                label="Date 1"
                                prepend-icon="mdi-calendar"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date7"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu6 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date7)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex>
                    <v-menu
                            ref="menu"
                            v-model="menu7"
                            :close-on-content-click="false"
                            :return-value.sync="date8"
                            transition="scale-transition"
                            clearable
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="searchForm.date8"
                                label="Date 2"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="searchForm.date8"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu7 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date8)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                    </v-menu>
                </v-flex>

                 <v-flex style="display:none;">
                     <v-autocomplete
                        v-model="formLock.feeList4"
                        :items="feeList4"
                        item-text="text"
                        item-value="value"
                        label="Fee">
                    </v-autocomplete>
                </v-flex>

                 <v-flex>
                    <v-btn
                        color="green"
                        style="margin-top:12px;"
                        @click="downloadExcels(3)"
                        >
                            <v-icon small color="white">mdi-download</v-icon>
                    </v-btn>
                </v-flex>

          </v-layout>
      </template>
    </CustomCard>

  </v-flex>

</v-layout>
  
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      visible: false,
      index: 0,
      modal: false,
      panel: null,
      date8: null,
      menu2: null,
      menu4: null,
      date4: null,
      menu5: null,
      date5: null,
      menu6: null,
      date6: null,
      menu3: null,
      date3: null,
      date7: null,
      menu7: null,
      menu: null,
      profiles:this.$store.state.auth.profile,
      feeList: [
            { text: "Yes", value: 1 },
            { text: "No", value: 0 }
            // { text: "Gagal", value: "2" },
      ],
      feeList2: [
            { text: "Yes", value: 1 },
            { text: "No", value: 0 }
            // { text: "Gagal", value: "2" },
      ],
      feeList3: [
            { text: "Yes", value: 1 },
            { text: "No", value: 0 }
            // { text: "Gagal", value: "2" },
      ],
      feeList4: [
            { text: "Yes", value: 1 },
            { text: "No", value: 0 }
            // { text: "Gagal", value: "2" },
      ],
      awb_no:"",
      counter:"",
      jadwal_pengambilan:"",
      verified:"",
      details:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      loading: false,
      loadingText: "Loading Data...",
      searchForm:{
          date:""
      },
      formLock:{
          date:""
      }
    };
  },
  methods: {
    showImg (index) {
        this.index = index
        this.visible = true
      },
    handleHide () {
        this.visible = false
    },
    downloadExcels(typeId){
        this.$store.commit("main/setLoading", true);
        this.loading = true;
        this.loadingtext = 'Downloading Report ... Please wait';

        
        let startsdate = this.searchForm.date;
        let endssdate = this.searchForm.date2;
        let feelist = this.formLock.feeList1;

        var today= new Date().toLocaleDateString();

        this.$store.state.transaction.exports.filename = "Trx-Export-Beli-emas-"+today+".xls";

        if(typeId == 2){
            startsdate = this.searchForm.date3;
            endssdate = this.searchForm.date4;
            feelist = this.formLock.feeList2;
            this.$store.state.transaction.exports.filename = "Trx-Export-Jual-emas-"+today+".xls";
        }
        else if(typeId == 3){
            startsdate = this.searchForm.date7;
            endssdate = this.searchForm.date8;
            feelist = this.formLock.feeList4;
            this.$store.state.transaction.exports.filename = "Trx-Export-Setor-emas-"+today+".xls";
        }

        this.$store
            .dispatch("transaction/exportTrxExcel", {
                type:typeId,
                fee:feelist,
                startdate:startsdate,
                enddate:endssdate
            })
            .then(() => {
                this.$store.commit("main/setLoading", false);
                this.loading = false;
            })
            .catch((res) => {
                this.$store.commit("main/setSnackbarFail", res.response.data.message);
            });
    },
    downloadNewExcels(typeId){
        this.$store.commit("main/setLoading", true);
        this.loading = true;
        this.loadingtext = 'Downloading Report ... Please wait';

        var today= new Date().toLocaleDateString();

        this.$store.state.transaction.exports.filename = "Trx-Export-Ambil-emas-"+today+".xls";

        this.$store
            .dispatch("transaction/exportNewTrxExcel", {
                type:typeId,
                fee:this.formLock.feeList3,
                startdate:this.searchForm.date5,
                enddate:this.searchForm.date6
        })
        .then(() => {
            this.$store.commit("main/setLoading", false);
            this.loading = false;
            //this.loadingtext = 'Loading... Please wait';
        // alert("downloaded");
        //console.log("detauls_trans" , this.$store.state.transaction.hisgold.detail);
            // this.details = this.$store.state.transaction.hisgold.list[0];
            // //alert(this.$store.state.transaction.hisgold.list[0].awb_no);
            // this.awb_no =  this.$store.state.transaction.hisgold.detail[0].awb_no;
            // this.jadwal_pengambilan = this.$store.state.transaction.hisgold.detail[0].jadwal_pengambilan;
            // this.counter = this.$store.state.transaction.hisgold.detail[0].counter;
            // this.verified = this.$store.state.transaction.hisgold.detail[0].verified;
        })
        .catch((res) => {
            this.$store.commit("main/setSnackbarFail", res.response.data.message);
        });
    },
    fetch() {
      //alert("new tes");

      this.$store
        .dispatch("transaction/fetchMemberListHisGold", {
            index:this.details.id
        })
        .then(() => {
          //console.log("detauls_trans" , this.$store.state.transaction.hisgold.detail);
            this.details = this.$store.state.transaction.hisgold.list[0];
            //alert(this.$store.state.transaction.hisgold.list[0].awb_no);
            this.awb_no =  this.$store.state.transaction.hisgold.detail[0].awb_no;
            this.jadwal_pengambilan = this.$store.state.transaction.hisgold.detail[0].jadwal_pengambilan;
            this.counter = this.$store.state.transaction.hisgold.detail[0].counter;
            this.verified = this.$store.state.transaction.hisgold.detail[0].verified;
        })
        .catch((res) => {
           this.$store.commit("main/setSnackbarFail", res.response.data.message);
        });
    },
  },
  mounted() {
      
  },
  watch: {
    pagination() {

        this.loading = true
        this.loadingtext = 'Loading... Please wait'
        this.fetch(0);

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};

</script>

<style>
</style>