<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-if="$store.state.affiliate.transaksi.page == 'list'"></List>
      <Detail v-if="$store.state.affiliate.transaksi.page == 'detail'"></Detail>
    </v-container>
  </div>
</template>

<script>
import List from "./transaksi/list.vue";
import Detail from "./transaksi/detail.vue";
export default { 
data() {
    return {
      pages:"list"
    }
},
    mounted() {
      this.$store.commit("affiliate/setAffiliatesTransaksiPages", "list");
    },
    components: { List, Detail } 
};
</script>

<style>

</style>