var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"color":"success","small":"","outlined":""},on:{"click":_vm.filterToggle}},[_vm._v(" filter "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('p'),_c('CustomCard',{scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('b',[_vm._v(" DATA BALANCE ")]),_vm._v("   ")]},proxy:true},{key:"expansion",fn:function(){return [_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{attrs:{"expand":"","focusable":""}},[_c('v-expansion-panel-content',[_c('v-layout',[_c('v-flex',[_c('v-text-field',{attrs:{"label":"Search By Email","small":"","clearable":""},model:{value:(_vm.searchForm.email),callback:function ($$v) {_vm.$set(_vm.searchForm, "email", $$v)},expression:"searchForm.email"}})],1)],1),_c('v-layout',{attrs:{"align-end":"","justify-end":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"m0",attrs:{"small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.fetch(1)}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Search ")],1)],1)],1)],1)],1)]},proxy:true},{key:"datatable",fn:function(){return [_c('v-flex',{staticStyle:{"overflow-x":"scroll"},attrs:{"md12":""}},[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-pagination":"","disable-sort":"","headers":_vm.headers,"items":_vm.$store.state.settings.attributes.listCheckDataBalance,"footer-props":{
              'items-per-page-options': [10, 25, 50, 100],
              'items-per-page-text': 'Baris per Halaman:',
              },"items-per-page":10,"loading":_vm.loading,"loading-text":_vm.loadingtext},scopedSlots:_vm._u([{key:"item.aksi",fn:function(ref){
              var item = ref.item;
return [_c('v-container',[_c('v-row',[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-col',{staticClass:"pa-2 font-weight-bold",attrs:{"outlined":"","tile":""}},[_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding":"5px","margin-top":"-3px"},attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.editTemplate(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"success","dark":""}},[_vm._v("Edit Custom Variable ")]),_c('v-layout',{staticStyle:{"display":"none"}},[_c('v-text-field',{attrs:{"label":"","hidden":""},model:{value:(_vm.formLock.edit_index_template),callback:function ($$v) {_vm.$set(_vm.formLock, "edit_index_template", $$v)},expression:"formLock.edit_index_template"}})],1),_c('v-container',[_c('v-layout',[_c('v-flex',[_c('v-text-field',{attrs:{"label":"type"},model:{value:(_vm.formLock.edit_type),callback:function ($$v) {_vm.$set(_vm.formLock, "edit_type", $$v)},expression:"formLock.edit_type"}})],1)],1),_c('v-layout',[_c('v-flex',[_c('v-text-field',{attrs:{"label":"code"},model:{value:(_vm.formLock.edit_code),callback:function ($$v) {_vm.$set(_vm.formLock, "edit_code", $$v)},expression:"formLock.edit_code"}})],1)],1),_c('v-layout',[_c('v-flex',[_c('v-text-field',{attrs:{"label":"value"},model:{value:(_vm.formLock.edit_value),callback:function ($$v) {_vm.$set(_vm.formLock, "edit_value", $$v)},expression:"formLock.edit_value"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.editTemplatePost(dialog)}}},[_vm._v("Submit")])],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding":"5px"},attrs:{"color":"red","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"red","dark":""}},[_vm._v("Delete Custom Variable ? ")]),_c('v-container',[_c('v-layout',[_c('v-flex',[_vm._v(" Yakin akan hapus Custom Variable "),_c('b',[_vm._v(" "+_vm._s(item.type)+" ")]),_vm._v(" ? ")])],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")]),_c('v-btn',{staticStyle:{"color":"#ffffff"},attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteTemplate(dialog , item.id)}}},[_vm._v("Submit")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Delete Custom Variable Form")])])],1)],1)]}}],null,true)})],1)]},proxy:true}])}),_c('CustomCard',{staticStyle:{"margin-top":"20px"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('b',[_vm._v(" DATA TRANSACTION ")])]},proxy:true},{key:"datatable",fn:function(){return [_c('v-flex',{staticStyle:{"overflow-x":"scroll"},attrs:{"md12":""}},[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-pagination":"","disable-sort":"","headers":_vm.headersTrx,"items":_vm.$store.state.settings.attributes.listCheckDataTrx,"footer-props":{
              'items-per-page-options': [10, 25, 50, 100],
              'items-per-page-text': 'Baris per Halaman:',
              },"items-per-page":10,"loading":_vm.loading,"loading-text":_vm.loadingtext}})],1)]},proxy:true}])}),_c('CustomCard',{staticStyle:{"margin-top":"20px"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('b',[_vm._v(" DATA TRANSACTION IPAYMU ")])]},proxy:true},{key:"datatable",fn:function(){return [_c('v-flex',{staticStyle:{"overflow-x":"scroll"},attrs:{"md12":""}},[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-pagination":"","disable-sort":"","headers":_vm.headersIpaymu,"items":_vm.$store.state.settings.attributes.listCheckDataTrxIpaymu,"options":_vm.pagination,"server-items-length":_vm.$store.state.settings.attributes.totalCheckTrxBalance,"footer-props":{
              'items-per-page-options': [10, 25, 50, 100],
              'items-per-page-text': 'Baris per Halaman:',
              },"items-per-page":10,"loading":_vm.loading,"loading-text":_vm.loadingtext},on:{"update:options":function($event){_vm.pagination=$event}}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }