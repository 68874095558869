import { apiFetchList  , apiFetchScore , apiGiveRewards } from "../../api/games"

const namespaced = true

const defaultState = () => {
    return {
        games: {
            list: [],
            detail: {},
            listDetail:[],
            total:0,
            page: "list"
        },
        score: {
            list: [],
            detail: {},
            listDetail:[],
            total:0,
            message:"",
            page: "list"
        },
        settlement: {
            list: [],
            detail: {}
        }
    }
}

const state = defaultState()

const mutations = {
    setGamesList: (state, payload) => {
        state.games.list = payload
    },
    setGamesTotal: (state, payload) => {
        state.games.total = payload
    },
    setGamesPages: (state, payload) => {
        state.games.page = payload
    },
    setScoreList: (state, payload) => {
        state.score.list = payload
    },
    setScoreTotal: (state, payload) => {
        state.score.total = payload
    },
    setScorePages: (state, payload) => {
        state.score.page = payload
    },
    setScoreMessage: (state, payload) => {
        state.score.message = payload
    }
}

const actions = {
    fetchGamesList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchList(payload)
                .then((res) => {
                    //if (res.status == 200) {
                        commit('setGamesList', res.data);
                        commit('setGamesTotal', res.total);
                        // state.member.total = res.total
                        //console.log("BankList",res.list)
                        resolve(res)
                    //}
                    //reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchGamesScore({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchScore(payload)
                .then((res) => {
                    //if (res.status == 200) {
                        commit('setScoreList', res.data);
                        commit('setScoreTotal', res.total);
                        // state.member.total = res.total
                        resolve(res)
                    //}
                    //reject(res)
                }).catch(res => reject(res))
        })
    },
    giveRewardScore({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiGiveRewards(payload)
                .then((res) => {
                    //if (res.status == 200) {
                        commit('setScoreMessage', "Success");
                        // state.member.total = res.total
                        resolve(res)
                    //}
                    //reject(res)
                }).catch(res => reject(res))
        })
    }
}

export default {
    namespaced, state, mutations, actions
}