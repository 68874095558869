<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-if="$store.state.webadmin.attributes.pageList == 'list'"></List>
      <Detail v-if="$store.state.webadmin.attributes.pageList == 'detail'"></Detail>
    </v-container>
  </div>
</template>

<script>
import List from "./pages/list.vue";
import Detail from "./pages/detail.vue";

export default { 
  data() {
    return {
      pages:"list"
    }
    },
    
    mounted() {
      //alert(this.$store.state.webadmin.attributes.pageList);
      this.$store.commit("webadmin/setPagesState", "list");
    },
    components: { List, Detail } 
};

</script>

<style>

</style>