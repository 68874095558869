<template>
  <div>
    <CustomCard :cardShow="true">
      <template v-slot:toolbar>
        <v-btn
          color="success"
          small
          outlined
          @click="$store.commit('transaction/setMemberPage', 'list')"
        >
          back
        </v-btn>
      </template>
      <template v-slot:content>
        <v-layout row wrap>
          <v-flex md4 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Data Profile </b> </v-toolbar>
            <v-simple-table  v-if="details.type_code == 1 || details.type_code == 10">
                <template v-slot:default>

                    <tbody v-if="details.receiver_id != null">
                        <tr><td>Nama</td><td style="text-align: right">{{ details.receiver_name }}</td></tr>
                        <tr><td>Email</td><td style="text-align: right">{{ details.receiver_email }}</td></tr>
                        <tr><td>Phone</td><td style="text-align: right">{{ details.receiver_phone }}</td></tr>
                        <tr><td>Status</td><td style="text-align: right">{{ details.receiver_verified }}</td></tr>
                    </tbody>

                </template>
            </v-simple-table>

            <v-simple-table  v-if="details.type_code == 2">
                <template v-slot:default>

                    <tbody v-if="details.sender_id != null">
                        <tr><td>Nama</td><td style="text-align: right">{{ details.sender_name }}</td></tr>
                        <tr><td>Email</td><td style="text-align: right">{{ details.sender_email }}</td></tr>
                        <tr><td>Phone</td><td style="text-align: right">{{ details.sender_phone }}</td></tr>
                        <tr><td>Status</td><td style="text-align: right">{{ details.sender_verified }}</td></tr>
                    </tbody>

                </template>
            </v-simple-table>
              
                <!-- if($detail->type==1 or $detail->type==10)
                {
                    if(isset($detail->receiveruser))
                    {
                        $form .= '<tr><td>Nama</td><td style="text-align: right">'.$detail->receiveruser['name'].'</td></tr>
                        <tr><td>Email</td><td style="text-align: right">'.$detail->receiveruser['email'].'</td></tr>
                        <tr><td>Phone</td><td style="text-align: right">'.$detail->receiveruser['phone'].'</td></tr>
                        <tr><td>Status</td><td style="text-align: right">';

                        if($detail->receiveruser['is_verified'] == true){ $form .= '<span class="text-success">Verified</span>'; }
                        else { $form .= '<span class="text-danger">Unverified</span>'; }

                        $form .= '</td></tr>';
                    }
                }
                else if($detail->type==2)
                {
                    if(isset($detail->senderuser))
                    {
                        $form .= '<tr><td>Nama</td><td style="text-align: right">'.$detail->senderuser['name'].'</td></tr>
                        <tr><td>Email</td><td style="text-align: right">'.$detail->senderuser['email'].'</td></tr>
                        <tr><td>Phone</td><td style="text-align: right">'.$detail->senderuser['phone'].'</td></tr>
                        <tr><td>Status</td><td style="text-align: right">';
                    
                        if($detail->senderuser['is_verified'] == true) { $form .= '<span class="text-success">Verified</span>'; }
                        else { $form .= '<span class="text-danger">Unverified</span>'; }

                        $form .= '</td></tr>';
                    }
                } -->
            
          </v-flex>
          <v-flex md4 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Data Transaksi </b> </v-toolbar>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Tanggal Trx</td><td> {{ details.created_at }}</td>
                  </tr>
                  <tr>
                    <td>ID Transaksi</td><td> {{ details.id }}</td>
                  </tr>
                  <tr>
                    <td>Ref ID</td><td> {{ details.ref_id }}</td>
                  </tr>
                  <tr>
                    <td>Type Transaksi</td><td> {{ details.type }}</td>
                  </tr>
                  <tr>
                    <td>Total Gramasi </td><td> {{ details.gramasi }} GR</td>
                  </tr>
                  <tr>
                    <td>Total Amount</td><td> {{ details.amount }}</td>
                  </tr>
                  <tr>
                    <td>Status Pembayaran</td><td> {{ details.status }}</td>
                  </tr>
                  <tr>
                    <td>Note</td><td> {{ details.note }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
          <v-flex md4 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> </v-toolbar>
            <v-simple-table>
              <template v-slot:default>
                <tbody v-if="details.ipaymu_id != 0">
                  <!-- <tr>
                    <td>{{ item.text }}</td>
                    <td style="text-align: right">{{ item.value }}</td>
                  </tr> -->
                  <tr>
                    <td>Ipaymu ID</td><td> {{ details.ipaymu_id }}</td>
                  </tr>
                  <tr>
                    <td>Ref ID</td><td> {{ details.ref_id_ipaymu }}</td>
                  </tr>
                  <tr>
                    <td>Payment Channel</td><td> {{ details.pay_channel }}</td>
                  </tr>
                  <tr>
                    <td>Payment Method </td><td> {{ details.pay_method }} </td>
                  </tr>
                  <tr>
                    <td>Payment No</td><td> {{ details.payment_no }}</td>
                  </tr>
                  <tr>
                    <td>Received Amount</td><td> {{ details.received_amount }}</td>
                  </tr>
                  <tr>
                    <td>Created</td><td> {{ details.updated_ipaymu }}</td>
                  </tr>
                  <tr>
                    <td>Expired</td><td> {{ details.expired_at }}</td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
      </template>
    </CustomCard>
  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      details:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    
  },
  mounted() {
   // alert("tes");
   this.details = this.$store.state.transaction.member.detail;
   // console.log("items" , this.$store.state.transaction.member.detail);
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        //if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        //}

        //if(this.$store.state.member.member.is_back == 1){
        //this.pagination.page = this.$store.state.member.member.paging_page;
        //}

       // this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};

</script>

<style>
</style>