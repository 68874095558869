<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-if="$store.state.member.member.pageReq == 'list'"></List>
      <Detail v-if="$store.state.member.member.pageReq == 'detail'"></Detail>
    </v-container>
  </div>
</template>

<script>
import List from "./verify/listData.vue";
import Detail from "./verify/detail.vue";
export default {
  components: {
    List,
    Detail,
  },
};
</script>