<template>
    <v-row justify="center">
      <v-dialog
        v-model="$store.state.member.member.dialogApproveCorporate"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="success"
          >
            
            <v-toolbar-title><v-icon> mdi-plus </v-icon> Add Detail Corporate ( {{ $store.state.member.member.selectedIndex.email }} )</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn
              icon
              dark
              @click="$store.state.member.member.dialogApproveCorporate = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list
            three-line
            subheader
          >
          <div style="padding:30px; height:85vh; overflow:auto;">

                    <v-row>
                        <v-col class="12" style="margin-bottom:20px; padding-left:25px;   border-bottom:1px solid #dddddd;">
                            <h3 style="font-size:24px;"> Informasi Bisnis/Usaha </h3>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>

                        <v-col sm="5" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Nama Usaha
                                    </v-col>
                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-text-field style="display:none;" v-model="searchForm.editIndexCorporate"
                                            label="id" single-line small clearable>
                                        </v-text-field>

                                        <v-text-field v-model="$store.state.corporate.editform.editNamaUsaha" label=""
                                            single-line small clearable>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-col>

                        <v-col sm="5" md="6">
                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2" outlined tile>
                                        Ijin Usaha (Upload)
                                    </v-col>
                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-file-input accept="image/*" label=""
                                            v-model="$store.state.corporate.editform.editIjinUsaha"></v-file-input>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-col>

                    </v-row>

                    <v-row no-gutters>

                        <v-col sm="6" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Website Utama
                                    </v-col>
                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-text-field v-model="$store.state.corporate.editform.websiteUtama" label=""
                                            single-line small clearable>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-col>


                        <v-col sm="6" md="6">
                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2" outlined tile>
                                        Foto Rekening Koran (Upload)
                                    </v-col>

                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-file-input accept="image/*" label=""
                                            v-model="$store.state.corporate.editform.rekeningkoran"></v-file-input>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="12" style="margin-bottom:20px; padding-left:25px;   border-bottom:1px solid #dddddd;">
                            <h3 style="font-size:24px;"> Informasi Lokasi Corporate </h3>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col sm="6" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Provinsi
                                    </v-col>

                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <!-- <v-text-field v-model="$store.state.corporate.editform.addrCorporate" label=""
                                            single-line small clearable>
                                        </v-text-field> -->
                                        <v-autocomplete v-model="$store.state.corporate.editform.provinsi"
                                            @change="(event) => getWilayah('kabupaten')" single-line
                                            :items="provinsiList" item-text="provinsi" item-value="provinsi" label="">
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-col>

                        <v-col sm="6" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Kabupaten
                                    </v-col>

                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-autocomplete v-model="$store.state.corporate.editform.editKabupaten" :disabled="dis_kab"
                                            :items="kabupatenList" single-line item-text="kabupaten"
                                            item-value="kabupaten" label="">
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-col>

                        <v-col sm="6" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Alamat Corporate
                                    </v-col>

                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-text-field v-model="$store.state.corporate.editform.editAlamat" label=""
                                            single-line small clearable>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-col>

                        <v-col sm="6" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Kode Post
                                    </v-col>

                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-text-field v-model="$store.state.corporate.editform.editKodePost" label=""
                                            single-line small clearable>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-col>

                    </v-row>

                <v-row>
            </v-row>

        </div>


            <div style="width:100%; margin-top:20px;">
                <v-row no-gutters>

                    <div style="width:100%;">

                        <div style="float:right; margin-right:20px;">
                            <v-progress-circular
                            v-if="isSubmit === true"
                                indeterminate
                                color="green"
                            ></v-progress-circular>
                            &nbsp; <span v-if="isSubmit === true"> Please Wait ...</span>
                            
                            <v-btn v-if="isSubmit === false" color="success" style="float:right;" @click="postCorporate">
                                <!-- <v-icon>mdi-chevron-left</v-icon> -->
                                Submit Data<v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>

                    </div>

                </v-row>
            </div>

          </v-list>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
export default {
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      provinsiList: [],
      kabupatenList: [],
      isSubmit: false,
      searchForm: {
            member: ''
        },
        dis_kab: true,
        dis_kec: true,
        dis_kel: true,
        loading: false,
        loadingText: "Loading Data...",
    }
  },
  methods: {
        fetchProv(params) {
            this.$store
                .dispatch("wilayah/fetchWilayah", {
                    find: params,
                })
                .then(() => {
                    this.provinsiList = this.$store.state.wilayah.wilayah.list;
                    //console.log("provlist" ,this.$store.state.wilayah.wilayah.list);
                    //alert("prov list");
                })
                .catch((res) => {
                    console.log("prov", res.response.data.message);
                });
        },
        backsteps() {
            this.$store.state.corporate.attributes.e1 = this.$store.state.corporate.attributes.e1 - 1;
        },
        getWilayah(value) {
            if (value == "kabupaten") {
                this.dis_kab = false;
            }

            this.$store
                .dispatch("wilayah/fetchAreaWilayah", {
                    find: value,
                    provinsi: this.$store.state.corporate.editform.provinsi,
                    kabupaten: this.$store.state.corporate.editform.editKabupaten
                })
                .then(() => {
                    if (value == "kabupaten") {
                        this.dis_kab = false;
                        this.kabupatenList = this.$store.state.wilayah.wilayah.listKabupaten;
                    }

                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        getBankValue() {
        },
        postCorporate() {
            this.$store.commit("main/setLoading", true);
            this.isSubmit = true;
            this.$store
                .dispatch("corporate/registerExistingToCorporate", {
                    userId: this.$store.state.member.member.selectedIndex.id,
                    name: this.$store.state.member.member.selectedIndex.name,
                    namausaha: this.$store.state.corporate.editform.editNamaUsaha,
                    ijinusaha: this.$store.state.corporate.editform.editIjinUsaha,
                    website: this.$store.state.corporate.editform.websiteUtama,
                    alamat: this.$store.state.corporate.editform.editAlamat,
                    rekeningkoran: this.$store.state.corporate.editform.rekeningkoran,
                    kodePost: this.$store.state.corporate.editform.editKodePost,
                    provinsi: this.$store.state.corporate.editform.provinsi,
                    kabupaten: this.$store.state.corporate.editform.editKabupaten,
                })
                .then(() => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarSuccess", "Update data Corporate Success");

                    this.isSubmit = false;

                    this.$router.push({ name: "Corporate Member List" });
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res);
                });
        }
    },
    mounted() {
      this.fetchProv("");

      this.dis_kab = false;
      this.kabupatenList = [];
    },
    computed: {
        itemsWithBank() {
            return this.$store.state.bank.bank.listBank.map(
                (items, index) => ({
                    ...items,
                    index: index + 1,
                    bankValue: items.bank_name + "#" + items.bank_id
                }))
        },
    }
}
</script>