import { apiFetchListMemberLogs, apiFetchProfitLoss, apiAddCorporateMember, apiMemberAddVerification, apiFetchListCorpTotals, apifetchListCorpMember, apiApproveCorporate, apiNewIdCash, apiApproveIdCash, apiFetchReqListMemberIdCash, ApiExportMemberExcel, ApiThMemberExcel, apieditUserLock, apieditMemberThirdParty, apiMemberThirdParty, apiFetchListVerifyEmoney, apiEditMemberVerifEmoney, exportHighBuy, apiEditProfiles, apiFetchListMember, apiPostLockGold, apiFetchMemberAll, apiFetchListTotals, apiEditUnverify, apiSuspendUser, apiUnSuspendUser, apiFetchListRank, apiFetchDetailListRank, apiFetchListMemberIdCash, apiFetchListReqVerify, apiFetchListVerifyDetail, apiEditVerification, apiMemberEditVerification, apiRequestVerification } from "../../api/member"

import fileDownload from 'js-file-download';

const namespaced = true

const defaultState = () => {
    return {
        main: {
            list: [],
            detail: {},
            search: {},
            rankListProfitNewMain: []
        },
        member: {
            list: [],
            dialogApproveCorporate: false,
            selectedIndex: [],
            detail: {},
            listVerify: [],
            listDetailVerify: [],
            totalVerify: [],
            listDetail: [],
            listAll: [],
            listLogs: [],
            listCorp: [],
            dialogLock:false,
            pageMember:1,
            listMemberVeMoney: [],
            dialogEmoney:false,
            listMemberThirdParty: [],
            listMemberTrans: [],
            listMemberBalTrans:[],
            listReqIdCash: [],
            listCorpDetail:[],
            total: 0,
            totalLogs: 0,
            totaluserreg: 0,
            totaluserver:0,
            totaluseractive: 0,
            totalusercorpreg: 0,
            totalcorp:0,
            totalusercorpactive: 0,
            is_back: 0,
            paging_page: 1,
            titlestab: "",
            indexRank: 1,
            pageEmoney: "list",
            page: "list",
            pageReq: "list",
            pageIdCash: "list",
            pageLogReq: "list",
            pageThirdParty: "list",
            pageReqIdCash: "list",
            pageCorpList: "list",
            DetailEmoney: [],
            success: "",
            formStates: false,
            rankListBal: [],
            rankListDetailBal: [],
            rankListProfit: [],
            rankListAct: [],
            rankListBuy: [],
            rankListSell: [],
            rankProfitList: [],
            rankListProfitNewAnj: [],
        },
        searchParams:{
            page: 1,
            itemsPerPage: 10,
            status:"",
            tipe:"",
            number:"",
            tanggal:"",
            name:""
        },
        list:{
            kabupatenList:[],
            kecamatanList:[],
            kelurahanlist:[],
            currKabupatenList:[],
            currKecamatanList:[],
            currKelurahanList:[],
            dis_kab:true,
            dis_kec:true,
            dis_kel:true
        },
        settlement: {
            list: [],
            listAll: [],
            detail: {}
        },
        exports: {
            filename: "",
        },
    }
}

const state = defaultState()

const mutations = {
    setTotalCorp: (state, payload) => {
        state.member.totalcorp = payload
    },
    setTotalUserCorpReg: (state, payload) => {
        state.member.totalusercorpreg = payload
    },
    setTotalUserCorpAct: (state, payload) => {
        state.member.totalusercorpactive = payload
    },
    setMemberEmoney: (state, payload) => {
        state.member.listMemberVeMoney = payload
    },
    setListReqIdCash: (state, payload) => {
        state.member.listReqIdCash = payload
    },
    setDetailEmoney: (state, payload) => {
        state.member.DetailEmoney = payload
    },
    setMemberPage: (state, payload) => {
        state.member.page = payload
    },
    setMemberCorpPage: (state, payload) => {
        state.member.pageCorpList = payload
    },
    setPageThirdParty: (state, payload) => {
        state.member.page = payload
    },
    setlistMemberThirdParty: (state, payload) => {
        state.member.listMemberThirdParty = payload
    },
    setFormSwitch: (state, payload) => {
        state.member.formStates = payload
    },
    setMemberEmoneys: (state, payload) => {
        state.member.formStates = payload
    },
    setMemberPageLogMember: (state, payload) => {
        state.member.pageLogReq = payload
    },
    setMemberPageReqVer: (state, payload) => {
        state.member.pageReq = payload
    },
    setMemberPageEmoney: (state, payload) => {
        state.member.pageEmoney = payload
    },
    setMemberPageIdCash: (state, payload) => {
        state.member.pageIdCash = payload
    },
    settotalLogsMember: (state, payload) => {
        state.member.totalLogs = payload
    },
    setMemberBackState: (state, payload) => {
        state.member.is_back = payload
    },
    setCorpList: (state, payload) => {
        state.member.listCorp = payload
    },
    setMemberList: (state, payload) => {
        state.member.list = payload
    },
    setMemberListAll: (state, payload) => {
        state.member.listAll = payload
    },
    setMemberListVerify: (state, payload) => {
        state.member.listVerify = payload
    },
    setMemberListDetailVerify: (state, payload) => {
        state.member.listDetailVerify = payload
    },
    setMemberTransListAll: (state, payload) => {
        state.member.listMemberTrans = payload
    },
    setMemberBalTransListAll: (state, payload) => {
        state.member.listMemberBalTrans = payload
    },
    setMemberListLogs: (state, payload) => {
        state.member.listLogs = payload
    },
    setSuccess: (state, payload) => {
        state.member.success = payload
    },
    setMemberDetail: (state, payload) => {
        state.member.detail = payload
    },
    setCorpMemberDetail: (state,payload) => {
        state.member.listCorpDetail = payload
    },
    setMemberListDetail: (state, payload) => {
        state.member.listDetail = payload
    },
    setTotalUserReg: (state, payload) => {
        state.member.totaluserreg = payload
    },
    setTotalUserver: (state, payload) => {
        state.member.totaluserver = payload
    },
    setTotalUserAct: (state, payload) => {
        state.member.totaluseractive = payload
    },
    setMemberlistRank: (state, payloadBal) => {
        state.member.rankListBal = payloadBal
    },
    setMemberDetailRank: (state, payloadBal) => {
        state.member.rankListDetailBal = payloadBal
    },
    setHighProfit:(state, payload) => {
        state.member.rankProfitList = payload;
    }
}

const actions = {
    postLockGold({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPostLockGold(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberListDetail', res.member)
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editProfiles({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiEditProfiles(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberListDetail', res.member)
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    addCardNewIdCash({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiNewIdCash(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setSuccess', "Success")

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editMemberVerification({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiMemberEditVerification(payload)
                .then((res) => {
                    if (res.status == 200) {
                        //alert("success");
                        commit('setSuccess', "200")
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    addMemberVerification({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiMemberAddVerification(payload)
                .then((res) => {
                    if (res.status == 200) {
                        //alert("success");
                        commit('setSuccess', "200")
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    approveIdCash({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiApproveIdCash(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setSuccess', "Success")
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editVerification({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiEditVerification(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setSuccess', res.member)
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editRequestVerification({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiRequestVerification(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setSuccess', "success")
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    userUnverify({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiEditUnverify(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMemberListDetail', res.member)
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    userSuspend({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiSuspendUser(payload)
                .then((res) => {
                    if (res.status == 200) {
                        //alert("Suspend");
                        commit('setMemberListDetail', res.member)
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    userUnSuspend({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiUnSuspendUser(payload)
                .then((res) => {
                    if (res.status == 200) {
                        //alert("Unsuspend");
                        commit('setMemberListDetail', res.member)
                        // state.member.total = res.total
                        // console.log("memberlistnew",state.member.list)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMember({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListMember(payload)
                .then((res) => {

                    if (res.status == 200) {
                        //console.log("listNewAll" , "tes"+state.member.page);
                        //alert("fetch");
                        if (state.member.page == 'list') {
                            commit('setMemberList', res.member)
                            // commit('setTotalUserReg', res.totaluserreg)
                            // commit('setTotalUserAct', res.totaluseractive)
                            //commit('setMemberListAll' , res.dataAll)
                            // console.log("memberlista",'tes'+res.dataAll)
                            //console.log("listNewAll" , "tes"+state.member.listAll);
                        }
                        else {
                            commit('setMemberListDetail', res.member);
                            // commit('setTotalUserReg', res.totaluserreg)
                            // commit('setTotalUserAct', res.totaluseractive)
                            //console.log("memberlistnew",state.member.listDetail)
                        }

                        state.member.total = res.total

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListCorpMember({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apifetchListCorpMember(payload)
                .then((res) => {

                    if (res.status == 200) {
                        //console.log("listNewAll" , "tes"+state.member.page);
                        //alert("fetch");
                        if (state.member.page == 'list') {
                            commit('setCorpList', res.member)
                            commit('setTotalCorp' , res.total)

                            console.log("corplist" , res.member);
                            
                            // commit('setTotalUserReg', res.totaluserreg)
                            // commit('setTotalUserAct', res.totaluseractive)
                            //commit('setMemberListAll' , res.dataAll)
                            // console.log("memberlista",'tes'+res.dataAll)
                            //console.log("listNewAll" , "tes"+state.member.listAll);
                        }
                        else {
                            commit('setCorpMemberDetail', res.member);
                            // commit('setTotalUserReg', res.totaluserreg)
                            // commit('setTotalUserAct', res.totaluseractive)
                            //console.log("memberlistnew",state.member.listDetail)
                        }

                        state.member.total = res.total

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchtotals({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListTotals(payload)
                .then((res) => {

                    if (res.status == 200) {
                        commit('setTotalUserReg', res.totaluserreg)
                        commit('setTotalUserAct', res.totaluseractive)
                        commit('setTotalUserver' , res.totaluserver);

                        resolve(res)
                    }

                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchProfitLoss({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchProfitLoss(payload)
                .then((res) => {

                    if (res.status == 200) {
                        //commit("setHighProfit" , res.profitloss);
                        commit('setSuccess', "200");
                        state.main.rankListProfitNewMain = res.profitloss;

                        resolve(res)
                    }

                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchcorptotals({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListCorpTotals(payload)
                .then((res) => {

                    if (res.status == 200) {
                        commit('setTotalUserCorpReg', res.totaluserreg)
                        commit('setTotalUserCorpAct', res.totaluseractive)

                        resolve(res)
                    }

                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchRank({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListRank(payload)
                .then((res) => {

                    if (res.status == 200) {
                        commit('setMemberlistRank', res.highbal);
                        //state.member.rankListBal  = res.highbal;
                        //state.member.rankListProfit = res.highprofit;
                        state.member.rankListAct = res.highactive;
                        state.member.rankListBuy = res.highbuytrx;
                        state.member.rankListSell = res.highselltrx;
                        //state.member.rankProfitList = res.highProfit;

                        resolve(res)
                    }

                    reject(res)
                }).catch(res => reject(res))
        })
    },
    approveCorporate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiApproveCorporate(payload)
                .then((res) => {

                    if (res.status == 200) {
                        commit('setStatus', "Success");

                        resolve(res)
                    }

                    reject(res)
                }).catch(res => reject(res))
        })
    },
    addCorporateMember({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiAddCorporateMember(payload)
                .then((res) => {

                    if (res.status == 200) {
                        commit('setStatus', "Success");

                        resolve(res)
                    }

                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchDetailRank({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchDetailListRank(payload)
                .then((res) => {
                    if (res.status == 200) {

                        // commit('setTotalUserReg', res.totaluserreg)
                        // commit('setTotalUserAct', res.totaluseractive)
                        if (res.find == '1') {
                            commit('setMemberDetailRank', res.detailbal);
                            //console.log("details_find" , state.member.rankListDetailBal);
                        }
                        else if (res.find == '2') {
                            commit('setMemberDetailRank', res.detailbal);
                        }
                        else if (res.find == '3') {
                            commit('setMemberDetailRank', res.detailbal);
                        }
                        else if (res.find == '4') {
                            commit('setMemberDetailRank', res.detailbal);
                        }
                        else if (res.find == '5') {
                            commit('setMemberDetailRank', res.detailbal);
                        }

                        state.member.titlestab = res.titles;
                        state.member.indexRank = res.find;

                        resolve(res)
                    }

                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMemberDetail({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListMember(payload)
                .then((res) => {

                    if (res.status == 200) {
                        //console.log("listNewAll" , "tes"+state.member.page);
                        //alert("fetch");
                        if (state.member.page == 'list') {
                            commit('setMemberList', res.member)
                        }
                        else {
                            commit('setMemberListDetail', res.member);
                            //console.log("memberlistnew",state.member.listDetail)
                        }

                        //state.member.total = res.total

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMemberCorpDetail({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apifetchListCorpMember(payload)
                .then((res) => {

                    if (res.status == 200) {
                        //console.log("listNewAll" , "tes"+state.member.page);
                        //alert("fetch");
                        if (state.member.pageCorpList == 'list') {
                            commit('setMemberList', res.member)
                        }
                        else {
                            commit('setCorpMemberDetail', res.member);
                            //console.log("memberlistnew",state.member.listDetail)
                        }

                        //state.member.total = res.total

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    exportHighBuy(payload) {
        return new Promise((resolve, reject) => {
            exportHighBuy(payload)
                .then((res) => {

                    if (res.status == 200) {

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMemberAll({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchMemberAll(payload)
                .then((res) => {

                    if (res.status == 200) {
                        commit('setMemberListAll', res.member)
                        // console.log("memberlista",'tes'+res.dataAll)
                        //return res.member;

                        state.member.total = res.total

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchTransMemberAll({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchMemberAll(payload)
                .then((res) => {

                    if (res.status == 200) {
                        commit('setMemberTransListAll', res.member)

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchStatusWbalMemberAll({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchMemberAll(payload)
                .then((res) => {
                    console.log('dataMembersAll',res.member)

                    if (res.status == 200) {
                        commit('setMemberBalTransListAll', res.member)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMemberAllIdCash({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListMemberIdCash(payload)
                .then((res) => {

                    if (res.status == 200) {

                        if (state.member.pageIdCash == 'list') {
                            commit('setMemberList', res.member)
                        }
                        else {
                            commit('setMemberListDetail', res.member);
                        }

                        state.member.total = res.total

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMemberReqAllIdCash({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchReqListMemberIdCash(payload)
                .then((res) => {

                    if (res.status == 200) {
                        commit('setListReqIdCash', res.data)
                        state.member.total = res.total

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMemberReqVerify({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListReqVerify(payload)
                .then((res) => {

                    if (res.status == 200) {

                        if (state.member.page == 'list') {
                            commit('setMemberListVerify', res.member)
                        }
                        else {
                            commit('setMemberListDetailVerify', res.member);
                        }

                        state.member.totalVerify = res.total
                        //console.log("total_member" , res.total)

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMemberLogs({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListMemberLogs(payload)
                .then((res) => {

                    if (res.status == 200) {

                        if (state.member.pageLogReq == 'list') {
                            commit('setMemberListLogs', res.data.data)
                        }
                        else {
                            commit('setMemberListDetailLogs', res.data.data);
                        }

                        state.member.totalLogs = res.total
                        console.log("res_error", res)

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMemberVerifyDetail({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListVerifyDetail(payload)
                .then((res) => {

                    if (res.status == 200) {

                        if (state.member.pageReq == 'list') {
                            commit('setMemberListVerify', res.member);
                        }
                        else {
                            commit('setMemberListDetailVerify', res.member);
                        }

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchListMemberVerifyEmoney({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListVerifyEmoney(payload)
                .then((res) => {

                    if (res.status == 200) {

                        // if (state.member.pageEmoney == 'list') {
                        //     commit('setMemberEmoney', res.data);
                        // }
                        // else {
                        //     commit('setMemberListDetail', res.member);
                        // }

                        commit('setMemberEmoney', res.data);

                        state.member.total = res.total

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editMemberVerifEmoney({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiEditMemberVerifEmoney(payload)
                .then((res) => {

                    if (res.status == 200) {
                        commit('setStatus', "Success");

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchMemberThirdParty({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiMemberThirdParty(payload)
                .then((res) => {

                    if (res.status == 200) {

                        //if(state.member.pageThirdParty == 'list'){
                        commit('setlistMemberThirdParty', res.data);
                        //}
                        console.log("datas", res.data);


                        state.member.total = res.total

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    exportMemberExcel({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiExportMemberExcel(payload)
                .then((res) => {
                    // if (res.status == 200) {
                    commit('setStatus', "Success");
                    //alert("success");
                    fileDownload(res, state.exports.filename);

                    resolve(res)
                    //}
                    // reject(res)
                }).catch(res => reject(res))
        })
    },
    exportThMemberExcel({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiThMemberExcel(payload)
                .then((res) => {
                    // if (res.status == 200) {
                    commit('setStatus', "Success");
                    //alert("success");
                    fileDownload(res, state.exports.filename);

                    resolve(res)
                    //}
                    // reject(res)
                }).catch(res => reject(res))
        })
    },
    editMemberThirdParty({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apieditMemberThirdParty(payload)
                .then((res) => {

                    if (res.status == 200) {

                        commit('setSuccess', "200")

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editUserLockMember({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apieditUserLock(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setSuccess', "200")

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    }
}

export default {
    namespaced, state, mutations, actions
}