import Vue from "vue";
//import VueRouter from "vue-router";
import VueRouter  from 'vue-router'
//import createWebHashHistory  from 'vue-router'

import Login from '../views/Login.vue'
import MainLayout from '../views/Main.vue'
import MainDashboard from '../views/pages/main.vue'
import TransaksiDashboard from '../views/pages/dashboard/transaksi/list.vue'
import MemberDashboard from '../views/pages/dashboard/member/list.vue'
import GoldRateCharts from '../views/pages/dashboard/grafik/list.vue'
import StatistikDashboard from '../views/pages/dashboard/statistik/list.vue'

import memberRoute from "./member/memberRoute";
import trxRoute from "./transaction/trxRoute";
import corpRoute from "./corporate/trxRoute";

import settingRoute from "./settings/settingRoute";
import adminRoute from "./webadmin/adminRoute";

import goldRoute from "./goldrate/goldRate";
import afxRoute from "./affiliate/afxRoute";

import gameRoute from "./games/Route";


if (!window.VueRouter) Vue.use(VueRouter)

const routes = [
    ...memberRoute,
    ...trxRoute,
    ...corpRoute,
    ...settingRoute,
    ...adminRoute,
    ...goldRoute,
    ...afxRoute,
    ...gameRoute,
    //Login
    {
        path: '/acceso',
        name: 'Login Admin',
        component: Login
    },
    {
        path: '/',
        name: 'Login Admin',
        component: Login
    },
    {
        path: '/panello',
        name: 'Main',
        component: MainLayout,
        children: [
            {
                path: '/panello/principale',
                name: 'Main Dashboard',
                component: MainDashboard
            },
            {
                path: '/panello/chart-trx',
                name: 'Transaksi Chart',
                component: TransaksiDashboard
            },
            {
                path: '/panello/statistik-akses',
                name: 'Statistik Akses Dinaran',
                component: StatistikDashboard
            },
            {
                path: '/panello/chart-member',
                name: 'Member Chart',
                component: MemberDashboard
            },
            {
                path: '/panello/gold-rate-chart',
                name: 'Gold Rate Chart',
                component: GoldRateCharts
            }
        ]
    }
]

const router = new VueRouter({
    //mode: createWebHashHistory(process.env.API_URL_LOCAL),
    //mode: "hash",
    mode: "hash",
    routes
});

export default router