<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-if="$store.state.settings.attributes.pageCustom == 'list'"></List>
      <!-- <Detail v-if="$store.state.settings.attributes.page == 'detail'"></Detail> -->
    </v-container>
  </div>
</template>

<script>
import List from "./customvar/list.vue";
// import Detail from "./admin/detail.vue";

export default { 
  data() {
    return {
      pages:"list"
    }
    },
    mounted() {
      this.$store.commit("settings/setPageCustom", "list");
    },
    components: { List } 
};

</script>

<style>

</style>