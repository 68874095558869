import MainComponent from '../../views/Main.vue'
import SettingLog from '../../views/pages/settings/settingFormatEmail.vue'
import adminAccessLog from '../../views/pages/settings/adminModuleAccess.vue'
import trxAccessLog from '../../views/pages/settings/transactionModuleAccess.vue'
import customVar from '../../views/pages/settings/customvarModuleAccess.vue'
import denomList from '../../views/pages/settings/adminDenomList.vue'
import checkdataModule from '../../views/pages/settings/checkdataModule.vue'
import openChannelNew from '../../views/pages/settings/openChannelModule.vue'
//import settingsMar from '../../views/pages/settings/openChannelModule.vue'

export default [
    {
        path: '/panello/',
        name: 'Main Member',
        component: MainComponent,
        children: [
            {
                path: '/panello/formatEmail',
                name: 'Format Email',
                component: SettingLog
            },
            {
                path: '/panello/adminAccess',
                name: 'Admin Access',
                component: adminAccessLog
            },
            {
                path: '/panello/transactionLog',
                name: 'Transaction Log',
                component: trxAccessLog
            },
            {
                path: '/panello/denom',
                name: 'Denom Nominal List',
                component: denomList
            },
            {
                path: '/panello/customVar',
                name: 'Custom Var',
                component: customVar
            },
            {
                path: '/panello/checkData',
                name: 'Check Data',
                component: checkdataModule
            },
            {
                path: '/panello/openChannel',
                name: 'Open Channel',
                component: openChannelNew
            }//
        ]
    }
]