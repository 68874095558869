import { apiFetchList } from "../../api/bank"

const namespaced = true

const defaultState = () => {
    return {
        bank: {
            listBank: [],
            detailBank: {},
            listDetailBank:[],
            total:0,
            page: "list"
        },
        settlement: {
            list: [],
            detail: {}
        }
    }
}

const state = defaultState()

const mutations = {
    setBankList: (state, payload) => {
        state.bank.listBank = payload
    }
}

const actions = {
    fetchBank({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchList(payload)
                .then((res) => {
                    //if (res.status == 200) {
                        commit('setBankList', res.list)
                        // state.member.total = res.total
                        console.log("BankList",res.list)
                        resolve(res)
                    //}
                    //reject(res)
                }).catch(res => reject(res))
        })
    }
}

export default {
    namespaced, state, mutations, actions
}