import MainComponent from '../../views/Main.vue'
import MemberList from '../../views/pages/transaction/mainMember.vue'
import listTrxList from '../../views/pages/transaction/mainSettleMember.vue'
import listHisGoldList from '../../views/pages/transaction/mainHistoryGoldMember.vue'
import listStockEmasGold from '../../views/pages/transaction/mainHistoryStockGoldMember.vue'
import listStockAtmEmasGold from '../../views/pages/transaction/mainHistoryAtmStockGoldMember.vue'
import listOfflineCounter from '../../views/pages/transaction/mainHistoryOfflineCounter.vue'
import listSetorEmas from '../../views/pages/transaction/mainSetorEmas.vue'
import listSetorTrxExport from '../../views/pages/transaction/mainTransactionExport.vue'
import listSetorExportTrxPlace from '../../views/pages/transaction/mainHistoryAtmStockGoldPlace.vue'
import listSetorExportTrxUpdate from '../../views/pages/transaction/mainHistoryAtmUpdateStockGold.vue'
import listSetorLokasiAtmUpdate from '../../views/pages/transaction/mainLokasiAtmGold.vue'
import listManualTrans from '../../views/pages/transaction/mainHistoryTransManual.vue'
import listVouchers from '../../views/pages/transaction/mainDiscount.vue'

import TrxMember from '../../views/pages/transaction/TrxMember.vue'

//import listTrxListCorp from '../../views/pages/corporate/mainTransactionCorporate.vue'

export default [
    {
        path: '/panello/',
        name: 'Main Transaction',
        component: MainComponent,
        children: [
            {
                path: '/panello/trx-member',
                name: 'Transaction Member List',
                component: MemberList
            },
            {
                path: '/panello/trx/member',
                name: 'Transaction Member',
                component: TrxMember
            },
            {
                path: '/panello/list-settlement-trx',
                name: 'List Trx Settlement',
                component: listTrxList
            },
            {
                path: '/panello/history-emas-trx',
                name: 'History Pengambilan  Emas',
                component: listHisGoldList
            },
            {
                path: '/panello/stock-emas',
                name: 'Stock Emas Dinaran In-Out',
                component: listStockEmasGold
            },
            {
                path: '/panello/atm-stock-emas',
                name: 'Stock Emas ATM Dinaran In-Out',
                component: listStockAtmEmasGold
            },
            {
                path: '/panello/offline-counter',
                name: 'Offline Counter Pengambilan Emas Dinaran',
                component: listOfflineCounter
            },
            {
                path: '/panello/setor-emas',
                name: 'Module Setor Emas Member',
                component: listSetorEmas
            },
            {
                path: '/panello/trx-export',
                name: 'Export Transaction',
                component: listSetorTrxExport
            },
            {
                path: '/panello/stockatm-tempat',
                name: 'Stock Atm Tempat',
                component: listSetorExportTrxPlace
            },
            {
                path: '/panello/updateatm-emas',
                name: 'Update Atm Emas',
                component: listSetorExportTrxUpdate
            },
            {
                path: '/panello/listmesin-emas',
                name: 'List Lokasi Atm Emas',
                component: listSetorLokasiAtmUpdate
            },
            {
                path: '/panello/manual-trx',
                name: 'Manual Trx Ipaymu ID',
                component: listManualTrans
            },
            {
                path: '/panello/voucher-discount',
                name: 'List Vouchers',
                component: listVouchers
            },
        ]
    }
]