<template>
    <div>
      <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->
  
<CustomCard>
  
    <template v-slot:toolbar>
        <v-dialog
            transition="dialog-top-transition"
            max-width="600">

                <template v-slot:activator="{ on, attrs }">

                    <div style="width:100%;">

                        <div style="float:left;">

                            <v-col
                                class="pa-2 font-weight-bold"
                                outlined
                                tile>
                                    <v-btn color="success" small outlined @click="filterToggle" style="float:left;">
                                        filter <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                            </v-col>

                        </div>

                        <div style="float:right;">

                            <v-col
                                class="pa-2 font-weight-bold"
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                @click="add_data()"
                                tile>
                                <v-btn color="success" small outlined>
                                <v-icon>mdi-plus</v-icon> Add New 
                            </v-btn>      
                            </v-col>

                        </div>


                    </div>
                    <!-- <span>Email Not Verified</span> -->

                </template>
                        
                <template v-slot:default="dialog">
                        <v-card>
                            <v-toolbar
                            color="success"
                            dark
                            >Add Vouchers </v-toolbar>

                        <v-container>

                                <v-layout>
                                    <v-flex>
                                        <v-select
                                            style="font-size:11px!important; margin-top:-10px;"
                                            v-model="formLock.type"
                                            label="Pilih Type Voucher"
                                            :items="vouchersType"
                                            item-value="id" 
                                            item-text="name"
                                            @change="cekStatus()">
                                        </v-select>
                                    </v-flex>
                                </v-layout>
                                
                                <v-layout>
                                    <v-flex>
                                    <v-text-field
                                            v-model="formLock.kode_voucher"
                                            type="text"
                                            label="Kode Voucher"
                                            :disabled="isDisabledType"
                                            >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>

                                <v-layout>
                                    <v-flex>
                                        <v-text-field
                                            v-model="formLock.limit"
                                            label="Limit Voucher"
                                            type="number"
                                            >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>


                                <v-layout>
                                    <v-flex>
                                        <v-select
                                            style="font-size:11px!important; margin-right:20px;"
                                            v-model="formLock.rate_type"
                                            label="Pilih Rate Type"
                                            :items="RateType"
                                            item-value="id" 
                                            item-text="name"
                                            @change="cekStatus()">
                                        </v-select>
                                    </v-flex>
                                    <v-flex>
                                        <v-text-field
                                            v-model="formLock.nominal_potongan"
                                            label="Nominal Potongan"
                                            type="number"
                                            >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>

                                
                                <v-layout>
                                    <v-flex>
                                        <v-text-field
                                            v-model="formLock.note"
                                            label="Note"
                                            >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>

                                <v-layout>
                                    <v-flex>
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="date"
                                            lazy
                                            transition="scale-transition"
                                            offset-y
                                            full-width
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="date"
                                                label="Start Date"
                                                readonly
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="date" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                                            <v-btn flat color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-flex>

                                    <v-flex style="margin-left:20px;">
                                        <v-menu
                                            ref="menu"
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="date2"
                                            lazy
                                            transition="scale-transition"
                                            offset-y
                                            full-width
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="date2"
                                                label="Expired Date"
                                                readonly
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="date2" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn flat color="primary" @click="menu2 = false">Cancel</v-btn>
                                            <v-btn flat color="primary" @click="$refs.menu.save(date2)">OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                </v-layout>
                                
                        </v-container>
                            

                        <v-card-actions class="justify-end">
                                
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Close</v-btn>
                            <v-btn
                                color="success"
                                @click="addVoucher(dialog)"
                            >Submit Add</v-btn>
                            </v-card-actions>
                        </v-card>

                </template>

        </v-dialog>
  
          </template>
  
          <template v-slot:expansion>
              <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel expand focusable>
                  <v-expansion-panel-content>
                      
                  <v-layout>
  
                      <v-flex>
                          <v-text-field
                              v-model="searchForm.kodeVoc"
                              label="Search By Kode Voucher or Note"
                              small
                              clearable
                          >
                          </v-text-field>
                      </v-flex>
                      
                      <v-flex>
                        <v-select
                            style="font-size:12px!important;"
                            v-model="searchForm.type"
                            label="Pilih Rate Type"
                            :items="vouchersTypeSearch"
                            item-value="id" 
                            item-text="name">
                        </v-select>
                      </v-flex>    
                      
                  </v-layout>
  
                  
  
                  <v-layout align-end justify-end>
                      <v-spacer></v-spacer>
                      <v-btn
                      small
                      depressed
                      color="success"
                      class="m0"
                      @click="fetch(1)"
                      >
                      <v-icon>mdi-magnify</v-icon> Search
                      </v-btn>
                  </v-layout>
  
                  </v-expansion-panel-content>
              </v-expansion-panel>
              </v-expansion-panels>
          </template>
  
          <template v-slot:datatable>
              <v-flex md12 style="overflow-x: scroll">
  
              <v-data-table
                  :headers="headers"
                  :items="$store.state.transaction.vouchers.list"
                  :options.sync="pagination"
                  :server-items-length="$store.state.transaction.vouchers.total"
                  :footer-props="{
                  'items-per-page-options': [10, 25, 50, 100],
                  'items-per-page-text': 'Baris per Halaman:',
                  }"
                  :items-per-page="10"
                  :loading="loading"
                  :loading-text="loadingtext">

                  <template  v-slot:[`item.tipe_voucher`]="{ item }">
                        <div v-if="item.rate_type === true"> Percentage </div>
                        <div v-if="item.rate_type === false"> Fix Rate </div>
                  </template>

                  <template  v-slot:[`item.idr_nominal_format`]="{ item }">
                        <div v-if="item.rate_type === true">  IDR {{  item.disc_value }} ( {{  item.idr_nominal }} )   </div>
                        <div v-if="item.rate_type === false"> {{  item.idr_nominal }} </div>
                  </template>
                  
  
                  <template  v-slot:[`item.aksi`]="{ item }">
  
                      <v-container>
  
                          <v-row>
                          
                          <v-dialog
                              transition="dialog-top-transition"
                              max-width="600">
  
                              <template v-slot:activator="{ on, attrs }">
  
                                  <v-col
                                      class="pa-2 font-weight-bold"
                                      outlined
                                      tile>
                                          
                                          <v-icon small 
                                          v-bind="attrs"
                                          color="success"
                                          v-on="on"
                                          style="padding:5px; margin-top:-3px;"
                                          @click="editTemplate(item)">
                                              mdi-pencil
                                          </v-icon> 
                                          
                                  </v-col>
                                  <!-- <span>Email Not Verified</span> -->
  
                              </template>
                                      
                              <template v-slot:default="dialog">
                                      <v-card>
                                          <v-toolbar
                                          color="success"
                                          dark
                                          >Edit Vouchers </v-toolbar>
  
                                          <v-layout style="display:none;">
  
                                              <v-text-field
                                                  v-model="editLock.edit_id"
                                                  label=""
                                                  hidden
                                                  >
                                              </v-text-field>
  
                                          </v-layout>
  
                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                <v-text-field
                                                        v-model="editLock.kode_voucher"
                                                        type="text"
                                                        label="Kode Voucher"
                                                        disabled="true"
                                                        >
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-select
                                                        style="font-size:11px!important; margin-top:-10px;"
                                                        v-model="editLock.type"
                                                        label="Pilih Type Voucher"
                                                        :items="vouchersType"
                                                        item-value="id" 
                                                        item-text="name">
                                                    </v-select>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="editLock.limit"
                                                        label="Limit Voucher"
                                                        type="number"
                                                        >
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>


                                            <v-layout>
                                                <v-flex>
                                                    <v-select
                                                        style="font-size:11px!important; margin-right:20px;"
                                                        v-model="editLock.rate_type"
                                                        label="Pilih Rate Type"
                                                        :items="RateType"
                                                        item-value="id" 
                                                        item-text="name"
                                                        @change="cekStatus()">
                                                    </v-select>
                                                </v-flex>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="editLock.nominal_potongan"
                                                        label="Nominal Potongan"
                                                        type="number"
                                                        >
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>


                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="editLock.note"
                                                        label="Note"
                                                        >
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-menu
                                                        ref="menuedit"
                                                        v-model="menuedit"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        :return-value.sync="edit_date"
                                                        lazy
                                                        transition="scale-transition"
                                                        offset-y
                                                        full-width
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="edit_date"
                                                            label="Start Date"
                                                            readonly
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="edit_date" no-title scrollable>
                                                        <v-spacer></v-spacer>
                                                        <v-btn flat color="primary" @click="menuedit = false">Cancel</v-btn>
                                                        <v-btn flat color="primary" @click="$refs.menuedit.save(edit_date)">OK</v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-flex>

                                                <v-flex style="margin-left:20px;">
                                                    <v-menu
                                                        ref="menuedit2"
                                                        v-model="menuedit2"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        :return-value.sync="edit_date2"
                                                        lazy
                                                        transition="scale-transition"
                                                        offset-y
                                                        full-width
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="edit_date2"
                                                            label="Expired Date"
                                                            readonly
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="edit_date2" no-title scrollable>
                                                        <v-spacer></v-spacer>
                                                        <v-btn flat color="primary" @click="menuedit2 = false">Cancel</v-btn>
                                                        <v-btn flat color="primary" @click="$refs.menuedit2.save(edit_date2)">OK</v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>

                                        </v-container>
                                          
  
                                      <v-card-actions class="justify-end">
                                          
                                      <v-btn
                                          text
                                          @click="dialog.value = false"
                                      >Close</v-btn>
                                      <v-btn
                                          color="success"
                                          @click="editDiscount(dialog)"
                                      >Submit</v-btn>
                                      </v-card-actions>
                                  </v-card>
                              </template>
                                      
                          </v-dialog>
  
                          <v-dialog
                              transition="dialog-top-transition"
                              max-width="600">
                              
                              <template v-slot:activator="{ on, attrs }">
  
                              <v-btn
                                  color="red"
                                  icon
                                  v-bind="attrs"
                                  style="padding:5px"
                                  v-on="on">
                                  <v-icon small>mdi-delete</v-icon>
                              </v-btn>
  
                              </template>
                              <template v-slot:default="dialog">
                              <v-card>
                                  <v-toolbar
                                  color="red"
                                  dark
                                  >Delete  Nominal Denom ? </v-toolbar>
                                  
                                  <v-container>
  
                                      <v-layout>
                                          <v-flex>
                                              Yakin akan hapus Nominal Denom <b> {{ item.amount }} </b> ?
                                          </v-flex>
                                      </v-layout>
  
                                  </v-container>
  
                                  <v-card-actions class="justify-end">
                                      
                                  <v-btn
                                      text
                                      @click="dialog.value = false"
                                  >Close</v-btn>
                                  <v-btn
                                      color="red"
                                      style="color:#ffffff;"
                                      @click="deleteTemplate(dialog , item.id)"
                                  >Submit</v-btn>
                                  </v-card-actions>
                              </v-card>
                              </template>
                          </v-dialog>
  
                      </v-row>
              </v-container>
  
              </template>
  
  
              </v-data-table>
  
          </v-flex>
      </template>
    </CustomCard>
  
    </div>
  </template>
  
  <script>
  import CustomCard from "../../../../components/CustomCard.vue";
  
  
  export default {
    data() {
      return {
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modal: false,
        panel: null,
        profiles:this.$store.state.auth.profile,
        provinsiList:[],
        currentPage:1,
        decimals:"10.00",
        icons:"chevron-right",
        isDisabledType: true,
        searchForm: {
          member: ''
          // tipe: 'ALL',
          // status: 'ALL',
          // sponsor: ''
        },
        formLock:{
  
        },
        editLock:{
  
        },
        tipe: [],
        menu: null,
        form: {},
        vouchersType: [{id: 0 , name: 'Non Event'},{id: 1 , name: 'Event'}],
        RateType: [{id: 0 , name: 'Fix Rate'},{id: 1 , name: 'Percentage'}],
        vouchersTypeSearch: [{id: "all" , name: 'All'},{id: 0 , name: 'Fix Rate'},{id: 1 , name: 'Percentage'}],
        pagination: {},
        headers: [
          { text: "Kode Voucher", value: "kode_voucher" , align: "left"},
          { text: "Rate Type", value: "tipe_voucher" , align: "left"},
          { text: "Nominal", value: "idr_nominal_format" , align: "center"},
          { text: "Limit", value: "limit_idr" , align: "center"},
          { text: "Note", value: "note" , align: "left"},
          { text: "Start Date", value: "start_date" , align: "left"},
          { text: "Expired Date", value: "expired_date" , align: "left"},
          { text: "Aksi", value: "aksi" , align: "left" , width:120}
        ],
        loading: false,
        loadingText: "Loading Data...",
      };
    },
    methods: {
      editTemplate(items){
          let type = items.tipe === true ? 1 : 0;
          let rate_type = items.rate_type === true ? 1 : 0;

          this.editLock.edit_id                = items.id
          this.editLock.type                   = type
          this.editLock.kode_voucher           = items.kode_voucher
          this.editLock.limit                  = items.limit
          this.editLock.nominal_potongan       = items.nominal
          this.editLock.note                   = items.note
          this.editLock.rate_type              = rate_type
          this.edit_date                       = items.start_date
          this.edit_date2                      = items.expired_date
          //alert(index);
      },
      clear(){
        this.formLock.kode_voucher = "";
        this.formLock.nominal_potongan = "0";
        this.formLock.type = "";
        this.formLock.note = "";
        this.date = "";
        this.date2 = "";
        this.formLock.limit = "0";
      },
      add_data(){
          this.formLock.add_email = ""
          this.formLock.add_module = ""
      },
      cekStatus(){
        if(this.formLock.type === 1){
            this.isDisabledType = false;
            this.formLock.kode_voucher = ""; 
        }
        else{
            this.isDisabledType = true;
        }
      },
      editDiscount(dialog){
          let type = this.editLock.type === 1 ? true : false;
          let rate_type = this.editLock.rate_type === 1 ? true : false;
          this.$store.commit("main/setLoading", true);

            this.$store
                .dispatch("transaction/editVouchers", {
                    id: this.editLock.edit_id,
                    kode_voucher: this.editLock.kode_voucher,
                    nominal: this.editLock.nominal_potongan,
                    tipe: type,
                    rate_type: rate_type,
                    note: this.editLock.note,
                    start_date: this.edit_date,
                    expired_date: this.edit_date2,
                    limit: this.editLock.limit
                })
                .then(() => {
                    this.fetch(1);
                    dialog.value = false;
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarSuccess", "Update Vouchers Successfully");

                    this.clear();
                })
                .catch((res) => {
                    console.log("status" , res);
            });
      },
      addVoucher(dialog){
        // alert("Type = " +this.formLock.type);
        let type = this.formLock.type === 1 ? true : false;
        let rate_type = this.formLock.rate_type === 1 ? true : false;
        this.$store.commit("main/setLoading", true);

          this.$store
              .dispatch("transaction/addVouchers", {
                  kode_voucher: this.formLock.kode_voucher,
                  nominal: this.formLock.nominal_potongan,
                  tipe: type,
                  rate_type: rate_type,
                  note: this.formLock.note,
                  start_date: this.date,
                  expired_date: this.date2,
                  limit: this.formLock.limit
              })
              .then(() => {
                  this.fetch(1);
                  dialog.value = false;
                  this.$store.commit("main/setLoading", false);
                  this.$store.commit("main/setSnackbarSuccess", "Add Vouchers Successfully");

                  this.clear();
                 // this.provinsiList = this.$store.state.wilayah.wilayah.list;
              })
              .catch((res) => {
                  console.log("status" , res);
          });
      },
      deleteTemplate(dialog , index){
           this.$store
              .dispatch("settings/deleteDenom", {
                  //find: params,
                  id: index,
              })
              .then(() => {
                  this.fetch(1);
                  dialog.value = false;
                 // this.provinsiList = this.$store.state.wilayah.wilayah.list;
              })
              .catch((res) => {
                  console.log("status" , res);
              });
      },
      fetch(cari) {
        if(cari == 1){
            this.pagination.page = 1;
        }
        this.$store.commit("main/setLoading", true);
        this.loading = true;
        this.loadingtext = 'Loading... Please wait';
        
        this.$store
          .dispatch("transaction/getVouchers", {
              page: this.pagination.page,
              itemsPerPage: this.pagination.itemsPerPage,
              key: this.searchForm.tipe,
              subject: this.searchForm.subject,
              tipe: this.searchForm.type,
              isi: this.searchForm.kodeVoc,
          })
          .then(() => {
            this.$store.commit("main/setLoading", false);
            this.loading = false;
            this.loadingtext = '';
          })
          .catch((res) => {
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
            this.loading = false;
            this.loadingtext = '';
          });
      },
      filterToggle() {
        if (this.panel == null)
          this.panel = [...Array(this.items).keys()].map((k, i) => i);
        else this.panel = null;
      },
      showDetail(item) {
          //this.$store.commit("main/setLoading", false);
          //this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.$store.state.member.member.paging_page = this.pagination.page;
          this.$store.commit("member/setMemberPage", "detail");
          this.$store.commit("member/setMemberDetail", item.id);
          this.loading = false;
          this.loadingtext = '';
      },
      showExpand: function (element,index){
          const detailElement = document.getElementById(index);
          if(detailElement.classList.contains('hidden')){
              element.target.classList.remove("mdi-chevron-right");
              element.target.classList.add("mdi-chevron-down");
              detailElement.classList.remove("hidden");
              //this.icons = "chevron-down";
          }
          else{
              element.target.classList.add("mdi-chevron-right");
              element.target.classList.remove("mdi-chevron-down");
              detailElement.classList.add("hidden");
              //this.icons = "chevron-right";
          }   
      },
      showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
        this.formLock.userId = userId
        this.formLock.last_balance = real_gramasi
        this.formLock.lock_user = lock_user
        this.formLock.lock_dinaran = lock_dinaran
      },
    },
    mounted() {
      //this.fetchTotal("");
    },
    watch: {
      pagination() {
          //alert(this.$store.state.member.member.is_back);
          if(this.$store.state.member.member.is_back != 1){
              this.loading = true
              this.loadingtext = 'Loading... Please wait'
              // alert("tes");
              //console.log("user_con" , "nils "+this.profiles.id);
              //alert("tes");
              
              this.fetch(0)
          }
  
          if(this.$store.state.member.member.is_back == 1){
              this.pagination.page = this.$store.state.member.member.paging_page;
          }
  
          this.$store.state.member.member.is_back = 0;
  
      },
      searchSponsor(val) {
          val && val !== this.searchForm.sponsor && this.getSponsor(val);
      },
      searchMember(val) {
          val && val !== this.searchForm.member && this.getMember(val);
      }
    },
    
    computed: {
      dateRangeText() {
        if (typeof this.form.dates != "undefined") {
          if (this.form.dates.length > 1) {
            return this.form.dates.join(" ~ ");
          } else {
            return this.form.dates[0];
          }
        } else {
          return null;
        }
      },
    },
    components: { CustomCard },
  };
  </script>
  
  <style>
  </style>