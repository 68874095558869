<template>

    <div v-if="$store.state.member.member.listCorpDetail[0].verified == 1">

        <v-tabs v-model="tab" align-with-title style="width:100vw">

            <v-tab v-for="item in items" :key="item" style="width:18vw">
                {{ item }}
            </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab" style="width:100vw; overflow:hidden;">
            <v-tab-item key="1" style="padding:40px 50px;">
                <Members />
            </v-tab-item>
            <v-tab-item key="2" style="padding:40px 50px;">
                <Corporate />
            </v-tab-item>
            <v-tab-item key="3" style="padding:40px 50px;">
                <Images />
            </v-tab-item>
            <v-tab-item key="4" style="padding:40px 0 0 0;">
                <Transaction />
            </v-tab-item>
        </v-tabs-items>

        <v-container v-if="$store.state.member.member.formStates == true">
            <v-row no-gutters>

                <div style="width:100%;">

                    <div style="float:right; margin-left:20px;">
                        <v-btn color="success" small @click="editCorporate()" style="float:right;">
                            <!-- <v-icon>mdi-chevron-left</v-icon> -->
                            Submit Data<v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </div>

                    <div style="float:left;">
                        <v-btn color="blue" small outlined @click="showListMember()" style="float:right;">
                            <!-- <v-icon>mdi-chevron-left</v-icon> -->
                            <v-icon>mdi-chevron-left</v-icon> Back to Detail
                        </v-btn>
                    </div>

                </div>

            </v-row>
        </v-container>

    </div>

</template>


<script>
//import CustomCard from "../../../../../components/CustomCard.vue";
import Members from "./component/members.vue";
import Corporate from "./component/corporate.vue";
import Images from "./component/images.vue";
import Transaction from "./component/transaction.vue";

export default {
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            panel: null,
            currentPage: 1,
            decimals: "10.00",
            icons: "chevron-right",
            showDialogUpdate: false,
            unverdialog: false,
            suspenddialog: false,
            dis_kab: true,
            dis_kec: true,
            dis_kel: true,
            curr_dis_kab: true,
            curr_dis_kec: true,
            curr_dis_kel: true,
            curr_dis_prov: true,
            profiles: this.$store.state.auth.profile,
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],
            currkabupatenList: [],
            currkecamatanList: [],
            currkelurahanList: [],
            IndexKel: "",
            bankList: [],
            detailsMember: this.$store.state.member.member.listCorpDetail[0],
            editAct: this.$store.state.member.member.formStates,
            tipe: [],
            menu: null,
            form: {},
            tab: null,
            items: [
                'Member Details', 'Corporate Detail', 'Bank Acc & Images' , 'History Trx'
            ],
            searchForm: {
                member: ''
            },
            pagination: {},
            formLock: {

            },
            headers: [
                { text: "Name", value: "name" },
                { text: "Email", value: "new_email" },
                { text: "Phone", value: "phone" },
                { text: "Reg Date", value: "verified_date" },
                { text: "E-Ver", value: "em_verified" },
                { text: "M-Ver", value: "verified" },
                { text: "Action", align: "center", value: "action" },
            ],
            gender: [
                { text: "All Gender", value: "" },
                { text: "Laki-Laki", value: "Laki-Laki" },
                { text: "Perempuan", value: "Perempuan" },
                // { text: "Gagal", value: "2" },
            ],
            religion: [
                { text: "All Religion", value: "" },
                { text: "Hindu", value: "Hindu" },
                { text: "Katolik", value: "Katolik" },
                { text: "Kristen", value: "Kristen" },
                { text: "Buddha", value: "Buddha" },
                { text: "Islam", value: "Islam" },
                { text: "Konghucu", value: "Konghucu" }
                // { text: "Gagal", value: "2" },
            ],
            loading: false,
            loadingText: "Loading Data...",
        };
    },
    methods: {
        fetchProv(params) {
            this.$store
                .dispatch("wilayah/fetchWilayah", {
                    find: params,
                })
                .then(() => {
                    this.provinsiList = this.$store.state.wilayah.wilayah.list;
                    //console.log("provlist" ,this.$store.state.wilayah.wilayah.list);
                    //alert("prov list");
                })
                .catch((res) => {
                    console.log("prov", res.response.data.message);
                });
        },
        getIndexKel(index) {
            // alert("index = "+index)
            console.log(index);
        },
        fetchBank(params) {
            this.$store
                .dispatch("bank/fetchBank", {
                    find: params,
                })
                .then(() => {
                    this.bankList = this.$store.state.bank.bank.listBank;
                })
                .catch((res) => {
                    console.log("prov", res.response.data.message);
                });
        },

        filterToggle() {
            if (this.panel == null)
                this.panel = [...Array(this.items).keys()].map((k, i) => i);
            else this.panel = null;
        },
        showDetail(item) {
            this.$store.commit("transaction/setMemberCorpPage", "detail");
            this.$store.commit("transaction/setMemberDetail", item);
            //this.detailsName = "New Member";
        },
        showList() {
            this.$store.commit("member/setMemberCorpPage", "list");
            this.$store.state.member.member.is_back = 1;
            //   this.$store.commit("transaction/setMemberDetail", item);
        },
        showListMember() {
            this.$store.commit("member/setFormSwitch", false);
        },
        fetchcorp() {
            this.loading = true;
            this.loadingtext = 'Loading... Please wait';
            this.$store.commit("main/setLoading", true);
            this.$store
                .dispatch("member/fetchListMemberCorpDetail", {
                    id_member: this.$store.state.member.member.detail,
                })
                .then(() => {
                    this.$store.commit("main/setLoading", false);
                    this.loading = false;
                    this.loadingtext = '';
                    this.detailsMember = this.$store.state.member.member.listCorpDetail[0];
                    this.$store.commit("member/setFormSwitch", false);

                    this.clear();
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        clear: function(){
            this.$store.state.corporate.editform.editIjinUsaha = "";
            this.$store.state.corporate.editform.editRekeningKoran = "";
            this.$store.state.corporate.editform.editSelfieImages = "";
            this.$store.state.corporate.editform.ktp_image = "";
            this.$store.state.corporate.editform.tab_image = "";
            this.$store.state.corporate.editform.editIjinUsaha = "";
        },
        editCorporate: function () {
            let BankValArr = this.$store.state.corporate.editform.editNamaBank.split("#");
            this.$store.commit("main/setLoading", true);
            // alert(this.$store.state.member.member.listCorpDetail[0].id);
            this.$store
                .dispatch("corporate/editCorporate", {
                    id: this.$store.state.member.member.listCorpDetail[0].id,
                    user_id: this.$store.state.member.member.listCorpDetail[0].user_id,
                    name: this.$store.state.corporate.editform.editName,
                    email: this.$store.state.corporate.editform.editEmail,
                    phone: this.$store.state.corporate.editform.editPhone,
                    password: this.$store.state.corporate.editform.editPassword,
                    nik: this.$store.state.corporate.editform.editNik,
                    gender: this.$store.state.corporate.editform.editGender,
                    religion: this.$store.state.corporate.editform.editReligion,
                    birth_place: this.$store.state.corporate.editform.editBirthPlace,
                    date_of_birth: this.$store.state.corporate.editform.editDateOfBirth,
                    namausaha: this.$store.state.corporate.editform.editNamaUsaha,
                    ijinusaha: this.$store.state.corporate.editform.editIjinUsaha,
                    website: this.$store.state.corporate.editform.websiteUtama,
                    alamat: this.$store.state.corporate.editform.editAddress,
                    corp_email: this.$store.state.corporate.editform.corp_email,
                    phone_num: this.$store.state.corporate.editform.phone_num,
                    propinsi: this.$store.state.corporate.editform.editProvinsi,
                    kabupaten: this.$store.state.corporate.editform.editKabupaten,
                    kecamatan: this.$store.state.corporate.editform.editKecamatan,
                    kelurahan: this.$store.state.corporate.editform.editKelurahan,
                    kodepost: this.$store.state.corporate.editform.editKodePost,
                    bank_id: BankValArr[1],
                    bank_name: BankValArr[0],
                    bank_acc_number: this.$store.state.corporate.editform.editBankAccNo,
                    bank_acc_holder: this.$store.state.corporate.editform.editBankAccHolder,
                    img_ktp: this.$store.state.corporate.editform.editKtpImages,
                    img_selfie: this.$store.state.corporate.editform.editSelfieImages,
                    img_bank: this.$store.state.corporate.editform.editBukuTabungan,
                    rekeningkoran: this.$store.state.corporate.editform.editRekeningKoran,
                    corporate_provinsi: this.$store.state.corporate.editform.corporateprovinsi,
                    corporate_kabupaten: this.$store.state.corporate.editform.editCorporateKabupaten,
                    corporate_alamat: this.$store.state.corporate.editform.editCorporateAlamat,
                    corporate_kodepost: this.$store.state.corporate.editform.editCorporateKodePost,
                })
                .then(() => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarSuccess", "Update Data Member Successfull");
                    this.fetchcorp();
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        getWilayah(value) {

            if (value == "kabupaten") {
                this.dis_kab = false;
            }

            if (value == "kecamatan") {
                this.dis_kec = false;
            }

            if (value == "kelurahan") {
                this.dis_kel = false;
            }

            this.$store
                .dispatch("wilayah/fetchAreaWilayah", {
                    find: value,
                    provinsi: this.searchForm.editProvinsi,
                    kabupaten: this.searchForm.editKabupaten,
                    kecamatan: this.searchForm.editKecamatan,
                    currprovinsi: this.searchForm.editCurrentProvinsi,
                    currkabupaten: this.searchForm.editCurrKabupaten,
                    currkecamatan: this.searchForm.editCurrKecamatan
                })
                .then(() => {

                    if (value == "kabupaten") {
                        this.kabupatenList = this.$store.state.wilayah.wilayah.listKabupaten;
                        this.dis_kab = false;
                        this.$store.state.corporate.editform.dis_kab = false;
                    }

                    if (value == "kecamatan") {
                        this.kecamatanList = this.$store.state.wilayah.wilayah.listKecamatan;
                        this.dis_kec = false;
                    }

                    if (value == "kelurahan") {
                        this.kelurahanList = this.$store.state.wilayah.wilayah.listKelurahan;
                        this.dis_kel = false;
                    }

                    if (value == "currkabupaten") {
                        this.currkabupatenList = this.$store.state.wilayah.wilayah.currlistKabupaten;
                        this.curr_dis_kab = false;
                    }

                    if (value == "currkecamatan") {
                        this.currkecamatanList = this.$store.state.wilayah.wilayah.currlistKecamatan;
                        this.curr_dis_kec = false;
                    }

                    if (value == "currkelurahan") {
                        this.currkelurahanList = this.$store.state.wilayah.wilayah.currlistKelurahan;
                        this.curr_dis_kel = false;
                    }

                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        editProfiles: function (dialog) {
            this.$store
                .dispatch("member/editProfiles", {
                    userId: this.formLock.userId,
                    edit_name: this.formLock.edit_name,
                    edit_email: this.formLock.edit_email,
                    updateId: this.profiles.id,
                    edit_phone: this.formLock.edit_phone
                })
                .then(() => {
                    //   this.$store.commit("main/setLoading", false);
                    //   this.loading = false;
                    //   this.loadingtext = '';
                    //this.fetch();
                    dialog.value = false;
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        userUnverify: function (dialog) {
            this.$store
                .dispatch("member/userUnverify", {
                    userId: this.formLock.userId,
                    updateId: this.profiles.id,
                })
                .then(() => {
                    //   this.$store.commit("main/setLoading", false);
                    //   this.loading = false;
                    //   this.loadingtext = '';
                    this.fetch();
                    dialog.value = false;
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        userSuspend: function (dialog) {
            this.$store
                .dispatch("member/userSuspend", {
                    userId: this.formLock.userId,
                    updateId: this.profiles.id,
                    suspend_reason: this.formLock.suspend_reason
                })
                .then(() => {
                    //   this.$store.commit("main/setLoading", false);
                    //   this.loading = false;
                    //   this.loadingtext = '';
                    this.formLock.suspend_reason = "";
                    this.fetch();
                    dialog.value = false;
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        userUnSuspend: function (dialog) {
            this.$store
                .dispatch("member/userUnSuspend", {
                    userId: this.formLock.userId,
                    updateId: this.profiles.id,
                    suspend_reason: this.formLock.suspend_reason
                })
                .then(() => {
                    //   this.$store.commit("main/setLoading", false);
                    //   this.loading = false;
                    //   this.loadingtext = '';
                    this.fetch();
                    this.formLock.suspend_reason = "";
                    dialog.value = false;
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        showLock: function (userId, member_name, member_email, member_phone) {
            this.formLock.userId = userId
            this.formLock.edit_name = member_name
            this.formLock.edit_email = member_email
            this.formLock.edit_phone = member_phone
            this.showDialogUpdate = true
        },
        showUnVer: function (userId, member_name, member_email, member_phone) {
            this.formLock.userId = userId
            this.formLock.edit_name = member_name
            this.formLock.edit_email = member_email
            this.formLock.edit_phone = member_phone
            this.unverdialog = true
        },
        showDetailId: function (userId) {
            this.formLock.userId = userId
            this.suspenddialog = true
        },
        showExpand: function (element, index) {
            const detailElement = document.getElementById(index);
            if (detailElement.classList.contains('hidden')) {
                element.target.classList.remove("mdi-chevron-right");
                element.target.classList.add("mdi-chevron-down");
                detailElement.classList.remove("hidden");
                //this.icons = "chevron-down";
            }
            else {
                element.target.classList.add("mdi-chevron-right");
                element.target.classList.remove("mdi-chevron-down");
                detailElement.classList.add("hidden");
                //this.icons = "chevron-right";
            }
        },
    },
    mounted() {
        this.$store.commit("member/setFormSwitch", false);

        //alert(this.$store.state.user.ac_update);
    },

    computed: {
        dateRangeText() {
            if (typeof this.form.dates != "undefined") {
                if (this.form.dates.length > 1) {
                    return this.form.dates.join(" ~ ");
                } else {
                    return this.form.dates[0];
                }
            } else {
                return null;
            }
        },
    },
    components: { Members, Corporate, Images, Transaction },
};
</script>