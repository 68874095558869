<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-if="$store.state.transaction.member.page == 'list'"></List>
    </v-container>
  </div>
</template>

<script>
import List from "./member/listData.vue";
export default {
  components: {
    List,
  },
};
</script>

<style>
</style>