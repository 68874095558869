<template>
  <v-dialog
    v-model="$store.state.memberMain.main.dialog.emasImpian"
    persistent
    :overlay="false"
    max-width="700px"
    transition="dialog-transition"
  >
    <custom-card :cardShow="true" :cardAction="true">
      <template v-slot:toolbar>
        <v-toolbar-title> List Emas Impian </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="black" text small @click="closeDialog()">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
      <template v-slot:content>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="width: 50%">Name</th>
                <th class="text-left">Emas Impian (balance/target)</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in $store.state.memberMain.main.emasImpianList"
                :key="item.id"
              >
                <td>{{ item.impian }}</td>
                <td style="text-align: right">
                  {{ item.lastbalance }} gr <b>/ {{ item.target }} gr </b>
                </td>
                <td style="text-align: center">
                  <v-btn
                    color="success"
                    small
                    text
                    v-show="!item.withdrawal"
                    @click="rebalance(item.impian_id)"
                  >
                    <v-icon icon>mdi-lock</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </custom-card>
  </v-dialog>
</template>

<script>
import CustomCard from "../../../../../components/CustomCard.vue";
export default {
  components: { CustomCard },
  methods: {
    rebalance(id) {
      this.$store.commit("main/setLoading");
      this.$store
        .dispatch("memberMain/rebalanceImpian", {
          impian_id: id,
        })
        .then(() => {
          this.$store.commit("main/setLoading");
          this.$store.commit("main/setSnackbarSuccess", "success");
          this.$store.commit("memberMain/setMainDialog", "emas impian");
          this.$store.commit("memberMain/setEmasImpianList", []);
        })
        .catch((res) => {
          this.$store.commit("main/setLoading");
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
        });
    },
    closeDialog() {
      this.$store.commit("memberMain/setMainDialog", "emas impian");
      this.$store.commit("memberMain/setEmasImpianList", []);
    },
  },
};
</script>

<style>
</style>