import { apiFetchTransactionMember } from "../../api/transaction"

const namespaced = true

const defaultState = () => {
    return {
        list: [],
        detail: {},
        page: 'list',
        dialog: {},
        pagination: {},
        search: {

        },
        total: 0,
    }
}

const state = defaultState()

const mutations = {
    setList: (state, payload) => {
        state.list = payload
    },
    setTotal: (state, payload) => {
        state.total = payload
    },
    setSearch: (state, payload) => {
        state.search = payload
        state.search.page = state.pagination.page
        state.search.itemsPerPage = state.pagination.itemsPerPage
    },
    setPaginationSetting: (state, payload) => {
        state.pagination = payload
        state.search.page = payload.page
        state.search.itemsPerPage = payload.itemsPerPage
    },
    setPage: (state, payload) => {
        state.page = payload
    }
}

const actions = {
    fetchTrx({ commit, state }) {
        return new Promise((resolve, reject) => {
            apiFetchTransactionMember(state.search)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setList', res.trx)
                        commit('setTotal', res.total)
                        resolve(res)
                    }
                    reject(res)
                }).catch((res) => reject(res))
        })
    }
}

export default {
    namespaced, state, mutations, actions
}