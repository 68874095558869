<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <CustomCard>

        <template v-slot:toolbar>
            <v-btn color="success" small outlined @click="filterToggle">
            filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn>

            <v-dialog
              v-model="dialog"
              max-width="600"
            >
              <v-card>
                <v-card-title class="text-h5">
                  Give Reward Highscore
                </v-card-title>

                <v-card-text>

                  <v-row>

                    <v-col md="12" sm="12" style="margin-top:10px;">

                      Yakin anda akan memberikan reward highscore games
                      Ke customer ini sebesar <b> 0.1 </b> g Gr?

                    </v-col>

                    <v-col md="12" sm="12">

                      Internal Note : <br />

                      <v-text-field
                        v-model="searchForm.rewardNotes"
                        label="Reward Games Dinaran"
                        outlined>
                      </v-text-field>

                    </v-col>

                  </v-row>

                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="red darken-1"
                    text
                    @click="dialog = false"
                  >
                    Cancel
                  </v-btn>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="giverewards()"
                  >
                    Okay
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

        </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.member"
                                label="Search By Name"
                                small
                                clearable
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.email"
                                label="Search By Email "
                                small
                                clearable
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.phone"
                                label="Search By Phone"
                                small
                                clearable
                            >
                            </v-text-field>
                        </v-flex>
                    
                </v-layout>

                <v-layout>

                  <v-flex>

                      <v-autocomplete
                          v-model="searchForm.sortingText"
                          :items="status"
                          item-text="text"
                          item-value="value"
                          label="Sorting">
                      </v-autocomplete>

                  </v-flex>

                  <v-flex>

                    <v-menu
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">

                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="searchForm.date"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="searchForm.date"
                        no-title
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="menu2 = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-menu>

                  </v-flex>

                  <v-flex>

                    <v-menu
                        :close-on-content-click="false"
                        :return-value.sync="date2"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">

                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="searchForm.date2"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="searchForm.date2"
                        no-title
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-menu>

                  </v-flex>

                </v-layout>
                

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">
            <v-data-table
                :headers="headers"
                :items="$store.state.games.score.list"
                :options.sync="pagination"
                :server-items-length="$store.state.games.score.total"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

                <template v-slot:[`item.membersDetail`]="{ item }">
                        <div> <b> {{ item.member.name }} </b> <br /> <div>  {{ item.member.email }} </div> <div>  {{ item.member.phone }} </div> </div>
                </template>

                <template v-slot:[`item.notes`]="{ item }">
                        <div>
                            {{ item.note }} <div v-if="item.trx_id != 0">Trx ID:  {{ item.trx_id }}</div>
                        </div>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="item.reward == null">
                          <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="info"
                                    icon
                                    @click.stop="showDialogs(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon small>mdi-gift</v-icon>
                                </v-btn>
                                </template>
                                <span>Beri Reward</span>
                            </v-tooltip>
                        </div>

                        <div v-if="item.reward != null">

                          <div>
                            Get Reward {{ item.reward.gramasi }} GR <div> {{ item.reward.note }} </div>
                          </div>

                        </div>

                </template>

            </v-data-table>
            </v-flex>
        </template>
    </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      showDialogUpdate:true,
      pilihan_id:"",
      dialog:false,
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      status: [
            { text: "Sort LastID", value: "lastid" },
            { text: "Sort HighScore", value: "highscore" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "ID", value: "id"},
      //  { text: "Trx", align: "center", value: "trxCount" },
        { text: "Datetime", align: "left", value: "created_at" , width:120},
        { text: "Member", align: "left", value: "membersDetail"},
      //  { text: "Lock Bal (gr)", align: "center" , value: "lock_balance" , width: "150"},
      //  { text: "Reg Date", value: "verified_date"},
        { text: "Tipe", align: "left", value: "tipe" , width:150},
      //  { text: "M-Ver", value: "verified"},
        { text: "Score", align: "left", value: "score" , width:250 },
        { text: "Note", align: "left", value: "notes" },
        //{ text: "Update By", align: "left", value: "admin.name" },
        { text: "Action", align: "center", value: "actions" , width: "120"},
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    fetchProv(params){
        this.$store
            .dispatch("wilayah/fetchWilayah", {
                find: params,
            })
            .then(() => {
                this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("prov" , res.response.data.message);
            });
    },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      //alert("limits "+this.pagination.itemsPerPage);
      //alert("tes new");
      this.$store
        .dispatch("games/fetchGamesScore", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            name:this.searchForm.member,
            email:this.searchForm.email,
            phone:this.searchForm.phone,
            startdate:this.searchForm.date,
            enddate:this.searchForm.date2,
            sort:this.searchForm.sortingText
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    showDialogs(data){
        // alert(data.id);
        this.pilihan_id = data.id
        this.dialog = true
    },
    giverewards(){
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      //alert("limits "+this.pagination.itemsPerPage);
      //alert("tes new");
      this.$store
        .dispatch("games/giveRewardScore", {
            page: this.pagination.page,
            scoreid:this.pilihan_id,
            note:this.searchForm.rewardNotes,
            updateId:this.profiles.id
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarSuccess", "Give Reward Successful");
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    // showDialogReward(items){
    //    this.showDialogUpdate = true
    // },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
      //this.fetch(0);
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        }

        if(this.$store.state.member.member.is_back == 1){
            this.pagination.page = this.$store.state.member.member.paging_page;
        }

        this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>