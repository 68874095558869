import MainComponent from '../../views/Main.vue'
import adminMedia from '../../views/pages/webadmin/adminMediaModule.vue'
import adminNotify from '../../views/pages/webadmin/adminNotifyModule.vue'
import webPopUp from '../../views/pages/webadmin/webPopUpModule.vue'
import webPages from '../../views/pages/webadmin/pagesPopUpModule.vue'

export default [
    {
        path: '/panello/',
        name: 'Main Media',
        component: MainComponent,
        children: [
            {
                path: '/panello/adminMedia',
                name: 'Admin Media',
                component: adminMedia
            },
            {
                path: '/panello/notifyApp',
                name: 'Notify App',
                component: adminNotify
            },
            {
                path: '/panello/webPopUp',
                name: 'Web PopUp',
                component: webPopUp
            },
            {
                path: '/panello/webPages',
                name: 'Web Pages Management',
                component: webPages
            }
        ]
    }
]