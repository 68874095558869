<template>
  <div>
    <v-container grid-list-xs fluid>
      <List></List>
    </v-container>
  </div>
</template>

<script>
import List from "./list/list.vue";

export default { 

  data() {
    return {
      pages:"list"
    }
    },
    mounted() {
      this.$store.commit("goldrate/setPageGold", "list");
    },
    components: { List } 
};

</script>

<style>

</style>