<template>
    <div>
        <div v-if="$store.state.member.member.formStates == true">
            <v-container>

                <v-row>
                    <v-col class="12" style="margin-bottom:40px; padding-left:25px;  border-bottom:1px solid #dddddd;">
                        <h3 style="font-size:24px;"> Informasi Akun </h3>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="6" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2" outlined tile>
                                    Nama
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>

                                    <v-text-field v-model="$store.state.corporate.editform.editName" label=""
                                        single-line small clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                    <v-col sm="6" md="6">
                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2" outlined tile>
                                    Email
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field v-model="$store.state.corporate.editform.editEmail" label=""
                                        single-line small clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="6" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2" outlined tile>
                                    Password
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>

                                    <v-text-field v-model="$store.state.corporate.editform.editPassword" value=""
                                        label="" single-line small type="password">
                                    </v-text-field>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                    <v-col sm="6" md="6">
                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2" outlined tile>
                                    Phone Num
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>

                                    <v-text-field v-model="$store.state.corporate.editform.editPhone" label=""
                                        single-line type="number" small clearable>
                                    </v-text-field>

                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>

                </v-row>

                <v-row>
                    <v-col class="12" style="margin-bottom:40px; padding-left:25px; border-bottom:1px solid #dddddd;">
                        <h3 style="font-size:24px;"> Informasi Lainnya </h3>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col sm="12" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    NIK
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field style="display:none;" v-model="$store.state.corporate.editform.editId"
                                        label="id" single-line small clearable>
                                    </v-text-field>

                                    <v-text-field v-model="$store.state.corporate.editform.editNik" label="" single-line
                                        small clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                    <v-col sm="12" md="6">
                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Provinsi
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-autocomplete v-model="$store.state.corporate.editform.editProvinsi"
                                        @change="(event) => getWilayah('kabupaten')" single-line
                                        :items="$store.state.wilayah.wilayah.list" item-text="provinsi"
                                        item-value="provinsi" label="">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>
                </v-row>


                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Birth Place
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>

                                    <v-text-field v-model="$store.state.corporate.editform.editBirthPlace"
                                        label="Search By BirthPlace" single-line small clearable>
                                    </v-text-field>

                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>

                    <v-col sm="5" md="6">
                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kabupaten
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-autocomplete v-model="$store.state.corporate.editform.editKabupaten"
                                        @change="(event) => getWilayah('kecamatan')"
                                        :disabled="$store.state.member.list.dis_kab"
                                        :items="$store.state.wilayah.wilayah.listKabupaten" single-line
                                        item-text="kabupaten" item-value="kabupaten" label="">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Date Of Birth
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>

                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                        :return-value.sync="date" single-line transition="scale-transition" offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="$store.state.corporate.editform.editDateOfBirth"
                                                label="BirthDate" prepend-icon="mdi-calendar" single-line readonly
                                                v-bind="attrs" v-on="on" clearable></v-text-field>
                                        </template>
                                        <v-date-picker v-model="$store.state.corporate.editform.editDateOfBirth"
                                            no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary" @click="$refs.menu.save(date)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>


                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>

                    <v-col sm="5" md="6">
                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kecamatan
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-autocomplete v-model="$store.state.corporate.editform.editKecamatan"
                                        :disabled="$store.state.member.list.dis_kec"
                                        @change="(event) => getWilayah('kelurahan')" single-line
                                        :items="$store.state.wilayah.wilayah.listKecamatan" item-text="kecamatan"
                                        item-value="kecamatan" label="">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Religion
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>

                                    <v-autocomplete v-model="$store.state.corporate.editform.editReligion"
                                        :items="religion" single-line item-text="text" item-value="value"
                                        label="Religion">
                                    </v-autocomplete>

                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kelurahan
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-autocomplete v-model="$store.state.corporate.editform.editKelurahan"
                                        :disabled="$store.state.member.list.dis_kel" single-line
                                        :items="$store.state.wilayah.wilayah.listKelurahan" item-text="kelurahan"
                                        item-value="mix_data" label="">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>
                </v-row>


                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Gender
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>

                                    <v-autocomplete v-model="$store.state.corporate.editform.editGender" :items="gender"
                                        single-line item-text="text" item-value="value" label="Gender">
                                    </v-autocomplete>

                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>

                    <v-col sm="5" md="6">
                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Address
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field v-model="$store.state.corporate.editform.editAddress" label="Address"
                                        single-line small clearable>
                                    </v-text-field>

                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">
                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kode Pos
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field v-model="$store.state.corporate.editform.editKodePost" label=""
                                        single-line small clearable>
                                    </v-text-field>

                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>
                </v-row>

            </v-container>

        </div>

        <div v-if="$store.state.member.member.formStates == false">

            <v-container>

                <v-row>
                    <v-col class="12" style="margin-bottom:40px; padding-left:25px;  border-bottom:1px solid #dddddd;">
                        <h3 style="font-size:24px;"> Informasi Akun </h3>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="12" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Name
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].name }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                    <v-col sm="12" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Email
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].email }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                </v-row>

                <v-row no-gutters>

                    <v-col sm="12" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Phone
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].phone }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                </v-row>


                <v-row>
                    <v-col class="12" style="margin-bottom:40px; padding-left:25px; border-bottom:1px solid #dddddd;">
                        <h3 style="font-size:24px;"> Informasi Lainnya </h3>
                    </v-col>
                </v-row>


                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Date of Birth
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].date_of_birth }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kecamatan
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].kecamatan }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Religion
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].religion }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Provinsi
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].provinsi }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>



                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Gender
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].gender }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kabupaten
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].kabupaten }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kecamatan
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].kecamatan }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kelurahan
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].kelurahan }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                </v-row>



                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kode Pos
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].kode_post }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>



                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Alamat
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].address }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                </v-row>

            </v-container>

        </div>
    </div>
</template>

<script>
//import CustomCard from "../../../../../../components/CustomCard.vue";



export default {
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            panel: null,
            currentPage: 1,
            decimals: "10.00",
            icons: "chevron-right",
            showDialogUpdate: false,
            unverdialog: false,
            suspenddialog: false,
            dis_kab: true,
            dis_kec: true,
            dis_kel: true,
            curr_dis_kab: true,
            curr_dis_kec: true,
            curr_dis_kel: true,
            curr_dis_prov: true,
            profiles: this.$store.state.auth.profile,
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],
            currkabupatenList: [],
            currkecamatanList: [],
            currkelurahanList: [],
            IndexKel: "",
            bankList: [],
            detailsMember: this.$store.state.member.member.listCorpDetail[0],
            editAct: this.$store.state.member.member.formStates,
            tipe: [],
            menu: null,
            form: {},
            tab: null,
            items: [
                'Member Detail', 'Corporate Detail'
            ],
            searchForm: {
                member: ''
            },
            pagination: {},
            formLock: {

            },
            headers: [
                { text: "Name", value: "name" },
                { text: "Email", value: "new_email" },
                { text: "Phone", value: "phone" },
                { text: "Reg Date", value: "verified_date" },
                { text: "E-Ver", value: "em_verified" },
                { text: "M-Ver", value: "verified" },
                { text: "Action", align: "center", value: "action" },
            ],
            gender: [
                { text: "All Gender", value: "" },
                { text: "Laki-Laki", value: "Laki-Laki" },
                { text: "Perempuan", value: "Perempuan" },
                // { text: "Gagal", value: "2" },
            ],
            religion: [
                { text: "All Religion", value: "" },
                { text: "Hindu", value: "Hindu" },
                { text: "Katolik", value: "Katolik" },
                { text: "Kristen", value: "Kristen" },
                { text: "Buddha", value: "Buddha" },
                { text: "Islam", value: "Islam" },
                { text: "Konghucu", value: "Konghucu" }
                // { text: "Gagal", value: "2" },
            ],
            loading: false,
            loadingText: "Loading Data...",
        };
    },
    methods: {
        fetchProv(params) {
            this.$store
                .dispatch("wilayah/fetchWilayah", {
                    find: params,
                })
                .then(() => {
                    this.provinsiList = this.$store.state.wilayah.wilayah.list;
                    //console.log("provlist" ,this.$store.state.wilayah.wilayah.list);
                    //alert("prov list");
                })
                .catch((res) => {
                    console.log("prov", res.response.data.message);
                });
        },
        getIndexKel(index) {
            // alert("index = "+index)
            console.log(index);
        },
        fetchBank(params) {
            this.$store
                .dispatch("bank/fetchBank", {
                    find: params,
                })
                .then(() => {
                    this.bankList = this.$store.state.bank.bank.listBank;
                })
                .catch((res) => {
                    console.log("prov", res.response.data.message);
                });
        },
        fetch() {
            // this.loading = true;
            // this.loadingtext = 'Loading... Please wait';
            // this.$store.commit("main/setLoading", true);
            this.$store
                .dispatch("member/fetchListMemberCorpDetail", {
                    id_member: this.$store.state.member.member.detail,
                })
                .then(() => {
                    //alert("tes-dd");
                    //console.log("itemsfetch", this.$store.state.member.member.listDetail[0]);
                    // this.$store.commit("main/setLoading", false);
                    // this.loading = false;
                    // this.loadingtext = '';
                    this.detailsMember = this.$store.state.member.member.listCorpDetail[0];
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        filterToggle() {
            if (this.panel == null)
                this.panel = [...Array(this.items).keys()].map((k, i) => i);
            else this.panel = null;
        },
        showListMember() {
            this.fetch();
            this.$store.commit("member/setFormSwitch", false);
        },
        editCorporate: function () {
            this.$store.commit("main/setLoading", true);
            this.$store
                .dispatch("corporate/editCorporate", {
                    name: this.$store.state.corporate.form.name,
                    email: this.$store.state.corporate.form.email,
                    phone: this.$store.state.corporate.form.phone,
                    password: this.$store.state.corporate.form.password,
                    nik: this.$store.state.corporate.form.nik,
                    gender: this.$store.state.corporate.form.gender,
                    religion: this.$store.state.corporate.form.religion,
                    birth_place: this.$store.state.corporate.form.birth_place,
                    date_of_birth: this.$store.state.corporate.form.date_of_birth,
                    namausaha: this.$store.state.corporate.form.namausaha,
                    ijinusaha: this.$store.state.corporate.form.ijin_perusahaan,
                    website: this.$store.state.corporate.form.website,
                    alamat: this.$store.state.corporate.form.alamat,
                    propinsi: this.$store.state.corporate.form.provinsi,
                    kabupaten: this.$store.state.corporate.form.kabupaten,
                    kecamatan: this.$store.state.corporate.form.kecamatan,
                    kelurahan: this.$store.state.corporate.form.kelurahan,
                    kodepost: this.$store.state.corporate.form.kode_pos,
                    // bank_id: BankValArr[1],
                    // bank_name: BankValArr[0],
                    bank_acc_number: this.$store.state.corporate.form.bank_acc_no,
                    bank_acc_holder: this.$store.state.corporate.form.bank_acc_holder,
                    img_ktp: this.$store.state.corporate.form.img_ktp,
                    img_selfie: this.$store.state.corporate.form.img_selfie,
                    img_bank: this.$store.state.corporate.form.img_bank,
                    rekeningkoran: this.$store.state.corporate.form.rekeningkoran
                })
                .then(() => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarSuccess", "Update Data Member Successfull");
                    this.fetch();
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        getWilayah(value) {

            if (value == "kabupaten") {
                this.$store.state.member.list.dis_kab = false;
            }

            if (value == "kecamatan") {
                this.$store.state.member.list.dis_kec = false;
            }

            if (value == "kelurahan") {
                this.$store.state.member.list.dis_kel = false;
            }

            this.$store
                .dispatch("wilayah/fetchAreaWilayah", {
                    find: value,
                    provinsi: this.$store.state.corporate.editform.editProvinsi,
                    kabupaten: this.$store.state.corporate.editform.editKabupaten,
                    kecamatan: this.$store.state.corporate.editform.editKecamatan,
                })
                .then(() => {

                    if (value == "kabupaten") {
                        this.kabupatenList = this.$store.state.wilayah.wilayah.listKabupaten;
                        this.$store.state.member.list.dis_kab = false;
                    }

                    if (value == "kecamatan") {
                        this.kecamatanList = this.$store.state.wilayah.wilayah.listKecamatan;
                        this.$store.state.member.list.dis_kec = false;
                    }

                    if (value == "kelurahan") {
                        this.kelurahanList = this.$store.state.wilayah.wilayah.listKelurahan;
                        this.$store.state.member.list.dis_kel = false;
                    }


                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        editProfiles: function (dialog) {
            this.$store
                .dispatch("member/editProfiles", {
                    userId: this.formLock.userId,
                    edit_name: this.formLock.edit_name,
                    edit_email: this.formLock.edit_email,
                    updateId: this.profiles.id,
                    edit_phone: this.formLock.edit_phone
                })
                .then(() => {
                    //   this.$store.commit("main/setLoading", false);
                    //   this.loading = false;
                    //   this.loadingtext = '';
                    this.fetch();
                    dialog.value = false;
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        userUnverify: function (dialog) {
            this.$store
                .dispatch("member/userUnverify", {
                    userId: this.formLock.userId,
                    updateId: this.profiles.id,
                })
                .then(() => {
                    //   this.$store.commit("main/setLoading", false);
                    //   this.loading = false;
                    //   this.loadingtext = '';
                    this.fetch();
                    dialog.value = false;
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        userSuspend: function (dialog) {
            this.$store
                .dispatch("member/userSuspend", {
                    userId: this.formLock.userId,
                    updateId: this.profiles.id,
                    suspend_reason: this.formLock.suspend_reason
                })
                .then(() => {
                    //   this.$store.commit("main/setLoading", false);
                    //   this.loading = false;
                    //   this.loadingtext = '';
                    this.formLock.suspend_reason = "";
                    this.fetch();
                    dialog.value = false;
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        userUnSuspend: function (dialog) {
            this.$store
                .dispatch("member/userUnSuspend", {
                    userId: this.formLock.userId,
                    updateId: this.profiles.id,
                    suspend_reason: this.formLock.suspend_reason
                })
                .then(() => {
                    //   this.$store.commit("main/setLoading", false);
                    //   this.loading = false;
                    //   this.loadingtext = '';
                    this.fetch();
                    this.formLock.suspend_reason = "";
                    dialog.value = false;
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        showLock: function (userId, member_name, member_email, member_phone) {
            this.formLock.userId = userId
            this.formLock.edit_name = member_name
            this.formLock.edit_email = member_email
            this.formLock.edit_phone = member_phone
            this.showDialogUpdate = true
        },
        showUnVer: function (userId, member_name, member_email, member_phone) {
            this.formLock.userId = userId
            this.formLock.edit_name = member_name
            this.formLock.edit_email = member_email
            this.formLock.edit_phone = member_phone
            this.unverdialog = true
        },
        showDetailId: function (userId) {
            this.formLock.userId = userId
            this.suspenddialog = true
        },
        showExpand: function (element, index) {
            const detailElement = document.getElementById(index);
            if (detailElement.classList.contains('hidden')) {
                element.target.classList.remove("mdi-chevron-right");
                element.target.classList.add("mdi-chevron-down");
                detailElement.classList.remove("hidden");
                //this.icons = "chevron-down";
            }
            else {
                element.target.classList.add("mdi-chevron-right");
                element.target.classList.remove("mdi-chevron-down");
                detailElement.classList.add("hidden");
                //this.icons = "chevron-right";
            }
        },
    },
    mounted() {
        this.$store.commit("member/setFormSwitch", false);
    },

    computed: {
        dateRangeText() {
            if (typeof this.form.dates != "undefined") {
                if (this.form.dates.length > 1) {
                    return this.form.dates.join(" ~ ");
                } else {
                    return this.form.dates[0];
                }
            } else {
                return null;
            }
        },
    },
    components: {},
};
</script>