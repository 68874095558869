<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-if="$store.state.member.member.pageIdCash == 'list'"></List>
      <Detail v-if="$store.state.member.member.pageIdCash == 'detail'"></Detail>
    </v-container>
  </div>
</template>

<script>
import List from "./idcash/list.vue";
import Detail from "./idcash/detail.vue";
export default { 
  data() {
    return {
      pages:"list",
      profiles:JSON.parse(localStorage.getItem("din-dashboard-profile")),
    }
    },
    mounted() {
      let segments      = window.location.toString();
      segments = segments.replace("http://","");
      segments = segments.replace("https://","");

      let segmentakhir = segments.split("/");

      let lastSegment = segmentakhir[(segmentakhir.length)-1];

      this.$store
          .dispatch("user/cekAkses", {
              segment: lastSegment,
              user: this.profiles.id,
          })
          .then(() => {
            if(this.$store.state.user.cekAkses <= 0){
                window.location = "/";
            }

          })
          .catch((res) => {
            window.location = "/";
            console.log(res);
      });

      this.$store.commit("member/setMemberPageIdCash", "list");
    },
    components: { List, Detail } 
};
</script>

<style>

</style>