<template>
    <div>

        <v-dialog transition="dialog-top-transition" max-width="900" v-model="showDialogBank">

            <template v-slot:default="dialog">
                <v-card>
                    <v-toolbar color="success" dark>Bank ACC & Images</v-toolbar>
                    <v-card-text>
                        <v-carousel hide-delimiters v-model="currentIndex">
                            <v-carousel-item v-for="(item, i) in imgItems" :key="i" :src="item.src" v-model="carousels">
                                <div style="position:relative; height:100%; width:100%;">
                                    <div
                                        style="position:absolute; font-size:21px; height:60px; padding:20px 0; width:100%; color:white; text-align:center; background:rgba(50,50,50,0.8); position:absolute; bottom:0; left:0;">
                                        <b>{{ item.title }}</b>
                                    </div>
                                </div>
                            </v-carousel-item>
                        </v-carousel>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </template>

        </v-dialog>

        <!-- <v-carousel hide-delimiters>
            <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src"></v-carousel-item>
        </v-carousel> -->

        <v-col md="12">
            <v-row>
                <v-col md="4">
                    <v-col md="12">
                        <v-file-input accept="image/*" placeholder="Buku Tabungan" prepend-icon="mdi-camera"
                            label="Buku Tabungan" v-model="$store.state.corporate.editform.editBukuTabungan"
                            v-if="$store.state.member.member.formStates == true"></v-file-input>
                        <div style="font-size:12px; text-align:right; margin-top:-10px;"> <small> <i> *) image size less than 1 mb </i> </small></div>
                    </v-col>
                    <v-col md="12" style="position:relative; cursor:pointer;" @click="openCarousel(0)">
                        <img v-bind:src="$store.state.member.member.listCorpDetail[0].tab_image"
                            style="width:100%; height:200px; object-fit:cover;" />
                        <div
                            style="position:absolute; height:40px; padding:10px 0; width:100%; color:white; text-align:center; background:rgba(50,50,50,0.8); position:absolute; bottom:0; left:0;">
                            <b>Buku Tabungan</b>
                        </div>
                    </v-col>
                </v-col>
                <v-col md="4">
                    <v-col md="12">
                        <v-file-input accept="image/*" placeholder="KTP Images" prepend-icon="mdi-camera"
                            label="KTP Images" v-model="$store.state.corporate.editform.editKtpImages"
                            v-if="$store.state.member.member.formStates == true"></v-file-input>
                        <div style="font-size:12px; text-align:right; margin-top:-10px;"> <small> <i> *) image size less than 1 mb </i> </small></div>
                    </v-col>
                    <v-col md="12" style="position:relative; cursor:pointer;" @click="openCarousel(1)">
                        <img v-bind:src="$store.state.member.member.listCorpDetail[0].ktp_image"
                            style="width:100%;  height:200px; object-fit:cover;" />
                        <div
                            style="position:absolute; height:40px; padding:10px 0; width:100%; color:white; text-align:center; background:rgba(50,50,50,0.8); position:absolute; bottom:0; left:0;">
                            <b>KTP Images</b>
                        </div>
                    </v-col>
                </v-col>
                <v-col md="4">
                    <v-col md="12">
                        <v-file-input accept="image/*" placeholder="Selfie Images" prepend-icon="mdi-camera"
                            label="Selfie Images" v-model="$store.state.corporate.editform.editSelfieImages"
                            v-if="$store.state.member.member.formStates == true"></v-file-input>
                        <div style="font-size:12px; text-align:right; margin-top:-10px;"> <small> <i> *) image size less than 1 mb </i> </small></div>
                    </v-col>
                    <v-col md="12" style="position:relative; cursor:pointer;" @click="openCarousel(2)">
                        <img v-bind:src="$store.state.member.member.listCorpDetail[0].slf_image"
                            style="width:100%;  height:200px; object-fit:cover;" />
                        <div
                            style="position:absolute; height:40px; padding:10px 0; width:100%; color:white; text-align:center; background:rgba(50,50,50,0.8); position:absolute; bottom:0; left:0;">
                            <b>Foto Selfie</b>
                        </div>
                    </v-col>
                </v-col>
                <v-col md="4">
                    <v-col md="12">
                        <v-file-input accept="image/*" placeholder="Rekening Koran" prepend-icon="mdi-camera"
                            label="Rekening Koran" v-model="$store.state.corporate.editform.editRekeningKoran"
                            v-if="$store.state.member.member.formStates == true"></v-file-input>
                        <div style="font-size:12px; text-align:right; margin-top:-10px;"> <small> <i> *) image size less than 1 mb </i> </small></div>
                    </v-col>
                    <v-col md="12" style="position:relative; cursor:pointer;" @click="openCarousel(3)">
                        <img v-bind:src="$store.state.member.member.listCorpDetail[0].rekeningkoran"
                            style="width:100%;  height:200px; object-fit:cover;" />
                        <div
                            style="position:absolute; height:40px; padding:10px 0; width:100%; color:white; text-align:center; background:rgba(50,50,50,0.8); position:absolute; bottom:0; left:0;">
                            <b>Rekening Koran</b>
                        </div>
                    </v-col>
                </v-col>
                <v-col md="4">
                    <v-col md="12">
                        <v-file-input accept="image/*" placeholder="Ijin Usaha" prepend-icon="mdi-camera"
                            label="Ijin Usaha" v-model="$store.state.corporate.editform.editIjinUsaha"
                            v-if="$store.state.member.member.formStates == true"></v-file-input>
                        <div style="font-size:12px; text-align:right; margin-top:-10px;"> <small> <i> *) image size less than 1 mb </i> </small></div>
                    </v-col>
                    <v-col md="12" style="position:relative; cursor:pointer;" @click="openCarousel(4)">
                        <img v-bind:src="$store.state.member.member.listCorpDetail[0].ijinusaha"
                            style="width:100%;  height:200px; object-fit:cover;" />
                        <div
                            style="position:absolute; height:40px; padding:10px 0; width:100%; color:white; text-align:center; background:rgba(50,50,50,0.8); position:absolute; bottom:0; left:0;">
                            <b>Ijin Usaha</b>
                        </div>
                    </v-col>
                </v-col>
            </v-row>
        </v-col>
    </div>
</template>

<script>
//import CustomCard from "../../../../../../components/CustomCard.vue";



export default {
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            panel: null,
            currentPage: 1,
            decimals: "10.00",
            icons: "chevron-right",
            showDialogUpdate: false,
            unverdialog: false,
            suspenddialog: false,
            dis_kab: true,
            dis_kec: true,
            dis_kel: true,
            curr_dis_kab: true,
            curr_dis_kec: true,
            curr_dis_kel: true,
            curr_dis_prov: true,
            currentIndex: 0,
            profiles: this.$store.state.auth.profile,
            detailsMember: this.$store.state.member.member.listCorpDetail[0],
            showDialogBank: false,
            imgItems: [{
                "title": "Buku Tabungan",
                "src": this.$store.state.member.member.listCorpDetail[0].tab_image,
            },
            {
                "title": "KTP Images",
                "src": this.$store.state.member.member.listCorpDetail[0].ktp_image,
            },
            {
                "title": "Foto Selfie",
                "src": this.$store.state.member.member.listCorpDetail[0].slf_image,
            },
            {
                "title": "Rekening Koran",
                "src": this.$store.state.member.member.listCorpDetail[0].rekeningkoran
            },
            {
                "title": "Ijin Usaha",
                "src": this.$store.state.member.member.listCorpDetail[0].ijinusaha
            }],
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],
            currkabupatenList: [],
            currkecamatanList: [],
            currkelurahanList: [],
            IndexKel: "",
            bankList: [],
            editAct: this.$store.state.member.member.formStates,
            tipe: [],
            loading: false,
            loadingText: "Loading Data...",
        };
    },
    methods: {
        openCarousel(index) {
            this.showDialogBank = true;
            this.currentIndex = index;
        }
    },
    mounted() {
    },

    computed: {
        dateRangeText() {
            if (typeof this.form.dates != "undefined") {
                if (this.form.dates.length > 1) {
                    return this.form.dates.join(" ~ ");
                } else {
                    return this.form.dates[0];
                }
            } else {
                return null;
            }
        },
    },
    components: {},
};
</script>