<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-if="$store.state.transaction.settlement.page == 'list'"></List>
      <Detail v-if="$store.state.transaction.settlement.page == 'detail'"></Detail>
    </v-container>
  </div>
</template>

<script>
import List from "./settlement/list.vue";
import Detail from "./settlement/detail.vue";
// export default { components: { List, Detail } };
// import List from "./thirdparty/list.vue";
// import Detail from "./thirdparty/detail.vue";
export default { 
  data() {
    return {
        pages:"list"
    }
    },
    mounted() {
        this.$store.commit("transaction/setMemberSettlePage", "list");
    },
    components: { List, Detail } 
};

</script>

<style>
</style>