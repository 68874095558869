<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-if="$store.state.transaction.updatestok.page == 'list'"></List>
      <Detail v-if="$store.state.transaction.updatestok.page == 'detail'"></Detail>
    </v-container>
  </div>
</template>

<script>

    import List from "./update_atmemas/list.vue";
    import Detail from "./update_atmemas/detail.vue";
    // export default { components: { List, Detail } }; 
    // import List from "./thirdparty/list.vue";
    // import Detail from "./thirdparty/detail.vue";
    export default { 
    data() 
        {
            return {
                pages:"list"
            }
        },
        mounted() {
            this.$store.commit("transaction/setMemberUpdateStokPage", "list");
        },
        components: { List, Detail } 
    };

</script>

<style>
</style>