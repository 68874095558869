import { apiFetchList , apiFetchAreaList } from "../../api/wilayah"

const namespaced = true

const defaultState = () => {
    return {
        wilayah: {
            list: [],
            detail: {},
            listDetail:[],
            listKabupaten:[],
            corplistKabupaten:[],
            listKecamatan:[],
            listKelurahan:[],
            currlistKabupaten:[],
            currlistKecamatan:[],
            currlistKelurahan:[],
            total:0,
            page: "list"
        },
        settlement: {
            list: [],
            detail: {}
        }
    }
}

const state = defaultState()

const mutations = {
    setProvList: (state, payload) => {
        state.wilayah.list = payload
    },
    setKabList: (state, payload) => {
        state.wilayah.listKabupaten = payload
        state.wilayah.corplistKabupaten = payload
    },
    setKecList: (state, payload) => {
        state.wilayah.listKecamatan = payload
    },
    setKelList: (state, payload) => {
        state.wilayah.listKelurahan = payload
    },
    setCurrKabList: (state, payload) => {
        state.wilayah.currlistKabupaten = payload
    },
    setCurrKecList: (state, payload) => {
        state.wilayah.currlistKecamatan = payload
    },
    setCurrKelList: (state, payload) => {
        state.wilayah.currlistKelurahan = payload
    }
}

const actions = {
    fetchWilayah({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchList(payload)
                .then((res) => {
                    //if (res.status == 200) {
                        commit('setProvList', res)
                        // state.member.total = res.total
                         console.log("ProvList",res)
                        resolve(res)
                    //}
                    //reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchAreaWilayah({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchAreaList(payload)
                .then((res) => {
                    //if (res.status == 200) {
                    if(res.module == "kabupaten"){
                        commit('setKabList', res.result);
                    }
                    else if(res.module == "kecamatan"){
                        commit('setKecList', res.result);
                    }
                    else if(res.module == "kelurahan"){
                        commit('setKelList', res.result);
                    }

                    if(res.module == "currkabupaten"){
                        commit('setCurrKabList', res.result);
                    }
                    else if(res.module == "currkecamatan"){
                        commit('setCurrKecList', res.result);
                    }
                    else if(res.module == "currkelurahan"){
                        commit('setCurrKelList', res.result);
                    }
                    // state.member.total = res.total
                    //console.log("ProvList",res)
                    resolve(res)
                    //}
                    //reject(res)
                }).catch(res => reject(res))
        })
    },
}

export default {
    namespaced, state, mutations, actions
}