<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->
     <v-btn color="success" small outlined @click="filterToggle">
                filter <v-icon>mdi-chevron-down</v-icon>
     </v-btn>

     <p></p>

    <CustomCard>

        <template v-slot:toolbar>

           <!-- <v-btn color="success" small outlined @click="filterToggle">
                filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn> -->
            <b> DATA BALANCE </b>

            &nbsp;

            <!-- <v-btn color="success" small outlined @click="filterToggle">
            filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn> -->

        </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.email"
                            label="Search By Email"
                            small
                            clearable
                        >
                        </v-text-field>
                    </v-flex>
                    
                </v-layout>

                

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>

                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">

            <v-data-table
                hide-default-footer
                disable-pagination
                disable-sort
                :headers="headers"
                :items="$store.state.settings.attributes.listCheckDataBalance"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

                <template  v-slot:[`item.aksi`]="{ item }">

                    <v-container>

                        <v-row>
                        
                        <v-dialog
                            transition="dialog-top-transition"
                            max-width="600">

                            <template v-slot:activator="{ on, attrs }">

                                <v-col
                                    class="pa-2 font-weight-bold"
                                    outlined
                                    tile>
                                        
                                        <v-icon small 
                                        v-bind="attrs"
                                        color="success"
                                        v-on="on"
                                        style="padding:5px; margin-top:-3px;"
                                        @click="editTemplate(item)">
                                            mdi-pencil
                                        </v-icon> 
                                        
                                </v-col>
                                <!-- <span>Email Not Verified</span> -->

                            </template>
                                    
                            <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar
                                        color="success"
                                        dark
                                        >Edit Custom Variable </v-toolbar>

                                        <v-layout style="display:none;">

                                            <v-text-field
                                                v-model="formLock.edit_index_template"
                                                label=""
                                                hidden
                                                >
                                            </v-text-field>

                                        </v-layout>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="formLock.edit_type"
                                                        label="type"
                                                        >
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="formLock.edit_code"
                                                        label="code"
                                                        >
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="formLock.edit_value"
                                                        label="value"
                                                        >
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>
                                        
                                    </v-container>
                                        

                                    <v-card-actions class="justify-end">
                                        
                                    <v-btn
                                        text
                                        @click="dialog.value = false"
                                    >Close</v-btn>
                                    <v-btn
                                        color="success"
                                        @click="editTemplatePost(dialog)"
                                    >Submit</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                                    
                        </v-dialog>

                        <v-dialog
                            transition="dialog-top-transition"
                            max-width="600">
                            
                            <template v-slot:activator="{ on, attrs }">

                            <v-btn
                                color="red"
                                icon
                                v-bind="attrs"
                                style="padding:5px"
                                v-on="on">
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>

                            </template>
                            <span>Delete Custom Variable Form</span>
                            <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                color="red"
                                dark
                                >Delete  Custom Variable ? </v-toolbar>
                                
                                <v-container>

                                    <v-layout>
                                        <v-flex>
                                            Yakin akan hapus Custom Variable <b> {{ item.type }} </b> ?
                                        </v-flex>
                                    </v-layout>

                                </v-container>

                                <v-card-actions class="justify-end">
                                    
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="red"
                                    style="color:#ffffff;"
                                    @click="deleteTemplate(dialog , item.id)"
                                >Submit</v-btn>
                                </v-card-actions>
                            </v-card>
                            </template>
                        </v-dialog>

                    </v-row>
            </v-container>

            </template>


            </v-data-table>

        </v-flex>
    </template>
  </CustomCard>

  <CustomCard style="margin-top:20px;">

        <template v-slot:toolbar>
            <b> DATA TRANSACTION </b>
        </template>

        

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">

            <v-data-table
                hide-default-footer
                disable-pagination
                disable-sort
                :headers="headersTrx"
                :items="$store.state.settings.attributes.listCheckDataTrx"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

            </v-data-table>

        </v-flex>
    </template>
  </CustomCard>

  <CustomCard style="margin-top:20px;">

        <template v-slot:toolbar>
            <b> DATA TRANSACTION IPAYMU </b>
        </template>

        

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">

            <v-data-table
                hide-default-footer
                disable-pagination
                disable-sort
                :headers="headersIpaymu"
                :items="$store.state.settings.attributes.listCheckDataTrxIpaymu"
                :options.sync="pagination"
                :server-items-length="$store.state.settings.attributes.totalCheckTrxBalance"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

            </v-data-table>

        </v-flex>
    </template>
  </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Id", value: "id" , align: "center"},
        { text: "User Id", value: "user_id" , align: "center"},
        { text: "Gramasi", value: "gramasi" , align: "center"},
        { text: "Incoming", value: "incoming" , align: "center"},
        { text: "OutGoing", value: "outgoing" , align: "center"},
        { text: "Conv Rate", value: "conv_rate" , align: "center"},
        { text: "Trx ID", value: "trx_id" , align: "center"},
        { text: "Gram Change", value: "gram_change" , align: "center"},
        { text: "Rate Id", value: "rate_id" , align: "center"},
        { text: "Type", value: "type" , align: "center"},
        { text: "Lock User", value: "lock_user" , align: "center"},
        { text: "Lock Dinaran", value: "lock_dinaran" , align: "center"},
        { text: "Lock Emas Impian", value: "lock_emasimpian" , align: "center"},
        { text: "Created At", value: "created_at" , align: "center" , width:150},
        { text: "Updated At", value: "updated_at" , align: "center" , width:150}
      ],
      headersTrx: [
        { text: "Id", value: "id" , align: "center"},
        { text: "Sender", value: "sender" , align: "center"},
        { text: "Receiver", value: "receiver" , align: "center"},
        { text: "Status", value: "status" , align: "center"},
        { text: "Type", value: "type" , align: "center"},
        { text: "Amount", value: "amount" , align: "center"},
        { text: "Gramasi", value: "gramasi" , align: "center"},
        { text: "Rate Id", value: "rate_id" , align: "center"},
        { text: "Comments", value: "comments" , align: "center"},
        { text: "Ref Id", value: "ref_id" , align: "center"},
        { text: "Created At", value: "created_at" , align: "center" , width:150},
        { text: "Updated At", value: "updated_at" , align: "center" , width:150}
      ],
      headersIpaymu:[
        { text: "Id", value: "id" , align: "center"},
        { text: "Trx Id", value: "trx_id" , align: "center"},
        { text: "Ipaymu Id", value: "ipaymu_id" , align: "center"},
        { text: "Ref Id", value: "ref_id" , align: "center"},
        { text: "Payment No", value: "payment_no" , align: "center"},
        { text: "Amount", value: "amount" , align: "center"},
        { text: "Received Amount", value: "received_amount" , align: "center"},
        { text: "Pay Method", value: "pay_method" , align: "center"},
        { text: "Pay Channel", value: "pay_channel" , align: "center"},
        { text: "Status", value: "status" , align: "center"},
        { text: "Expired At", value: "expired_at" , align: "center" , width:150},
        { text: "Created At", value: "created_at" , align: "center" , width:150},
        { text: "Updated At", value: "updated_at" , align: "center" , width:150}
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    editTemplate(items){
        this.formLock.edit_index_template = items.id
        this.formLock.edit_type           = items.type
        this.formLock.edit_code           = items.code
        this.formLock.edit_value          = items.value
        //alert(index);
    },
    add_data(){
        this.formLock.add_email = ""
        this.formLock.add_module = ""
    },
    editTemplatePost(dialog){
        //alert("posted");
        this.$store
            .dispatch("settings/editCustomVar", {
                type: this.formLock.edit_type,
                code: this.formLock.edit_code,
                value: this.formLock.edit_value,
                index: this.formLock.edit_index_template
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    addSubmitVar(dialog){
        this.$store
            .dispatch("settings/addSubmitVar", {
                //find: params,
                type: this.formLock.custom_type,
                code: this.formLock.custom_code,
                value: this.formLock.custom_value
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    deleteTemplate(dialog , index){
         this.$store
            .dispatch("settings/deleteCustomVar", {
                //find: params,
                id: index,
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
            });
    },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      //alert("limits "+this.pagination.itemsPerPage);
      
      this.$store
        .dispatch("settings/fetchCheckData", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            // email:this.searchForm.email,
            email:this.searchForm.email,
            logId:this.profiles.id
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
    //this.fetchTotal("");
   // alert(this.$store.state.settings.attributes.totalCheckBalance);
  },
  watch: {
    pagination() {
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            this.fetch(0)
    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>