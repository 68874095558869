<template>
  <div>
    <v-container grid-list-xs fluid>
      <!-- <List v-if="$store.state.transaction.hisgold.page == 'list'"></List> -->
      <Detail></Detail>
    </v-container>
  </div>
</template>

<script>
//import List from "./manual/list.vue";
import Detail from "./manual/detail.vue";
// export default { components: { List, Detail } };
// import List from "./thirdparty/list.vue";
// import Detail from "./thirdparty/detail.vue";
export default { 
  data() {
    return {
        pages:"list"
    }
    },
    mounted() {
        this.$store.commit("transaction/setMemberHisGoldPage", "list");
    },
    components: { Detail } 
};

</script>

<style>
</style>