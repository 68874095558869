import { apiFetchAffiliate , AddPromoPostAffiliate , apiPostAffiliate , apiPromoListAffiliate , apiRewardPostAffiliate , AddRewardPostAffiliate , apiTransaksiPostAffiliate } from "../../api/affiliate"

//import fileDownload from 'js-file-download';

const namespaced = true

const defaultState = () => {
    return {
        user: {
            list: [],
            detail: [],
            trxList:[],
            status:"",
            total:0,
            page: "list"
        },
        reward: {
            list: [],
            detail: [],
            trxList:[],
            status:"",
            total:0,
            page: "list"
        },
        transaksi: {
            list: [],
            detail: [],
            trxList:[],
            status:"",
            total:0,
            page: "list"
        },
        promo:{
            list: [],
            detail: [],
            trxList:[],
            status:"",
            total:0,
            page: "list"
        }
    }
}

const state = defaultState()

const mutations = {
    setAffiliatesPages: (state, payload) => {
        state.user.page = payload
    },
    setAffiliatesList: (state, payload) => {
        state.user.list = payload
    },
    setAffiliatesDetail: (state, payload) => {
        state.user.page = payload
    },
    setAffiliatesTotal: (state, payload) => {
        state.user.total = payload
    },
    setAffiliatesSuccess: (state, payload) => {
        state.user.status = payload
    },
    setAffiliatesRewardMember: (state, payload) => {
        state.reward.list = payload
    },
    setAffiliatesRewardTotal: (state, payload) => {
        state.reward.total = payload
    },
    setAffiliatesTransaksiPages: (state, payload) => {
        state.transaksi.page = payload
    },
    setAffiliatesTransaksiList: (state, payload) => {
        state.transaksi.list = payload
    },
    setAffiliatesTransaksiTotal: (state, payload) => {
        state.transaksi.total = payload
    },
    setAffiliatesPromoList: (state, payload) => {
        state.promo.list = payload
    },
    setAffiliatesPromoTotal: (state, payload) => {
        state.promo.total = payload
    },
    setAffiliatesPromoStatus: (state, payload) => {
        state.promo.status = payload
    },
}

const actions = {
    fetchListAffiliateMember({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchAffiliate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        // commit('setAffiliatesPages' , 'list');
                        commit('setAffiliatesList', res.data);
                        //console.log("data" , res.data);
                        //commit('setAffiliatesDetail', res.data);
                        commit('setAffiliatesTotal' , res.total)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postAffiliate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPostAffiliate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setAffiliatesSuccess', "success");

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchRewardAffiliate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiRewardPostAffiliate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setAffiliatesRewardMember', res.data);
                        commit('setAffiliatesRewardTotal', res.total);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    AddRewardPostAffiliate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AddRewardPostAffiliate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setAffiliatesRewardMember', res.data);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchTransaksiAffiliate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiTransaksiPostAffiliate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setAffiliatesTransaksiList', res.data.data);
                        commit('setAffiliatesTransaksiTotal', res.data.total);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchPromoAffiliate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPromoListAffiliate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setAffiliatesPromoList', res.data.data);
                        commit('setAffiliatesPromoTotal', res.data.total);

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    AddPromoPostAffiliate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            AddPromoPostAffiliate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        //commit('setAffiliatesPromoList', res.data.data);
                        //commit('setAffiliatesPromoTotal', res.data.total);
                        commit('setAffiliatesPromoStatus' , "success");

                        resolve(res)
                    }
                    reject(res)
            }).catch(res => reject(res))
        })
    },
}

export default {
    namespaced, state, mutations, actions
}