<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

<CustomCard>

    <template v-slot:toolbar>
        <v-dialog
            transition="dialog-top-transition"
            max-width="600">

            <template v-slot:activator="{ on, attrs }">

            <div style="width:100%;">

                <div style="float:left;">

                    <v-col
                        class="pa-2 font-weight-bold"
                        outlined
                        tile>
                            <v-btn color="success" small outlined @click="filterToggle" style="float:left;">
                                filter <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                    </v-col>

                </div>

                <div style="float:right;">

                    <v-col
                        class="pa-2 font-weight-bold"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @click="add_data()"
                        tile>
                            <v-btn color="success" small outlined>
                                <v-icon>mdi-plus</v-icon> Add Notification
                            </v-btn>        
                    </v-col>

                </div>

                
            </div>

                

            </template>
                            
            <template v-slot:default="dialog">

                <v-card>
                    <v-toolbar
                    color="success"
                    dark>
                        Add Notification  
                </v-toolbar>

                        <v-container>

                            <v-layout>

                                <v-flex>

                                    <v-autocomplete
                                        v-model="searchForm.notifikasi"
                                        @change="(event) => showElement()"
                                        :items="notifikasi"
                                        class="pad10"
                                        item-text="text"
                                        item-value="value"
                                        label="Notifikasi"
                                    >
                                    </v-autocomplete>

                                </v-flex>

                                <v-flex>

                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :return-value.sync="date"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="searchForm.date"
                                            label="Publish Date"
                                            class="pad10"
                                            prepend-icon="mdi-calendar"
                                            clearable
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="searchForm.date"
                                        no-title
                                        scrollable
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menu = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(date)"
                                        >
                                            OK
                                        </v-btn>
                                        </v-date-picker>
                                    </v-menu>

                                </v-flex>

                            </v-layout>

                            <v-layout>

                                <v-flex>

                                    <v-text-field 
                                        v-model="searchForm.tipe"
                                        :class="hidden1"
                                        label="Title"
                                        small
                                        clearable>
                                    </v-text-field>

                                    <v-text-field 
                                        v-model="searchForm.url_images"
                                        :class="hidden2"
                                        label="Url Images"
                                        small
                                        clearable>
                                    </v-text-field>

                                </v-flex>

                                <v-flex>

                                    <v-autocomplete
                                        class="pad10"
                                        v-model="searchForm.status_aktif"
                                        :items="status_aktif"
                                        item-text="text"
                                        item-value="value"
                                        label="Status Aktif">
                                    </v-autocomplete>

                                </v-flex>

                            </v-layout>

                            <v-layout>

                                <v-flex>

                                    <v-textarea
                                        v-model="searchForm.message"
                                        :class="hidden1"
                                        outlined
                                        name="input-7-4"
                                        label="Message"
                                        value="">
                                    </v-textarea>

                                        <v-text-field 
                                            :class="hidden2"
                                            v-model="searchForm.url"
                                            label="Url"
                                            small
                                        clearable>
                                    </v-text-field>

                                </v-flex>

                            </v-layout>
                            
                        </v-container>
                                

                                <v-card-actions class="justify-end">
                                    
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="success"
                                    @click="save_data(dialog)"
                                >Submit Add</v-btn>
                                </v-card-actions>
                            </v-card>
                    </template>

            </v-dialog>

            &nbsp;

            <!-- <v-btn color="success" small outlined @click="filterToggle">
            filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn> -->

        </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                    <v-flex>

                        <v-autocomplete
                            v-model="searchForm.search_notif"
                            :items="notifikasi"
                            item-text="text"
                            item-value="value"
                            label="Search By Type"
                        >
                        </v-autocomplete>

                    </v-flex>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.search_title"
                            label="Search By Title"
                            small
                            clearable
                        >
                        </v-text-field>
                    </v-flex>    

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.search_deskripsi"
                            label="Search By Deskripsi"
                            small
                            clearable
                        >
                        </v-text-field>
                    </v-flex>    
                    
                </v-layout>

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>

                

                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">

            <v-data-table
                :headers="headers"
                :items="$store.state.webadmin.attributes.listNotifyApp"
                :options.sync="pagination"
                :server-items-length="$store.state.webadmin.attributes.totalNotify"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

                    <template  v-slot:[`item.content`]="{ item }">
                        


                        <div  style="padding:15px;" v-if="item.jenis != 'popup'">

                            <b>Title:</b>
                            <br />
                            {{ item.title }}
                            
                            <p>&nbsp;</p>

                            <b>Message:</b>
                            <br />
                            {{ item.message }}

                        </div>

                        <div v-if="item.jenis == 'popup'">

                             <v-dialog
                                v-model="dialogImage"
                                width="500"
                                >

                                <template v-slot:activator="{ on, attrs }">

                                    <img v-bind:src="item.img_url" style="width:150px; padding:10px;"  v-bind="attrs"
                                    v-on="on" />

                                </template>

                                <v-card>
                                    <v-card-title class="text-h5 primary-colors text-light" style="color:#FFF;">
                                     Image Detail
                                    </v-card-title>

                                    <v-card-text style="margin-top:20px;">
                                       <img v-bind:src="item.img_url" style="width:100%;" />
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                        </div>
                        
                    </template>

                    <template  v-slot:[`item.status_act`]="{ item }">

                        <span v-if="item.status == 1">
                            <v-icon small color="green">mdi-check</v-icon> Aktif
                        </span>

                        <span v-if="item.status == 0">
                            <v-icon small color="green">mdi-check</v-icon> Aktif
                        </span>

                    </template>

                    <template  v-slot:[`item.actions`]="{ item }">

                        <!-- <v-icon>mdi-pencil</v-icon>  -->

                        <v-dialog
            transition="dialog-top-transition"
            max-width="600">

            <template v-slot:activator="{ on, attrs }">

                <v-col
                    class="pa-2 font-weight-bold"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="edit_data_popup(item)"
                    tile>
                            <v-icon>mdi-pencil</v-icon> 
                </v-col>

            </template>
                            
            <template v-slot:default="dialog">

                <v-card>
                    <v-toolbar
                    color="success"
                    dark>
                        Edit Notification  
                </v-toolbar>

                        <v-container>

                            <v-layout>

                                <v-flex>

                                    <v-text-field 
                                        v-model="searchForm.edit_index"
                                        style="display:none;"
                                        label="Edit Index">
                                    </v-text-field>

                                    <v-autocomplete
                                        v-model="searchForm.edit_notifikasi"
                                        @change="(event) => showElement()"
                                        :items="notifikasi"
                                        class="pad10"
                                        item-text="text"
                                        item-value="value"
                                        label="Notifikasi"
                                    >
                                    </v-autocomplete>

                                </v-flex>

                                <v-flex>

                                    <v-menu
                                        ref="menusave"
                                        v-model="menusave"
                                        :close-on-content-click="true"
                                        :return-value.sync="date4"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="searchForm.edit_date"
                                            label="Publish Date"
                                            class="pad10"
                                            prepend-icon="mdi-calendar"
                                            clearable
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="searchForm.edit_date"
                                        no-title
                                        scrollable
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menusave = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menusave.save(date4);"
                                        >
                                            OK
                                        </v-btn>
                                        </v-date-picker>
                                    </v-menu>

                                </v-flex>

                            </v-layout>

                            <v-layout>

                                <v-flex>

                                    <v-text-field 
                                        v-model="searchForm.edit_tipe"
                                        :class="hidden1"
                                        label="Title"
                                        small
                                        clearable>
                                    </v-text-field>

                                    <v-text-field 
                                        v-model="searchForm.edit_url_images"
                                        :class="hidden2"
                                        label="Url Images"
                                        small
                                        clearable>
                                    </v-text-field>

                                </v-flex>

                                <v-flex>

                                    <v-autocomplete
                                        class="pad10"
                                        v-model="searchForm.edit_status_aktif"
                                        :items="status_aktif"
                                        item-text="text"
                                        item-value="value"
                                        label="Status Aktif">
                                    </v-autocomplete>

                                </v-flex>

                            </v-layout>

                            <v-layout>

                                <v-flex>

                                    <v-textarea
                                        v-model="searchForm.edit_message"
                                        :class="hidden1"
                                        outlined
                                        name="input-7-4"
                                        label="Message"
                                        value="">
                                    </v-textarea>

                                        <v-text-field 
                                            :class="hidden2"
                                            v-model="searchForm.edit_url"
                                            label="Url"
                                            small
                                        clearable>
                                    </v-text-field>

                                </v-flex>

                            </v-layout>
                            
                        </v-container>
                                

                                <v-card-actions class="justify-end">
                                    
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="success"
                                    @click="edit_post_data(dialog)"
                                >Submit Add</v-btn>
                                </v-card-actions>
                            </v-card>
                    </template>

                </v-dialog>

                    </template>
                    
            </v-data-table>

        </v-flex>
    </template>
  </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      hidden1:"pad10",
      hidden2:"hiddenpad",
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      status_aktif: [
            { text: "Tidak Aktif", value: 0 },
            { text: "Aktif", value: 1 }
            // { text: "Gagal", value: "2" },
      ],
      notifikasi: [
            { text: "Notification", value: "notification" },
            { text: "PopUp", value: "popup" },
            { text: "IdCash", value: "idcash" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        notifikasi:"notification",
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Jenis", value: "jenis" , width:120 , align: "left"},
        { text: "Content" , value:"content" , width:400, align: "left"},
        { text: "Url", value: "url" , align: "left"},
        { text: "Publish", value: "publish" , width:120, align: "left"},
        { text: "Status", value: "status_act" , align: "left"},
        { text: "Aksi", value: "actions" , align: "left" }
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    editTemplate(items){
        this.formLock.edit_index_template = items.id
        this.formLock.edit_email = items.member.email
        this.formLock.edit_module = items.modul
        //alert(index);
    },
    add_data(){
        this.searchForm.notifikasi = "notification"
        this.searchForm.date = ""
        this.searchForm.tipe = ""
        this.searchForm.url_images = ""
        this.searchForm.status_aktif = ""
        this.searchForm.message = ""
        this.searchForm.url = ""
    },
    edit_data_popup(items){
        // alert(items.status);
        if(items.jenis == "popup"){
            this.hidden2 = "pad10";
            this.hidden1 = "hiddenpad";
        }
        else{
            this.hidden1 = "pad10";
            this.hidden2 = "hiddenpad";
        }
        
        this.searchForm.edit_notifikasi = items.jenis
        this.searchForm.edit_date = items.publish
        this.searchForm.edit_tipe = items.title
        this.searchForm.edit_url_images = items.img_url
        this.searchForm.edit_status_aktif = items.status
        this.searchForm.edit_message = items.message
        this.searchForm.edit_url = items.url
        this.searchForm.edit_index = items.id
    },
    showElement(){
       // alert("tes");
        if(this.searchForm.notifikasi == "popup"){
            this.hidden2 = "pad10";
            this.hidden1 = "hiddenpad";
        }
        else{
            this.hidden1 = "pad10";
            this.hidden2 = "hiddenpad";
        }
    },
    save_data(dialog){
        this.$store
            .dispatch("webadmin/postAddNotify", {
                //find: params,
                notifikasi: this.searchForm.notifikasi,
                date: this.searchForm.date,
                tipe: this.searchForm.tipe,
                url_images: this.searchForm.url_images,
                status_aktif: this.searchForm.status_aktif,
                message: this.searchForm.message,
                url: this.searchForm.url
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    edit_post_data(dialog){
        this.$store
            .dispatch("webadmin/editAddNotify", {
                //find: params,
                id:this.searchForm.edit_index,
                notifikasi: this.searchForm.edit_notifikasi,
                date: this.searchForm.edit_date,
                tipe: this.searchForm.edit_tipe,
                url_images: this.searchForm.edit_url_images,
                status_aktif: this.searchForm.edit_status_aktif,
                message: this.searchForm.edit_message,
                url: this.searchForm.edit_url
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    fetch(cari) {
      this.dialogImage = false
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      this.$store
        .dispatch("webadmin/fetchWebNotifyApp", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            notif:this.searchForm.search_notif,
            title:this.searchForm.search_title,
            deskripsi:this.searchForm.search_deskripsi,
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
    //this.fetchTotal("");
    // this.searchForm.notifikasi = 0;
  },
  watch: {
    pagination() {
        this.dialogImage = false
        //alert(this.$store.state.member.member.is_back);
        this.fetch(0)
    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
    itemsWithIndex() {
      return this.$store.state.webadmin.attributes.listMedia.map(
        (items, index) => ({
          ...items,
          no:index
        }))
    }
  },
  components: { CustomCard },
};
</script>

<style>
</style>