<template>
    <div>
        <div v-if="$store.state.member.member.formStates == true">
            <v-container>

                <v-row>
                    <v-col class="12" style="margin-bottom:20px; padding-left:25px;   border-bottom:1px solid #dddddd;">
                        <h3 style="font-size:24px;"> Informasi Bisnis/Usaha </h3>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Nama Usaha
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field style="display:none;" v-model="searchForm.editIndexCorporate"
                                        label="id" single-line small clearable>
                                    </v-text-field>

                                    <v-text-field v-model="$store.state.corporate.editform.editNamaUsaha" label=""
                                        single-line small clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>


                    <v-col sm="12" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Email
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field v-model="$store.state.corporate.editform.corp_email" label=""
                                        single-line small clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                    <v-col sm="12" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Phone Num
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field v-model="$store.state.corporate.editform.phone_num" label=""
                                        single-line small clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                    <v-col sm="12" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Website Utama
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field v-model="$store.state.corporate.editform.websiteUtama" label=""
                                        single-line small clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                </v-row>

                    <v-row>
                        <v-col class="12" style="margin-bottom:20px; padding-left:25px;   border-bottom:1px solid #dddddd;">
                            <h3 style="font-size:24px;"> Informasi Lokasi Corporate </h3>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col sm="6" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Provinsi
                                    </v-col>
                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-autocomplete v-model="$store.state.corporate.editform.corporateprovinsi"
                                            @change="(event) => getWilayah('kabupaten')" single-line
                                            :items="$store.state.wilayah.wilayah.list" item-text="provinsi" item-value="provinsi" label="">
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>

                            </v-col>

                        </v-col>

                        <v-col sm="6" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Kabupaten
                                    </v-col>

                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-autocomplete v-model="$store.state.corporate.editform.editCorporateKabupaten" 
                                            :disabled="$store.state.corporate.editform.dis_kab"
                                            :items="$store.state.corporate.editform.kabupatenList" 
                                            single-line 
                                            item-text="kabupaten"
                                            item-value="kabupaten" 
                                            label="">
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-col>

                        <v-col sm="6" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Alamat Corporate
                                    </v-col>

                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-text-field v-model="$store.state.corporate.editform.editCorporateAlamat" label=""
                                            single-line small clearable>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-col>

                        <v-col sm="6" md="6">

                            <v-col class="pa-2" outlined tile>

                                <v-row no-gutters>
                                    <v-col class="pa-2 font-weight-bold" outlined tile>
                                        Kode Post
                                    </v-col>

                                </v-row>

                                <v-row class="" no-gutters>
                                    <v-col class="pa-2 martop-min" outlined tile>
                                        <v-text-field v-model="$store.state.corporate.editform.editCorporateKodePost" label=""
                                            single-line small clearable>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-col>

                    </v-row>

                <v-row>
                    <v-col class="12" style="margin-bottom:20px; padding-left:25px;   border-bottom:1px solid #dddddd;">
                        <h3 style="font-size:24px;"> Informasi Rekening Bank </h3>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Nama Bank
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>

                                    <v-autocomplete v-model="$store.state.corporate.editform.editNamaBank"
                                        :items="itemsWithBank" @change="getBankValue()" single-line
                                        item-text="bank_name" item-value="bankValue" label="">
                                    </v-autocomplete>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                    <v-col sm="5" md="6">
                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Atas Nama ( Rekening Bank )
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field v-model="$store.state.corporate.editform.editAtasNamaRek" label=""
                                        single-line small clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-col>

                </v-row>

                <v-row no-gutters>

                    <v-col sm="12" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>
                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Nomor Rekening
                                </v-col>

                            </v-row>

                            <v-row class="" no-gutters>
                                <v-col class="pa-2 martop-min" outlined tile>
                                    <v-text-field v-model="$store.state.corporate.editform.editNoRekening" label=""
                                        single-line small clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                </v-row>

            </v-container>

        </div>

        <div v-if="$store.state.member.member.formStates == false">

            <v-container>

                <v-row>
                    <v-col class="12" style="margin-bottom:20px; padding-left:25px; border-bottom:1px solid #dddddd;">
                        <h3 style="font-size:24px;"> Informasi Usaha </h3>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Nama Usaha
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].namausaha }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>


                    <!-- <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Ijin Usaha
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].ijinusaha }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col> -->

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Website
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].website }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>


                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Email
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].corp_email }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    No.Telp
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].phone_num }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                </v-row>

                <v-row>
                    <v-col class="12" style="margin-bottom:20px; padding-left:25px;   border-bottom:1px solid #dddddd;">
                        <h3 style="font-size:24px;"> Informasi Alamat </h3>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Provinsi
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].provinsicorp }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>


                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kabupaten
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].kabupatencorp }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>


                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Kode Post
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].kode_postcorp }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Alamat Corporate
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].alamatcorp }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="12" style="margin-bottom:20px; padding-left:25px;   border-bottom:1px solid #dddddd;">
                        <h3 style="font-size:24px;"> Informasi Bank Account </h3>
                    </v-col>
                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Bank Name
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].bank_name_detail }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>


                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Bank Acc. Holder
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].bank_acc_holder }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>


                </v-row>

                <v-row no-gutters>

                    <v-col sm="5" md="6">

                        <v-col class="pa-2" outlined tile>

                            <v-row no-gutters>

                                <v-col class="pa-2 font-weight-bold" outlined tile>
                                    Bank Acc. No.
                                </v-col>

                                <v-col class="pa-2 text-right" outlined tile>
                                    {{ $store.state.member.member.listCorpDetail[0].bank_acc_number }}
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-col>

                </v-row>

            </v-container>

        </div>
    </div>
</template>

<script>
//import CustomCard from "../../../../../../components/CustomCard.vue";



export default {
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            panel: null,
            currentPage: 1,
            decimals: "10.00",
            icons: "chevron-right",
            showDialogUpdate: false,
            unverdialog: false,
            suspenddialog: false,
            dis_kab: true,
            dis_kec: true,
            dis_kel: true,
            curr_dis_kab: true,
            curr_dis_kec: true,
            curr_dis_kel: true,
            curr_dis_prov: true,
            detailsMember: this.$store.state.member.member.listCorpDetail[0],
            profiles: this.$store.state.auth.profile,
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],
            currkabupatenList: [],
            currkecamatanList: [],
            currkelurahanList: [],
            IndexKel: "",
            bankList: [],
            editAct: this.$store.state.member.member.formStates,
            tipe: [],
            menu: null,
            form: {},
            tab: null,
            items: [
                'Member Detail', 'Corporate Detail'
            ],
            searchForm: {
                member: ''
            },
            pagination: {},
            formLock: {

            },
            headers: [
                { text: "Name", value: "name" },
                { text: "Email", value: "new_email" },
                { text: "Phone", value: "phone" },
                { text: "Reg Date", value: "verified_date" },
                { text: "E-Ver", value: "em_verified" },
                { text: "M-Ver", value: "verified" },
                { text: "Action", align: "center", value: "action" },
            ],
            gender: [
                { text: "All Gender", value: "" },
                { text: "Laki-Laki", value: "Laki-Laki" },
                { text: "Perempuan", value: "Perempuan" },
                // { text: "Gagal", value: "2" },
            ],
            religion: [
                { text: "All Religion", value: "" },
                { text: "Hindu", value: "Hindu" },
                { text: "Katolik", value: "Katolik" },
                { text: "Kristen", value: "Kristen" },
                { text: "Buddha", value: "Buddha" },
                { text: "Islam", value: "Islam" },
                { text: "Konghucu", value: "Konghucu" }
                // { text: "Gagal", value: "2" },
            ],
            loading: false,
            loadingText: "Loading Data...",
        };
    },
    methods: {
        getBankValue() {
          //  alert("Values = " + this.$store.state.corporate.editform.editNamaBank);
        },
        fetch() {
            this.loading = true;
            this.loadingtext = 'Loading... Please wait';
            this.$store.commit("main/setLoading", true);
            this.$store
                .dispatch("member/fetchListMemberCorpDetail", {
                    id_member: this.$store.state.member.member.detail,
                })
                .then(() => {
                    //alert("tes-dd");
                    //console.log("itemsfetch", this.$store.state.member.member.listDetail[0]);
                    this.$store.commit("main/setLoading", false);
                    this.loading = false;
                    this.loadingtext = '';
                    this.detailsMember = this.$store.state.member.member.listCorpDetail[0];
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        filterToggle() {
            if (this.panel == null)
                this.panel = [...Array(this.items).keys()].map((k, i) => i);
            else this.panel = null;
        },
        showDetail(item) {
            this.$store.commit("transaction/setMemberCorpPage", "detail");
            this.$store.commit("transaction/setMemberDetail", item);
            //this.detailsName = "New Member";
        },
        showList() {
            this.$store.commit("member/setMemberCorpPage", "list");
            this.$store.state.member.member.is_back = 1;
            //   this.$store.commit("transaction/setMemberDetail", item);
        },
        showListMember() {
            this.fetch();
            this.$store.commit("member/setFormSwitch", false);
        },
        editVerification: function () {
            this.$store.commit("main/setLoading", true);
            this.$store
                .dispatch("member/editMemberVerification", {
                    updateId: this.profiles.id,
                    userId: this.searchForm.editId,
                    nik: this.searchForm.editNik,
                    provinsi: this.searchForm.editProvinsi,
                    kabupaten: this.searchForm.editKabupaten,
                    kecamatan: this.searchForm.editKecamatan,
                    kelurahan: this.searchForm.editKelurahan,
                    birthplace: this.searchForm.editBirthPlace,
                    religion: this.searchForm.editReligion,
                    birthdate: this.searchForm.date_of_birth,
                    gender: this.searchForm.editGender,
                    address: this.searchForm.editAddress,
                    kodepost: this.searchForm.editKodePos,
                    bankname: this.searchForm.editBankName,
                    bankaccnumber: this.searchForm.editBankAccNo,
                    bankaccholder: this.searchForm.editBankAccHolder,
                    curprovinsi: this.searchForm.editCurrentProvinsi,
                    curkecamatan: this.searchForm.editCurrKecamatan,
                    curkelurahan: this.searchForm.editCurrKelurahan,
                    curkabupaten: this.searchForm.editCurrKabupaten,
                    curaddress: this.searchForm.editBankCurrentAddress
                })
                .then(() => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarSuccess", "Update Data Member Successfull");
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        getWilayah(value) {
            if (value == "kabupaten") {
                this.$store.state.corporate.editform.dis_kab = false;
            }

            this.$store
                .dispatch("wilayah/fetchAreaWilayah", {
                    find: value,
                    provinsi: this.$store.state.corporate.editform.corporateprovinsi,
                    kabupaten: this.$store.state.corporate.editform.editCorporateKabupaten
                })
                .then(() => {
                    if (value == "kabupaten") {
                        this.$store.state.corporate.editform.dis_kab = false;
                        this.$store.state.corporate.editform.kabupatenList = this.$store.state.wilayah.wilayah.listKabupaten;
                    }
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        editProfiles: function (dialog) {
            this.$store
                .dispatch("member/editProfiles", {
                    userId: this.formLock.userId,
                    edit_name: this.formLock.edit_name,
                    edit_email: this.formLock.edit_email,
                    updateId: this.profiles.id,
                    edit_phone: this.formLock.edit_phone
                })
                .then(() => {
                    //   this.$store.commit("main/setLoading", false);
                    //   this.loading = false;
                    //   this.loadingtext = '';
                    this.fetch();
                    dialog.value = false;
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
    },
    mounted() {
        //this.fetchProv("");
    },
    computed: {
        dateRangeText() {
            if (typeof this.form.dates != "undefined") {
                if (this.form.dates.length > 1) {
                    return this.form.dates.join(" ~ ");
                } else {
                    return this.form.dates[0];
                }
            } else {
                return null;
            }
        },
        itemsWithBank() {
            return this.$store.state.bank.bank.listBank.map(
                (items, index) => ({
                    ...items,
                    index: index + 1,
                    bankValue: items.bank_name + "#" + items.bank_id
                }))
        },
    },
    components: {},
};
</script>
