import {  ApiRefferalList , apiVerifyCorporate , ApiRegisterExistingCorporate , ApiTrxRefferalList , ApiAddCorporate , ApiRegisterCorporate, ApiEditCorporate  } from "../../api/corporate"
 
const namespaced = true

const defaultState = () => {
    return {
        attributes: {
            listEmail: [],
            detail: {},
            listDetail:[],
            listEmailTemplate:[],
            listAdminModule:[],
            e1:1,
            page: "list",
            pageCustom:"list",
            pageCheck:"list",
            status:""
        },
        form:{
            name:"",
            email: "",
            phone: "",
            password: "",
            nik: "",
            gender: "",
            kode_pos:"",
            date:"",
            address:"",
            religion: "",
            negara:"",
            birth_place: "",
            date_of_birth: "",
            provinsi:"",
            kabupaten: "",
            kecamatan: "",
            kelurahan: "",
            img_ktp: "",
            img_selfie: "",
            img_bank: "",
            rekeningkoran: "",
            bank_acc_no:"",
            bank_name:"",
            bank_acc_holder:"",
            namausaha:"",
            website:"",
            ijin_perusahaan:""
        },
        editform:{
            editNik:"",
            provinsi:"",
            dis_kab:"",
            kabupatenList: [],
        },
        referral: {
            page: "list",
            total:0,
            list:[],
            detail:[],
            listFitur:[],
            totalFitur:0,
            marketList:[],
        },
        trx_referral: {
            page: "list",
            total:0,
            list:[],
            detail:[],
            listFitur:[],
            totalFitur:0,
            marketList:[],
        },
        settlement: {
            list: [],
            detail: {}
        }
    }
}

const state = defaultState()

const mutations = {
    setCorpFormState: (state, payload) => {
        state.attributes.e1 = payload
    },
    setCorporate: (state, payload) => {
        state.attributes.listEmail = payload
    },
    setStatus: (state, payload) => {
        state.attributes.status = payload
    },
    setPage: (state, payload) => {
        state.attributes.page = payload
    },
    setReferral: (state, payload) => {
        state.referral.list = payload
    },
    setTotalReferral: (state, payload) => {
        state.referral.total = payload
    },
    setTrxReferral: (state, payload) => {
        state.trx_referral.list = payload
    },
    setTrxTotalReferral: (state, payload) => {
        state.trx_referral.total = payload
    }
}

const actions = {
    fetchRefferalList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiRefferalList(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setReferral', res.referral);
                        // alert(res.total);
                        console.log("referral_list" , res.referral);

                        state.referral.total = res.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    verifyCorporate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiVerifyCorporate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('status', "success");
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchTrxReferral({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiTrxRefferalList(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setTrxReferral', res.trx);
                        // alert(res.total);
                        console.log("referral_list" , res.trx);

                        state.trx_referral.total = res.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    tambahCorporate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiAddCorporate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('status', "success");
                        // alert(res.total);
                        //console.log("referral_list" , res.trx);

                        //state.trx_referral.total = res.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editCorporate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiEditCorporate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('status', "success");
                        // alert(res.total);
                        //console.log("referral_list" , res.trx);

                        //state.trx_referral.total = res.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    registerNewCorporate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiRegisterCorporate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('status', "success");
                        // alert(res.total);
                        //console.log("referral_list" , res.trx);

                        //state.trx_referral.total = res.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    registerExistingToCorporate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiRegisterExistingCorporate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('status', "success");
                        // alert(res.total);
                        //console.log("referral_list" , res.trx);

                        //state.trx_referral.total = res.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
}

export default {
    namespaced, state, mutations, actions
}