<template>
    <div>
        <v-footer app class="pa-1 footer">
            <v-col class="text-center footerStyle" cols="12">
                2022 — Dinaran.id - PT. Ciptalintang Aji Dana. All Rights Reserved. version 2.0.0
            </v-col>
        </v-footer>
    </div>
</template>

<script>
    export default {

    }
</script>

<style>

.v-navigation-drawer--fixed {
    z-index: 1;
}

.header-jadwal-table{
    background-color:#F5F5F5;
}

.padLeft20{
    padding-left:20px!important;
}

.pad20{
    padding:20px 0!important;
    border-top:1px solid #DDD; 
    border-bottom:1px solid #DDD; padding:15px 0;
}

.padnoBorder20{
    padding:20px 0!important;
}


.hidden{
    display:none;
}

.hiddenpad{
    padding:10px;
    display:none;
}

.pad10{
    padding:10px;
}

.rowStyles{
    padding:15px 0;
    border-bottom:1px solid #ddd;
}

.sub-items-tables{
    margin-left:20px;
}

.primary-colors{
    background:rgb(16, 121, 72)!important;
}

.no-pad-left{
    padding-left:0!important; 
    margin-left:0!important;
}

.martop-min{
    margin-top:-25px;
}
</style>