<template>
    <div>
        <v-layout row wrap style="margin-top:30px;">
            <!-- <div>
                <div style="float:right; font-size:32px; font-weight:bold;"> Rp. {{ hargaJual }}</div>
            </div>
            <br clear="all" /> -->
            <v-row style="margin-top:30px;">

                <v-col md="12" cols="12" style="padding:0; margin:-15px 0 0 0; min-height:50px;">

                    <v-autocomplete
                        @change="selectItemsMember"
                        :search-input.sync="search"
                        :loading="isLoading"
                        v-model="formLock.searchMember"
                        :items="items"
                        item-text="name"
                        item-value="id"
                        class="mx-4 lighten-3"
                        outlined
                        flat
                        return-object
                        hide-no-data
                        hide-selected
                        style="margin:10px 0 40px 0; height:20px;"
                        label="Search Member By Name Or Email ( min 3 karakter )">
                    </v-autocomplete> 

                </v-col>

                <v-col md="12" style="margin-top:30px;">

                    <v-row>

                    <v-col md="8" cols="8" style="padding:0; margin:-15px 0 0 0; min-height:50px;">
                        <v-col md="12">
                            <v-text-field
                                v-model="searchForm.nama_pemegang_rek"
                                label="Nama Pemegang Rekening"
                                :disabled="true"
                                single-line
                                small
                                clearable>
                            </v-text-field>
                            <!-- {{ addemail }} -->
                        </v-col>
                    </v-col>

                    <v-col md="4" cols="4" style="padding:0; margin:-15px 0 0 0; min-height:50px;">
                            <v-col md="12">
                                <v-text-field
                                    v-model="searchForm.lastBalance"
                                    label="Sisa Balance"
                                    :disabled="true"
                                    single-line
                                    small
                                    clearable>
                                </v-text-field>
                                <!-- {{ addemail }} -->
                            </v-col>
                        </v-col>

                    <v-col md="6" cols="6" style="padding:0; margin:-15px 0 0 0; min-height:50px;">
                        <v-col md="12">
                            <v-text-field
                                v-model="searchForm.email_member"
                                label="Email Member"
                                :disabled="true"
                                single-line
                                small
                                clearable>
                            </v-text-field>
                            <!-- {{ addname }} -->
                        </v-col>
                    </v-col>

                    <v-col md="6" cols="6" style="padding:0; margin:-15px 0 0 0; min-height:50px;">
                        <v-col md="12">
                            <v-text-field
                                v-model="searchForm.phone_number"
                                label="Phone Number"
                                :disabled="true"
                                single-line
                                small
                                clearable>
                            </v-text-field>
                        </v-col>
                    </v-col>

                    <!-- <v-col md="4" cols="4" style="padding:0; margin:-15px 0 0 0; min-height:50px;">
                        <v-col md="12">
                            <v-select
                                :items="satuanpembayaran"
                                label="Pilih Satuan Jual"
                                v-model="searchForm.pilihsatuanjual"
                            ></v-select>
                        </v-col>
                    </v-col> -->

                    <v-col md="6" cols="6" style="padding:0; margin:-8px 0 0 0; min-height:50px;">
                        <v-col md="12" style="display:flex; flex:1; justify-content: center; flex-direction: column;"> 
                            <div><small>Buy Rate ( today ) : </small></div>
                            <!-- <h3> Rp. {{ $store.state.goldrate.attributes.goldvalue[0].sell_rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }} </h3> -->
                            <h3> Rp. {{ $store.state.goldrate.attributes.goldvalue[0].buy_rate != null ? $store.state.goldrate.attributes.goldvalue[0].buy_rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0 }} </h3>
                        </v-col>
                    </v-col>

                    <v-col md="6" cols="6" style="padding:0; margin:-8px 0 0 0; min-height:50px;">
                        <v-col md="12"> 
                            <small>Sell Rate ( today ) : </small>
                            <!-- <h3> Rp. {{ $store.state.goldrate.attributes.goldvalue[0].sell_rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }} </h3> -->
                            <h3> Rp. {{ $store.state.goldrate.attributes.goldvalue[0].sell_rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }} </h3>
                        </v-col>
                    </v-col>

                    <v-col md="6" cols="6"  style="padding:0; margin:20px 0 0 0; min-height:50px;">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >

                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateText"
                                        label="Transactions Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                    </v-text-field>
                                </template>

                                <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="savedates()"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                    </v-col>

                    <v-col md="3" cols="3" style="padding:0; margin:12px 0 0 0; min-height:50px;">
                        <v-col md="12"> 
                            <small>Pilih Sell Rate  : </small>
                            <!-- <h3> Rp. {{ $store.state.goldrate.attributes.goldvalue[0].sell_rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }} </h3> -->
                            <h3> Rp. {{ $store.state.transaction.member.goldRateList.length > 0  ? $store.state.transaction.member.goldRateList[0].sell_rate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0 }} </h3>
                        </v-col>
                    </v-col>

                    <v-col cols="12" style="padding:0; margin:30px 0 0 0; min-height:50px;">
                         <v-row>
                            <v-col md="3">
                                    <small>Pilih Type ( nominal / gramasi ) : </small>
                                    <v-autocomplete
                                        @change="changefunc()"
                                        v-model="searchForm.statusCounter"
                                        :items="statusver"
                                        item-text="text"
                                        item-value="value"
                                        label="Nominal">
                                    </v-autocomplete>
                            </v-col>

                            <v-col md="6" v-if="isType == 0">
                                    <small>Nominal ( Rp ) : <i>  </i> </small>
                                    <v-text-field
                                        v-model="searchForm.jumlahnominal"
                                        label="Masukkan Nominal ( Include Fee )"
                                        @change="findGramasi()"
                                        :disabled="isDateTrans"
                                        :rules="nominalRules"
                                        single-line
                                        small
                                        clearable>
                                    </v-text-field>
                            </v-col>

                            <v-col md="3" v-if="isType == 0">
                                <p></p>
                                <small>Jumlah Gramasi : </small>
                                <h3> {{ $store.state.goldrate.attributes.gramasiconvert.toFixed(4) }} <sup style="font-weight:normal; font-size:12px;">Gr</sup></h3>
                            </v-col>

                            <v-col md="5" v-if="isType == 1">
                                        <small>Gramasi ( gr ) : <i>  </i> </small>
                                        <v-text-field
                                            v-model="searchForm.jumlahGramasi"
                                            label="Masukkan Jumlah Gramasi ( Include fee )"
                                            @change="findRupiah()"
                                            :disabled="isDateTrans"
                                            :rules="nominalRules"
                                            single-line
                                            small
                                            clearable>
                                        </v-text-field>
                            </v-col>

                            <v-col md="4" v-if="isType == 1">
                                <p></p>
                                <small>Jumlah Rupiah : </small>
                                <h3> Rp. {{ $store.state.goldrate.attributes.rupiahconvert.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} </h3>
                            </v-col>

                        </v-row>
                    </v-col>

                    <v-col
                            cols="12"
                            md="12"
                            style="margin-top:30px;">
                            <v-textarea
                            v-model="searchForm.notesManual"
                            outlined
                            name="input-7-4"
                            label="Notes"
                            value="User ini melakukan transaksi penjualan manual dikarenakan ..."
                            ></v-textarea>
                    </v-col>


                </v-row>

            </v-col>

            </v-row>
        </v-layout>
        <div style="width:100%;">
            <div style="float:right; margin-top:30px;"> 
            <v-btn
                color="success"
                medium
                outlined
                :disabled="createTrans"
                @click="withdrawTrx()">
                <v-icon>mdi-plus</v-icon> Create Transactions New
            </v-btn>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                modal: false,
                panel: null,
                profiles:this.$store.state.auth.profile,
                provinsiList:[],
                isDisabled:false,
                details:[],
                dinDetailsIp:null,
                dinDetailsDin:null,
                dinDetailsLog:null,
                isDateTrans: true,
                hargaJual:0,
                selectedsMembers:"",
                createTrans:true,
                addname:"-",
                menu:"",
                trans_date:"",
                addemail:"-",
                gramasiconvert:0,
                isSubmits:false,
                listMembers:[],
                nominalRules: [
                    v => !!v || 'Nominal is required',
                ],
                isLoading: false,
                isType: 0,
                typingTimer:null,
                search: null,
                currentPage:1,
                decimals:"10.00",
                icons:"chevron-right",
                searchValue:"",
                tab: null,
                formLock:{
                    searchMember:{id: null, name: null , email:null , phone:null}
                },
                tipetrans: [
                    "Transfer Bank" , "E-Money"
                ],
                jenispembayaran:[
                    "Pilih Jenis Pembayaran"
                ],
                statusver: [
                    { text: "Nominal", value: 0 },
                    { text: "Gramasi", value: 1 }
                ],
                satuanpembayaran: [
                    { text: "Semua Satuan", value: "" },
                    { text: "Gram Emas", value: "1" },
                    { text: "Rupiah", value: "2" },
                    // { text: "Gagal", value: "2" },
                ],
                loading: false,
                loadingText: "Loading Data...",
                searchForm:{

                }
            };
        },
        methods:{
            findGramasi(){
                if(this.searchForm.jumlahnominal != "" && this.selectItemsMember != ""){
                    this.createTrans = false
                }
                this.$store.state.goldrate.attributes.gramasiconvert = parseInt(this.searchForm.jumlahnominal) / (this.$store.state.transaction.member.goldRateList.length > 0 ? parseInt(this.$store.state.transaction.member.goldRateList[0].sell_rate) : 0);
            },
            findRupiah(){
                if (this.searchForm.jumlahGramasi != "" && this.selectItemsMember != "") {
                    this.createTrans = false
                }
                this.$store.state.goldrate.attributes.rupiahconvert = parseFloat(this.searchForm.jumlahGramasi) * (this.$store.state.transaction.member.goldRateList.length > 0 ? parseInt(this.$store.state.transaction.member.goldRateList[0].sell_rate) : 0);
            },
            cleanse(){
                this.searchForm.nama_pemegang_rek = "";
                this.searchForm.email_member = "";
                this.searchForm.phone_number = "";
                this.searchForm.lastBalance = "";
                this.$store.state.goldrate.attributes.selectedsMembers = "";
                this.$store.state.goldrate.attributes.rupiahconvert = 0;
                this.isType = 0;
                this.searchForm.jumlahGramasi = "";

                this.formLock.searchMember.id = "";
                this.dateText = "";
                this.$store.state.transaction.member.goldRateList = [];
                this.searchForm.jumlahnominal = "";
                this.searchForm.notesManual = "";
                this.isDateTrans = true;
                this.$store.state.goldrate.attributes.gramasiconvert = 0;
            },
            fetchMember(cari) {
                this.isLoading = true;

                if(this.searchValue.length > 2){
                    this.$store
                        .dispatch("member/fetchStatusWbalMemberAll", {
                            member:cari
                        })
                        .then((res) => {
                            //console.log("listmembersTrans" , this.$store.state.member.member.listMemberBalTrans);
                            this.listMembers = this.$store.state.member.member.listMemberBalTrans;
                            this.isLoading = false;
                            return res;
                        })
                        .catch((res) => {
                        console.log("fetchdata" , res.response.data.message);
                        this.isLoading = false;
                    });
                }
            },
            changefunc(){
                this.isType = this.searchForm.statusCounter;
            },
            addJualTrans(){

                if(this.searchForm.jumlahnominal == "" || this.searchForm.jumlahnominal == null){
                    alert("Silakan Masukkan Jumlah Nominal Terlebih Dahulu");
                }

                if(this.$store.state.goldrate.attributes.selectedsMembers == ""){
                    alert("Silakan Pilih Members Terlebih Dahulu");
                }

            },
            savedates(){
                this.dateText = this.date;
                this.menu = false;

                this.$store
                .dispatch("transaction/getGoldRatesbyDates", {
                    tanggal: this.date
                })
                .then(() => {
                    if (this.$store.state.transaction.member.goldRateList.length != "") {
                        this.isDateTrans = false;
                    }
                })
                .catch((res) => {
                    this.$store.commit("main/setSnackbarFail", res);
                });
            },
            withdrawTrx(){
                if ((this.searchForm.jumlahnominal == "" && this.$store.state.goldrate.attributes.rupiahconvert == 0) || (this.searchForm.jumlahnominal == null && this.$store.state.goldrate.attributes.rupiahconvert == 0)) {
                    alert("Silakan Masukkan Jumlah Nominal Terlebih Dahulu");
                }
                else if (this.$store.state.goldrate.attributes.selectedsMembers == "") {
                    alert("Silakan Pilih Members Terlebih Dahulu");
                }
                else if (this.dateText == "") {
                    alert("Transaction Date tidak boleh kosong !");
                }
                else if(this.$store.state.goldrate.attributes.gramasiconvert > this.formLock.searchMember.real_gramasi){
                    alert("Balance tidak mencukupi");
                }
                else{
                    // console.log("tes",
                    // "user_id => " + this.formLock.searchMember.id,
                    // "date => " + this.date,
                    // "rate_id => " + this.$store.state.transaction.member.goldRateList.length > 0 ? this.$store.state.transaction.member.goldRateList[0].id : 0,
                    // "amount => " + this.searchForm.jumlahnominal,
                    // "note => " + this.searchForm.notesManual);

                    this.$store.commit("main/setLoading", true);
                    this.loading = true;
                    this.loadingtext = 'Loading... Please wait';

                    this.$store
                        .dispatch("transaction/withdrawTrx", {
                            user_id: this.formLock.searchMember.id,
                            date: this.dateText,
                            rate_id: this.$store.state.transaction.member.goldRateList.length > 0 ? this.$store.state.transaction.member.goldRateList[0].id : 0,
                            amount: this.isType == 0 ? this.searchForm.jumlahnominal : this.$store.state.goldrate.attributes.rupiahconvert,
                            note: this.searchForm.notesManual
                        })
                        .then(() => {
                            this.$store.commit("main/setLoading", false);
                            this.loading = false;
                            this.loadingtext = '';
                            this.$store.commit("main/setSnackbarSuccess", "Success");

                            this.cleanse();

                        })
                        .catch((res) => {
                            this.$store.commit("main/setLoading", false);
                            this.$store.commit("main/setSnackbarFail", res.response.data.message != null ? res.response.data.message : res);
                            this.loading = false;
                            this.loadingtext = '';
                        });
                }
                // this.$store.commit("main/setLoading", true);
                // this.loading = true;
                // this.loadingtext = 'Loading... Please wait';

                // this.$store
                //     .dispatch("transaction/withdrawTrx", {
                //         user_id: this.formLock.searchMember,
                //         date: this.date,
                //         rate_id: this.$store.state.goldrate.attributes.goldvalue[0].sell_rate,
                //         amount: this.searchForm.jumlahnominal,
                //         note: this.searchForm.notesManual
                //     })
                //     .then(() => {
                //         this.$store.commit("main/setLoading", false);
                //         this.loading = false;
                //         this.loadingtext = '';
                //         this.$store.commit("main/setSnackbarSuccess", "Success");

                //     })
                //     .catch((res) => {
                //         this.$store.commit("main/setLoading", false);
                //         this.$store.commit("main/setSnackbarFail", res);
                //         this.loading = false;
                //         this.loadingtext = '';
                //     });
            },
            getRates(dates){
                this.$store
                .dispatch("goldrate/fetchRatesByDate", {
                    datesr:dates
                })
                .then(() => {
                   // return res;
                })
                .catch((res) => {
                    console.log("fetchdata" , res.response.data.message);
                });
            },
            selectItemsMember(){    
                this.$store.state.goldrate.attributes.selectedsMembers = this.formLock.searchMember.id
                this.searchForm.nama_pemegang_rek = this.formLock.searchMember.name
                this.searchForm.email_member = this.formLock.searchMember.email
                this.searchForm.phone_number = this.formLock.searchMember.phone
                this.searchForm.lastBalance = this.formLock.searchMember.real_gramasi
                //alert("id =" + this.formLock.searchMember.id);
            },
        },
        mounted() {
            // console.log("tes_gold_rate" , this.$store.state.goldrate.attributes.goldvalue);
            this.searchForm.date = this.date;
            this.getRates("");
            this.date = "";
            this.searchForm.statusCounter = 0;
        },
        computed: {
            fields () {
                if (!this.formLock.searchMember) return []

                return Object.keys(this.formLock.searchMember).map(key => {
                return {
                    key,
                    value: this.formLock.searchMember[key] || 'n/a',
                }
                })
            },
            items () {
                return this.listMembers.map(entry => {
                const Description = entry.name;

                return Object.assign({}, entry, { Description })
                })
            },
        },
        watch: {
            search (val) {
                clearTimeout(this.typingTimer);
                if (val) {
                    this.typingTimer = setTimeout(()=> {
                        this.searchValue = val;
                        this.fetchMember(val);
                    }, 1000);
                }
            },
            searchSponsor(val) {
                val && val !== this.searchForm.sponsor && this.getSponsor(val);
            },
            searchMember(val) {
                val && val !== this.searchForm.member && this.getMember(val);
            }
        },
    }
</script>