<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <CustomCard>

        <template v-slot:toolbar>
            <v-btn color="success" small outlined @click="filterToggle">
            filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn>

        </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.member"
                                label="Search By Name"
                                small
                                clearable
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.email"
                                label="Search By Email "
                                small
                                clearable
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.phone"
                                label="Search By Phone"
                                small
                                clearable
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex>

                            <v-menu
                                :close-on-content-click="false"
                                :return-value.sync="date2"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">

                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchForm.date2"
                                    label="Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="searchForm.date2"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>

                        </v-flex>
                    
                </v-layout>
                

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">
            <v-data-table
                :headers="headers"
                :items="$store.state.games.games.list"
                :options.sync="pagination"
                :server-items-length="$store.state.games.games.total"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext"
            >

                <template v-slot:[`item.membersDetail`]="{ item }">
                        <div> <b> {{ item.member.name }} </b> <br /> <div>  {{ item.member.email }} </div> <div>  {{ item.member.phone }} </div> </div>
                </template>

                <template v-slot:[`item.scores`]="{ item }">
                        <div>
                            <b>Play:</b> {{ item.games.note }}<br>
							<b>Score:</b> {{ item.games.score }}
                        </div>
                </template>

                <template v-slot:[`item.rewards`]="{ item }">
                        <div>
                            <b>TrxID:</b> {{ item.trx_id }}<br>
							<b>Reward:</b> {{ item.gramasi }} Gr / {{ item.amount }} <br />
							<b>GoldRate:</b> {{ item.goldRateBuy }} / {{ item.goldRateSell }}
                            <!-- {{ number_format($row->goldRate->buy_rate) }}/{{ number_format($row->goldRate->sell_rate) }} -->
                        </div>
                </template>

            </v-data-table>
            </v-flex>
        </template>
    </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      status: [
            { text: "All Status", value: "" },
            { text: "Email Verified", value: "email_verified" },
            { text: "Email Not Verified", value: "email_not_verified" },
            { text: "User Verified", value: "user_verified" },
            { text: "User Not Verified", value: "user_not_verified" },
            { text: "User Suspend", value: "user_suspend" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "ID", value: "id"},
      //  { text: "Trx", align: "center", value: "trxCount" },
        { text: "Tanggal", align: "left", value: "created" , width:120},
        { text: "Member", align: "left", value: "membersDetail"},
      //  { text: "Lock Bal (gr)", align: "center" , value: "lock_balance" , width: "150"},
      //  { text: "Reg Date", value: "verified_date"},
        { text: "Score", align: "left", value: "scores" , width:150},
      //  { text: "M-Ver", value: "verified"},
        { text: "Reward", align: "left", value: "rewards" , width:250 },
        { text: "Note", align: "left", value: "note" },
        { text: "Update By", align: "left", value: "admin.name" },
        //{ text: "Action", align: "center", value: "action" , width: "120"},
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    fetchProv(params){
        this.$store
            .dispatch("wilayah/fetchWilayah", {
                find: params,
            })
            .then(() => {
                this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("prov" , res.response.data.message);
            });
    },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      //alert("limits "+this.pagination.itemsPerPage);
      //alert("tes new");
      this.$store
        .dispatch("games/fetchGamesList", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            rewardname:this.searchForm.member,
            rewardemail:this.searchForm.email,
            rewardphone:this.searchForm.phone,
            rewarddate:this.searchForm.date2,
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
      //this.fetch(0);
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        }

        if(this.$store.state.member.member.is_back == 1){
            this.pagination.page = this.$store.state.member.member.paging_page;
        }

        this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>