<template>
    <div>
        <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

        <CustomCard>

            <template v-slot:toolbar>
                <div style="width:100%;">

                    <v-btn color="success" small outlined @click="filterToggle" style="float:left;">
                        filter <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>


                    <v-btn color="success" small outlined @click="fetch(pagination.page)" style="float:right;">
                        <v-icon>mdi-refresh</v-icon> Refresh
                    </v-btn>

                    <v-btn color="success" small outlined @click="exportByFilter()"
                        style="float:right; margin-right:20px;">
                        <v-icon>mdi-download</v-icon> Export By Filter
                    </v-btn>

                </div>

            </template>

            <template v-slot:expansion>
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel expand focusable>
                        <v-expansion-panel-content>

                            <v-layout>

                                <v-flex>
                                    <v-text-field v-model="searchForm.member"
                                        label="(Name, Email, Payment No, Ref ID, iPaymu ID)" single-line
                                        @keyup.13="fetch(1)" small clearable>
                                    </v-text-field>
                                </v-flex>

                                <!-- <v-flex>
                            <v-autocomplete
                                v-model="searchForm.thirdparty"
                                :items="thirdparty"
                                item-text="text"
                                item-value="value"
                                label="ThirdParty Tipe"
                            >
                            </v-autocomplete>
                        </v-flex> -->

                                <v-flex>
                                    <v-autocomplete v-model="searchForm.typetransaksi" :items="typetransaksi"
                                        item-text="text" item-value="value" label="Type Transaksi">
                                    </v-autocomplete>
                                </v-flex>

                                <v-flex>
                                    <v-autocomplete v-model="searchForm.statusPembayaran" :items="statusPembayaran"
                                        item-text="text" item-value="value" label="Status">
                                    </v-autocomplete>
                                </v-flex>

                            </v-layout>

                            <v-layout>

                                <v-flex>

                                    <v-menu :close-on-content-click="false" :return-value.sync="date"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="searchForm.date" label="Start Date"
                                                prepend-icon="mdi-calendar" readonly clearable v-bind="attrs"
                                                v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="searchForm.date" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary" @click="$refs.menu.save(date)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>

                                </v-flex>

                                <v-flex>

                                    <v-menu :close-on-content-click="false" :return-value.sync="date2"
                                        transition="scale-transition" offset-y min-width="auto">

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="searchForm.date2" label="End Date"
                                                prepend-icon="mdi-calendar" readonly clearable v-bind="attrs"
                                                v-on="on"></v-text-field>
                                        </template>

                                        <v-date-picker v-model="searchForm.date2" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary" @click="$refs.menu.save(date)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>

                                    </v-menu>

                                </v-flex>

                            </v-layout>

                            <v-layout align-end justify-end>
                                <v-spacer></v-spacer>
                                <v-btn small depressed color="success" class="m0" @click="fetch(1)">
                                    <v-icon>mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>

            <template v-slot:datatable>
                <v-flex md12 style="overflow-x: scroll">
                    <v-data-table :headers="headers" :items="$store.state.transaction.member.list"
                        :options.sync="pagination" :server-items-length="$store.state.transaction.member.total"
                        :footer-props="{
                            'items-per-page-options': [10, 25, 50, 100],
                            'items-per-page-text': 'Baris per Halaman:',
                        }" :items-per-page="10" :loading="loading" :loading-text="loadingtext">

                        <template v-slot:[`item.rates_sell`]="{ item }">
                            <div> <b> {{ item.buy_rate }} </b> </div>
                            <div> {{ item.sell_rate }} </div>
                        </template>

                        <template v-slot:[`item.status_act`]="{ item }">
                            <!-- <div>  <b> {{ item.buy_rate }} </b> </div>
                                <div>  {{ item.sell_rate }} </div> -->
                            <div v-if="item.status == 'GAGAL'"> <v-icon small color="red">mdi-block-helper</v-icon>
                                <br /> <span style="color:red;"> {{ item.status }} </span>
                            </div>
                            <div v-if="item.status == 'BERHASIL'"> <v-icon small color="green">mdi-check</v-icon> <br />
                                <span style="color:green;"> {{ item.status }} </span>
                            </div>

                            <div v-if="item.status != 'GAGAL' && item.status != 'BERHASIL'"> <v-icon
                                    small>mdi-clock</v-icon> <br /> <span> {{ item.status }} </span> </div>
                        </template>

                        <template v-slot:[`item.icdx_status`]="{ item }">
                            <div v-if="item.icdx_hit == '0'"> <v-icon small color="red">mdi-block-helper</v-icon> <br />
                                <span style="color:red;"> Belum Terkirim</span>
                            </div>
                            <div v-if="item.icdx_hit == '1'"> <v-icon small color="green">mdi-check</v-icon> <br />
                                <span style="color:green;"> Terkirim</span>
                            </div>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="info" icon @click="showDetail(item)" v-bind="attrs" v-on="on">
                                        <v-icon small>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Detail</span>
                            </v-tooltip>

                            <v-dialog transition="dialog-top-transition" max-width="600">

                                <template v-slot:activator="{ on, attrs }">

                                    <!-- <v-tooltip> -->
                                    <v-btn color="red" icon v-bind="attrs"
                                        v-bind:class="item.status_code != 2 ? '' : 'hidden'" v-on="on">
                                        <v-icon small color="red">mdi-block-helper</v-icon>
                                    </v-btn>
                                    <!-- 
                                            <span>Detail</span>
                                        </v-tooltip> -->

                                </template>
                                <!-- <span>Unverified User</span> -->
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar color="red" dark>Manual Banned Transaction</v-toolbar>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    Yakin akan Banned Transaksi ini ?
                                                </v-flex>
                                            </v-layout>

                                        </v-container>

                                        <v-card-actions class="justify-end">
                                            <v-btn text @click="dialog.value = false">Close</v-btn>
                                            <v-btn style="color:#ffffff;" color="red"
                                                @click="editVerificationBan(dialog, item.id, 0)">Submit</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>

                            <v-dialog transition="dialog-top-transition" max-width="600">

                                <template v-slot:activator="{ on, attrs }">

                                    <!-- <v-tooltip> -->
                                    <v-btn color="warning" icon v-bind="attrs"
                                        v-bind:class="item.status_code != 1 ? '' : 'hidden'" v-on="on">
                                        <v-icon small color="green">mdi-thumb-up-outline</v-icon>
                                    </v-btn>
                                    <!-- 
                                            <span>Detail</span> 
                                        </v-tooltip> -->

                                </template>
                                <!-- <span>Unverified User</span> -->
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar color="success" dark>Manual Success Transaction</v-toolbar>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    Yakin akan Approve Transaksi ini ?
                                                </v-flex>
                                            </v-layout>

                                        </v-container>

                                        <v-card-actions class="justify-end">
                                            <v-btn text @click="dialog.value = false">Close</v-btn>
                                            <v-btn color="success"
                                                @click="editVerificationSuccess(dialog, item.id, 1)">Submit</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>


                            <v-btn color="success" icon
                                v-if="item.status_code == 1 && item.icdx_hit == 0 && (item.type_code == 1 || item.type_code == 2)"
                                @click="uploadICDX(item.id)">
                                <v-icon small>mdi-upload</v-icon>
                            </v-btn>

                        </template>


                        <template v-slot:item.emailmember="{ item }">
                            <div v-if="item.type !=='PEMBELIAN' || item.type !=='PENJUALAN'">
                                {{  item.emailmember }}
                            </div>
                            <div v-if="item.type ==='PEMBELIAN' && item.type ==='PENJUALAN'">
                                <div v-if="item.sender === -1">
                                    {{ item.receiver_email }}
                                </div>
                                <div v-if="item.receiver === -1">
                                    {{ item.sender_email }}
                                </div>
                            </div>
                        </template>

                        <template v-slot:item.amounts="{ item }">
                            <div v-if="item.type_code == 1">
                                {{ item.ipaymu.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') }}
                            </div>
                            <div v-else>
                                {{ item.amount }}
                            </div>
                        </template>

                        <template v-slot:item.idFormat="{ item }">
                            <div>
                                {{ item.id.replaceAll(".", "") }}
                            </div>
                        </template>

                    </v-data-table>

                </v-flex>
            </template>

        </CustomCard>

    </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";


export default {
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            panel: null,
            profiles: this.$store.state.auth.profile,
            provinsiList: [],
            searchParam: [],
            currentPage: 1,
            decimals: "10.00",
            icons: "chevron-right",
            statusPembayaran: [
                { text: "All Status", value: "" },
                { text: "Menunggu Pembayaran", value: "0" },
                { text: "Berhasil", value: "1" },
                { text: "Gagal", value: "2" },
                // { text: "Menunggu Settlement", value: "3" },
                { text: "Menunggu Settlement Komisi", value: "4" }
                // { text: "Gagal", value: "2" },
            ],
            typetransaksi: [
                { text: "All Type", value: "" },
                { text: "Pembelian", value: "1" },
                { text: "Setor Emas", value: "10" },
                { text: "Refund IdCash", value: "11" },
                { text: "Reward Dinaran Games", value: "12" },
                { text: "Reward Dinaran Affiliate", value: "13" },
                { text: "TopUp Balance IDR", value: "14" },
                { text: "Penjualan", value: "2" },
                { text: "Beri Emas", value: "3" },
                { text: "Fee Pembelian", value: "4" },
                { text: "Fee Penjualan", value: "5" },
                { text: "Ambil Emas", value: "6" },
                { text: "Fee Ambil Emas", value: "7" },
                { text: "Ongkir Ambil Emas", value: "8" },
                { text: "Komisi", value: "9" }
                // { text: "Gagal", value: "2" },
            ],
            thirdparty: [
                { text: "All", value: "all" },
                { text: "IDCASH", value: "idcash" }
                // { text: "Gagal", value: "2" },
            ],
            statusver: [
                { text: "All", value: "all" },
                { text: "Request Verfikasi", value: "false" },
                { text: "Terverifikasi", value: "true" }
                // { text: "Gagal", value: "2" },
            ],
            statusaddr: [
                { text: "All", value: "all" },
                { text: "Address + Current Address", value: "1" },
                { text: "Address", value: "2" },
                { text: "No Address", value: "3" }
                // { text: "Gagal", value: "2" },
            ],
            searchForm: {
                member: ''
                // tipe: 'ALL',
                // status: 'ALL',
                // sponsor: ''
            },
            formLock: {

            },
            tipe: [],
            menu: null,
            form: {},
            pagination: {},
            headers: [
                { text: "TrxID", value: "idFormat" },
                { text: "Email", value: "emailmember" },
                { text: "Type", value: "type", width: "150px" },
                { text: "Status", value: "status_act", width: "200px", align: "center" },
                { text: "ICDX Send", value: "icdx_status", width: "200px", align: "center" },
                { text: "Trx Date", value: "created_at", width: "180px" },
                { text: "Paid Date", value: "updated_at", width: "180px" },
                { text: "Paid Amount", value: "amounts", align: "right" },
                { text: "Gramasi", value: "gramasi", align: "right" },
                { text: "Rate", value: "rates_sell", align: "right" },
                { text: "Payment Method", value: "pay_method", width: "200px"},
                { text: "Update By", value: "admin", width: "120px" },
                { text: "Action", align: "left", value: "action", width: "200px" },
            ],
            loading: false,
            loadingText: "Loading Data...",
        };
    },
    methods: {

        fetch(cari) {
            //alert(this.pagination.page);
            if (this.$store.state.transaction.member.searchDetail == "no") {
                //alert("fetched");
                if (cari == 1) {
                    this.pagination.page = 1;
                }

                this.$store.commit("main/setLoading", true);
                this.loading = true;
                this.loadingtext = 'Loading... Please wait';
                let searched = [];

                searched.push({
                    "member": this.searchForm.member != null ? this.searchForm.member : "",
                    "typetransaksi": this.searchForm.typetransaksi != null ? this.searchForm.typetransaksi : "",
                    "statusPembayaran": this.searchForm.statusPembayaran != null ? this.searchForm.statusPembayaran : "",
                    "startDate": this.searchForm.date != null ? this.searchForm.date : "",
                    "endDate": this.searchForm.date2 != null ? this.searchForm.date2 : "",
                    "pages": this.pagination.page
                });

                this.$store.state.transaction.member.searchtransaksi = searched;

                this.$store
                    .dispatch("transaction/fetchMember", {
                        page: this.pagination.page,
                        itemsPerPage: this.pagination.itemsPerPage,
                        member: this.$store.state.transaction.member.searchtransaksi[0].member,
                        typetransaksi: this.$store.state.transaction.member.searchtransaksi[0].typetransaksi,
                        statusPembayaran: this.$store.state.transaction.member.searchtransaksi[0].statusPembayaran,
                        startDate: this.$store.state.transaction.member.searchtransaksi[0].startDate,
                        endDate: this.$store.state.transaction.member.searchtransaksi[0].endDate
                    })
                    .then(() => {
                        this.$store.commit("main/setLoading", false);
                        this.loading = false;
                        this.loadingtext = '';
                    })
                    .catch((res) => {
                        this.$store.commit("main/setLoading", false);
                        this.$store.commit("main/setSnackbarFail", res.response.data.message);
                        this.loading = false;
                        this.loadingtext = '';
                    });
            }
            else {
                this.$store.state.transaction.member.searchDetail = "no";
                this.loading = false;
                
            }

        },
        refresh(cari) {
            if (cari == 1) {
                this.pagination.page = 1;
            }

            this.$store
                .dispatch("transaction/fetchMember", {
                    page: this.pagination.page,
                    itemsPerPage: this.pagination.itemsPerPage,
                    member: this.searchForm.member,
                    typetransaksi: this.searchForm.typetransaksi,
                    statusPembayaran: this.searchForm.statusPembayaran,
                    startDate: this.searchForm.date,
                    endDate: this.searchForm.date2
                })
                .then(() => {
                })
                .catch((res) => {
                    console.log("ref", res);
                });
        },
        exportByFilter() {
            this.$store.commit("main/setLoading", true);
            this.loading = true;
            this.loadingtext = 'Downloading Report ... Please wait';

            // let startsdate = this.searchForm.date;
            // let endssdate = this.searchForm.date2;

            var today = new Date().toLocaleDateString();
            this.$store.state.transaction.exports.filename = "Trx-Export-Transaction-Member-List-" + today + ".xls";

            this.$store
                .dispatch("transaction/exportMemberTrx", {
                    page: this.pagination.page,
                    itemsPerPage: this.pagination.itemsPerPage,
                    member: this.searchForm.member,
                    typetransaksi: this.searchForm.typetransaksi,
                    statusPembayaran: this.searchForm.statusPembayaran,
                    startDate: this.searchForm.date,
                    endDate: this.searchForm.date2
                })
                .then(() => {
                    this.$store.commit("main/setLoading", false);
                    this.loading = false;
                    this.loadingtext = '';
                })
                .catch((res) => {
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res.response.data.message);
                    this.loading = false;
                    this.loadingtext = '';
                });

        },
        uploadICDX(id) {
            this.$store.commit("main/setLoading", true);
            this.loading = true;
            this.loadingtext = 'Loading... Please wait';

            this.$store
                .dispatch("transaction/uploadICDX", {
                    index: id.replaceAll(".", "")
                })
                .then(() => {
                    //alert("success");
                    this.$store.commit("main/setLoading", false);
                    this.loading = false;
                    this.loadingtext = '';

                    this.$store.commit("main/setSnackbarSuccess", "Update Data ICDX Successfully");
                    //alert("Update Data ICDX Successfully");
                    this.fetch(this.pagination.page);
                })
                .catch((res) => {
                    //alert("catch");
                    this.$store.commit("main/setSnackbarFail", res);

                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res);
                    this.loading = false;
                    this.loadingtext = '';
                });
            //this.fetch(this.pagination.page);
        },
        filterToggle() {
            if (this.panel == null)
                this.panel = [...Array(this.items).keys()].map((k, i) => i);
            else this.panel = null;
        },
        editVerificationBan: function (dialog, id, code) {
            this.$store.commit("main/setLoading", true);
            //alert("ban");
            let arr = [];
            let obj = {
                "id": id,
            };
            arr[0] = obj;

            this.$store
                .dispatch("transaction/editMemberTransactionBan", {
                    updateId: this.profiles.id,
                    trxid: JSON.stringify(arr),
                    code: code,
                    id: JSON.stringify(arr)
                })
                .then(() => {
                    this.fetch(this.pagination.page);
                    dialog.value = false;
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
                })
                .catch((res) => {
                    this.fetch(this.pagination.page);
                    dialog.value = false;
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        editVerificationSuccess: function (dialog, id, code) {
            let arr = [];

            let obj = {
                "id": id.replaceAll(".", ""),
            };

            arr[0] = obj;

            this.$store.commit("main/setLoading", true);
            this.$store
                .dispatch("transaction/editMemberTransactionSuccess", {
                    updateId: this.profiles.id,
                    code: code,
                    trxid: JSON.stringify(arr),
                    id: JSON.stringify(arr)
                })
                .then(() => {
                    this.fetch(this.pagination.page);
                    dialog.value = false;
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
                })
                .catch((res) => {
                    this.fetch(this.pagination.page);
                    dialog.value = false;
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res);
                    this.loading = false;
                    this.loadingtext = '';
                });
        },
        userLock: function (dialog, id, code) {

            this.$store.commit("main/setLoading", true);

            this.$store
                .dispatch("member/editUserLockMember", {
                    updateId: this.profiles.id,
                    code: code,
                    id: id
                })
                .then(() => {
                    this.fetch(this.pagination.page);
                    dialog.value = false;
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
                })
                .catch((res) => {
                    this.fetch(this.pagination.page);
                    dialog.value = false;
                    this.$store.commit("main/setLoading", false);
                    this.$store.commit("main/setSnackbarFail", res);
                });

        },
        showDetail(item) {
            //this.$store.commit("main/setLoading", false);
            //this.$store.commit("main/setSnackbarFail", res.response.data.message);
            this.$store.state.member.member.paging_page = this.pagination.page;
            this.$store.commit("transaction/setMemberPage", "detail");
            this.$store.commit("transaction/setMemberDetail", item);
            this.$store.commit("transaction/setSearchTransaksi", "");

            this.$store.state.transaction.member.currentPage = this.pagination.page;

            let searched = [];

            searched.push({
                "member": this.searchForm.member != null ? this.searchForm.member : "",
                "typetransaksi": this.searchForm.typetransaksi != null ? this.searchForm.typetransaksi : "",
                "statusPembayaran": this.searchForm.statusPembayaran != null ? this.searchForm.typetransaksi : "",
                "startDate": this.searchForm.date != null ? this.searchForm.date : "",
                "endDate": this.searchForm.date2 != null ? this.searchForm.date2 : "",
                "pages": this.pagination.page
            });

            this.$store.state.transaction.member.searchtransaksi = searched;
            //this.$store.commit('transaction/setSearchTransaksi' , searched);
            //console.log("ItemList" , item);
            this.loading = false;
            this.loadingtext = '';
        },

        showExpand: function (element, index) {
            const detailElement = document.getElementById(index);
            if (detailElement.classList.contains('hidden')) {
                element.target.classList.remove("mdi-chevron-right");
                element.target.classList.add("mdi-chevron-down");
                detailElement.classList.remove("hidden");
                //this.icons = "chevron-down";
            }
            else {
                element.target.classList.add("mdi-chevron-right");
                element.target.classList.remove("mdi-chevron-down");
                detailElement.classList.add("hidden");
                //this.icons = "chevron-right";
            }
        },
        showLock: function (userId, real_gramasi, lock_user, lock_dinaran) {
            this.formLock.userId = userId
            this.formLock.last_balance = real_gramasi
            this.formLock.lock_user = lock_user
            this.formLock.lock_dinaran = lock_dinaran
        },
    },
    mounted() {
    },
    watch: {
        pagination() {
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            if(this.$store.state.transaction.member.searchDetail == "yes"){
                this.fetch(this.$store.state.transaction.member.currentPage);
                //alert(this.$store.state.transaction.member.currentPage);
            }
            else{
                this.fetch(0);
            }
            
        },
        searchSponsor(val) {
            val && val !== this.searchForm.sponsor && this.getSponsor(val);
        },
        searchMember(val) {
            val && val !== this.searchForm.member && this.getMember(val);
        }
    },

    computed: {
        dateRangeText() {
            if (typeof this.form.dates != "undefined") {
                if (this.form.dates.length > 1) {
                    return this.form.dates.join(" ~ ");
                } else {
                    return this.form.dates[0];
                }
            } else {
                return null;
            }
        },
    },
    components: { CustomCard },
};
</script>

<style>

</style>