import { apiAddGoldRate, apifetchGoldList , apiHitDataGoldChart , ApiExportTrxExcel , apiFetchRatesByDate } from "../../api/goldrate"
 
import fileDownload from 'js-file-download';

const namespaced = true

const defaultState = () => {
    return {
        attributes: {
            detail: {},
            listGoldList:[],
            DetailGoldList:[],
            listGoldItem:[],
            total:0,
            goldvalue:0,
            rupiahconvert:0,
            selisihbuy:0,
            selisihbuyformatted:0,
            selisihsell:0,
            gramasiconvert:0,
            selectedsMembers:"",
            selisihsellformatted:0,
            pageGold:"list",
            status:"",
            arrays_bulanan_buyrate:0,
            arrays_bulanan_sellrate:0,
        },
        settlement: {
            list: [],
            detail: {}
        },
        exports:{
            list: [],
            detail: {},
            page:"list",
            filename:"",
            status:"",
            total:0,
            listTotals1:0
        }
    }
}



const state = defaultState()

const mutations = {
    setStatus: (state, payload) => {
        state.attributes.status = payload
    },
    setGoldList: (state, payload) => {
        state.attributes.listGoldList  = payload
    },
    setDetailGoldList: (state, payload) => {
        state.attributes.DetailGoldList = payload
    },
    setPageGold: (state, payload) => {
        state.attributes.pageGold = payload
    },
    setTotal: (state, payload) => {
        state.attributes.total = payload
    },
    setBuyRates: (state, payload) => {
        state.attributes.arrays_bulanan_buyrate = payload
    },
    setGoldRateNow:(state, payload) => {
        state.attributes.goldvalue = payload
    },
    setSellRates: (state, payload) => {
        state.attributes.arrays_bulanan_sellrate = payload
    },
}

const actions = {
    fetchGoldList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apifetchGoldList(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setGoldList', res.rate);
                        commit('setTotal' , res.total)
                        //state.attributes.total = res.total;
                        state.attributes.selisihbuy   = res.selisihbuy;
                        state.attributes.selisihbuyround  = res.selisihbuyround;
                        state.attributes.selisihsell   = res.selisihsell;
                        state.attributes.selisihsellround   = res.selisihsellround;

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchRatesByDate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchRatesByDate(payload)
                .then((res) => {
                    //console.log("statusRes" , res.status);
                    if (res.status == 200) {
                        commit('setGoldRateNow', res.data)
                        //state.attributes.goldvalue = res.data;
                        // state.member.total = res.total
                        console.log("setGoldRateNow",res.data)
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    addGoldRate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiAddGoldRate(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit("setStatus" , "success");

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    exportTrxExcel({ commit } , payload) {
        return new Promise((resolve, reject) => {
            ApiExportTrxExcel(payload)
                .then((res) => {
                   // if (res.status == 200) {
                        commit('setStatus', "Success");
                        //alert("success");
                        fileDownload(res, state.exports.filename);

                        resolve(res)
                    //}
                   // reject(res)
                }).catch(res => reject(res))
        })
    },
    apiHitDataGoldChart({ commit } , payload) {
        return new Promise((resolve, reject) => {
            apiHitDataGoldChart(payload)
                .then((res) => {
                   if (res.status == 200) {
                        commit('setStatus', "Success");
                        commit('setBuyRates', res.array_bulanan_buy);
                        commit('setSellRates', res.array_bulanan_sell);
                        //console.log("apiHitDataMemberChart" , res.dataMember);
                       
                        resolve(res)
                    }
                   reject(res)
                }).catch(res => reject(res))
        })
    },
}

export default {
    namespaced, state, mutations, actions
}