<template>
    <div>
    <v-layout row wrap 
    >
        <v-flex md10 class="px-2">
            <v-text-field
                v-model="searchForm.member"
                label="Input Ipaymu ID"
                single-line
                small
                @keyup.13="fetch()"
                clearable>
            </v-text-field>
        </v-flex>
        <v-flex md2 class="px-2">
            <br />
            <v-btn
            color="success"
            small
            outlined
            @click="fetch()">
            Cari Data
            </v-btn>
        </v-flex>
    </v-layout>
    <div style="padding:0 10px; margin:30px 0;">
    <h3>Data Ipaymu</h3>
        <p></p>
        <hr />
        <p></p>
    </div>
    <v-layout row wrap>
    
        <v-flex md6 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Data Transaksi Ipaymu </b> </v-toolbar>
            <v-simple-table>
            <template v-slot:default>
                <tbody>
                <tr>
                    <td>Ipaymu ID</td><td> {{ details.TransactionId }}</td>
                </tr>
                <tr>
                    <td>Ref ID</td><td> {{ details.ReferenceId }}</td>
                </tr>
                <tr>
                    <td>Buyer Name</td><td> {{ details.BuyerName }}</td>
                </tr>
                <tr>
                    <td>Buyer Email</td><td> {{ details.BuyerEmail }}</td>
                </tr>
                <tr>
                    <td>Amount </td><td> {{ details.Amount }} IDR</td>
                </tr>
                <tr>
                    <td>Fee</td><td> {{ details.Fee }}</td>
                </tr>
                <tr>
                    <td>Status</td><td> {{ details.Status }}</td>
                </tr>
                <tr>
                    <td>Status Desc</td>
                    <td> {{ details.StatusDesc }}</td>
                </tr>
                </tbody>
            </template>
            </v-simple-table>
        </v-flex>
        <v-flex md6 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Detail Transaksi </b> </v-toolbar>
            <v-simple-table>
            <template v-slot:default>
                <tbody v-if="details.ipaymu_id != 0">
                <tr>
                    <td>Payment Channel</td><td> {{ details.PaymentChannel }}</td>
                </tr>
                <tr>
                    <td>Payment Code</td><td> {{ details.PaymentCode }}</td>
                </tr>
                <tr>
                    <td>Sender</td><td> {{ details.Sender }}</td>
                </tr>
                <tr>
                    <td>Receiver </td><td> {{ details.Receiver }} </td>
                </tr>
                <tr>
                    <td>Type</td><td> {{ details.Type }}</td>
                </tr>
                <tr>
                    <td>Type Desc</td><td> {{ details.TypeDesc }}</td>
                </tr>
                <tr>
                    <td>Notes</td><td> {{ details.Notes }}</td>
                </tr>
                <tr>
                    <td>Success Date</td><td> {{ details.SuccessDate }}</td>
                </tr>
                <tr>
                    <td>Settlement Date</td><td> {{ details.SettlementDate }}</td>
                </tr>
                <tr>
                    <td>Created Date</td><td> {{ details.CreatedDate }}</td>
                </tr>
                <tr>
                    <td>Expired Date</td><td> {{ details.ExpiredDate }}</td>
                </tr>
                </tbody>
            </template>
            </v-simple-table>
        </v-flex>
        </v-layout>
        <p>&nbsp;</p>
        <div style="padding:0 10px; margin:30px 0;">
        <h3>Data Transaksi Dinaran</h3>
            <p></p>
            <hr />
            <p></p>
        </div>
        <v-layout row wrap>
        <v-flex md6 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Data Transaksi Ipaymu </b> </v-toolbar>
            <v-simple-table>
            <template v-slot:default>
                <tbody v-if="dinDetailsIp == null">
                <tr>
                    <td align="center"> - Tidak Ditemukan  Data - </td>
                </tr>
                </tbody>
                <tbody v-if="dinDetailsIp != null">
                <tr v-if="dinDetailsIp == null">
                    <td align="center"> - Tidak Ditemukan Data - </td>
                </tr>
                <tr v-if="dinDetailsIp != null">
                    <td>Ipaymu ID</td>
                    <td v-if="dinDetailsIp.ipaymu_id == null"> - </td>
                    <td v-if="dinDetailsIp.ipaymu_id != null"> {{ dinDetailsIp.ipaymu_id }}</td>
                    <!-- <td> {{ dinDetailsIp.ipaymu_id }}</td> -->
                </tr>
                <tr>
                    <td>Ref ID</td>
                    <td v-if="dinDetailsIp.ipaymu_id == null"> - </td>
                    <td v-if="dinDetailsIp.ref_id != null"> {{ dinDetailsIp.ref_id }}</td>
                </tr>
                <tr>
                    <td>Buyer Name</td>
                    <td v-if="dinDetailsIp.ipaymu_id == null"> - </td>
                    <td v-if="dinDetailsIp.ipaymu_id != null"> {{ details.BuyerName }}</td>
                </tr>
                <tr>
                    <td>Buyer Email</td>
                    <td v-if="dinDetailsIp.ipaymu_id == null"> - </td>
                    <td v-if="dinDetailsIp.ipaymu_id != null"> {{ details.BuyerEmail }}</td>
                </tr>
                <tr>
                    <td>Amount </td>
                    <td v-if="dinDetailsIp.ipaymu_id == null"> - </td>
                    <td v-if="dinDetailsIp.ipaymu_id != null"> {{ details.Amount }} </td>
                </tr>
                <tr>
                    <td>Fee </td>
                    <td v-if="dinDetailsIp.ipaymu_id == null"> - </td>
                    <td v-if="dinDetailsIp.ipaymu_id != null"> {{ details.Fee }}</td>
                </tr>
                <tr>
                    <td> Status</td>
                    <td  v-if="dinDetailsIp.ipaymu_id == null"> - </td>
                    <td  v-if="dinDetailsIp.ipaymu_id != null"> {{ dinDetailsIp.status }}</td>
                </tr>
                <tr>
                    <td> Status Desc</td>
                    <td  v-if="dinDetailsIp.ipaymu_id == null"> - </td>
                    <td  v-if="dinDetailsIp.ipaymu_id != null && dinDetailsIp.status == 0"> Pending </td>
                    <td  v-if="dinDetailsIp.ipaymu_id != null && dinDetailsIp.status != 0"> Berhasil </td>
                </tr>
                </tbody>
            </template>
            </v-simple-table>

        </v-flex>

        <v-flex md6 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Detail Transaksi </b> </v-toolbar>
            <v-simple-table>
            <template v-slot:default>
                <tbody v-if="dinDetailsDin != null">
                <tr>
                    <td>Payment Channel</td>
                    <td> {{ details.PaymentChannel }}</td>
                </tr>
                <tr>
                    <td>Payment Code</td>
                    <td> {{ details.PaymentCode }}</td>
                </tr>
                <tr>
                    <td>Sender</td>
                    <td> {{ details.Sender }}</td>
                </tr>
                <tr>
                    <td>Receiver </td>
                    <td> {{ details.Receiver }} </td>
                </tr>
                <tr>
                    <td>Type</td>
                    <td> {{ details.Type }}</td>
                </tr>
                <tr>
                    <td>Type Desc</td>
                    <td> {{ details.TypeDesc }}</td>
                </tr>
                <tr>
                    <td>Notes</td>
                    <td> {{ details.Notes }}</td>
                </tr>
                <tr>
                    <td>Success Date</td>
                    <td> {{ details.SuccessDate }}</td>
                </tr>
                <tr>
                    <td>Settlement Date</td>
                    <td> {{ details.SettlementDate }}</td>
                </tr>
                <tr>
                    <td>Created Date</td>
                    <td> {{ details.CreatedDate }}</td>
                </tr>
                <tr>
                    <td>Expired Date</td>
                    <td> {{ details.ExpiredDate }}</td>
                </tr>
                </tbody>
            </template>
            </v-simple-table>
        </v-flex>
        </v-layout>
        <p>&nbsp;</p>
        <div style="padding:0 10px; margin:30px 0;">
        <h3>Data Log Transaksi Dinaran</h3>
            <p></p>
            <hr />
            <p></p>
        </div>
        <v-layout>
        <v-flex md12 class="px-2">
            <v-toolbar color="#9e9e9e59" dense flat> <b> Detail Transaksi </b> </v-toolbar>
            <v-simple-table>
            <template v-slot:default>
                <tbody v-if="dinDetailsLog == null">
                    <tr>
                        <td align="center"> - Tidak Ditemukan  Data - </td>
                    </tr>
                    </tbody>
                    <tbody v-if="dinDetailsLog != null && details.ipaymu_id != 0">
                    <tr>
                        <td>Payment Channel</td>
                        <td> {{ details.PaymentChannel }} </td>
                    </tr>
                    <tr>
                        <td>Payment Code</td>
                        <td> {{ details.PaymentCode }}</td>
                    </tr>
                    <tr>
                        <td>Sender</td>
                        <td> {{ details.Sender }}</td>
                    </tr>
                    <tr>
                        <td>Receiver </td>
                        <td> {{ details.Receiver }} </td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td> {{ details.Type }}</td>
                    </tr>
                    <tr>
                        <td>Type Desc</td>
                        <td> {{ details.TypeDesc }}</td>
                    </tr>
                    <tr>
                        <td>Notes</td>
                        <td> {{ details.Notes }}</td>
                    </tr>
                    <tr>
                        <td>Success Date</td>
                        <td> {{ details.SuccessDate }}</td>
                    </tr>
                    <tr>
                        <td>Settlement Date</td>
                        <td> {{ details.SettlementDate }}</td>
                    </tr>
                    <tr>
                        <td>Created Date</td>
                        <td> {{ details.CreatedDate }}</td>
                    </tr>
                    <tr>
                        <td>Expired Date</td>
                        <td> {{ details.ExpiredDate }}</td>
                    </tr>
                </tbody>
            </template>
            </v-simple-table>
        </v-flex>
        </v-layout>
        <div style="width:100%;" v-show="isDisabled === true">
            <div style="float:right; margin-top:30px;"> 
            <v-btn
                color="success"
                medium
                outlined
                @click="addTrans()">
                <v-icon>mdi-plus</v-icon> Create Transactions
            </v-btn>
            </div>
        </div>
        <br clear="all" />
    </div>
</template>

<script>
//import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      isDisabled:false,
      details:[],
      dinDetailsIp:null,
      dinDetailsDin:null,
      dinDetailsLog:null,
      isSubmits:false,
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      tab: null,
      items: [
        'Trx Pembelian', 'Trx Penjualan'
      ],
      loading: false,
      loadingText: "Loading Data...",
      searchForm:{

      }
    };
  },
  methods: {
    fetch() {

      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      this.isSubmits = true;

      //alert("limits "+this.pagination.itemsPerPage);
        this.$store
          .dispatch("transaction/manualtrx", {
              ipaymuid:this.searchForm.member
          })
          .then(() => {
            this.$store.commit("main/setLoading", false);
            this.loading = false;
            this.loadingtext = '';

            this.details        = this.$store.state.transaction.member.manualList;
            this.dinDetailsIp   = this.$store.state.transaction.member.dinmanualList;
            this.dinDetailsDin  = this.$store.state.transaction.member.dinTrxDinList;
            this.dinDetailsLog   = this.$store.state.transaction.member.dinTrxDinLog;

            //console.log("detailsDin" , this.dinDetailsDin);

            if(this.dinDetailsIp == null){
              this.isDisabled = true;
            }

          })
          .catch((res) => {
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
            this.loading = false;
            this.loadingtext = '';
        });

    },
    clear(){
        this.details        = null;
        this.dinDetailsIp   = null;
        this.dinDetailsDin  = null;
        this.dinDetailsLog  = null;

        this.isDisabled = false;
    },
    addTrans(){
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      this.$store
          .dispatch("transaction/postTrxManual", {
              ref_id:this.details.ReferenceId,
              transaksi:JSON.stringify(this.dinDetailsLog),
              ipaymu:JSON.stringify(this.details)
          })
          .then(() => {
            this.$store.commit("main/setLoading", false);
            this.loading = false;
            this.loadingtext = '';
            this.$store.commit("main/setSnackbarSuccess", "Success");
            this.fetch();

          // console.log("lengthMax" , this.dinDetailsIp);
            if(this.dinDetailsIp == null){
              this.isDisabled = false;
            }

          })
          .catch((res) => {
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
            this.loading = false;
            this.loadingtext = '';
      });
    }
  },
  mounted() {
   this.details = this.$store.state.transaction.member.manualList;
   this.clear();
  },
  watch: {
    pagination() {
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            this.fetch()
    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: {  },
};

</script>

<style>
</style>