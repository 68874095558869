import request_util from '../util/request'

import request_util_download from '../util/requestdownload'
//tes

export function apiFetchListMember(data) {
    return request_util({
        url: '/admin/member/list',
        method: 'POST',
        data: data
    })
}

// export function apifetchListCorpMember(data) {
//     return request_util({
//         url: '/admin/corporate/list',
//         method: 'POST',
//         data: data
//     })
// }

export function apiFetchEmasImpian(data) {
    return request_util({
        url: '/admin/member/emas-impian',
        method: 'POST',
        data: data
    })
}

export function apiRebalanceImpian(data) {
    return request_util({
        url: '/admin/member/rebalance-impian',
        method: "POST",
        data: data
    })
}

export function apifetchListCorpMember(data) {
    return request_util({
        url: '/admin/corporate/corporateList',
        method: 'POST',
        data: data
    })
}

export function apiFetchListTotals(data) {
    return request_util({
        url: '/admin/member/totals',
        method: 'POST',
        data: data
    })
}

export function apiFetchListCorpTotals(data) {
    return request_util({
        url: '/admin/member/corptotals',
        method: 'POST',
        data: data
    })
}

export function apiFetchProfitLoss(data) {
    return request_util({
        url: '/admin/dashrankprofitloss',
        method: 'POST',
        data: data
    })
}

export function apiFetchListRank(data) {
    return request_util({
        url: '/admin/member/rank',
        method: 'POST',
        data: data
    })
}

export function apiFetchDetailListRank(data) {
    return request_util({
        url: '/admin/member/detail/rank',
        method: 'POST',
        data: data
    })
}

export function apiFetchMemberAll(data) {
    return request_util({
        url: '/admin/member/allmembersnolimit',
        method: 'POST',
        data: data
    })
}

export function apiFetchMemberTransAll(data) {
    return request_util({
        url: '/admin/member/allmemberswithtrans',
        method: 'POST',
        data: data
    })
}

export function apiPostLockGold(data) {
    return request_util({
        url: '/admin/member/postGoldLock',
        method: 'POST',
        data: data
    })
}

export function apiEditProfiles(data) {
    return request_util({
        url: '/admin/member/editProfiles',
        method: 'POST',
        data: data
    })
}

export function apiEditVerification(data) {
    return request_util({
        url: '/admin/member/editVerification',
        method: 'POST',
        data: data
    })
}

export function apiEditUnverify(data) {
    return request_util({
        url: '/admin/member/userUnverify',
        method: 'POST',
        data: data
    })
}

export function apiSuspendUser(data) {
    return request_util({
        url: '/admin/member/userSuspend',
        method: 'POST',
        data: data
    })
}

export function apiUnSuspendUser(data) {
    return request_util({
        url: '/admin/member/userUnSuspend',
        method: 'POST',
        data: data
    })
}

export function exportHighBuy(data) {
    return request_util({
        url: '/admin/member/highbuy',
        method: 'POST',
        data: data
    })
}
// IDCASH MEMBER

export function apiFetchListMemberIdCash(data) {
    return request_util({
        url: '/admin/member/listIdcash',
        method: 'POST',
        data: data
    })
}

export function apiFetchListReqVerify(data) {
    return request_util({
        url: '/admin/member/listMemberVerify',
        method: 'POST',
        data: data
    })
}

export function apiMemberEditVerification(data) {
    return request_util({
        url: '/admin/member/editVerify',
        method: 'POST',
        data: data
    })
}

export function apiMemberAddVerification(data) {
    return request_util({
        url: '/admin/member/addcorporate',
        method: 'POST',
        data: data
    })
}

export function apiFetchListVerifyDetail(data) {
    return request_util({
        url: '/admin/member/verifyDetail',
        method: 'POST',
        data: data
    })
}

export function apiFetchListMemberLogs(data) {
    return request_util({
        url: '/admin/settings/listLogActivities',
        method: 'POST',
        data: data
    })
}

export function apiRequestVerification(data) {
    return request_util({
        url: '/admin/member/editRequestVerify',
        method: 'POST',
        data: data
    })
}

export function apiFetchListVerifyEmoney(data) {
    return request_util({
        url: '/admin/member/listVerifyEmoney',
        method: 'POST',
        data: data
    })
}

export function apiEditMemberVerifEmoney(data) {
    return request_util({
        url: '/admin/member/apiEditMemberEmoney',
        method: 'POST',
        data: data
    })
}

export function apiMemberThirdParty(data) {
    return request_util({
        url: '/admin/member/listVerifyThirdparty',
        method: 'POST',
        data: data
    })
}

export function apieditMemberThirdParty(data) {
    return request_util({
        url: '/admin/member/verifyThirdpartyUpdate',
        method: 'POST',
        data: data
    })
}

export function apieditUserLock(data) {
    return request_util({
        url: '/admin/member/verifyThirdpartyLocked',
        method: 'POST',
        data: data
    })
}

export function apiFetchReqListMemberIdCash(data) {
    return request_util({
        url: '/admin/member/reqcardThirdparty',
        method: 'POST',
        data: data
    })
}

export function apiNewIdCash(data) {

    return request_util({
        url: '/admin/member/addCardNewIdCash',
        method: 'POST',
        data: data
    })

}

export function apiApproveCorporate(data) {

    return request_util({
        url: '/admin/corporate/update-status',
        method: 'POST',
        data: data
    })

}

export function apiAddCorporateMember(data) {

    return request_util({
        url: '/admin/member/addCorporateMember',
        method: 'POST',
        data: data
    })

}

export function apiApproveIdCash(data) {
    return request_util({
        url: '/admin/member/apiApproveIdCash',
        method: 'POST',
        data: data
    })
}

export function apifetchGoldRateList(data) {
    return request_util({
        url: '/admin/goldrate/list',
        method: 'POST',
        data: data
    })
}

export function ApiExportMemberExcel(data) {
    return request_util_download({
        url: '/admin/export/memberDownload',
        method: 'POST',
        data: data
    })
}

export function ApiThMemberExcel(data) {
    return request_util_download({
        url: '/admin/export/thirdpartyDownload',
        method: 'POST',
        data: data
    })
}

//export default function apiFetchListMember()