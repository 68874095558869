<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->
    
    <v-container no-gutters style="margin-top:-75px;">

            <v-row
                class="flex-row-reverse"
                no-gutters>

            <v-col
                sm="2"
                md="2">
                   <v-btn
                        elevation="2"
                        color="primary"
                        style="margin-top:12px;"
                        @click="getSubmitYear()"> 
                            <v-icon>mdi-magnify</v-icon> Filter Date 
                    </v-btn>
            </v-col>

             <v-col
                    sm="4"
                    md="4"
                    >
            
                    <v-menu
                        ref="menudates"
                        v-model="menudates"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">

                        <template v-slot:activator="{ on, attrs }">

                            <v-text-field
                                v-model="searchForm.globaldates"
                                label="Register FromDate"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>

                        </template>
                        <v-date-picker
                        v-model="searchForm.globaldates"
                        type="date"
                        no-title
                        scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="menudates = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menudates.save(date)"
                        >
                            OK
                        </v-btn>

                    </v-date-picker>
                </v-menu>
            </v-col>

        </v-row>

    </v-container>


    <v-container>

        <v-row
            no-gutters>

            <v-col
                sm="6"
                md="6"
                style="margin-bottom:10px;">
                <v-col
                sm="12"
                md="12">
                    <v-card
                        elevation="2"
                        style="border-top: 3px solid #17a2b8;"
                        >
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center">
                                <v-icon medium color="#17a2b8">mdi-web</v-icon> 
                                <div style="font-size:18px; color:#333;"> 50.140 </div>
                                <div>  <small  style="font-size:10px;"> Browser Desktop Akses </small> </div>
                        </v-col>
                    </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="6"
                md="6"
                style="margin-bottom:10px;">
                <v-col
                sm="12"
                md="12">
                    <v-card
                        elevation="2"
                        style="border-top: 3px solid #17a2b8;"
                        >
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center">
                                <v-icon medium color="#17a2b8">mdi-phone</v-icon> 
                                <div style="font-size:18px; color:#333;"> 45.215 </div>
                                <div>  <small  style="font-size:10px;"> Mobile Browser Akses  </small> </div>
                        </v-col>

                    </v-card>
                </v-col>
            </v-col>

        </v-row>

        <v-row
                no-gutters>

         <!--    <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                sm="12"
                md="12">
                    <v-card
                        elevation="2"
                        style="border-top: 3px solid #17a2b8;"
                        >
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center">
                                <v-icon medium color="#17a2b8">mdi-android</v-icon> 
                                <div style="font-size:14px; color:#333;" v-html="andrReviews"></div>
                                <div>  <small  style="font-size:10px;"> User Reviews </small> </div>
                        </v-col>
                    </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                sm="12"
                md="12">
                    <v-card
                        elevation="2"
                        style="border-top: 3px solid #17a2b8;"
                        >
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center">
                                <v-icon medium color="#17a2b8">mdi-android</v-icon> 
                                <div style="font-size:14px; color:#333;" v-html="andrDownloads"></div>
                                <div>  <small  style="font-size:10px;"> Total Download </small> </div>
                        </v-col>
                    </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                sm="12"
                md="12">
                    <v-card
                        elevation="2"
                        style="border-top: 3px solid #17a2b8;"
                        >
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center">
                                <v-icon medium color="#17a2b8">mdi-android</v-icon> 
                                <div style="font-size:14px; color:#333;" v-html="andrRates"></div>
                                <div>  <small  style="font-size:10px;"> Rated For </small> </div>
                        </v-col>
                    </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                sm="12"
                md="12">
                    <v-card
                        elevation="2"
                        style="border-top: 3px solid #17a2b8;"
                        >
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center">
                                <v-icon medium color="#17a2b8">mdi-apple</v-icon> 
                                <div style="font-size:14px; color:#333;" v-html="appReviews"></div>
                                <div>  <small  style="font-size:10px;"> Total User Reviews </small> </div>
                        </v-col>
                    </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                sm="12"
                md="12">
                    <v-card
                        elevation="2"
                        style="border-top: 3px solid #17a2b8;"
                        >
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center">
                                <v-icon medium color="#17a2b8">mdi-apple</v-icon> 
                                <div> 0 <br /> - </div>
                                <div>  <small  style="font-size:10px;"> Total User Download </small> </div>
                        </v-col>
                    </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                sm="12"
                md="12">
                    <v-card
                        elevation="2"
                        style="border-top: 3px solid #17a2b8;"
                        >
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center">
                                <v-icon medium color="#17a2b8">mdi-apple</v-icon> 
                                <div> 0 <br /> - </div>
                                <div>  <small  style="font-size:10px;"> Total User Rates </small> </div>
                        </v-col>
                    </v-card>
                </v-col>
            </v-col>

            -->

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                sm="12"
                md="12">
                    <v-card
                        elevation="2"
                        style="border-top: 3px solid #17a2b8;"
                        >
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center">
                                <v-icon medium color="#17a2b8">mdi-account-circle</v-icon> 
                                <h5 style="font-size:28px; color:#888;"> {{ jmltranslist.members }} </h5>
                                <div>  <small  style="font-size:10px;"> Total User Register </small> </div>
                        </v-col>
                    </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                    sm="12"
                    md="12">
                        <v-card
                            elevation="2"
                            style="border-top: 3px solid #17a2b8;">

                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="text-center">
                                    <v-icon medium color="#17a2b8">mdi-account-check</v-icon> 
                                    <h5 style="font-size:28px; color:#888;"> {{ jmltranslist.verified }} </h5>
                                    <small  style="font-size:10px;"> Total User Aktif </small>
                            </v-col>

                        </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                    sm="12"
                    md="12">
                        <v-card
                            elevation="2"
                            style="border-top: 3px solid #17a2b8;"
                            >
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="text-center">
                                    <v-icon medium color="#17a2b8">mdi-account-minus</v-icon> 
                                    <h5 style="font-size:28px; color:#888;"> {{ jmltranslist.unverified }} </h5>
                                    <small  style="font-size:10px;"> Total User Non Aktif </small>
                            </v-col>
                        </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                    sm="12"
                    md="12">
                        <v-card
                            elevation="2"
                            style="border-top: 3px solid #B59410;"
                            >
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="text-center">
                                    <v-icon medium color="#B59410">mdi-account-circle</v-icon> 
                                    <h5 style="font-size:28px; color:#888;"> {{ jmltranslist.reqVerifs }} </h5>
                                    <small  style="font-size:10px;"> Total Request Verifikasi </small>
                            </v-col>
                        </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                    sm="12"
                    md="12">
                        <v-card
                            elevation="2"
                            style="border-top: 3px solid #B59410;"
                            >
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="text-center">
                                    <v-icon medium color="#B59410">mdi-account-check</v-icon> 
                                    <h5 style="font-size:28px; color:#888;"> {{ jmltranslist.userVerif }} </h5>
                                    <small  style="font-size:10px;"> Total User Verified </small>
                            </v-col>
                        </v-card>
                </v-col>
            </v-col>

            <v-col
                sm="2"
                md="2"
                style="margin-bottom:10px;">
                <v-col
                    sm="12"
                    md="12">
                        <v-card
                            elevation="2"
                            style="border-top: 3px solid #B59410;"
                            >
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="text-center">
                                    <v-icon medium color="#B59410">mdi-account-minus</v-icon> 
                                    <h5 style="font-size:28px; color:#888;"> {{ jmltranslist.pendVerif }} </h5>
                                    <small style="font-size:10px;"> Total User Unverified </small>
                            </v-col>
                        </v-card>
                </v-col>
            </v-col>

        </v-row> 

        <v-row
            no-gutters>

            <v-col
                sm="6"
                md="6">
                
                <v-col
                    sm="12"
                    md="12">
                    
                    <template>
                        <div class="chart">
                           
                            <p></p>
                            <center>
                                <h3> Grafik Registrasi Member ( Tahunan )</h3>

                            <v-row style="margin-top:10px;">

                                <v-col
                                    sm="5"
                                    md="5">

                                    <v-text-field
                                        sm="12"
                                        md="12"
                                        v-model="searchForm.year_registrasi"
                                        hide-details
                                        single-line
                                        type="number"
                                        
                                    />
                                </v-col>

                                <v-col
                                    sm="3"
                                    md="3">

                                    <v-btn
                                        elevation="2"
                                        color="primary"
                                        style="margin-top:12px; font-size:12px;"
                                        @click="getSubmitYear()"> 
                                            <v-icon>mdi-magnify</v-icon> Search 
                                    </v-btn>

                                </v-col>

                                <v-col
                                    sm="3"
                                    md="3">

                                    <v-btn
                                        elevation="2"
                                        color="red"
                                        style="margin-top:12px; font-size:12px; color:#ffffff;"
                                        @click="exportPdf('BarMemberTahunan' , 'BarMemberTahunan.pdf')"> 
                                            <v-icon>mdi-download</v-icon> Export Pdf 
                                    </v-btn>

                                </v-col>

                            </v-row>
                                
                            </center>

                            <p></p>

                            <Bar
                                :chart-options="chartOptions"
                                :chart-data="chartData"
                                :chart-id="chartId"
                                :dataset-id-key="datasetIdKey"
                                :plugins="plugins"
                                :css-classes="cssClasses"
                                :styles="styles"
                                :width="width"
                                :height="height"
                                id="BarMemberTahunan" />
                            
                        </div>

                    </template>

                </v-col>

            </v-col>

            <v-col
                sm="6"
                md="6">

                <v-col
                    sm="12"
                    md="12">

                    <template>
                        <div class="chart">
                           
                            <p></p>
                            <center>
                                <h3> Grafik Registrasi Member ( Bulanan )</h3>

                                <v-row style="margin-top:10px;">

                                    <v-col
                                        sm="5"
                                        md="5"
                                        >
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="date"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="searchForm.date"
                                                label="Register FromDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="searchForm.date"
                                            type="text"
                                            no-title
                                            scrollable
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menu = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menu.save(date)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col
                                        sm="3"
                                        md="3">

                                        <v-btn
                                           
                                            color="primary"
                                            style="margin-top:12px; font-size:12px;"
                                            @click="getSubmitYear()"> 
                                                <v-icon>mdi-magnify</v-icon> Search 
                                        </v-btn>

                                    </v-col>

                                    <v-col
                                        sm="3"
                                        md="3">

                                        <v-btn
                                            
                                            color="red"
                                            style="margin-top:12px; color:#ffffff;  font-size:12px;"
                                            @click="exportPdf('BarMemberBulanan' , 'BarMemberBulanan.pdf')"> 
                                                <v-icon>mdi-download</v-icon> Export Pdf 
                                        </v-btn>

                                    </v-col>

                            </v-row>
                                
                            </center>

                            <p></p>
                            
                            <Bar
                                :chart-options="chartOptions"
                                :chart-data="chartData2"
                                :chart-id="chartId"
                                :dataset-id-key="datasetIdKey"
                                :plugins="plugins"
                                :css-classes="cssClasses"
                                :styles="styles"
                                :width="width"
                                :height="height"
                                id="BarMemberBulanan"
                            />
                            
                        </div>
                    </template>

                </v-col>

            </v-col>

        </v-row>

    </v-container>


  </div>
</template>

<script>
//import CustomCard from "../../../../components/CustomCard.vue";
//import { Bar } from 'vue-chartjs'
//import { Bar } from 'vue-chartjs/legacy'
//import LineChart from '../../../../components/Line.vue'
//import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

import { Bar } from 'vue-chartjs/legacy'
import Exporter from "vue-chartjs-exporter";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
name: 'BarChart',
components: {
    Bar
},
props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    modal: false,
    panel: null,
    name: 'BarChart',
    profiles:this.$store.state.auth.profile,
    storesMarket:[],
    jmltranslist:[],
    andrReviews:"",
    andrDownloads:"",
    andrRates:"",
    appReviews:"",
    year_registrasi:"",
    provinsiList:[],
    chartOptions: {
        responsive: true,
        maintainAspectRatio: false
    },
    chartData:{},
    chartData2:{},
    currentPage:1,
    decimals:"10.00",
    icons:"chevron-right",
    status: [
        { text: "All Status", value: "" },
        { text: "Email Verified", value: "email_verified" },
        { text: "Email Not Verified", value: "email_not_verified" },
        { text: "User Verified", value: "user_verified" },
        { text: "User Not Verified", value: "user_not_verified" },
        { text: "User Suspend", value: "user_suspend" }
    ],
    searchForm: {
    member: '',
    year_registrasi:''
    },
        formLock:{

        },
        
        tipe: [],
        menu: null,
        form: {},
        pagination: {},
        headers: [
        { text: "ID", value: "id"},
        //  { text: "Trx", align: "center", value: "trxCount" },
        { text: "Tanggal", align: "left", value: "created" , width:120},
        { text: "Member", align: "left", value: "membersDetail"},
        //  { text: "Lock Bal (gr)", align: "center" , value: "lock_balance" , width: "150"},
        //  { text: "Reg Date", value: "verified_date"},
        { text: "Score", align: "left", value: "scores" , width:150},
        //  { text: "M-Ver", value: "verified"},
        { text: "Reward", align: "left", value: "rewards" , width:250 },
        { text: "Note", align: "left", value: "note" },
        { text: "Update By", align: "left", value: "admin.name" },
        //{ text: "Action", align: "center", value: "action" , width: "120"},
        ],
        loading: false,
        loadingText: "Loading Data...",
    };
},
  methods: {
    getSubmitYear() {
        this.fetch(0 , this.searchForm.year_registrasi);
    },
    exportPdf(idfile,namafile) {
        //this.fetch(0 , this.searchForm.yearsDate);
        let bar = document.getElementById(idfile);

        //const exp = new Exporter([line, bar, radar, pie, area]);
        const exp = new Exporter([bar]);
        exp.export_pdf().then((pdf) => pdf.save(namafile));
    },
    fetchStore() {

        // this.$store.commit("main/setLoading", true);
        // this.loading = true;
        // this.loadingtext = 'Loading... Please wait';
        //alert("limits "+this.pagination.itemsPerPage);
        //alert("tes new");
        this.$store
        .dispatch("settings/openMarketData", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            // years:"2022"
        })
        .then(() => {
            // this.$store.commit("main/setLoading", false);
            // this.loading = false;
            // this.loadingtext = '';
            this.storesMarket = this.$store.state.settings.channel.marketList;
            this.andrReviews = this.storesMarket[0].vars;
            this.andrReviews = this.andrReviews.replace("star" , " star<br />");

            this.andrDownloads = this.storesMarket[1].vars;
            this.andrDownloads = this.andrDownloads.replace("Downloads" , "<br />Downloads");

            this.andrRates = this.storesMarket[2].vars;
            this.andrRates = this.andrRates.replace("for" , "for<br />");

            this.appReviews = this.storesMarket[3].vars;
            this.appReviews = this.appReviews.replace("•" , " •<br />");

        })
        .catch((res) => {
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
            this.loading = false;
            this.loadingtext = '';
        });

    },
    fetch(cari , year) {

      if(cari == 1){
          this.pagination.page = 1;
      }

      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      //alert("limits "+this.pagination.itemsPerPage);
      //alert("tes new");
      this.$store
        .dispatch("transaction/apiHitDataMemberChart", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            year:year,
            yearSearch:this.searchForm.date,
            enddate:this.searchForm.globaldates
            // years:"2022"
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
          
          this.jmltranslist = this.$store.state.transaction.member.totalMemberFormatted;
         // console.log("tesjmltrans" , this.jmltranslist);

            this.chartData = {
                labels: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'Agustus',
                'September',
                'Oktober',
                'November',
                'Desember'
                ],
                datasets: [
                    {
                        label: 'User Register',
                        backgroundColor: 'rgb(16, 121, 72)',
                        data: this.$store.state.transaction.member.totaltahunan
                    }
                ]
            };

            this.chartData2 = {
                // labels: [
                // '01',
                // '02',
                // '03',
                // '04',
                // '05',
                // '06',
                // '07',
                // '08',
                // '09',
                // '10',
                // '11',
                // '12',
                // '13',
                // '14',
                // '15',
                // '16',
                // '17',
                // '18',
                // '19',
                // '20',
                // '21',
                // '22',
                // '23',
                // '24',
                // '25',
                // '26',
                // '27',
                // '28',
                // '29',
                // '30',
                // '31'
                // ],
                labels:this.$store.state.transaction.member.lastDaytrans,
                datasets: [
                    {
                        label: 'User Register Daily',
                        backgroundColor: 'rgb(181, 148, 16)',
                        data: this.$store.state.transaction.member.totalbulanan
                    }
                ]
            };
          //this.renderLineChart();
          //alert(this.$store.state.transaction.member.transaksi);
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
    var dt = new Date();

     if(this.searchForm.year_registrasi == null || this.searchForm.year_registrasi == ""){
        this.searchForm.year_registrasi = dt.getFullYear();
     }

     //alert(this.searchForm.year_registrasi);
      this.fetch(0 , this.searchForm.year_registrasi);
      //this.fetchStore()
      
      this.year_registrasi = dt.getFullYear(); 
    //   let {chartType,chartData,chartOptions} = this;
    //   this.chartConstructor(chartType, chartData, chartOptions);
      //this.jmltranslist = this.$store.state.transaction.member.transaksi;
     // console.log("translist" , "tesbaca" + this.$store.state.transaction.member.transaksi);
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        }

        if(this.$store.state.member.member.is_back == 1){
            this.pagination.page = this.$store.state.member.member.paging_page;
        }

        this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
    
      
  },
};
</script>

<style>
</style>