<template>
  <div>

    <v-container grid-list-xs fluid>
        <List v-if="$store.state.settings.attributes.page == 'list'"></List>
        <Detail v-if="$store.state.settings.attributes.page == 'detail'"></Detail>
        <Edit v-if="$store.state.settings.attributes.page == 'edit'"></Edit>
    </v-container>

  </div>
</template>

<script>

import List from "./admin/list.vue";
import Detail from "./admin/detail.vue";
import Edit from "./admin/edit.vue";

export default { 
  data() {
    return {
      pages:"list"
    }
    },
    mounted() {
      this.$store.commit("settings/setPageEmail", "list");
    },
    components: { List, Detail , Edit } 
};

</script>

<style>

</style>