<template>
  <div>
    <v-container grid-list-xs fluid>
        <List v-if="$store.state.transaction.exports.page == 'list'"></List>
    </v-container>
  </div>
</template>

<script>
import List from "./export/list.vue";
// export default { components: { List, Detail } };
// import List from "./thirdparty/list.vue";
// import Detail from "./thirdparty/detail.vue";
export default { 
  data() {
    return {
        pages:"list"
    }
    },
    mounted() {
       // this.$store.commit("transaction/setMemberHisGoldPage", "list");
    },
    components: { List } 
};

</script>

<style>
</style>