<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <CustomCard>

        <template v-slot:toolbar>

            <div style="width:100%;">

                <v-btn color="success" small outlined @click="filterToggle" style="float:left;">
                    filter <v-icon>mdi-chevron-down</v-icon>
                </v-btn>

                <v-dialog
                    transition="dialog-top-transition"
                    max-width="600">
                    <!-- <template v-slot:activator="{ on, attrs }">
                        <v-btn color="success" small outlined  style="float:right;">
                            ADD RESELLER / AFFILIATE EKSLUSIF / AFFILIATE COMMUNITY
                        </v-btn>
                    </template> -->
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="success" small outlined  style="float:right;" 
                            v-bind="attrs"
                            v-on="on" 
                            @click="clearall()">
                            ADD PROMO
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                color="success"
                                dark
                                >ADD PROMO</v-toolbar>

                                <v-container>

                                    <v-layout>
                                        <v-flex>
                                            <v-autocomplete
                                                    v-model="formLock.text_reseller"
                                                    :items="typedinaran"
                                                    item-text="text"
                                                    item-value="value"
                                                    label="Type"
                                                >
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.text_image"
                                                label="Image">
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout>
                                        <v-textarea
                                            outlined
                                            v-model="formLock.textarea_note"
                                            label="Desc">
                                        </v-textarea>
                                    </v-layout>

                                </v-container>

                                <v-card-actions class="justify-end">
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="success"
                                    @click="postAffiliatePromo(dialog)"
                                >Submit</v-btn>
                                </v-card-actions>
                            </v-card>
                            </template>
                </v-dialog>

            </div>

        </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                        <v-flex>
                            <!-- <v-text-field
                                v-model="searchForm.search_type"
                                label="Type"
                                single-line
                                small
                                clearable
                            >
                            </v-text-field> -->
                            <v-autocomplete
                                v-model="searchForm.search_type"
                                :items="tipeAff"
                                item-text="text"
                                item-value="value"
                                label="Type"
                            >
                            </v-autocomplete>
                        </v-flex>

                        <!-- <v-flex>
                            <v-autocomplete
                                v-model="searchForm.tipereseller"
                                :items="tipereseller"
                                item-text="text"
                                item-value="value"
                                label="Type"
                            >
                            </v-autocomplete>
                        </v-flex> -->
                    
                </v-layout>

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">

            <v-data-table
                :headers="headers"
                :items="$store.state.affiliate.promo.list"
                :options.sync="pagination"
                :server-items-length="$store.state.affiliate.promo.total"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

            <template v-slot:[`item.promotions`]="{ item }">
                <v-card-text style="margin-top:20px;">
                    <img v-bind:src="item.image" style="width:250px;" />
                    <div style="border:1px solid #CCC; height:100px; background:#ffffff; padding:10px; width:250px; box-sizing:border-box;">
                        {{ item.desc }} <p></p>
                        <center>
                            
                             <v-btn color="red" small  style="margin-right:10px; color:white;">
                                Copy Code
                            </v-btn>

                            <v-btn color="success" small>
                                Copy Links
                            </v-btn>
                            
                        </center>
                    </div>
                </v-card-text> 
            </template>

            <template v-slot:[`item.statusact`]="{ item }">
                <div v-if="item.status == 0" style="color:red;"> <v-icon color="red"> mdi-block-helper </v-icon> Not Active  </div>
                <div v-if="item.status == 1" style="color:green;"> <v-icon color="green"> mdi-check </v-icon> Active </div>
            </template>

            <template v-slot:[`item.jenisaff`]="{ item }">
                <div> Dinaran {{ item.jenis }}  </div>
            </template>

                <!-- <template v-slot:[`item.email`]="{ item }">
                <div v-if="item.type_code == 1 || item.type_code == 10">
                    {{ item.receiver_email }}
                </div>
                <div v-else>
                    {{ item.sender_email }}
                </div>
                </template> -->
            </v-data-table>

            </v-flex>
        </template>
    </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";
//import downloadexcel from "vue-json-excel";


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      bankList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      status: [
            { text: "All Status", value: "" },
            { text: "Email Verified", value: "email_verified" },
            { text: "Email Not Verified", value: "email_not_verified" },
            { text: "User Verified", value: "user_verified" },
            { text: "User Not Verified", value: "user_not_verified" },
            { text: "User Suspend", value: "user_suspend" }
            // { text: "Gagal", value: "2" },
      ],
      json_fields: {
        'Id': 'id',
        'Name': 'name',
        'Email': 'email',
      },
      reward: [
            { text: "Balance", value: "balance" }
            // { text: "Gagal", value: "2" },
      ],
      tipereseller: [
            { text: "Register", value: "register" }
      ],
      tipeAff: [
            { text: "Viralmu Regular", value: "viralmu regular" },
            { text: "Dinaran Reguler", value: "dinaran reguler" },
            { text: "Dinaran Esklusif", value: "dinaran esklusif" },
      ],
      typedinaran: [
        { text: "Dinaran Reguler", value: "dinaran reguler" },
        { text: "Dinaran Ekslusif", value: "dinaran esklusif" },
        // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Date", value: "created_at" , width:120},
        { text: "Tipe Affiliate", value: "jenisaff"  , width:150},
        { text: "Promotion", align: "left", value: "promotions"},
        // { text: "Limit", align: "left", value: "limit"},
        // { text: "Limit Used", align: "left", value: "limit_used"},
        // { text: "Periode", align: "center", value: "periode_reward" , width:250},
        // { text: "Note", align: "center", value: "note"},
        { text: "Status", align: "center", value: "statusact"},
        //{ text: "Action", align: "center", value: "action"},
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    clear(){
        this.formLock.tipe_reseller = "";
        this.formLock.tipe_email = "";
    },
    fetchProv(params){
        this.$store
            .dispatch("wilayah/fetchWilayah", {
                find: params,
            })
            .then(() => {
                this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("prov" , res.response.data.message);
            });
    },
    fetchTotal(params){
        this.$store
            .dispatch("member/fetchtotals", {
                find: params,
            })
            .then(() => {
                //alert("tess");
                //this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("prov" , res.response.data.message);
            });
    },
    fetchAll() {
    //   if(cari == 1){
    //       this.pagination.page = 1;
    //   }
    //   this.loading = true;
    //   this.loadingtext = 'Loading... Please wait';
    //   this.$store.commit("main/setLoading", true);
      this.$store
        .dispatch("affiliate/fetchListAffiliateMember", {
            page: this.pagination.page,
            member:this.searchForm.member,
            religion:this.searchForm.religion,
            status_verified:this.searchForm.status_verified
        })
        .then((res) => {
        //   this.$store.commit("main/setLoading", false);
        //   this.loading = false;
        //   this.loadingtext = '';
              console.log("tesData" , res);
              return res;
        })
        .catch((res) => {
           console.log("fetchdata" , res.response.data.message);
        //   this.$store.commit("main/setLoading", false);
        //   this.$store.commit("main/setSnackbarFail", res.response.data.message);
        //   this.loading = false;
        //   this.loadingtext = '';
        });
    },
    startDownload(){
        alert('show loading');
    },
    finishDownload(){
        alert('hide loading');
    },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }

      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      
      this.$store
        .dispatch("affiliate/fetchPromoAffiliate", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            type:this.searchForm.search_type
            // tipe:this.searchForm.tipereseller
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });

    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
    clearall(){
        this.formLock.action_pembelian = "";
        this.formLock.referral_code  = "";
        this.formLock.jenis_pemberian  = "";
        this.formLock.limit_reward   = 1000;
        this.formLock.reward_amount  = 25000;
        this.searchForm.date  = "";
        this.searchForm.date2  = "";
        this.formLock.textarea_note  = "";
    },
    postAffiliatePromo: function(dialog){
        this.$store.commit("main/setLoading", true);
            this.$store
            .dispatch("affiliate/AddPromoPostAffiliate", {
                type: this.formLock.text_reseller,
                image: this.formLock.text_image,
                desc: this.formLock.textarea_note
            })
            .then(() => {
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Add Promo Reseller Successfully");

            this.fetch(0);
            dialog.value = false;
            })
            .catch((res) => {
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res.response.data.message);
            this.loading = false;
            this.loadingtext = '';
        });
    },
  },
  mounted() {
    this.formLock.limit_reward   = 1000;
    this.formLock.reward_amount  = 25000;
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        }

        if(this.$store.state.member.member.is_back == 1){
            this.pagination.page = this.$store.state.member.member.paging_page;
        }

        this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard  },
};
</script>

<style>
</style>