import MainComponent from '../../views/Main.vue'
import listTrxList from '../../views/pages/corporate/mainTransactionCorporate.vue'
import listReferralList from '../../views/pages/corporate/mainRefferalList.vue'
import listReferralTrx from '../../views/pages/corporate/mainRefferalListTrx.vue'

export default [
    {
        path: '/panello/',
        name: 'Main Transaction',
        component: MainComponent,
        children: [
            {
                path: '/panello/corporate-trans',
                name: 'Transaction Corporate List',
                component: listTrxList
            },
            {
                path: '/panello/referral-list',
                name: 'Refferal List',
                component: listReferralList
            },
            {
                path: '/panello/referral-trx',
                name: 'Transaction Refferal List',
                component: listReferralTrx
            },
        ]
    }
]