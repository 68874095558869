<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <v-container>

        <v-row
            no-gutters>

            <v-col
                sm="12"
                md="12">

                <v-col
                    sm="12"
                    md="12">

                    <template>
                        <div class="chart">
                           
                            <p></p>
                            <center>
                                <h3> Grafik Gold Rate ( Bulanan )</h3>

                            <v-row style="margin-top:10px;">

                                    <v-col
                                        sm="8"
                                        md="8">
                                       
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="date"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-text-field
                                                  v-model="searchForm.yearsDate"
                                                  label="Pilih Bulan & Tahun"
                                                  prepend-icon="mdi-calendar"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"
                                              ></v-text-field>
                                            </template>
                                              <v-date-picker
                                              v-model="searchForm.yearsDate"
                                              type="text"
                                              no-title
                                              scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.menu.save(date)">
                                                    OK
                                                </v-btn>
                                              </v-date-picker>
                                        </v-menu>

                                    </v-col>

                                    <v-col
                                        sm="2"
                                        md="2">

                                        <v-btn
                                            elevation="2"
                                            color="primary"
                                            style="margin-top:12px;"
                                            @click="getDataGoldRate()"> 
                                                <v-icon>mdi-magnify</v-icon> Search 
                                        </v-btn>

                                    </v-col>

                                    <v-col
                                        sm="2"
                                        md="2">

                                        <v-btn
                                            elevation="2"
                                            color="red"
                                            style="margin-top:12px; color:#ffffff;"
                                            @click="exportPdf()"> 
                                                <v-icon>mdi-download</v-icon> Export Pdf
                                        </v-btn>

                                    </v-col>

                            </v-row>
                                
                            </center>

                            <p></p>
                            
                            <Bar
                                :chart-options="chartOptions"
                                :chart-data="chartData2"
                                :chart-id="chartId"
                                :dataset-id-key="datasetIdKey"
                                :plugins="plugins"
                                :css-classes="cssClasses"
                                :styles="styles"
                                :width="width"
                                :height="height"
                                id="barCharts"
                            />
                            
                        </div>
                    </template>

                </v-col>

            </v-col>

        </v-row>

    </v-container>


  </div>
</template>

<script>
//import CustomCard from "../../../../components/CustomCard.vue";
//import { Bar } from 'vue-chartjs'
//import { Bar } from 'vue-chartjs/legacy'
//import LineChart from '../../../../components/Line.vue'
//import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
//ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

import { Bar } from 'vue-chartjs/legacy'
import Exporter from "vue-chartjs-exporter";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
name: 'BarChart',
components: {
    Bar
},
props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    modal: false,
    panel: null,
    name: 'BarChart',
    profiles:this.$store.state.auth.profile,
    jmltranslist:[],
    year_registrasi:"",
    provinsiList:[],
    chartOptions: {
        responsive: true,
        maintainAspectRatio: false
    },
    chartData:{},
    chartData2:{},
    currentPage:1,
    decimals:"10.00",
    icons:"chevron-right",
    status: [
        { text: "All Status", value: "" },
        { text: "Email Verified", value: "email_verified" },
        { text: "Email Not Verified", value: "email_not_verified" },
        { text: "User Verified", value: "user_verified" },
        { text: "User Not Verified", value: "user_not_verified" },
        { text: "User Suspend", value: "user_suspend" }
    ],
    searchForm: {
    member: '',
    year_registrasi:''
    },
        formLock:{

        },
        
        tipe: [],
        menu: null,
        form: {},
        pagination: {},
        headers: [
        { text: "ID", value: "id"},
        //  { text: "Trx", align: "center", value: "trxCount" },
        { text: "Tanggal", align: "left", value: "created" , width:120},
        { text: "Member", align: "left", value: "membersDetail"},
        //  { text: "Lock Bal (gr)", align: "center" , value: "lock_balance" , width: "150"},
        //  { text: "Reg Date", value: "verified_date"},
        { text: "Score", align: "left", value: "scores" , width:150},
        //  { text: "M-Ver", value: "verified"},
        { text: "Reward", align: "left", value: "rewards" , width:250 },
        { text: "Note", align: "left", value: "note" },
        { text: "Update By", align: "left", value: "admin.name" },
        //{ text: "Action", align: "center", value: "action" , width: "120"},
        ],
        loading: false,
        loadingText: "Loading Data...",
    };
},
  methods: {
    getDataGoldRate() {
        this.fetch(0 , this.searchForm.yearsDate);
    },
    exportPdf() {
        //this.fetch(0 , this.searchForm.yearsDate);
        let bar = document.getElementById("barCharts");

        //const exp = new Exporter([line, bar, radar, pie, area]);
        const exp = new Exporter([bar]);
        exp.export_pdf().then((pdf) => pdf.save("goldRate.pdf"));
    },
    fetch(cari , year) {

      if(cari == 1){
          this.pagination.page = 1;
      }

      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';
      //alert("limits "+this.pagination.itemsPerPage);
      //alert("tes new");
      this.$store
        .dispatch("goldrate/apiHitDataGoldChart", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            year:year,
            // years:"2022"
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
          
          this.jmltranslist = this.$store.state.transaction.member.totalMemberFormatted;
         // console.log("tesjmltrans" , this.jmltranslist);

          this.chartData2 = {
                labels: [
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
                '25',
                '26',
                '27',
                '28',
                '29',
                '30',
                '31'
                ],
                datasets: [
                    {
                        label: 'Buy Rate',
                        backgroundColor: 'rgb(16, 121, 72)',
                        data: this.$store.state.goldrate.attributes.arrays_bulanan_buyrate
                    },
                    {
                        label: 'Sell Rate',
                        backgroundColor: 'rgb(181, 148, 16)',
                        data: this.$store.state.goldrate.attributes.arrays_bulanan_sellrate
                    }
                ]
            };
          //this.renderLineChart();
          //alert(this.$store.state.transaction.member.transaksi);
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
        
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
    var dt = new Date();

     if(this.searchForm.year_registrasi == null || this.searchForm.year_registrasi == ""){
        this.searchForm.year_registrasi = dt.getFullYear();
     }

     //alert(this.searchForm.year_registrasi);
      this.fetch(0 , "");
      
      this.year_registrasi = dt.getFullYear(); 
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        }

        if(this.$store.state.member.member.is_back == 1){
            this.pagination.page = this.$store.state.member.member.paging_page;
        }

        this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
    
      
  },
};
</script>

<style>
</style>