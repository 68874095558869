<template>
<div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

<template v-for="(item, i) in $store.state.transaction.goldatm.listTotals">
                       
<v-container class="no-pad-left" :value="true" :key="i" no-action>

    <v-row
        no-gutters>

        <v-col
            sm="12"
            md="12"
            style="border-bottom:1px solid #DDDDDD;">
            <center>
              <b> <h4> Last Stock Mesin ATM <v-icon>mdi-arrow-right</v-icon>  {{ item.lokasi }}</h4> </b>
            </center>

            <p style="font-size:14px; margin-top:20px;">

            <center> Kode Mesin <v-icon>mdi-arrow-right</v-icon>  {{ item.kodemesin }} </center>

            </p>
        </v-col>

        <v-col
            sm="12"
            md="12" style="padding:10px;">
        </v-col>

        <v-col
            sm="2"
            md="2">
            <v-card
                elevation="2"
                style="border-top: 3px solid rgb(40, 167, 69);"
                >
                    <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="text-left">
                        <!-- <v-icon medium color="green">mdi-gold</v-icon>  -->
                        <!-- <v-icon>mdi-account-card-details</v-icon> -->
                        <h5 style="font-size:28px; color:#888;"> {{ item.totalall1 }}  </h5>
                        <div> <small> Total Keping 2gr </small> </div>
                        <div> <small> Nomor Rak 1 : {{ item.total1 != null ? item.total1 : "0" }} </small> </div> 
                        <div> <small> Nomor Rak 2 : {{ item.total2 != null ? item.total2 : "0"   }} </small> </div>
                </v-col>
            </v-card>
        </v-col>

        <v-col
            sm="2"
            md="2"
            style="margin-left:20px;">
            <v-card
                elevation="2"
                style="border-top: 3px solid rgb(40, 167, 69);"
                >
                    <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="text-left">
                        <!-- <v-icon medium color="green">mdi-gold</v-icon>  -->
                        <h5 style="font-size:28px; color:#888;"> {{ item.totalall2 }}  </h5>
                        <div> <small> Total Keping 3gr </small> </div>
                        <div> <small> Nomor Rak 3 : {{ item.total3 != null ? item.total3 : "0" }} </small> </div> 
                        <div> <small> Nomor Rak 4 : {{ item.total4 != null ? item.total4 : "0" }} </small> </div>
                </v-col>
            </v-card>
        </v-col>

        <v-col
            sm="2"
            md="2"
            style="margin-left:20px;">
            <v-card
                elevation="2"
                style="border-top: 3px solid rgb(40, 167, 69);"
                >
                    <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="text-left">
                        <!-- <v-icon medium color="green">mdi-gold</v-icon>  -->
                        <h5 style="font-size:28px; color:#888;"> {{ item.totalall3 }}  </h5>
                        <div> <small> Total Keping 5gr </small> </div>
                        <div> <small> Nomor Rak 5 : {{ item.total5 != null ? item.total5 : "0" }} </small> </div> 
                        <div> <small> Nomor Rak 6 : {{ item.total6 != null ? item.total6 : "0" }} </small> </div>
                </v-col>
            </v-card>
        </v-col>

        <v-col
            sm="2"
            md="2"
            style="margin-left:20px;">
            <v-card
                elevation="2"
                style="border-top: 3px solid rgb(40, 167, 69);"
                >
                    <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="text-left">
                        <!-- <v-icon medium color="green">mdi-gold</v-icon>  -->
                        <h5 style="font-size:28px; color:#888;"> {{ item.totalall4 }}  </h5>
                        <div> <small> Total Keping 10gr </small> </div>
                        <div> <small> Nomor Rak 7 : {{ item.total7 != null ? item.total7 : "0"  }} </small> </div> 
                        <div> <small> Nomor Rak 8 : {{ item.total8 != null ? item.total8 : "0"  }} </small> </div>
                </v-col>
            </v-card>
        </v-col>

        <v-col
            sm="2"
            md="2"
            style="margin-left:20px;">
            <v-card
                elevation="2"
                style="border-top: 3px solid rgb(40, 167, 69);"
                >
                    <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="text-left">
                        <!-- <v-icon medium color="green">mdi-gold</v-icon>  -->
                        <h5 style="font-size:28px; color:#888;"> {{ item.totalall5 }}  </h5>
                        <div> <small> Total Keping 25gr </small> </div>
                        <div> <small> Nomor Rak 9 : {{ item.total9 != null ? item.total9 : "0" }} </small> </div> 
                        <div> <small> Nomor Rak 10 : {{ item.total10 != null ? item.total10 : "0" }} </small> </div>
                </v-col>
            </v-card>
        </v-col>

    </v-row>

</v-container>

</template>


</div>
</template>

<script>
//import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      listTotalAll:this.$store.state.transaction.atmgold.listTotals,
      provinsiList:[],
      currentPage:1,
      listTotals:[],
      decimals:"10.00",
      keping2gr:0,
      keping3gr:0,
      keping5gr:0,
      keping10gr:0,
      icons:"chevron-right",
      statusPembayaran: [
            { text: "All Status", value: "" },
            { text: "Pending", value: 0 },
            { text: "Proses Pengiriman", value: 1 },
            { text: "Cancel", value: 2 }
            // { text: "Gagal", value: "2" },
      ],
      kepinggramasi: [
            { text: "2 GR", value: 2 },
            { text: "3 GR", value: 3 },
            { text: "5 GR", value: 5 },
            { text: "10 GR", value: 10 },
            { text: "25 GR", value: 25 }
            // { text: "Gagal", value: "2" },
      ],
      typetransaksi: [
            { text: "All Type", value: "" },
            { text: "NDP", value: "NDP" },
            { text: "SDP", value: "SDP" }
            // { text: "Gagal", value: "2" },
      ],
      thirdparty: [
            { text: "All", value: "all" },
            { text: "IDCASH", value: "idcash" }
            // { text: "Gagal", value: "2" },
      ],
      tipestock: [
            { text: "All", value: "all" },
            { text: "IN", value: "IN" },
            { text: "OUT", value: "OUT" },
            // { text: "Gagal", value: "2" },
      ],
      statusver: [
            { text: "All", value: "all" },
            { text: "Request Verfikasi", value: "false" },
            { text: "Terverifikasi", value: "true" }
            // { text: "Gagal", value: "2" },
      ],
      statusaddr: [
            { text: "All", value: "all" },
            { text: "Address + Current Address", value: "1" },
            { text: "Address", value: "2" },
            { text: "No Address", value: "3" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: '',
        keping2gr:0,
        keping3gr:0,
        keping5gr:0,
        keping10gr:0,
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{
        keping2gr:0,
        jumlahkeping:1,
        nomorrak:0,
      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Tanggal", value: "created_at" },
        { text: "Email", value: "user" },
        { text: "Tipe", value: "tipe" },
        { text: "Kode Mesin", value: "kodemesin" },
        { text: "Flag", value: "note" },
        { text: "TRX ID", value: "trx_id" },
        { text: "Gramasi", value: "gramasi" },
        { text: "Nomor Rak", value: "slot" },
        { text: "Stock", value: "stock" },
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      //alert("limits "+this.pagination.itemsPerPage);
      this.$store
        .dispatch("transaction/fetchMemberListGoldAtm", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            index:"",
            tipestock:this.searchForm.tipeStock,
            flag:this.searchForm.flag,
            trxId:this.searchForm.trx_id,
            emailmember:this.searchForm.emailmember,
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';

          this.listTotals = this.$store.state.transaction.goldatm.listTotals;

        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    postStockGold: function(dialog){
        this.$store.commit("main/setLoading", true);

        this.$store
            .dispatch("transaction/postAtmStockGold", {
                updateId: this.profiles.id,
                gramasi: this.formLock.kepingemas,
                slot: this.formLock.nomorrak,
                stock: this.formLock.jumlahkeping
            })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Update Data Stock Successfully");
        })
        .catch((res) => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
        });

    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        //this.$store.state.member.member.paging_page = this.pagination.page;
        //alert("tes");
        this.$store.commit("transaction/setMemberHisGoldPage", "detail");
        this.$store.commit("transaction/setMemberTrxHisGold", item);
        //console.log("ItemList" , item);
        this.loading = false;
        this.loadingtext = '';
    },

    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
      this.fetch(0);
  },
  watch: {
    pagination() {
        //alert("tess");
        //alert(this.$store.state.member.member.is_back);
        //if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        //}

        //if(this.$store.state.member.member.is_back == 1){
        //this.pagination.page = this.$store.state.member.member.paging_page;
        //}

       // this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  //components: { CustomCard },
};
</script>

<style>
</style>