<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

<CustomCard>

        <template v-slot:toolbar>
            
            Channel Pembayaran
            &nbsp;
            <!-- 
            <v-btn color="success" small outlined @click="filterToggle">
                filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn> 
            -->

        </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.tipe"
                            label="Search By Key"
                            small
                            clearable
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.subject"
                            label="Search By Subject"
                            small
                            clearable
                        >
                        </v-text-field>
                    </v-flex>    
                    
                </v-layout>

                

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>

                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

<template v-slot:datatable>
    <v-flex md12 style="overflow-x: scroll">

    <v-data-table
                :headers="headers"
                :items="$store.state.settings.channel.list"
                :options.sync="pagination"
                :server-items-length="$store.state.settings.channel.total"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

                <template v-slot:[`item.logos`]="{ item }">
                    <div style="padding:10px">

                        <img v-bind:src="item.icon" style="height:30px;" /> 
                        <div style="padding:10px 0; margin-top:10px; border-top:1px solid #666666;"></div>
                        {{ item.settlement_msg }}
                    </div>
                </template>

                <template v-slot:[`item.statusver`]="{ item }">
                    <div v-if="item.offline == 1" style="color:green;"> ON  </div>
                    <div v-if="item.offline == 0" style="color:red;"> OFF </div>
                </template>

                <template  v-slot:[`item.aksi`]="{ item }">

                    <v-container>

                        <v-row>
                        
                        <v-dialog
                            transition="dialog-top-transition"
                            max-width="600">

                            <template v-slot:activator="{ on, attrs }">

                                <v-col
                                    class="pa-2 font-weight-bold"
                                    outlined
                                    tile>
                                        
                                        <v-icon small 
                                        v-bind="attrs"
                                        color="success"
                                        v-on="on"
                                        style="padding:5px; margin-top:-3px;"
                                        @click="editTemplate(item)">
                                            mdi-pencil
                                        </v-icon> 
                                        
                                </v-col>
                                <!-- <span>Email Not Verified</span> -->

                            </template>
                                    
                            <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar
                                        color="success"
                                        dark
                                        >Edit Custom Variable </v-toolbar>

                                        <v-layout style="display:none;">

                                            <v-text-field
                                                v-model="formLock.edit_index_template"
                                                label=""
                                                hidden
                                                >
                                            </v-text-field>

                                        </v-layout>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="formLock.edit_channels"
                                                        label="Channel">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="formLock.edit_fitur"
                                                        label="Fitur">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <!-- <v-flex>
                                                    <v-text-field
                                                        v-model="formLock.edit_popup_status"
                                                        label="Popup Msg Status">
                                                    </v-text-field>
                                                </v-flex> -->
                                                <v-autocomplete
                                                            v-model="formLock.edit_popup_status"
                                                            :items="statusact"
                                                            item-text="text"
                                                            item-value="value"
                                                            label="Popup Msg Status"
                                                        >
                                                </v-autocomplete>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="formLock.edit_popup_message"
                                                        label="Popup Msg">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <!-- <v-text-field
                                                        v-model="formLock.edit_status"
                                                        label="Status">
                                                    </v-text-field> -->
                                                    <v-autocomplete
                                                            v-model="formLock.edit_status_dinaran"
                                                            :items="statusact"
                                                            item-text="text"
                                                            item-value="value"
                                                            label="Status"
                                                        >
                                                    </v-autocomplete>
                                                </v-flex>
                                            </v-layout>
                                        
                                    </v-container>
                                        

                                    <v-card-actions class="justify-end">
                                        
                                    <v-btn
                                        text
                                        @click="dialog.value = false"
                                    >Close</v-btn>
                                    <v-btn
                                        color="success"
                                        @click="editTemplatePost(dialog)"
                                    >Submit</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                                    
                        </v-dialog>

                    </v-row>
            </v-container>

            </template>

            </v-data-table>

        </v-flex>
    </template>
</CustomCard>

<CustomCard style="margin-top:30px;">

        <template v-slot:toolbar>
            
            Fitur Dinaran

        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">

            <v-data-table
                :headers="headersFitur"
                :items="$store.state.settings.channel.listFitur"
                :options.sync="pagination"
                :server-items-length="$store.state.settings.channel.totalFitur"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

                <template v-slot:[`item.logos`]="{ item }">
                    <img v-bind:src="item.icon" style="height:30px;" />
                </template>

                <template v-slot:[`item.statusver`]="{ item }">
                    <div v-if="item.offline == 1" style="color:green;"> ON  </div>
                    <div v-if="item.offline == 0" style="color:red;"> OFF </div>
                </template>

                <template  v-slot:[`item.aksi`]="{ item }">

                    <v-container>

                        <v-row>
                        
                        <v-dialog
                            transition="dialog-top-transition"
                            max-width="600">

                            <template v-slot:activator="{ on, attrs }">

                                <v-col
                                    class="pa-2 font-weight-bold"
                                    outlined
                                    tile>
                                        
                                        <v-icon small 
                                        v-bind="attrs"
                                        color="success"
                                        v-on="on"
                                        style="padding:5px; margin-top:-3px;"
                                        @click="editTemplateFitur(item)">
                                            mdi-pencil
                                        </v-icon> 
                                        
                                </v-col>
                                <!-- <span>Email Not Verified</span> -->

                            </template>
                                    
                            <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar
                                        color="success"
                                        dark
                                        >Update Status Fitur Dinaran</v-toolbar>

                                        <v-layout style="display:none;">

                                            <v-text-field
                                                v-model="formLock.list_index_template"
                                                label=""
                                                hidden
                                                >
                                            </v-text-field>

                                        </v-layout>

                                        <v-container>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="formLock.list_fitur"
                                                        label="Fitur">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="formLock.list_param"
                                                        label="Param">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>


                                            <v-layout>
                                                <v-flex>
                                                    <v-autocomplete
                                                            v-model="formLock.edit_fitur_status"
                                                            :items="statusact"
                                                            item-text="text"
                                                            item-value="value"
                                                            label="Status">
                                                    </v-autocomplete>
                                                </v-flex>
                                            </v-layout>
                                        
                                    </v-container>
                                        

                                    <v-card-actions class="justify-end">
                                        
                                    <v-btn
                                        text
                                        @click="dialog.value = false"
                                    >Close</v-btn>
                                    <v-btn
                                        color="success"
                                        @click="editChannelStatus(dialog)"
                                    >Submit</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                                    
                        </v-dialog>

                    </v-row>
            </v-container>

            </template>

            </v-data-table>

        </v-flex>
    </template>
  </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      statusact: [
        { text: "Active", value: 1 },
        { text: "Not Active", value: 0 }
    ],
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Channel", value: "channel" , align: "left"},
        { text: "Jenis", value: "name" , align: "left"},
        { text: "Logo", value: "logos" , align: "left"  , height:40},
        { text: "Status", value: "statusver" , align: "left"},
        // { text: "Value", value: "value" , align: "center"},
        { text: "Aksi", value: "aksi" , align: "left" , width:110}
      ],
      headersFitur: [
        { text: "Fitur", value: "name" , align: "left"},
        { text: "Channel", value: "channel" , align: "left"},
        { text: "Status", value: "statusver" , align: "left"},
        // { text: "Value", value: "value" , align: "center"},
        { text: "Aksi", value: "aksi" , align: "left" , width:110}
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    editTemplate(items){
        this.formLock.edit_index_template = items.id
        this.formLock.edit_channels       = items.channel
        this.formLock.edit_fitur          = items.name
        this.formLock.edit_code           = items.code
        this.formLock.edit_value          = items.value
        this.formLock.edit_popup_status   = items.settlement_status
        this.formLock.edit_popup_message  = items.settlement_msg
        this.formLock.edit_status_dinaran = items.offline
       // alert(items.settlement_status);
    },
    editTemplateFitur(items){
        this.formLock.list_index_template  = items.id
        this.formLock.list_fitur           = items.name
        this.formLock.list_param           = items.channel
        this.formLock.edit_fitur_status    = items.offline
    },
    add_data(){
        this.formLock.add_email = ""
        this.formLock.add_module = ""
    },
    editTemplatePost(dialog){
        //alert("posted");
        this.$store
            .dispatch("settings/openChannelBayar", {
                id                : this.formLock.edit_index_template,
                channel           : this.formLock.edit_channels,
                name              : this.formLock.edit_fitur,
                settlement_status : this.formLock.edit_popup_status,
                settlement_msg    : this.formLock.edit_popup_message,
                offline           : this.formLock.edit_status_dinaran,
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    editChannelStatus(dialog){
        this.$store
            .dispatch("settings/openChannelStatus", {
                id: this.formLock.list_index_template,
                name: this.formLock.list_fitur,
                param: this.formLock.list_param,
                status: this.formLock.edit_fitur_status
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    addSubmitVar(dialog){
        this.$store
            .dispatch("settings/addSubmitVar", {
                //find: params,
                type: this.formLock.custom_type,
                code: this.formLock.custom_code,
                value: this.formLock.custom_value
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    deleteTemplate(dialog , index){
         this.$store
            .dispatch("settings/deleteCustomVar", {
                //find: params,
                id: index,
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
            });
    },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      //alert("limits "+this.pagination.itemsPerPage);
      
      this.$store
        .dispatch("settings/fetchOpenChannelBal", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            key:this.searchForm.tipe,
            subject:this.searchForm.subject
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
    //this.fetchTotal("");
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        }

        if(this.$store.state.member.member.is_back == 1){
            this.pagination.page = this.$store.state.member.member.paging_page;
        }

        this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>