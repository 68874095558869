<template>
  <div>
    <v-container grid-list-xs fluid>
      <List v-show="$store.state.memberMain.main.page == 'list'"></List>
      <Detail v-show="$store.state.memberMain.main.page == 'detail'"></Detail>
    </v-container>
  </div>
</template>

<script>
import List from "./module/listData.vue";
import Detail from "./module/detailData.vue";
export default {
  components: {
    List,
    Detail,
  },
};
</script>

<style>
</style>