<template>
  <div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

    <CustomCard>

        <template v-slot:toolbar>
            <v-dialog
                transition="dialog-top-transition"
                max-width="600">

                    <template v-slot:activator="{ on, attrs }">

                        <v-col
                            class="pa-2 font-weight-bold"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            @click="add_data()"
                            tile>
                                
                                <v-btn color="success" small outlined>
                                    <v-icon>mdi-plus</v-icon> Add New Data 
                                </v-btn>
                                
                        </v-col>
                        <!-- <span>Email Not Verified</span> -->

                    </template>
                            
                    <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                color="success"
                                dark
                                >Add Template Format Email </v-toolbar>


                                <v-container>

                                    <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.add_name_new"
                                                label="Key"
                                                >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout>
                                        <v-flex>
                                            <v-text-field
                                                v-model="formLock.add_subject_new"
                                                label="Subject"
                                                >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout>
                                        <v-flex>
                                            <v-textarea
                                                outlined
                                                v-model="formLock.add_template_new"
                                                label="Template"
                                                ></v-textarea>
                                        </v-flex>
                                    </v-layout>
                                    
                                </v-container>
                                

                                <v-card-actions class="justify-end">
                                    
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="success"
                                    @click="addTemplatePost(dialog)"
                                >Submit Add</v-btn>
                                </v-card-actions>
                            </v-card>
                    </template>

            </v-dialog>

            &nbsp;

            <v-btn color="success" small outlined @click="filterToggle">
            filter <v-icon>mdi-chevron-down</v-icon>
            </v-btn>

        </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.tipe"
                            label="Search By Key"
                            small
                            clearable
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex>
                        <v-text-field
                            v-model="searchForm.subject"
                            label="Search By Subject"
                            small
                            clearable
                        >
                        </v-text-field>
                    </v-flex>    
                    
                </v-layout>

                

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>

                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>
            <v-flex md12 style="overflow-x: scroll">

            <v-data-table
                :headers="headers"
                :items="$store.state.settings.attributes.listEmailTemplate"
                :options.sync="pagination"
                :server-items-length="$store.state.settings.attributes.totalEmailTemplate"
                :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': 'Baris per Halaman:',
                }"
                :items-per-page="10"
                :loading="loading"
                :loading-text="loadingtext">

                    <template v-slot:[`item.keysub`]="{ item }">
                        <p></p>
                        <div style="font-size:18px;">  <b>{{ item.key }}</b> </div>
                        <div> <small> {{ item.subject }} </small> </div> <br />

                        <div style="display:block;padding:20px 0"><div style="display:block;margin:0 auto;padding:0;width:100%;min-width:250px;overflow:hidden">
                                    <table cellpadding="0" cellspacing="0" border="0" style="width:100%"><tbody><tr><td valign="top">
                                    <div style="padding:20px;background:#fff;border:1px solid #dedede;text-align:center">
                                    <img src="https://dinaran-gold.com/assets/images/dinaranimg/logodinaran-small.png" alt="Dinaran Logo!" align="center" width="200px" class="CToWUd">
                        </div>
                        <div style="padding:10px 3em;background:#fff;border:1px solid #dedede;border-top:none">
                            <!-- <div> <small> {{ item.template }} </small> </div> -->
                            <div v-html="item.template"></div>
                        
                        </div><div style="padding:20px 3em;background:#fff;border:1px solid #dedede;border-top:none;text-align:center">
                                            <img src="https://idcash.s3.amazonaws.com/google-play-badge-300x89.png" alt="dinaran Logo!" align="center" width="100px" class="CToWUd">
                                            </div><div style="padding:20px;background:#f1f1f1;font-size:11px;border:1px solid #dedede;border-top:none;text-align:center">
                                            <p><i><b>"Setiap Rupiah Anda Sekarang Bernilai Emas"</b></i></p>
                        Copyright © 2020 <span class="il">dinaran</span> &nbsp;&nbsp;|&nbsp;&nbsp; Website. www.dinaran.id &nbsp;&nbsp;|&nbsp;&nbsp; Instagram. Dinaranofficial</div></td></tr></tbody></table></div></div>

                    </template>

                    <template  v-slot:[`item.items`]="{ item }">

                    <v-container>

                        <v-row>
                        
                        <v-dialog
                            transition="dialog-top-transition"
                            max-width="600">

                                    <template v-slot:activator="{ on, attrs }">

                                        <v-col
                                            class="pa-2 font-weight-bold"
                                            outlined
                                            tile>
                                               
                                                <v-icon small 
                                                v-bind="attrs"
                                                color="success"
                                                v-on="on"
                                                style="padding:5px; margin-top:-3px;"
                                                @click="editTemplate(item)">
                                                    mdi-pencil
                                                </v-icon> 
                                                
                                        </v-col>
                                        <!-- <span>Email Not Verified</span> -->

                                    </template>
                                            
                                    <template v-slot:default="dialog">
                                            <v-card>
                                                <v-toolbar
                                                color="success"
                                                dark
                                                >Edit Template Format Email </v-toolbar>

                                                <v-layout style="display:none;">

                                                    <v-text-field
                                                        v-model="formLock.edit_index_template"
                                                        label=""
                                                        hidden
                                                        >
                                                    </v-text-field>

                                                </v-layout>

                                                <v-container>

                                                    <v-layout>
                                                        <v-flex>
                                                            <v-text-field
                                                                v-model="formLock.edit_name_new"
                                                                label="Key"
                                                                >
                                                            </v-text-field>
                                                        </v-flex>
                                                    </v-layout>

                                                    <v-layout>
                                                        <v-flex>
                                                            <v-text-field
                                                                v-model="formLock.edit_subject_new"
                                                                label="Subject"
                                                                >
                                                            </v-text-field>
                                                        </v-flex>
                                                    </v-layout>

                                                    <v-layout>
                                                        <v-flex>
                                                            <v-textarea
                                                                outlined
                                                                v-model="formLock.edit_template_new"
                                                                label="Template"
                                                                ></v-textarea>
                                                        </v-flex>
                                                    </v-layout>
                                                    
                                                </v-container>
                                                

                                                <v-card-actions class="justify-end">
                                                    
                                                <v-btn
                                                    text
                                                    @click="dialog.value = false"
                                                >Close</v-btn>
                                                <v-btn
                                                    color="success"
                                                    @click="editTemplatePost(dialog)"
                                                >Submit</v-btn>
                                                </v-card-actions>
                                            </v-card>
                            </template>
                                    
                        </v-dialog>

                        <v-dialog
                            transition="dialog-top-transition"
                            max-width="600">
                            
                            <template v-slot:activator="{ on, attrs }">

                            <v-btn
                                color="red"
                                icon
                                v-bind="attrs"
                                style="padding:5px"
                                v-on="on">
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>

                            </template>
                            <span>Delete Email Template Form</span>
                            <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar
                                color="red"
                                dark
                                >Delete  Template ? </v-toolbar>
                                
                                <v-container>

                                    <v-layout>
                                        <v-flex>
                                            Yakin akan hapus template ini ?
                                        </v-flex>
                                    </v-layout>

                                </v-container>

                                <v-card-actions class="justify-end">
                                    
                                <v-btn
                                    text
                                    @click="dialog.value = false"
                                >Close</v-btn>
                                <v-btn
                                    color="red"
                                    style="color:#ffffff;"
                                    @click="deleteTemplate(dialog , item.id)"
                                >Submit</v-btn>
                                </v-card-actions>
                            </v-card>
                            </template>
                        </v-dialog>

                        </v-row>

                    </v-container>

                    </template>
                    <!-- <template v-slot:[`item.created_at_formatted`]="{ item }">
                        <div> {{ item.created_at | moment("add", "-1 hours") | moment("YYYY-MM-DD HH:mm:ss") }} </div>
                    </template>

                    <template v-slot:[`item.log_items`]="{ item }">
                        <div>       
                               <small> {{ item.before}} </small>
                               <hr />
                               <small> {{ item.after}} </small>
                        </div>
                    </template> -->

                    {{ someDate | moment("YYYY") }}

            </v-data-table>

        </v-flex>
    </template>
  </CustomCard>

  </div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";


export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Key & Subject & Template", value: "keysub" , align: "center"},
        // { text: "Template", align: "left", value: "templates" , width:'85%'},
        { text: "Action", align: "left", value: "items" , width:110}
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    editTemplate(items){
        this.formLock.edit_index_template = items.id
        this.formLock.edit_name_new = items.key
        this.formLock.edit_subject_new = items.subject
        this.formLock.edit_template_new = items.template
        //alert(index);
    },
    add_data(){
        this.formLock.add_name_new = ""
        this.formLock.add_subject_new = ""
        this.formLock.add_template_new = ""
    },
    editTemplatePost(dialog){
        //alert("posted");
        this.$store
            .dispatch("settings/postEmailTemplate", {
                //find: params,
                index: this.formLock.edit_index_template,
                key: this.formLock.edit_name_new,
                subject: this.formLock.edit_subject_new,
                template: this.formLock.edit_template_new,
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    addTemplatePost(dialog){
        this.$store
            .dispatch("settings/addEmailTemplate", {
                //find: params,
                key: this.formLock.add_name_new,
                subject: this.formLock.add_subject_new,
                template: this.formLock.add_template_new,
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
        });
    },
    deleteTemplate(dialog , index){
         this.$store
            .dispatch("settings/deleteEmailTemplate", {
                //find: params,
                id: index,
            })
            .then(() => {
                this.fetch(1);
                dialog.value = false;
               // this.provinsiList = this.$store.state.wilayah.wilayah.list;
            })
            .catch((res) => {
                console.log("status" , res);
            });
    },
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      //alert("limits "+this.pagination.itemsPerPage);
      
      this.$store
        .dispatch("settings/fetchEmailTemplate", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            key:this.searchForm.tipe,
            subject:this.searchForm.subject
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        this.$store.state.member.member.paging_page = this.pagination.page;
        this.$store.commit("member/setMemberPage", "detail");
        this.$store.commit("member/setMemberDetail", item.id);
        this.loading = false;
        this.loadingtext = '';
    },
    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
    //this.fetchTotal("");
  },
  watch: {
    pagination() {
        //alert(this.$store.state.member.member.is_back);
        if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        }

        if(this.$store.state.member.member.is_back == 1){
            this.pagination.page = this.$store.state.member.member.paging_page;
        }

        this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>