import { apiFetchWebAdmin , apiFetchSubmitPages , apiDeletePages , apiFetchWebPopUp , apiFetchWebPages , apiEditPopUpAdmin , apiPostPopUpAdmin , apiEditAddNotify, apiUploadWebAdmin , apiFetchNotifyApp , apiPostAddNotify } from "../../api/webadmin"
 
const namespaced = true

const defaultState = () => {
    return {
        attributes: {
            detail: {},
            listPages:[],
            listMedia:[],
            listNotifyApp:[],
            listPopUp:[],
            DetailTrxModule:[],
            pagesItem:[],
            total:0,
            totalNotify:0,
            is_back:0,
            totalMediaTemplate:0,
            pageList:"list",
            pageListAction:"",
            pageMedia:"list",
            pageNotifyApp:"list",
            pageWebAdmin:"list",
            status:""
        },
        settlement: {
            list: [],
            detail: {}
        }
    }
}



const state = defaultState()

const mutations = {
    setStatus: (state, payload) => {
        state.attributes.status = payload
    },
    setPagesItem: (state, payload) => {
        state.attributes.pagesItem = payload
    },
    setPagesState: (state, payload) => {
        state.attributes.pageList = payload
    },
    setMediaList: (state, payload) => {
        state.attributes.listMedia = payload
    },
    setPageMedia: (state, payload) => {
        state.attributes.pageMedia = payload
    },
    setPageListAction: (state, payload) => {
        state.attributes.pageListAction = payload
    },
    setPopUpList: (state, payload) => {
        state.attributes.listPopUp = payload
    },
    setPagesList: (state, payload) => {
        state.attributes.listPages = payload
    },
    setNotifyApp: (state, payload) => {
        state.attributes.listNotifyApp = payload
    },
    setPageWebAdmin: (state, payload) => {
        state.attributes.listNotifyApp = payload
    },
}

const actions = {
    fetchWebAdmin({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchWebAdmin(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMediaList', res.data.data);

                        state.attributes.total = res.data.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchWebNotifyApp({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchNotifyApp(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setNotifyApp', res.data.data);

                        state.attributes.totalNotify = res.data.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchWebPages({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchWebPages(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setPagesList', res.data.data);

                        state.attributes.total = res.data.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchSubmitPages({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchSubmitPages(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    fetchWebPopUp({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchWebPopUp(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setPopUpList', res.data.data);

                        state.attributes.total = res.data.total
                        //alert(res.data.total);
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    deletePages({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiDeletePages(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postWebAdmin({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiUploadWebAdmin(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postPopUpNew({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPostPopUpAdmin(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editPopUpNew({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiEditPopUpAdmin(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    postAddNotify({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiPostAddNotify(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
    editAddNotify({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiEditAddNotify(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setStatus', "success");
                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
}

export default {
    namespaced, state, mutations, actions
}