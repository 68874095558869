<template>
  <v-expansion-panels v-model="panel" multiple>
    <v-expansion-panel expand focusable>
      <v-expansion-panel-content>
        <v-layout>
          <v-flex>
            <v-text-field
              v-model="form.member"
              label="Search By Email / Nama / Phone"
              single-line
              small
              clearable
            >
            </v-text-field>
          </v-flex>
          <v-flex>
            <v-autocomplete
              v-model="form.type"
              :items="type"
              item-text="text"
              item-value="value"
              label="Transaction Type"
            >
            </v-autocomplete>
          </v-flex>
          <v-flex>
            <v-autocomplete
              v-model="form.status"
              :items="status"
              item-text="text"
              item-value="value"
              label="Status"
            >
            </v-autocomplete>
          </v-flex>
          <v-flex>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Register Date"
                  prepend-icon="mdi-calendar"
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.date" no-title scrollable range>
              </v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>

        <v-layout align-end justify-end>
          <v-btn color="warning" @click="clearForm()" small depressed>
            Clear
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small depressed color="success" class="m0" @click="search()">
            <v-icon>mdi-magnify</v-icon> Search
          </v-btn>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    panel: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      form: {},
      menu: null,
      status: [
        { text: "All Status", value: "" },
        { text: "Menunggu Pembayaran", value: "0" },
        { text: "Berhasil", value: "1" },
        { text: "Gagal", value: "2" },
        // { text: "Menunggu Settlement", value: "3" },
        { text: "Menunggu Settlement Komisi", value: "4" },
        // { text: "Gagal", value: "2" },
      ],
      type: [
        { text: "All Type", value: "" },
        { text: "Pembelian", value: "1" },
        { text: "Setor Emas", value: "10" },
        { text: "Refund IdCash", value: "11" },
        { text: "Reward Dinaran Games", value: "12" },
        { text: "Reward Dinaran Affiliate", value: "13" },
        { text: "Penjualan", value: "2" },
        { text: "Beri Emas", value: "3" },
        { text: "Fee Pembelian", value: "4" },
        { text: "Fee Penjualan", value: "5" },
        { text: "Ambil Emas", value: "6" },
        { text: "Fee Ambil Emas", value: "7" },
        { text: "Ongkir Ambil Emas", value: "8" },
        { text: "Komisi", value: "9" },
      ],
      date: "",
    };
  },
  methods: {
    search() {
      this.$store.commit("main/setLoading", true);
      this.$store.commit("trxmember/setSearch", this.form);
      this.$store
        .dispatch("trxmember/fetchTrx")
        .then(() => {
          this.$store.commit("main/setLoading", false);
        })
        .catch((res) =>
          this.$store.commit("main/setSnackbarFail", res.response.data.message)
        );
    },
    clearForm() {
      this.form = {};
    },
  },
  mounted() {},
  computed: {
    dateRangeText() {
      if (typeof this.form.date != "undefined") {
        if (this.form.date.length > 1) {
          return this.form.date.join(" - ");
        } else {
          return this.form.date[0];
        }
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
</style>